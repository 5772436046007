import { createSlice } from "@reduxjs/toolkit";
import { loading } from "../../helpers/loading";

const initialState = {
  loading: loading.INIT,
  error: null,
  team_id: null,
  teamSaved: false,
  InvitationLoading: loading.INIT,
  sentInvites: [],

  allTeams: [],
  teamLoading: loading.INIT,
  teamMembers: [],
  teamMemberAction: [],

  teamChallanges: [],
  team: loading.INIT,
  teamData: {},
};

export const teamSlice = createSlice({
  name: "Teams",
  initialState,
  reducers: {
    /*create team  */
    createTeamStart: (state) => {
      state.loading = loading.PENDING;
      state.teamSaved = false;
    },
    createTeamCompleted: (state, { payload }) => {
      state.loading = loading.SUCSUSS;
      state.team_id = payload;
      state.teamSaved = true;
    },
    createTeamError: (state) => {
      state.loading = loading.ERROR;
    },

    /*Edit team  */
    editTeamStart: (state) => {
      state.loading = loading.PENDING;
      state.teamSaved = false;
    },
    editTeamCompleted: (state, { payload }) => {
      state.loading = loading.SUCSUSS;
      state.team_id = payload;
      state.teamSaved = true;
    },
    editTeamError: (state) => {
      state.loading = loading.ERROR;
    },

    /*Get team by Id  */
    getTeamByIdStart: (state) => {
      state.loading = loading.PENDING;
    },
    getTeamByIdCompleted: (state, { payload }) => {
      state.loading = loading.SUCSUSS;
      state.teamData = payload;
    },
    getTeamByIdError: (state) => {
      state.loading = loading.ERROR;
    },

    /*Email Invitation  */
    sendInviteeEmailStart: (state) => {
      state.InvitationLoading = loading.PENDING;
    },
    sendInviteeEmailCompleted: (state, { payload }) => {
      state.InvitationLoading = loading.SUCSUSS;
      state.sentInvites = payload.sentInvites;
    },
    sendInviteeEmailError: (state) => {
      state.InvitationLoading = loading.ERROR;
    },
    /*Get all team by user id*/
    getTeamsForUserIDStart: (state) => {
      state.teamLoading = loading.PENDING;
    },
    getTeamsForUserIDCompleted: (state, { payload }) => {
      state.teamLoading = loading.SUCSUSS;
      state.allTeams = payload;
    },
    getTeamsForUserIDError: (state) => {
      state.teamLoading = loading.ERROR;
    },

    /*Get team by user id*/
    getTeamMembersStart: (state) => {
      state.teamLoading = loading.PENDING;
    },
    getTeamMembersCompleted: (state, { payload }) => {
      state.teamLoading = loading.SUCSUSS;
      state.teamMembers = payload;
    },
    getTeamMembersError: (state) => {
      state.teamLoading = loading.ERROR;
    },

    /*Put team member actions*/
    putTeamMembersActionStart: (state) => {
      state.teamLoading = loading.PENDING;
    },
    putTeamMembersActionCompleted: (state, { payload }) => {
      state.teamLoading = loading.SUCSUSS;
      state.teamMemberAction = payload;
    },
    putTeamMembersActionError: (state) => {
      state.teamLoading = loading.ERROR;
    },

    /*Get all challenge of Team  */
    getTeamsChallangesStart: (state) => {
      state.teamLoading = loading.PENDING;
    },
    getTeamsChallangesCompleted: (state, { payload }) => {
      state.teamLoading = loading.SUCSUSS;
      state.allTeams = payload;
    },
    getTeamsChallangesError: (state) => {
      state.teamLoading = loading.ERROR;
    },
    resetTeamStates: (state) => {
      state.teamSaved = initialState.teamSaved;
      state.teamMembers = initialState.teamMembers;
    },
  },
});

export const {
  /*create team  */
  createTeamStart,
  createTeamCompleted,
  createTeamError,

  // Edit team
  editTeamStart,
  editTeamCompleted,
  editTeamError,

  // Get Team By Id
  getTeamByIdStart,
  getTeamByIdCompleted,
  getTeamByIdError,

  /*Email Invitation  */
  sendInviteeEmailStart,
  sendInviteeEmailCompleted,
  sendInviteeEmailError,

  /*Get all team by user id*/
  getTeamsForUserIDStart,
  getTeamsForUserIDCompleted,
  getTeamsForUserIDError,

  /*Get team members by user id*/
  getTeamMembersStart,
  getTeamMembersCompleted,
  getTeamMembersError,

  /*Put team members actions*/
  putTeamMembersActionStart,
  putTeamMembersActionCompleted,
  putTeamMembersActionError,

  /*Get all challenge of Team  */
  getTeamsChallangesStart,
  getTeamsChallangesCompleted,
  getTeamsChallangesError,

  // Reset all states
  resetTeamStates,
} = teamSlice.actions;

export default teamSlice.reducer;
