import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ChallengeMenu from '../../../Components/ChallengeMenu';
import Styles from './createChallenge.module.scss';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Basic from '../../../Components/ChallengeTabs/Basic';
import Content from '../../../Components/ChallengeTabs/Content';
import Design from '../../../Components/ChallengeTabs/Design';
import DataSubmission from '../../../Components/ChallengeTabs/DataSubmission';
import { challengeSchema } from '../../../../helpers/schemas';
import { Form, Switch } from 'formik-antd';
import { Formik } from 'formik';
import { ChallengeObj } from '../../../../models/challenge';
import Eye from '../../../../asset/icons/eye.svg';
import {
  getCompetitionCategories,
  getCompetitionTypes,
  getParticipationTypes,
  getScoringMetrics
} from '../../../../redux-store/actions/lookUpAction';
import {
  createChallenge,
  editChallenge,
  getChallengeById,
  publishChallenge,
  resetChallengeForm,
  resetChallengeId,
  resetErrorTab,
  resetPublish,
  resetSpecificErrorTab,
  selectMenuAction,
  setErrorTab,
  unPublishChallenge,
  updateChallengeStatus
} from '../../../../redux-store/actions/ChallengeAction';
import Notify from '../../../../theme/notifications';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { notify } from '../../../../theme/antNotify';
import Icon from '../../../../components/Icons';
import Back from '../../../../components/Back';
import { useTranslation } from 'react-i18next';
import { Prizes } from '../../../../models/challenge/Prizes';
import cleanDeep from 'clean-deep';
import { PrizePoints } from '../../../../models/challenge/PrizePoints';
import { PrizeRanking } from '../../../../models/challenge/PrizeRanking';
import { setScoringEvaluation } from '../../../../redux-store/actions/utilsAction';
import { getSponsorListings } from '../../../../redux-store/actions/sponsorAction';
import { buildParams } from '../../../../utils';

const antIcon = <LoadingOutlined style={{ fontSize: 22 }} spin />;
const antIconBlue = <LoadingOutlined style={{ fontSize: 28, color: '#5f76ff' }} spin />;

const Challenge = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const sponsorFilters = {};
  const [challengeId, setChallengeId] = useState(params.challengeId);
  const [thumbnailImageId, setThumbnailImageId] = useState(null);
  const [coverImageId, setCoverImageId] = useState(null);
  const [solutionFileId, setSolutionFileId] = useState(null);
  const [dataSetFileId, setDataSetFileId] = useState(null);
  const [dataSetFile, setDataSetFile] = useState(null);
  const [solutionFile, setSolutionFile] = useState(null);
  const [mainDescription, setMainDescription] = useState({});
  const [rules, setRules] = useState({});
  const [evaluation, setEvaluation] = useState({});
  const [prizeDescription, setPrizeDescription] = useState({});
  const [participationMessage, setParticipationMessage] = useState({});
  const history = useHistory();
  const [publishState, setPublishState] = useState(false);
  const { requestObject, loading, publishStatus } = useSelector((state) => state.challengePayload);
  const { loading: challengeLoading, challenge_id } = useSelector((state) => state.createChallenge);

  const { basic, design, content, submission } = useSelector((state) => state.challengeTabError);

  const { listings: scoringMetrics } = useSelector((state) => state.scoringMetrics);

  const { error, loading: publishLoading } = useSelector((state) => state.publishChallenge);
  const [challengePayload, setChallengePayload] = useState(new ChallengeObj());

  useEffect(() => {
    if (params) {
      setChallengeId(params.challengeId);
    }
    sponsorFilters.is_my_company = true;
    let sponsorParams = buildParams(sponsorFilters);
    dispatch(getSponsorListings(sponsorParams));
    dispatch(getCompetitionCategories());
    dispatch(getCompetitionTypes());
    dispatch(getParticipationTypes());
    dispatch(getScoringMetrics());
    setCoverImageId(null);
    setThumbnailImageId(null);

    setDataSetFileId(null);
    setSolutionFileId(null);

    dispatch(selectMenuAction('basic'));
    return () => {
      // dispatch(resetChallengeId());
      // dispatch(resetChallengeForm());
      dispatch(setScoringEvaluation('auto'));
      dispatch(resetErrorTab());
      dispatch(resetPublish());
    };
  }, []);

  useEffect(() => {
    if (challengeId) {
      dispatch(getChallengeById(challengeId));
    }
  }, [challengeId]);

  const selectedMenu = useSelector((state) => state.challengeMenu.menu, shallowEqual);

  const { scoringEvaluation } = useSelector((state) => state.utils);

  useEffect(() => {
    if (error && error?.type == 'ERROR_PUBLISH_CHALLENGE') {
      setPublishState(false);
      let { fields } = error?.errorObj;

      if (fields && fields.length && fields.length > 0) {
      }
      if (!challengePayload.cover_image_id || !challengePayload.thumbnail_image_id) {
        dispatch(setErrorTab('design'));
      }
      if (
        !challengePayload.challengeproperty.data_set_file_id ||
        !challengePayload.evaluation_function_id
      ) {
        dispatch(setErrorTab('submission'));
      }
    } else {
      setPublishState(true);
    }
  }, [error]);

  useEffect(() => {
    if (scoringMetrics) {
      if (requestObject?.evaluation_function_id) {
        const scoringMetric = scoringMetrics.filter(
          ({ evaluation_function_id }) =>
            evaluation_function_id == requestObject.evaluation_function_id
        )[0];
        requestObject.scoring_metric_temp = scoringMetric?.evaluation_function_name;
      }
    }
  }, [scoringMetrics]);

  useEffect(() => {
    if (thumbnailImageId && coverImageId) {
      dispatch(resetSpecificErrorTab('design'));
    }
    if (dataSetFileId) {
      dispatch(resetSpecificErrorTab('submission'));
    }
  }, [thumbnailImageId, coverImageId, dataSetFileId]);

  useEffect(() => {
    if (requestObject && challengeId) {
      setThumbnailImageId(requestObject.thumbnail_image_id);
      setCoverImageId(requestObject.cover_image_id);

      // if(requestObject.thumbnailImageId)

      if (!requestObject.thumbnail_image_id) {
        dispatch(setErrorTab('design'));
      }
      if (!requestObject.cover_image_id) {
        dispatch(setErrorTab('design'));
      }
      if (!requestObject.challengeproperty.data_set_file_id) {
        dispatch(setErrorTab('submission'));
      }

      setDataSetFileId(requestObject.challengeproperty.data_set_file_id);
      setSolutionFileId(requestObject.challengeproperty.solution_file_id);

      setMainDescription(requestObject.challengeproperty.competition_details);
      setEvaluation(requestObject.challengeproperty.competition_evaluation_description);
      setPrizeDescription(requestObject.challengeproperty.competition_prize_description);
      setParticipationMessage(requestObject.challengeproperty.participation_message);
      setRules(requestObject.challengeproperty.competition_rules_description);
      setPublishState(requestObject.is_published);
      requestObject.challengeproperty.start_time = requestObject.challengeproperty.start_date;
      requestObject.challengeproperty.end_time = requestObject.challengeproperty.end_date;
      requestObject.challengeproperty.participation_type =
        requestObject.challengeproperty?.participation_type?.toString();

      if (requestObject.prizeSet.prizes == null) {
        requestObject.prizeSet.prizes = new Prizes();
      }

      if (!requestObject.prizeSet.prizes.prize_ranking) {
        requestObject.prizeSet.prizes.prize_ranking = [];
        requestObject.prizeSet.prizes.prize_ranking.push(new PrizeRanking());
      }

      if (!requestObject.prizeSet.prizes.prize_points) {
        requestObject.prizeSet.prizes.prize_points = [];
        requestObject.prizeSet.prizes.prize_points.push(new PrizePoints());
      }

      dispatch(setScoringEvaluation(requestObject?.is_auto_calculate ? 'auto' : 'manual'));

      if (challengeId) {
        dispatch(updateChallengeStatus(requestObject?.is_published));
        setChallengePayload(requestObject);
      }
    }
  }, [requestObject]);

  const submitChallenge = async (values, { setSubmitting }, errors) => {
    try {
      const challengePayload = new ChallengeObj().deserialize(values);
      challengePayload.thumbnail_image_id = thumbnailImageId;
      challengePayload.cover_image_id = coverImageId;

      challengePayload.challengeproperty.data_set_file_id = dataSetFileId;
      challengePayload.challengeproperty.solution_file_id = solutionFileId;

      challengePayload.challengeproperty.competition_details = mainDescription;
      challengePayload.challengeproperty.competition_rules_description = rules;
      challengePayload.challengeproperty.competition_evaluation_description = evaluation;
      challengePayload.challengeproperty.competition_prize_description = prizeDescription;
      challengePayload.challengeproperty.participation_message = participationMessage;
      if (
        selectedMenu == 'basic' &&
        challengePayload?.challengeproperty?.is_limited_participation &&
        !challengePayload?.challengeproperty?.limit_participation_file_name
      ) {
        notify('error', 'Please upload a csv file to limit participants.');
        return;
      }

      if (challengeId && selectedMenu == 'design' && !challengePayload.thumbnail_image_id) {
        dispatch(setErrorTab('design'));
        notify('error', 'Please add Thumbnail image');
        return;
      }
      if (challengeId && selectedMenu == 'design' && !challengePayload.cover_image_id) {
        dispatch(setErrorTab('design'));
        notify('error', 'Please add Challenge cover image');
        return;
      }

      if (
        challengeId &&
        selectedMenu == 'submission' &&
        !challengePayload.challengeproperty.data_set_file_id
      ) {
        dispatch(setErrorTab('submission'));
        notify('error', 'Please add Dataset file');
        return;
      }

      if (
        challengeId &&
        selectedMenu == 'submission' &&
        !challengePayload.challengeproperty.solution_file_id &&
        scoringEvaluation == 'auto'
      ) {
        dispatch(setErrorTab('submission'));
        notify('error', 'Please add Solution file');
        return;
      }

      if (!challengeId) dispatch(createChallenge(challengePayload));
      else {
        challengePayload.competition_id = challengeId;

        if (!challengePayload.cover_image_id || !challengePayload.thumbnail_image_id) {
          dispatch(setErrorTab('design'));
        } else {
          dispatch(resetErrorTab());
        }

        if (
          !challengePayload.challengeproperty.data_set_file_id ||
          !challengePayload.evaluation_function_id
        ) {
          dispatch(setErrorTab('submission'));
        } else {
          dispatch(resetErrorTab());
        }

        dispatch(editChallenge(challengePayload));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (challenge_id) {
      setChallengeId(challenge_id);
    }
  }, [challenge_id]);

  const handleSubmit = () => {};

  const onChallengeStatusChange = (isPublish) => {
    if (isPublish) {
      const requestObject = new ChallengeObj().deserialize(challengePayload);

      dispatch(publishChallenge(requestObject));
    } else {
      dispatch(unPublishChallenge(challengeId));
    }
  };

  const routeToPreview = () => {
    history.push(`/admin/challenge/${challengeId}/preview`);
  };

  return (
    <div className={Styles.challengeCrudPage}>
      <Formik
        initialValues={challengePayload}
        enableReinitialize={true}
        validationSchema={challengeSchema}
        onSubmit={submitChallenge}>
        {({ values, errors, isValid, touched, setFieldValue }) => (
          <>
            <div className={Styles.title}>
              <Back
                link={'/admin/challenges'}
                onClick={() => {
                  dispatch(resetChallengeId());
                  dispatch(resetChallengeForm());
                }}
              />
              <h2>
                {!params.challengeId
                  ? t('odp-ad.challenge.create-basics-tab.create-challenge-')
                  : t('odp-ad.challenge.leaderboard-participants-submission.edit-challenge')}
              </h2>
              <div></div>
            </div>

            {loading && (
              <div className="d-flex justify-content-center mt-3 position-absolute start-0 w-100">
                <Spin indicator={antIconBlue} />
              </div>
            )}
            {(basic || design || content || submission) && (
              <div className="d-flex justify-content-center mt-4">
                <Notify
                  msg={t(
                    'odp-ad.challenge.create-alert-messages.some-fields-are-missing-you-need-to-com'
                  )}
                  className={Styles.error}
                  twoToneColor="#eb2f2f"
                />
              </div>
            )}
            {challengeId && requestObject && (
              <div className={`d-flex justify-content-between mb-3 ${Styles.previewContainer}`}>
                <div className={`switch-wrapper ${Styles.switchContainer}`}>
                  <span className={Styles.statusLabel}>{t('odp-ad.sponsor.create.status')}</span>
                  <span className="show">{t('odp-ad.sponsor.create.draft')}</span>
                  <Switch
                    name="is_published"
                    className={`mr-2 ${Styles.switch}`}
                    onChange={onChallengeStatusChange}
                    loading={publishLoading}
                    checked={publishStatus}
                  />
                  <span className="show">{t('odp-ad.sponsor.create.online')}</span>
                  {(basic || design || content || submission) && (
                    <span style={{ marginLeft: '15px' }}>
                      *you need to complete all required fields in order to publish the challenge{' '}
                    </span>
                  )}
                </div>
                <Button
                  className={Styles.btnPreview}
                  onClick={routeToPreview}
                  icon={<Icon src={Eye} />}>
                  <span>Preview challenge</span>
                </Button>
              </div>
            )}
            <ChallengeMenu
              challengeId={challengeId}
              isContentHasError={content}
              isSubmissionHasError={submission}
              isDesignHasError={design}
              isFormValid={isValid}
              selectedMenu={selectedMenu}
              style={{
                marginTop: challengeId && requestObject ? '20px' : '56px'
              }}
            />
            <Form onSubmit={handleSubmit}>
              <div className={Styles.challengeTabContainer}>
                {selectedMenu == 'basic' ? (
                  <Basic
                    errors={errors}
                    touched={touched}
                    challenge={values}
                    values={values}
                    evaluationFunctionId={values?.evaluation_function_id}
                    setFieldValue={setFieldValue}
                  />
                ) : selectedMenu == 'design' ? (
                  <Design
                    errors={errors}
                    setThumbnail={setThumbnailImageId}
                    setCover={setCoverImageId}
                    coverImageId={coverImageId}
                    thumbnailImageId={thumbnailImageId}
                  />
                ) : selectedMenu == 'content' ? (
                  <Content
                    errors={errors}
                    setMainDescription={setMainDescription}
                    setEvaluation={setEvaluation}
                    setPrizeDescription={setPrizeDescription}
                    setParticipationMessage={setParticipationMessage}
                    setRules={setRules}
                    mainDescription={mainDescription}
                    evaluation={evaluation}
                    prizeDescription={prizeDescription}
                    participationMessage={participationMessage}
                    rules={rules}
                    values={values}
                  />
                ) : selectedMenu == 'submission' ? (
                  <DataSubmission
                    errors={errors}
                    challenge={values}
                    values={values}
                    setSolutionFileId={setSolutionFileId}
                    setDataSetFileId={setDataSetFileId}
                    solutionFileId={solutionFileId}
                    dataSetFileId={dataSetFileId}
                    dataSetFile={dataSetFile}
                    solutionFile={solutionFile}
                    setDataSetFile={setDataSetFile}
                    setSolutionFile={setSolutionFile}
                    evaluationFunctionId={values?.evaluation_function_id}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <></>
                )}
                <div className={Styles.actions}>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      history.push('/admin/challenges');
                    }}>
                    {t('odp-ad.challenge.leaderboard-participants-submission.cancel')}
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {!challengeLoading ? (
                      `${t('odp-pu.label.profile.save')}`
                    ) : (
                      <Spin indicator={antIcon} />
                    )}
                  </button>
                </div>
                <div className={Styles.footer}>
                  <p>
                    {t('odp-ad.challenge.create-basics-tab.need-help-contact-us-at')}{' '}
                    <a className="fw-bold" href="mailto:info@opendataplayground.com">
                      {t('odp-ad.challenge.create-basics-tab.email')}
                    </a>
                  </p>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Challenge;
