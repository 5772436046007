import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Hero from '../../components/Hero';
import Stats from '../../components/Stats';
import LandingPageChallenges from '../../components/LandingPageChallenges';
import HomeLeaderBoard from '../../components/HomeLeaderBoard';
import queryString from 'query-string';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Styles from './landingPage.module.scss';
import HeroInfoCard from '../../components/HeroInfoCard';
import Join from '../../asset/images/join-hero.png';
import Organize from '../../asset/images/organize-hero.png';
import Train from '../../asset/images/train-hero.png';
import sponnsor1 from '../../asset/images/sponsors-logo/nexi.jpg';
import sponnsor2 from '../../asset/images/sponsors-logo/sap.jpg';
import sponnsor3 from '../../asset/images/sponsors-logo/illimity.jpg';
import sponnsor4 from '../../asset/images/sponsors-logo/axa.jpg';
import sponnsor5 from '../../asset/images/sponsors-logo/credimi.jpg';
import sponnsor6 from '../../asset/images/sponsors-logo/osn.jpg';
import sponnsor7 from '../../asset/images/sponsors-logo/docebo.jpg';
import sponnsor8 from '../../asset/images/sponsors-logo/rgi.jpg';
import sponnsor9 from '../../asset/images/sponsors-logo/cuebiq.jpg';
import sponnsor10 from '../../asset/images/sponsors-logo/cellularline.jpg';
import sponnsor11 from '../../asset/images/sponsors-logo/conTe.jpg';
import sponnsor12 from '../../asset/images/sponsors-logo/venchi.jpg';
import sponnsor13 from '../../asset/images/sponsors-logo/minsait.jpg';
import BackgroundBlob from '../../asset/images/background-blobs.png';
import sponnsor14 from '../../asset/images/sponsors-logo/bee.jpg';
import Sponsors from '../../components/Sponsors/sponsors';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([EffectFade, Navigation, Pagination]);
const Sponsor = {
  sponsors: [
    {
      id: 1,
      image: sponnsor1
    },
    {
      id: 2,
      image: sponnsor2
    },
    {
      id: 3,
      image: sponnsor3
    },
    {
      id: 4,
      image: sponnsor4
    },
    {
      id: 5,
      image: sponnsor5
    },
    {
      id: 6,
      image: sponnsor6
    },
    {
      id: 7,
      image: sponnsor7
    },
    {
      id: 8,
      image: sponnsor8
    },
    {
      id: 9,
      image: sponnsor9
    },
    {
      id: 10,
      image: sponnsor10
    },
    {
      id: 11,
      image: sponnsor11
    },
    {
      id: 12,
      image: sponnsor12
    },
    {
      id: 13,
      image: sponnsor13
    },
    {
      id: 14,
      image: sponnsor14
    }
  ]
};

const LandingPage = () => {
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();

  const prevRefNum = useRef(null);
  const nextRefNum = useRef(null);
  useEffect(() => {
    let queryParams = queryString.parse(history.location.search);
    if ('code' in queryParams) {
      history.push('/linkedin', { code: queryParams?.code, state: queryParams.state });
    }
  }, []);
  return (
    <div className={`${Styles.landing__page}`}>
      <Hero />
      <Stats />
      <HomeLeaderBoard btnLabel={t('odp-pu.homepage.see-all-results')} />
      <LandingPageChallenges
        prevRefNum={prevRefNum}
        nextRefNum={nextRefNum}
        btnLabel={t('odp-pu.homepage.see-all-challenges')}
      />
      <Sponsors
        details={Sponsor}
        title={t('odp-pu.homepage.our-sponsors')}
        description={t('odp-pu.homepage.find-the-different-entities-that-believe')}
        styles={{ padding: '150px 0 150px 0' }}
      />

      <div className={`mt-5 ${Styles.backgroundHero}`}>

        <img src={BackgroundBlob} className={Styles.blob} />
        <HeroInfoCard
          title={t('odp-pu.homepage.join-the-community')}
          paragraph={t('odp-pu.homepage.join-open-data-playground-to-test-and-tr')}
          btnLabel={t('odp-pu.homepage.discover-more')}
          onClick={() => history.push('/data-expert')}
          Image={Join}
          alt="Image">
          <p>
            {t('odp-pu.homepage.join-open-data-playground-to-test-and-tr')}{' '}
            <span className="highlight">{t('odp-pu.homepage.highlight.e-skills')}</span>
            {t('odp-pu.homepage..-at-open-data-playground-you-can-not-on')}{' '}
            <span className="fw-bold">{t('odp-pu.homepage.bold.professional-opportunity')}</span>
            {t('odp-pu.homepage..-the-platform-is-a-springboard-as-well-')}{' '}
            <span className="highlight">{t('odp-pu.homepage.highlight.accelerator-of-ideas')}</span>
            {t('odp-pu.homepage.-all-we-need-is-your-contribution-')}
          </p>
        </HeroInfoCard>

        <HeroInfoCard
          title={t('odp-pu.homepage.organize-a-challenge')}
          paragraph={t('odp-pu.homepage.with-open-data-playground-you-can-involv')}
          btnLabel={t('odp-pu.homepage.discover-more')}
          onClick={() => history.push('/companies')}
          style={{ flexDirection: 'row-reverse' }}
          Image={Organize}
          alt="Image"
          isSecond={true}>
          <p>{t('odp-pu.homepage.with-open-data-playground-you-can-involv')}</p>
        </HeroInfoCard>
        <HeroInfoCard
          title={t('odp-pu.homepage.train-your-students')}
          paragraph={t('odp-pu.homepage.transform-and-innovate-the-university-wo')}
          btnLabel={t('odp-pu.homepage.discover-more')}
          onClick={() => history.push('/educational')}
          Image={Train}
          alt="Image">
          <p>
            {t('odp-pu.homepage.transform-and')}{' '}
            <span className="fw-bold">{t('odp-pu.homepage.bold.innovate-the-university')} </span>
            {t('odp-pu.homepage.world-with-data-challenges-and-research-')}{' '}
            <span className="highlight">{t('odp-pu.homepage.highlight.gamification-system')}</span>
            {t('odp-pu.homepage.-to-the-analysis-of-real-datasets-inte')}
          </p>
        </HeroInfoCard>
      </div>
    </div>
  );
};

export default LandingPage;
