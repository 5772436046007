import { AButton } from "../../../theme/button";
import { useHistory, useRouteMatch } from "react-router-dom";

import "./teamHeader.scss";
import { resetTeamStates } from "../../../store/team";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const WellcomeTeam = ({ totalTeams }) => {
  const { t } = useTranslation();
  let history = useHistory();
  let { url } = useRouteMatch();
  const dispatch = useDispatch();

  const toCreateNewTeam = () => {
    dispatch(resetTeamStates());
    history.push(`${url}/create-team`);
  };
  return (
    <div className="team__header">
      <p className="total_teams">
        <span>{t("odp-pu.label.team.total-teams-")}</span> {totalTeams}
      </p>
      <AButton onClick={() => toCreateNewTeam()} label={`${t('odp-pu.team.create-team')}`} />
    </div>
  );
};

export default WellcomeTeam;
