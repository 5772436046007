import { getNotification } from "../redux-store/actions/notificationActions";

class subscribeNotifications {
  constructor() {
    if (!this.constructor.instance) {
      this.constructor.instance = this;
    }
    this.interval = undefined;

    return this.constructor.instance;
  }

  start(dispatch) {
    this.interval = setInterval(() => {
      dispatch(getNotification(null));
    }, 60000);
  }

  clear() {
    clearInterval(this.interval);
  }
}

export default subscribeNotifications;
