import { all } from "redux-saga/effects";
import ChallengeSaga from "./ChallengeSaga";
import AuthenticationSaga from "./authenticationSaga";
import lookUpSaga from "./lookUpSaga";
import notificationSaga from "./notificationSaga";
import accountSettingsSaga from "./accountSettingsSaga";
import protectedFile from "./protectedFileSaga";
import userSaga from './userSaga';
import sponsorSaga from "./sponsorSaga";

export default function* rootSaga() {
  yield all([
    ...ChallengeSaga,
    ...AuthenticationSaga,
    ...lookUpSaga,
    ...notificationSaga,
    ...accountSettingsSaga,
    ...protectedFile,
    ...userSaga,
    ...sponsorSaga
  ]);
}
