import React, { useEffect, useState } from 'react';
import Styles from './challengeTab.module.scss';
import { Input, Select } from 'formik-antd';
import { Checkbox } from 'antd';
import Icon from '../../../components/Icons';
import Progress from '../../../components/ProgressBar';
import downArrow from '../../../asset/icons/downArrow.svg';
import { saveProtectedDataSetFile, saveProtectedFile } from '../../../services/fileupload/api';
import FileDragger from '../../../components/FileDragger';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../theme/antNotify';
import { DATASET, SOLUTION } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { getProtectedFile } from '../../../redux-store/services/File';
import { setScoringEvaluation } from '../../../redux-store/actions/utilsAction';
import { toTitleCase } from '../../../utils';
import { axiosInstance } from '../../../configs/axois';
const { v4: uuidv4 } = require('uuid');

const { Option } = Select;

const DataSubmission = ({
  setSolutionFileId,
  setDataSetFileId,
  dataSetFile,
  solutionFile,
  setDataSetFile,
  setSolutionFile,
  solutionFileId,
  dataSetFileId,
  evaluationFunctionId,
  values,
  setFieldValue
}) => {
  const { t } = useTranslation();
  const [isMaxSubmission, setIsMaxSubmission] = useState(true);
  const [isDelaySubmission, setIsDelaySubmission] = useState(true);

  const [dataSetLoading, setDataSetLoading] = useState(false);
  const [solutionLoading, setSolutionLoading] = useState(false);
  const [isSolutionError, setIsSolutionError] = useState(false);
  const [isDataSetError, setIsDataSetError] = useState(false);
  const [selectedScoringMetrics, setSelectedScoringMetric] = useState();

  const [uploadProgress, setUploadProgress] = useState(0);

  const dispatch = useDispatch();

  const { requestObject: challengeObject } = useSelector((state) => state.challengePayload);

  const { listings: scoringMetrics } = useSelector((state) => state.scoringMetrics);

  const { scoringEvaluation } = useSelector((state) => state.utils);

  useEffect(() => {
    if (evaluationFunctionId) {
      const scoringMetric = scoringMetrics.filter(
        ({ evaluation_function_id }) => evaluationFunctionId == evaluation_function_id
      )[0];
      setSelectedScoringMetric(scoringMetric);
      setFieldValue('scoring_metric_temp', scoringMetric?.evaluation_function_name);
    }
  }, [evaluationFunctionId]);

  const onDataFileUpload = async (e) => {
    try {
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files;
      let fileExtension = files[0].name.match(/\.(.+)$/)[1];
      const uuid = uuidv4();

      if (validateFile(fileExtension)) {
        setDataSetFile(files[0]);
        setIsDataSetError(false);

        const chunkSize = 4 * 1024 * 1024; // 4MB chunk size
        const totalChunks = Math.ceil(files[0].size / chunkSize);
        let currentChunk = 0;

        const uploadChunk = (start, end) => {
          const formData = new FormData();
          // console.log('upfile', files[0].slice(start, end));
          formData.append('upfile', files[0].slice(start, end), files[0].name);
          formData.append('file_type', DATASET);
          axiosInstance
            .post(
              `challenge/${challengeObject?.competition_id}/resources/protectedfiles`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'X-Chunk-Number': currentChunk,
                  'X-Total-Chunks': totalChunks,
                  'X-file-uuid': uuid
                },
                onUploadProgress: (progressEvent) => {
                  const progress = Math.round(
                    ((currentChunk * chunkSize + progressEvent.loaded) * 100) / files[0].size
                  );
                  setUploadProgress(progress);
                }
              }
            )
            .then((response) => {
              // Chunk uploaded successfully, proceed to next chunk
              currentChunk++;
              if (currentChunk < totalChunks) {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize;
                uploadChunk(start, end);
              } else {
                // All chunks uploaded

                setDataSetFileId(response.data.protected_file_id);
                setDataSetLoading(false);
                console.log('File upload complete!');
              }
            })
            .catch((error) => {
              setIsDataSetError(true);
              setDataSetLoading(false);
              console.error('Error uploading chunk:', error);
            });

          // Start uploading the first chunk

          // const dataFileId = await saveProtectedDataSetFile(dispatch, payload);
        };
        // Start uploading the first chunk
        const start = currentChunk * chunkSize;
        const end = start + chunkSize;
        uploadChunk(start, end);
        setUploadProgress(0);
        setDataSetLoading(true);
      }
    } catch (err) {
      setIsDataSetError(true);
      setDataSetLoading(false);
    }
  };

  const onSolutionFileUpload = async (e) => {
    try {
      e.preventDefault();
      const files = e.target.files || e.dataTransfer.files;
      let fileExtension = files[0].name.match(/\.(.+)$/)[1];
      setIsSolutionError(false);
      if (validateFile(fileExtension)) {
        setSolutionFile(files[0]);
        const fileData = new FormData();
        fileData.append('upfile', files[0]);
        fileData.append('file_type', SOLUTION);
        let payload = {
          challengeId: challengeObject?.competition_id,
          upFile: fileData,
          fileType: SOLUTION,
          evaluation_function_id: selectedScoringMetrics?.evaluation_function_id
        };
        setSolutionLoading(true);
        const solutionFileId = await saveProtectedFile(dispatch, payload);
        setSolutionFileId(solutionFileId);
        setSolutionLoading(false);
      } else {
      }
    } catch (err) {
      setSolutionLoading(false);
      setIsSolutionError(true);
    }
  };

  const downloadFile = async (fileId, fileType) => {
    try {
      fileType == 'dataSet' ? setDataSetLoading(true) : setSolutionLoading(true);
      const response = await getProtectedFile({
        challengeId: challengeObject?.competition_id,
        protectedFileId: fileId,
        is_url: fileType == 'dataSet'
      });
      if (response) {
        const url =
          fileType == 'dataSet'
            ? response?.file_url
            : window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileType == 'dataSet' ? 'data-set-file.zip' : 'solution-file.zip'
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        fileType == 'dataSet' ? setDataSetLoading(false) : setSolutionLoading(false);
        return;
      }
    } catch (e) {
      throw e;
    }
  };

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case 'zip':
        return true;
      default:
        notify('error', 'Invalid File', 'File with extension (.zip) is allowed');
    }
  };

  const onScoringMetricChange = (scoringMetricId) => {
    if (scoringMetrics.length > 0) {
      const { is_auto_calculate, evaluation_function_name } = scoringMetrics.filter(
        ({ evaluation_function_id }) => scoringMetricId == evaluation_function_id
      )[0];
      dispatch(setScoringEvaluation(is_auto_calculate ? 'auto' : 'manual'));
      setFieldValue('scoring_metric_temp', evaluation_function_name);
    }

    const scoringMetric = scoringMetrics.filter(
      ({ evaluation_function_id }) => scoringMetricId == evaluation_function_id
    )[0];
    setSelectedScoringMetric(scoringMetric);

    if (
      scoringMetric.evaluationFunctionParams &&
      scoringMetric.evaluationFunctionParams.length == 0
    ) {
      setFieldValue('evaluation_function_values', {});
    } else {
      scoringMetric.evaluationFunctionParams.map(({ param_key, param_values }) => {
        setFieldValue(`evaluation_function_values.${param_key}`, param_values);
      });
    }
  };

  return (
    <div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-design-submission.dataset-or-file-upload')}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
          <span>
            {t('odp-ad.challenge.create-design-submission.-upload-the-dataset-or-files-in-a-singl')}
          </span>
          <FileDragger
            id="dataSetFile"
            buttonText="file"
            fileExtensions="xx or xxx"
            fileSize="200 MB"
            loading={dataSetLoading}
            imageDimensions="400 x 400 px"
            style={{ width: 100 + '%', height: 160 + 'px' }}
            error={isDataSetError}
            selectedFile={dataSetFile || dataSetFileId}
            onFileDrop={onDataFileUpload}
            showProgress={true}
            removeFile={() => {
              setDataSetFile(null);
              setDataSetFileId(null);
            }}
            progress={uploadProgress}
            downloadFile={() => {
              downloadFile(dataSetFileId, 'dataSet');
            }}
          />
        </div>
      </div>
      {/* <Progress percentage={uploadProgress} /> */}
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-design-submission.maximum-submission')}</h4>
        </div>
        <div className={`${Styles.formGroup} `}>
          <span>
            {t(
              'odp-ad.challenge.create-design-submission.-participants-will-need-to-wait-after-su'
            )}
          </span>
          <div className={`${Styles.checkBoxInputNumber}`}>
            <Checkbox
              onChange={(e) => {
                setIsMaxSubmission(!e.target.checked);
              }}>
              {t('odp-ad.challenge.create-design-submission.maximum-number-of-daily-submissions')}
            </Checkbox>
            <Input
              className={Styles.inputNumber}
              type="number"
              id="quantity"
              name="challengeproperty.maximum_daily_submissions"
              min="1"
              max="100"
              disabled={isMaxSubmission}
            />
          </div>
          <div className={`${Styles.checkBoxInputNumber}`}>
            <Checkbox
              onChange={(e) => {
                setIsDelaySubmission(!e.target.checked);
              }}>
              {t('odp-ad.challenge.create-design-submission.delay-between-submissions-in-seconds')}
            </Checkbox>
            <Input
              className={Styles.inputNumber}
              type="number"
              id="quantity"
              name="challengeproperty.delay_between_submissions"
              min="1"
              max="10000"
              disabled={isDelaySubmission}
            />
          </div>
        </div>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-design-submission.scoring-metric')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>
            {t('odp-ad.challenge.create-design-submission.select-the-challenge-scoring-metric')}
          </span>
          <Select
            style={{
              width: 100 + '%',
              borderRadius: 5 + 'px'
            }}
            suffixIcon={<Icon src={downArrow} />}
            placeholder="Select"
            name="evaluation_function_id"
            onChange={(scoringMetricId) => {
              onScoringMetricChange(scoringMetricId);
            }}>
            {scoringMetrics.map(({ evaluation_function_id, evaluation_function_name }) => (
              <Option key={evaluation_function_id} value={evaluation_function_id}>
                {evaluation_function_name}
              </Option>
            ))}
          </Select>

          <div className={`mt-4 d-flex ${Styles.evaluationContainer}`}>
            {selectedScoringMetrics &&
              selectedScoringMetrics.evaluationFunctionParams &&
              selectedScoringMetrics.evaluationFunctionParams.length > 0 &&
              selectedScoringMetrics.evaluationFunctionParams.map(({ param_key, param_values }) => (
                <div className={`mt-2 mr-2 ${Styles.formGroup} ${Styles.evaluationField}`}>
                  <span>{toTitleCase(param_key.replace(/_/g, ' '))}</span>
                  <Input
                    defaultValue={param_values}
                    name={`evaluation_function_values.${param_key}`}
                    placeholder={`Enter ${toTitleCase(param_key.replace(/_/g, ' '))}`}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {scoringEvaluation == 'auto' && (
        <div className={Styles.inputContainer}>
          <div className={Styles.inputTitle}>
            <h4>{t('odp-ad.challenge.create-design-submission.solution-file')}</h4>
          </div>
          <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
            <span>
              {t(
                'odp-ad.challenge.create-design-submission.-upload-a-solution-file.-player-submissi'
              )}
            </span>
            <FileDragger
              id="submissionFile"
              buttonText="file"
              fileExtensions="xx or xxx"
              fileSize="5 MB"
              loading={solutionLoading}
              error={isSolutionError}
              imageDimensions="400 x 400 px"
              style={{ width: 100 + '%', height: 160 + 'px' }}
              selectedFile={solutionFile || solutionFileId}
              onFileDrop={onSolutionFileUpload}
              removeFile={() => {
                setSolutionFile(null);
                setSolutionFileId(null);
              }}
              downloadFile={() => {
                downloadFile(solutionFileId, 'solutionFile');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DataSubmission;
