import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChallengeCardSmall from '../../components/ChallengeCard/CallengeCardSmall/ChallengeCardSmall';
import { getChallengeListings } from '../../redux-store/actions/ChallengeAction';
import Styles from '../LandingPageChallenges/landingPageChallenges.module.scss';
import { PaginationModel, buildParams } from '../../utils';
import { useIsDesktop } from '../../hooks/customHooks';
import { Swiper, SwiperSlide } from 'swiper/react';

import './ourEvents.scss';

const OurEvents = ({ title, description, btnLabel }) => {
  const challengeFilters = {};
  const dispatch = useDispatch();

  const { listings: challengeListings } = useSelector((state) => state.challenge);


  challengeFilters.pagination = new PaginationModel();
  challengeFilters.pagination.pageSize = 3;

  useEffect(() => {
    let filters = buildParams(challengeFilters);
    getChallenges(filters);
  }, []);

  const getChallenges = (filters) => {
    filters.is_published = true;
    dispatch(getChallengeListings(filters));
  };
  const isMobile = useIsDesktop();

  return (
    <div className="ourEvents__wrapper">
      <h1>{title}</h1>
      <p>{description}</p>
      {!isMobile && (
        <div className="row justify-content-center">
          <div className="row col-md-10 justify-content-center d-flex">
            {challengeListings &&
              challengeListings.length > 0 &&
              challengeListings.map((challenge, index) => (
                <div className="col-md-4" key={index}>
                  <div className={`d-flex justify-content-center ${Styles.challengeCard}`}>
                    <ChallengeCardSmall
                      {...challenge}
                      previewChallenge={`/challenge/${challenge.competition_id}`}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {isMobile && (
        <Swiper
          style={{ width: '100%' }}
          slidesPerView="1"
          pagination={{
            clickable: true,
            el: '.custom-pagination-1',
            bulletActiveClass: Styles.customPaginationBulletActive,
            renderBullet: (index, className) => {
              return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
            }
          }}>
          {challengeListings.length > 0 &&
            challengeListings.map((challenge, index) => (
              <div className="col-md-4" key={index}>
                <div className={Styles.challengeCard}>
                  <SwiperSlide>
                    <ChallengeCardSmall
                      {...challenge}
                      previewChallenge={`/challenge/${challenge.competition_id}`}
                    />
                  </SwiperSlide>
                </div>
              </div>
            ))}
          <div className="col-12 ">
            <div
              className="custom-pagination-1"
              style={{
                textAlign: 'center',
                marginTop: 20,
                whiteSpace: 'nowrap',
                marginRight: '10px'
              }}></div>
          </div>
        </Swiper>
      )}
    </div>
  );
};

export default OurEvents;
