import "./team.scss";
import WellcomeTeam from "./wellcomeTeam";
import Teams from "./teams/index";
import { useSelector, useDispatch } from "react-redux";

import { Spin } from "antd";
import { getTeams } from "../../services/team/api";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Index = () => {
  const { teamLoading } = useSelector((state) => state.Team);
  const { allTeams } = useSelector((state) => state.Team);
  const { user_id } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    getTeams(dispatch, { user_id });
  }, []);
  return (
    <div className="team__wrapper">
      <div className="team__content text-center">
        <h1>{t('odp-pu.profile.profile-links-your-team')}</h1>
        {/* <p className="pg-regular">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu
          pretium eget in volutpat mi a cursus nisi. Praesent elit consequat,
          est diam fringilla placerat risus.
        </p> */}
      </div>
      {teamLoading === "pending" ? (
        <div className="full__spinner__wrapper">
          <Spin spinning={teamLoading === "pending"} className="spinner" />
        </div>
      ) : allTeams.length > 0 ? (
        <Teams allTeams={allTeams} />
      ) : (
        <WellcomeTeam />
      )}
    </div>
  );
};

export default Index;
