import { Switch } from 'formik-antd';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import './manage.scss';
import { useTranslation } from 'react-i18next';
import { updateManageVisibilty } from '../../../store/profile';
import { useMediaQuery } from 'react-responsive';
const Index = ({ isCompanyAdmin }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const [manage_visibility, setmanage_visibility] = useState(false);
  const [toggle, setToggle] = useState(false);
  const smallScreen = useMediaQuery({ query: '(max-width: 1024px)' });
  const largeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  useEffect(() => {
    if (user) {
      let { userProfile } = user;
      setmanage_visibility(userProfile.manage_visibility);
    }
  }, [user]);

  let toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  return (
    <>
      <div className="content">
        <h4>{t('odp-pu.user.profile.sponsor-visibility')}</h4>
        <p>{t('odp-pu.user.profile.private-information-label')}</p>
      </div>
      <div className="manage__wrapper">
        <div className="left__skill">
          <h4>{t('odp-pu.label.profile.manage-visibility')}</h4>
        </div>
        <div className="rigt__skill">
          <div className="body">
            {manage_visibility ? (
              <span className={toggle ? 'hide_active' : ''}>{t('odp-pu.user.profile.hide')}</span>
            ) : (
              <span className={toggle ? '' : 'hide_active'}>{t('odp-pu.user.profile.hide')}</span>
            )}
            <div className="switch-wrapper">
              <Switch onChange={toggler} name="userProfile.manage_visibility" disabled={isCompanyAdmin}/>
            </div>
            {manage_visibility ? (
              <span className={toggle ? 'show' : 'show_active'}>
                {t('odp-pu.user.profile.show')}
              </span>
            ) : (
              <span className={toggle ? 'show_active' : 'show'}>
                {t('odp-pu.user.profile.show')}
              </span>
            )}

            {largeScreen && <span>{t('odp-pu.user.profile.data-visibility')}</span>}
          </div>
          {smallScreen && (
            <span className="visibility">{t('odp-pu.user.profile.data-visibility')}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
