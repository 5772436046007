import "./createTeam.scss";
import Create from "./form/create";
import Content from "./content";
import { useHistory, Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import ChallangeCard from "../teams/teamChallanges/card";

import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { getTeamChallanges } from "../../../services/team/api";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
const CreateNewTeam = (props) => {
  const [challenges, setChallenges] = useState([]);
  const largeScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  let history = useHistory();

  const { t } = useTranslation();

  let editData = history.location.state;
  editData = { ...editData, params: props.match.params };
  const toTeam = () => {
    history.goBack();
  };

  useEffect(async () => {
    if (editData?.team_id) {
      const teamChallenges = await getTeamChallanges({
        team_id: editData.team_id,
      });

      if (teamChallenges !== null)
        setChallenges(teamChallenges?.data?.listings);
    }
  }, []);

  return (
    <div className="create__new__Team__wrapper">
      <div className="creae__header">
        <div onClick={() => toTeam()} className="goback">
          <ArrowLeftOutlined className="arrow__icon" />
          Back Teams
        </div>
        <h1>{t('odp-pu.team.create-team')}</h1>
      </div>

      <div className="create__body">
        <div className="create__form">
          <Create data={editData} />
        </div>
        <div className="create-content">
          <Content data={editData} />
        </div>
      </div>

      {largeScreen && (
        <div className="create-footer">
          <div className="team__members__accordian">
            <div className="team__members__wrapper">
              <Collapse ghost expandIconPosition="right">
                <Panel
                  header={`Active Challenges (${challenges?.length}) `}
                  key="1"
                >
                  {challenges?.length > 0 &&
                    challenges?.map((challenge) => {
                      return <ChallangeCard {...challenge} />;
                    })}
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="team__members__accordian">
            <div className="team__members__wrapper">
              <Collapse ghost expandIconPosition="right">
                <Panel
                  header={`All Challenges (${challenges?.length}) `}
                  key="2"
                >
                  {challenges?.length > 0 &&
                    challenges?.map((challenge) => {
                      return <ChallangeCard {...challenge} />;
                    })}
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNewTeam;
