import GP from "../../../../asset/images/gp.jpg";
import { CustomDropDown } from "../../../Dropdown";
import "./list.scss";
import Leave from "../../../../asset/images/team/leave.svg";
import RemoveUser from "../../../../asset/images/team/removeUser.svg";
import SettingPlus from "../../../../asset/images/team/settingplus.svg";
import SettingCross from "../../../../asset/images/team/settingCross.svg";
import UserImage from "../../../../asset/images/user.svg";

const List = ({ teamMembers }) => {
  const myDropdown = [
    { icon: SettingCross, label: "Remove Admin", action: 2, disabled: false },
    { icon: Leave, label: "Leave Team", action: 3, disabled: false },
  ];
  const adminDropdown = [
    { icon: SettingCross, label: "Remove Admin", action: 2, disabled: false },
    { icon: RemoveUser, label: "Remove Member", action: 4, disabled: false },
  ];
  const memberDropdown = [
    { icon: SettingPlus, label: "Make Admin", action: 1, disabled: false },
    { icon: RemoveUser, label: "Remove Member", action: 4, disabled: false },
  ];
  const defaultDropdown = [
    { icon: Leave, label: "Leave Team", action: 3, disabled: true },
  ];

  const getMenuContext = (team) => {
    if (team) {
      if (team.is_my_user && team.can_leave_team) {
        return myDropdown;
      } else if (!team.is_my_user && team.is_team_lead) {
        return adminDropdown;
      } else if (!team.is_my_user && !team.is_team_lead) {
        return memberDropdown;
      } else {
        return myDropdown;
      }
    }
  };

  return (
    <div className="memmber-list row" style={{ marginLeft: 3 }}>
      {teamMembers?.team_users?.map((team) => (
        <div className="member-content">
          <div className="img-box">
            <img
              src={`${team?.avatar_url ? process.env.REACT_APP_RESOURCES_URL + team?.avatar_url : UserImage}`}
              alt={GP}
            />
          </div>
          <div className="member-body">
            <p className="name">{team?.display_name}</p>
            <p className="designation">
              {team?.is_team_lead ? "Administrator" : ""}
            </p>
          </div>
          <CustomDropDown
            menuList={getMenuContext(team)}
            team={team}
            teamMembers={teamMembers}
          />
        </div>
      ))}
    </div>
  );
};

export default List;
