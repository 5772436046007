import { NavLink, Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

import { links } from "../../helpers/links";
import TextLogo from "../../asset/images/header/text-logo.svg";
import { AButton } from "../../theme/button";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import "./nav.scss";
import { getFile } from "../../services/fileupload/api";
import ProfileDropDown from "./profileDropDown";
import Notification from "./notification";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getUserID, getToken } from "../../services/token";
import { getProfile, fetchCountries } from "../../services/profile/api";
import subscribeNotifications from "../../utils/subscribeNotifications";

import { useTranslation } from "react-i18next";

const Index = ({ isActive, setisMenuActive }) => {
  const { t } = useTranslation();
  const [check, setcheck] = useState(true);
  const { user } = useSelector((state) => state.userProfile);
  const smallScreen = useMediaQuery({ query: "(max-width: 1024px)" });
  const history = useHistory();
  const dispatch = useDispatch();
  const toLogin = () => {
    history.push("/login");
  };
  useEffect(() => {
    if (getToken() !== null && getUserID() !== null && user == null) {
      getProfile(dispatch, {
        data: {
          user_id: getUserID(),
        },
      });
      initCounties();
    }
  }, []);
  useEffect(() => {
    if (user !== null) {
      let { userProfile } = user;
      if (userProfile.avatar_url !== null) {
        if (check) {
          setcheck(false);
        }
      }
    }
  }, [user]);

  const initCounties = async () => {
    await fetchCountries(dispatch);
  };

  return (
    <div className="main__nav__wrapper">
      <nav className="upper__nav">
        <Link to="/">
          <img src={TextLogo} alt={TextLogo} className="text__logo" />
        </Link>
        <MenuOutlined
          className="menu"
          onClick={() => setisMenuActive(!isActive)}
        />
        {smallScreen && getUserID() == null ? (
          <div className="login__btn mobile">
            <AButton
              label="LOGIN"
              onClick={toLogin}
              type="regular"
              className=""
            ></AButton>
          </div>
        ) : (
          <div className="profile__notfiy mobile">
            <ProfileDropDown />
            <Notification />
          </div>
        )}

        <ul
          className={
            isActive
              ? "upper__nav__links link__off active"
              : "upper__nav__links link__off"
          }
        >
          {links.map((route) => {
            return (
              <li className="upper__nav__link" key={route.name}>
                <NavLink
                  to={{
                    pathname: route.path,
                    auth: route.auth,
                  }}
                  onClick={() => setisMenuActive(!isActive)}
                >
                  {t(route.name)}
                </NavLink>
              </li>
            );
          })}
          <li className="upper__nav__link" >
            <a href="https://journal.opendataplayground.com/" target={"_blank"}>Journal</a>
          </li>
          {getUserID() == null ? (
            <div className="login__btn">
              <AButton label="LOGIN" onClick={toLogin} type="regular"></AButton>
            </div>
          ) : (
            <div className="profile__notfiy">
              <ProfileDropDown />
              <Notification />
            </div>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Index;
