import React, { useState } from 'react';
import { Button, Input, Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../hooks/customHooks';
import infoCircle from '../../asset/icons/info-circle.svg';
import { Link } from 'react-router-dom';

import Styles from './newsletter.module.scss';
import 'antd/dist/antd.css';

const NewsletterForm = ({ status, message, onValidated }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  (function (w, d) {
    var loader = function () {
      var s = d.createElement('script'),
        tag = d.getElementsByTagName('script')[0];
      s.src = 'https://cdn.iubenda.com/iubenda.js';
      tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
      w.addEventListener('load', loader, false);
    } else if (w.attachEvent) {
      w.attachEvent('onload', loader);
    } else {
      w.onload = loader;
    }
  })(window, document);

  const [name, setName] = useState(null);
  const [surName, setSurName] = useState(null);
  const [email, setEmail] = useState(null);
  const [authorize, setAuthorize] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    name &&
      surName &&
      email &&
      email.indexOf('@') > -1 &&
      onValidated({ Name: name, Surname: surName, EMAIL: email });
  };

  return (
    <div className={Styles.subscribeContainer}>
      <div>
        <div className={Styles.nameContainer}>
          <Input
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder={t('odp-pu.user.profile.name')}
          />
          <Input
            onChange={(e) => setSurName(e.target.value)}
            type="text"
            placeholder={t('odp-pu.user.profile.surname')}
          />
        </div>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder={t('odp-pu.general.labels.your-email-address')}
        />
        {status === 'sending' && <div>sending...</div>}
        {status === 'error' && <div dangerouslySetInnerHTML={{ __html: message }} />}
        {status === 'success' && <div dangerouslySetInnerHTML={{ __html: message }} />}
        <Tooltip
          overlayStyle={{ maxWidth: !isMobile ? '400px' : '300px', borderRadius: '10px' }}
          placement="topLeft"
          trigger={!isMobile ? 'hover' : 'none'}
          title="* Consenso facoltativo che può essere revocato in qualsiasi momento scrivendo a privacy@opendataplayground.com. La revoca non pregiudica la liceità dei trattamenti basati sul consenso precedentemente prestato, ma ti impedisce di ricevere le newsletter">
          <Checkbox
            onClick={() => {
              setAuthorize(!authorize);
            }}
            className={Styles.checkbox}>
            Cliccando sul tasto “Subscribe”, acconsento al trattamento dei miei dati personali (e.g.
            dati anagrafici e di contatto), per la ricezione delle Newsletter di OST (cfr. punto 3
            lett. f dell’
            <Link to="/privacy-policy" className={Styles.information}>
              Informativa Privacy
            </Link>
            {/* <a
              href="https://www.iubenda.com/privacy-policy/42858618"
              class={`iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe link ${Styles.information}`}
              title="Privacy Policy">
              Informativa Privacy
            </a> */}
            ).*
            <Tooltip
              overlayStyle={{ maxWidth: !isMobile ? '400px' : '300px', borderRadius: '10px' }}
              placement="bottom"
              trigger={isMobile ? 'click' : 'none'}
              title="* Consenso facoltativo che può essere revocato in qualsiasi momento scrivendo a privacy@opendataplayground.com. La revoca non pregiudica la liceità dei trattamenti basati sul consenso precedentemente prestato, ma ti impedisce di ricevere le newsletter">
              <img src={infoCircle} alt="info circle" />
            </Tooltip>
          </Checkbox>
        </Tooltip>

        <Button
          disabled={!authorize}
          onClick={(e) => handleFormSubmit(e)}
          className="mt-3"
          type="primary">
          {t('odp-pu.general.labels.subscribe')}
        </Button>
      </div>
    </div>
  );
};

export default NewsletterForm;
