import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Checkbox } from 'antd';
import { AButton } from "../../../theme/button";
import { useTranslation } from "react-i18next";
import "./ContactForm.scss";
import Button from "../../../components/Button";
import { contactFormSchema } from "../../../helpers/schemas";
import { submitQueryForm } from '../../../redux-store/services/Contact';
import { notify } from "../../../theme/antNotify";

const ContactForm = ({ queryType }) => {
  const [termCondition, setTermCondition] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const submitForm = async (values, { setSubmitting }, errors) => {
    try {
      setLoading(true);
      const payload = { ...values };
      payload.body = `The following message has been sent by ODP ${queryType == 1 ? 'contact' : queryType == 2 ? 'contact sponsor' : 'Technical support'} form: ${payload.body}`
      const response = await submitQueryForm(payload);
      notify('success', response.statusMessage);
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div className="form__wrapper">
      <Formik
        initialValues={{
          name: null,
          email: null,
          body: null,
          email_type: queryType,
        }}
        enableReinitialize={true}
        validationSchema={contactFormSchema}
        onSubmit={submitForm}
        render={({ values, errors, isValid, touched }) => (
          <Form layout="vertical">
            <div className="name__email">
              <Form.Item name="name" label={t("odp-pu.contact.name")}>
                <Input
                  placeholder={t("odp-pu.contact.name-placeholder")}
                  style={{
                    height: 40,
                    borderRadius: 5 + "px",
                  }}
                  name="name"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label={t("odp-pu.contact.email") + " *"}
              >
                <Input
                  placeholder={t("odp-pu.contact.email-placeholder")}
                  style={{
                    height: 40,
                    borderRadius: 5 + "px",
                  }}
                  name="email"
                />
              </Form.Item>
            </div>
            <Form.Item name="body" label={t("odp-pu.contact.message")}>
              <Input.TextArea
                placeholder={t("odp-pu.contact.message-placeholder")}
                maxLength={500}
                style={{
                  resize: "none",
                }}
                name="body"
              />
            </Form.Item>
            <p>{t("odp-pu.contact.-required")}</p>
            <Checkbox onClick={() => { setTermCondition(!termCondition) }}>
              {t("odp-pu.contact.i-agree-to-the-open-data-playground-term")}
            </Checkbox>
            <div className="contact__sendButton">
              <Button type='accent' className="button-contact"
                disabled={loading || !termCondition}
                submitType="submit">{t("odp-pu.contact.send")}</Button>
            </div>
          </Form>)}
      />
    </div>
  );
};

export default ContactForm;
