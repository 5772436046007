import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';
import { ChallengeProperty } from './ChallengeProperty'
import { Prizes } from './Prizes';

export class PrizeSet {
    prize_set_id = null;
    prize_set_name = "";
    prizes = {};
    constructor() {
        this.prize_set_id = null;
        this.prize_set_name = null;
        this.prizes = new Prizes();
    }

    deserialize(model) {
        this.prize_set_id = StringConverter(model.prize_set_id);
        this.prize_set_name = StringConverter(model.prize_set_name);

        if (model.prizes) {
            this.prizes = new Prizes().deserialize(model.prizes);
        }

        return this;

    }

}