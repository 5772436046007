import { ExclamationCircleTwoTone } from '@ant-design/icons';

import './notification.scss';
const Index = ({ title, msg, className, twoToneColor }) => {
  return (
    <div className={`notify__wrapper ${className}`}>
      <div className="notify">
        <span className="notify__icon">
          <ExclamationCircleTwoTone twoToneColor={twoToneColor} />
        </span>
        <div className="notify__body">
          {title && <p className="notify__titile">{title}</p>}
          <p className="notify__massege">{msg}</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
