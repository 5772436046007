import React from 'react';
import { useIsMobile, useIsDesktop } from '../../hooks/customHooks';
import { Swiper, SwiperSlide } from 'swiper/react';

import './sponsors.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination';
import Button from '../Button';
import SwiperCore, { Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

SwiperCore.use([Pagination]);

const Sponsors = ({ details, title, description, styles }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isTablet = useIsDesktop();
  const history = useHistory();

  return (
    <div className="sponsors__wrapper" style={styles}>
      <div className="sponsors__text">
        <h1 className="sponsors__heading">{title}</h1>
        <p className="sponsors__description">{description}</p>
      </div>
      <Swiper
        slidesPerView={isMobile ? 2 : isTablet ? 3 : 4}
        // slidesPerColumn={isMobile ? 7 : 1}
        slidesPerGroup={isMobile ? 2 : isTablet ? 3 : 4}
        slidesPerColumnFill="row"
        spaceBetween={0}
        className="mySwiper"
        pagination={{
          clickable: true
          // dynamicBullets: true,
          // el: '.custom-pagination-1',
          // bulletActiveClass: 'customPaginationBulletActive',
          // renderBullet: (index, className) => {
          //   return `<span class="${className} customPaginationBullet"></span>`;
          // }
        }}>
        {details.sponsors.map((sponsor) => (
          <SwiperSlide key={sponsor.id}>
            <img src={sponsor.image} alt={sponsor.id} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="custom-pagination-1"></div> */}

      <div className="d-flex justify-content-center col-md-12 text-center sponsor-explore-button">
        <Button type="secondary" onClick={() => history.push('/sponsors')}>
          {t('odp-pu.homepage.discover-our-sponsors')}
        </Button>
      </div>
    </div>
  );
};

export default Sponsors;
