import React from 'react';
import { FieldArray, FastField } from 'formik';
import { Input, DatePicker, Checkbox } from 'formik-antd';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;

const WorkForm = ({ values, isCompanyAdmin }) => {
  const { t } = useTranslation();
  return (
    <FieldArray
      name="userExperienceDetails"
      render={({ insert, remove, push }) => (
        <>
          {values?.userExperienceDetails &&
            values?.userExperienceDetails?.map((work, index) => (
              <div className="rigt__skill" style={{ marginBottom: '30px' }} key={index}>
                <div className="upper__section row">
                  <div className="col-12 col-md-6">
                    <span className="inputLabels">{t('odp-pu.label.profile.company')}</span>
                    <Input
                      label={t('odp-pu.label.profile.company')}
                      type="text"
                      placeholder={t('odp-pu.label.profile.company')}
                      name={`userExperienceDetails.${index}.company_name`}
                      className="inputFields"
                      disabled={isCompanyAdmin}
                    />
                    <span className="error-text"></span>
                  </div>

                  <div className="col-12 col-md-6">
                    <span className="inputLabels">{t('odp-ad.user.manage.role')}</span>
                    <Input
                      label={t('odp-ad.user.manage.role')}
                      type="text"
                      placeholder={t('odp-ad.user.manage.role')}
                      name={`userExperienceDetails.${index}.job_title`}
                      className="inputFields"
                      disabled={isCompanyAdmin}
                    />
                    <span className="error-text"></span>
                  </div>
                </div>
                <div className="lower__section">
                  <div className="form__to">
                    <div className="form__inner">
                      <div className="input__wrapper calender__wrapper">
                        <span className="inputLabels">{t('odp-pu.user.profile.from-date')}</span>
                        <DatePicker
                          name={`userExperienceDetails.${index}.start_date`}
                          className="inputFields"
                          disabled={isCompanyAdmin}
                        />
                      </div>
                      <div className="input__wrapper calender__wrapper">
                        <span className="inputLabels">
                          {t('odp-ad.challenge.create-content.to')}
                        </span>
                        <DatePicker
                          disabled={work.is_current_job || isCompanyAdmin}
                          name={`userExperienceDetails.${index}.end_date`}
                          className="inputFields"
                        />
                      </div>
                    </div>
                    <div className="checkbox-wrapper">
                      <Checkbox name={`userExperienceDetails.${index}.is_current_job`} disabled={isCompanyAdmin}>
                        {t('odp-pu.label.profile.current')}
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="about">
                  <div className="input__wrapper">
                    <span className="inputLabels">{t('odp-pu.menu.footer.about')}</span>
                    <FastField name={`userExperienceDetails.${index}.job_description`}>
                      {({ field, form, meta }) => (
                        <TextArea
                          showCount
                          maxLength={1000}
                          placeholder={`${t('odp-pu.user.profile.add-description')} ( 1000 max )`}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          {...field}
                          disabled={isCompanyAdmin}
                        />
                      )}
                    </FastField>
                  </div>
                </div>
                {values?.userExperienceDetails.length > 1 && (
                  <div className="text-right mt-3">
                    {!isCompanyAdmin && <Button danger onClick={() => remove(index)} disabled={isCompanyAdmin}>
                      {t('odp-pu.user.profile.remove')}
                    </Button>}
                  </div>
                )}

                <span className="border"></span>
              </div>
            ))}
          {!isCompanyAdmin && <button
            onClick={(e) => {
              e.preventDefault();
              push({
                job_title: null,
                company_name: null,
                job_description: null,
                is_current_job: false,
                start_date: null,
                end_date: null
              });
            }}
            disabled={isCompanyAdmin}
            className="link__btn float_right">
            <span>{t('odp-ad.challenge.create-content.add-new')}</span>
          </button>}
        </>
      )}
    />
  );
};

export default WorkForm;
