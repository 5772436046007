import { call, takeLatest, put } from "redux-saga/effects";
import { axiosInstance } from "../../configs/axois";
import { getNotification, NOTIFICATIONS } from "../actions/notificationActions";
import {
  fetchComplete,
  fetchError,
  fetchStart,
} from "../reducers/notificationReducer";

function* fetchNotifications(payload = null) {
  payload.notification_status = 1;
  try {
    yield put(fetchStart());
    const notifications = yield call(axiosInstance.get, "notification", {
      params: { notification_status: payload.notification_status },
    });

    yield put(fetchComplete(notifications?.data?.listings));
  } catch (error) {
    yield put(fetchError());
  }
}

export default [takeLatest(NOTIFICATIONS, fetchNotifications)];
