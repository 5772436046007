import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../helpers/schemas';
import { useSelector } from 'react-redux';
import { Input } from '../../theme/input';

import { TwitterOutlined } from '@ant-design/icons';
const Index = () => {
  const { testEmail, testName } = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: testName,
      email: testEmail,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const submit = (data, errors) => { };
  return (
    <div>


      <Input
        label="Frist Name"
        type="text"
        name="firstName"
        placeholder="Frist Name"
        // icon={<FacebookFilled />}
        errorMsg={errors.firstName?.message}
        erroClassType={errors.firstName?.message ? 'error_input' : ''}
        ref={register}
      />
      <Input
        label="Email"
        type="email"
        name="email"
        icon={<TwitterOutlined />}
        error={errors.email?.message}
        ref={register}
      />

      <button onClick={handleSubmit(submit)}>yup form submit</button>
    </div>
  );
};

export default Index;
