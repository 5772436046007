import "./teamInfo.scss";
import CompleteIcon from "../../../asset/images/team/completeIcon.svg";
import FlagIcon from "../../../asset/images/team/flagIcon.svg";
import EditIcon from "../../../asset/images/team/editIcon.svg";
import Team from "../../../asset/images/team.jpg";
import PlaceholderImg from "../../../asset/images/team/placeholder.svg";

import { SButtonWithIcon } from "../../../theme/button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Info = ({
  team_id,
  team_name,
  description,
  file_id,
  country_id,
  competition_stats,
  can_edit,
  ...rest
}) => {
  let history = useHistory();
  let { url } = useRouteMatch();
  const { t } = useTranslation();

  const toEditTeam = () => {
    let data = {
      team_id,
      team_name,
      description,
      file_id,
      country_id,
      rest,
    };
    history.push({
      pathname: `${url}/edit-team/${team_id}`,
      state: data,
    });
  };

  return (
    <div className="teamInfo_wrapper">
      <div className="teamInfo_content">
        <div className="teamInfo_header">
          <div className="teamInfo_imgbox">
            {/* <img src={Team} alt={Team} /> */}
            <img
              src={`${file_id
                  ? process.env.REACT_APP_RESOURCES_URL + file_id
                  : PlaceholderImg
                }`}
              alt={Team}
            />
          </div>
          <div className="teamInfo_header_content">
            <p className="team__name">{team_name}</p>
            <p className="team_country">
              <img src={FlagIcon} alt={FlagIcon} />
              <span className="country__name">{rest.country_name}</span>
            </p>
            <p className="team_challanges">
              <img src={CompleteIcon} alt={CompleteIcon} /> Challenges:
              <span>{competition_stats?.competition_count}</span>
            </p>
          </div>
        </div>
        <div className="teamInfo_body">
          <p>
            {t("odp-pu.label.team.description")}: {description}
          </p>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu
            pretium eget in volutpat mi a cursus nisi.
          </p> */}
        </div>
        {can_edit && (
          <div className="teamInfo_footer">
            <SButtonWithIcon
              onClick={() => toEditTeam(team_id)}
              label={t("odp-pu.label.team.manage")}
              icon={EditIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Info;
