import {
  challengeEndpoint,
  challengeIdEndpoint,
  challengeLeaderBoardEndpoint,
  challengeSolutionSubmissionsEndpoint,
  subscribeChallengeEndpoint,
  submitChallengeSolutionEndpoint,
  globalLeaderBoardEndpoint,
  userEndpoint,
  UnsubscribeChallengeEndpoint,
  publishChallengeEndpoint,
} from "../../utils/endPoints";
import { axiosInstance } from "../../configs/axois";

export const getChallengeListings = async (params) => {
  try {
    let queryParams = {};
    if (params.request) {
      queryParams = params.request;
    }
    const { data, status } = await axiosInstance.get(challengeEndpoint, {
      params: queryParams,
    });
    if (status !== 200) {
      throw `Error Fetching Challenges ${status}`;
    }
    let { listings, statusCode, _metadata } = data;
    return { listings, statusCode, _metadata };
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const getChallengeById = async ({ challengeId }) => {
  try {
    const { data, status } = await axiosInstance.get(
      challengeIdEndpoint(challengeId)
    );
    if (status !== 200) {
      throw `Error Fetching Challenge ${status}`;
    }
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const getLeaderBoardByChallengeId = async (params) => {
  try {
    let { challengeId } = params;
    delete params.challengeId;
    const { data, status } = await axiosInstance.get(
      challengeLeaderBoardEndpoint(challengeId),
      { params: params }
    );
    if (status !== 200) {
      throw `Error Fetching Challenge ${status}`;
    }
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const getGlobalLeaderboardListings = async ({ params }) => {
  try {
    const { data, status } = await axiosInstance.get(
      globalLeaderBoardEndpoint,
      { params }
    );
    if (status !== 200) {
      throw `Error Fetching Global Leaderboard ${status}`;
    }
    return data;
  } catch (err) {
    throw "Error Fetching Global Leaderboard";
  }
};

export const getUsers = async ({ params }) => {
  try {
    const { data, status } = await axiosInstance.get(userEndpoint, {
      params,
    });
    if (status !== 200) {
      throw `Error Fetching Users Listings ${status}`;
    }
    return data;
  } catch (err) {
    throw "Error Fetching Users Listings";
  }
};

export const createChallenge = async ({ request }) => {
  try {
    const { data, status } = await axiosInstance.post(
      challengeEndpoint,
      request
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const updateChallenge = async ({ request }) => {
  try {
    const { data, status } = await axiosInstance.put(
      challengeIdEndpoint(request?.competition_id),
      request
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const publishChallengeApi = async (requestObject) => {
  try {

    const response = await axiosInstance.put(
      publishChallengeEndpoint(requestObject.competition_id), requestObject
    );

    return response;
  } catch (err) {
    throw err;
  }
};

export const joinChallenge = async ({ request }) => {
  try {
    let challengeId = request?.challengeId;
    delete request?.challengeId;
    const { data, status } = await axiosInstance.put(
      subscribeChallengeEndpoint(challengeId),
      request
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const getChallengeSubmissionHistories = async ({ params }) => {
  try {
    let challengeId = params.challengeId;
    delete params.challengeId;
    const { data, status } = await axiosInstance.get(
      challengeSolutionSubmissionsEndpoint(challengeId), { params: params }
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const submitChallengeFile = async ({ request }) => {
  try {
    let challengeId = request.challengeId;
    delete request.challengeId;
    const { data, status } = await axiosInstance.put(
      submitChallengeSolutionEndpoint(challengeId),
      request
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const closeChallengeService = async (payload) => {
  try {
    let challengeId = payload.challengeId;
    delete payload.challengeId;
    const { data, status } = await axiosInstance.put(
      `challenge/${challengeId}/action`,
      payload
    );
    return data;
  } catch (err) {
    throw "Error Fetching Challenges";
  }
};

export const updateParticipantStatus = async (payload) => {
  try {
    let challengeId = payload.challengeId;
    delete payload.challengeId;
    const { data, status } = await axiosInstance.put(
      `challenge/${challengeId}/participants`,
      payload
    );
    return data;
  } catch (err) {
    throw 'Error in API'
  }
}

export const leaveChallenge = async (challengeId) => {
  try {
    const { data, status } = await axiosInstance.put(
      UnsubscribeChallengeEndpoint(challengeId),
      {}
    );
    return data;
  } catch (err) {
    throw 'Error in API'
  }
}

export const updateChallengeScore = async (payload) => {
  try {
    const submissionId = payload.submission_id;
    delete payload.submission_id;
    const { data } = await axiosInstance.put(`submission/${submissionId}`, payload);
    return data;
  } catch (err) {

  }
}
export const fetchChallengeData = async (params) => {
  try {
    const { data, headers, status } = await axiosInstance.get(`challenge/assets/export/${params.exportId}?type=${params.type}`, { responseType: 'blob' });
    console.log('status', status);
    let fileName = headers['content-disposition'] ? headers['content-disposition'].split('=')[1] : null;
    const fileExtension = fileName.split('.').pop();
    console.log(fileName);
    return { data, fileName, fileExtension };
  } catch (err) {
    throw err;
  }
};
