import React, { useEffect } from 'react';
import Styles from './userProfile.module.scss';
import image from '../../asset/images/profile/profilePlaceHolder.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById } from '../../redux-store/actions/userAction';
import { useParams } from 'react-router';
import ChallengeCard from '../../components/ChallengeCard';
import { getChallengeByUserId } from '../../redux-store/actions/ChallengeAction';
import { Empty } from 'antd';
import SkeletonLoading from 'react-loading-skeleton';
import ProfileCard from '../../components/ProfileCard';
import UserStats from '../../components/UserStats';
import Github from '../../asset/icons/githubBlue.svg';
import Globe from '../../asset/icons/globe.svg';
import LinkedIn from '../../asset/icons/linkedInBlue.svg';
import Twitter from '../../asset/icons/twitterBlue.svg';
import Briefcase from '../../asset/icons/briefcaseAlt.svg';
import Phone from '../../asset/icons/phone.svg';
import File from '../../asset/icons/filePdf.svg';
import Icon from '../../components/Icons';
import { toTitleCase } from '../../utils';
import Back from '../../components/Back';
import { useTranslation } from 'react-i18next';

const dateFormat = 'DD/MM/YYYY';
const UserProfile = () => {
  const { t } = useTranslation();
  const { user_id } = useParams();
  const levels = [1, 2, 3, 4, 5];
  const { user, loading: userLoading } = useSelector((state) => state.publicProfile);
  const { user: own } = useSelector((state) => state.userProfile);

  const { listings, loading } = useSelector((state) => state.userChallenge);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserById(user_id));
    dispatch(getChallengeByUserId({ userId: user_id }));
  }, []);

  return (
    <>
      <Back link={'/leaderboard'} />

      <div className={Styles.userProfileContainer}>
        {(!own?.user_id || (own?.user_id && own?.user_id != user_id && !own?.is_admin)) && (
          <ProfileCard user={user} userLoading={userLoading} />
        )}

        {((own?.user_id && own?.user_id == user_id) || own?.is_admin) && (
          <div className={Styles.fullProfileContainer}>
            <div className={Styles.userBasicInfo}>
              <img
                src={
                  user?.userProfile?.avatar_url
                    ? `${process.env.REACT_APP_RESOURCES_URL}${user?.userProfile?.avatar_url}`
                    : image
                }
                className={Styles.userAvatar}
              />
              <div className={Styles.userTitle}>
                <p className={Styles.userName}>{user?.userProfile?.display_name}</p>
                <p className={Styles.userOccupation}>{user?.userProfile?.occupation || '-'}</p>
                <p>{user?.userProfile?.organization || '-'}</p>
                <div className={Styles.userAddress}>
                  <p>{user?.userProfile?.city || '-'}</p>
                  <p>{user?.userProfile?.country_name || '-'}</p>
                </div>
              </div>
              <div className={Styles.socialLinks}>
                {user?.userProfile?.linkedin_url && (
                  <a href={user?.userProfile?.linkedin_url} target="_blank">
                    <Icon src={LinkedIn} />
                  </a>
                )}
                {user?.userProfile?.twitter_url && (
                  <a href={user?.userProfile?.twitter_url} target="_blank">
                    <Icon src={Twitter} />
                  </a>
                )}
                {user?.userProfile?.github_url && (
                  <a href={user?.userProfile?.github_url} target="_blank">
                    <Icon src={Github} style={{ width: '14px' }} />
                  </a>
                )}
                {user?.userProfile?.link_url && (
                  <a href={user?.userProfile?.link_url} target="_blank">
                    <Icon src={Globe} style={{ width: '14px' }} />
                  </a>
                )}
              </div>
              {user?.userProfile?.is_open_to_work && (
                <div className={Styles.openToWork}>
                  <Icon src={Briefcase} />
                  <p>{t('odp-pu.user.profile.open-to-work')}</p>
                </div>
              )}

              <div className={Styles.privateInfo}>
                <h4>{t('odp-pu.user.profile.private-information')}</h4>
                <p>
                  {user?.userProfile?.first_name} {user?.userProfile?.last_name}
                </p>
                {user?.userProfile?.date_of_birth && (
                  <p>{moment(user?.userProfile?.date_of_birth).format(dateFormat)}</p>
                )}
                <p>{toTitleCase(user?.userProfile?.gender)}</p>
                {user?.userProfile?.mobile && (
                  <div className={Styles.contactInfo}>
                    <Icon src={Phone} />
                    <p>{user?.userProfile?.mobile}</p>
                  </div>
                )}
              </div>
              <div className={Styles.resume}>
                <h4>{t('odp-pu.label.profile.curriculum-vitae')}</h4>
                <div className={Styles.cv}>
                  <Icon src={File} />
                  <p className="m-0">Resume.pdf</p>
                </div>
              </div>
            </div>
            <div className={Styles.userAboutInfo}>
              <div className={Styles.about}>
                <p>{t('odp-pu.menu.footer.about')}</p>
                <p>{user?.userProfile?.about || 'Nothing to show here...'}</p>
              </div>

              <div className={Styles.userSkills}>
                <p>{t('odp-pu.label.profile.skills')}</p>

                {user?.userSkillSets?.length ? (
                  <div className={Styles.skillsContainer}>
                    {user?.userSkillSets.length > 0 &&
                      user?.userSkillSets.map(({ skill_set_name, skill_level }) => (
                        <div className={Styles.skill}>
                          <p className={Styles.skillBadge}>{skill_set_name}</p>
                          <div className={Styles.skillRating}>
                            {levels.map((level) => (
                              <div
                                className={`${Styles.circle} ${
                                  level <= skill_level ? Styles.skillFilled : ''
                                }`}></div>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  'No Skills'
                )}
              </div>
            </div>
          </div>
        )}

        <UserStats user={user} userLoading={userLoading} />

        {((own?.user_id && own?.user_id == user_id) || own?.is_admin) && (
          <div className={`row ${Styles.workExperience}`}>
            <h4 className="col-12 col-md-3">Work Experience</h4>
            <div className="col-12 col-md-9">
              {user?.userExperienceDetails &&
                user?.userExperienceDetails.length > 0 &&
                user?.userExperienceDetails.map(
                  ({
                    company_name,
                    is_current_job,
                    job_description,
                    job_title,
                    start_date,
                    end_date
                  }) => (
                    <div className={`${Styles.workLists}`}>
                      <p>{job_title}</p>
                      <p>{company_name}</p>
                      {is_current_job ? (
                        <p>{t('odp-pu.label.profile.current')}</p>
                      ) : (
                        <p>
                          {moment(start_date).format(dateFormat)} -{' '}
                          {moment(end_date).format(dateFormat)}{' '}
                        </p>
                      )}
                      <p className={Styles.jobDescription}>{job_description}</p>
                    </div>
                  )
                )}
            </div>
          </div>
        )}

        {((own?.user_id && own?.user_id == user_id) || own?.is_admin) && (
          <div className={`row ${Styles.education}`}>
            <h4 className="col-12 col-md-3">Education</h4>
            <div className="col-12 col-md-9">
              {user?.userEducationDetails &&
                user?.userEducationDetails.length > 0 &&
                user?.userEducationDetails.map(
                  ({
                    education_name,
                    start_date,
                    end_date,
                    is_current_education,
                    institute_name,
                    education_description
                  }) => (
                    <div className={`${Styles.educationList}`}>
                      <p>{education_name}</p>
                      <p>{institute_name}</p>
                      {is_current_education ? (
                        <p>{t('odp-pu.label.profile.current')}</p>
                      ) : (
                        <p>
                          {moment(start_date).format(dateFormat)} -{' '}
                          {moment(end_date).format(dateFormat)}{' '}
                        </p>
                      )}
                      <p className={Styles.educationDescription}>{education_description}</p>
                    </div>
                  )
                )}
            </div>
          </div>
        )}

        <div className={Styles.challenges}>
          <h2 className="text-center">Challenges</h2>
          {!loading && (
            <div className="mt-5 mb-5">
              {listings.length > 0 ? (
                listings.map((challenge) => (
                  <div className="mt-4">
                    <ChallengeCard
                      {...challenge}
                      previewChallenge={`/challenge/${challenge.competition_id}`}
                    />
                  </div>
                ))
              ) : (
                <div className="mb-5">
                  <Empty />
                </div>
              )}
            </div>
          )}
          {loading && (
            <div className="mt-5 mb-5">
              <SkeletonLoading style={{ height: '250px', width: '100%', borderRadius: '10px' }} />
              <SkeletonLoading
                style={{
                  height: '250px',
                  width: '100%',
                  marginTop: '30px',
                  borderRadius: '10px'
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
