import update from 'immutability-helper';
import { GET_PROTECTED_FILE, RESET_PROTECT_FILE, SAVE_PROTECTED_FILE } from '../actions/protectedFileAction';
import { failure, request, success } from "../../utils/fetch";

const initialState = {
    statusCode: 0,
    challengeSolutionFile: null,
    challengeDataSetFile: null,
    actionType: null,
    loading: false
}

export function createProtectedFileReducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_PROTECTED_FILE:
            return update(state, {
                loading: { $set: false },
                actionType: { $set: 'SAVE' },
                error: { $set: null }
            });

        case request(SAVE_PROTECTED_FILE):
            return update(state, {
                loading: { $set: true },
                actionType: { $set: 'SAVE' },
                error: { $set: null }
            });
        case success(SAVE_PROTECTED_FILE):
            return update(state, {
                statusCode: { $set: 0 },
                actionType: { $set: 'SAVE' },
                challengeSolutionFile: { $set: action.response },
                loading: { $set: false }
            });
        case failure(SAVE_PROTECTED_FILE):
            return update(state, {
                loading: { $set: false },
                actionType: { $set: 'SAVE' },
                error: { $set: action.error }
            });
        case RESET_PROTECT_FILE:
            return update(state, {
                challengeSolutionFile: { $set: null },
                loading: { $set: false },
                error: { $set: null }
            });
        case request(GET_PROTECTED_FILE):
            return update(state, {
                loading: { $set: true },
                actionType: { $set: 'GET' },
                error: { $set: null }
            });
        case success(GET_PROTECTED_FILE):
            const { statusCode } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                actionType: { $set: 'GET' },
                challengeSolutionFile: { $set: action.response },
                loading: { $set: false }
            });
        case failure(GET_PROTECTED_FILE):
            return update(state, {
                loading: { $set: false },
                actionType: { $set: 'GET' },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}