import { useSelector, useDispatch } from 'react-redux';
import { Formik } from "formik";
import { Form, Input, Checkbox } from "formik-antd";
import { Checkbox as Check } from "antd";
import { singup } from '../../services/auth/api';
import {
  LinkedinFilled,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './signup.scss';
import { useTranslation } from 'react-i18next';
import { getLinkedInUrl } from '../../utils';
import { signUpValidatorSchema } from '../../helpers/schemas';
import Button from '../../components/Button'
import { Modal, Spin } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { useState } from 'react';
import { resetCalls } from 'react-ga';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Index = () => {
  const { t } = useTranslation();
  const [consentModal, setConsentModal] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedNoCoolingOff, setHasAcceptedNoCoolingOff] = useState(false);
  const [hasAcceptedShareData, setHasAcceptedShareData] = useState(false);
  const [isLinkedIn, setIsLinkedIn] = useState(false);
  const [payload, setPayload] = useState({});

  const { loading } = useSelector((state) => state.signup);

  const onLinkedIn = () => {
    window.location.href = getLinkedInUrl('signUp_linkedIn', hasAcceptedTerms, hasAcceptedNoCoolingOff, hasAcceptedShareData);
  }

  const dispatch = useDispatch();

  const submitSignUp = (values, { setSubmitting }, errors) => {

    setPayload(values);
    setConsentModal(true)

  }

  const signupUser = () => {
    if (isLinkedIn) {
      onLinkedIn();
      return
    }
    let requestObject = {
      ...payload,
      has_accepted_terms_of_use: hasAcceptedTerms,
      has_accepted_no_cooling_off_period: hasAcceptedNoCoolingOff,
      has_accepted_share_data_3rd_parties: hasAcceptedShareData,
    }


    singup(dispatch, {
      data: requestObject,
    });
  }

  const handleSubmit = () => { };

  const reset = () => {
    setHasAcceptedNoCoolingOff(false);
    setHasAcceptedShareData(false);
    setHasAcceptedTerms(false);
    setIsLinkedIn(false)
  }

  return (
    <>
      <div className="signup__wrapper">
        <div className="signup__form">
          <div className="form__header">
            <h1>{t('odp-pu.signup.join-open-data-playground')}</h1>
            <p>Open Search Tech Srl - P.IVA 15951221009</p>
            <p className="pg-regular">
              {t('odp-pu.signup.check-out-open-data-playground-s-ranking')}
            </p>
          </div>

          <div className="form__body">
            <div className="left__form">
              <button className="linkedin__btn" onClick={() => { setConsentModal(true); setIsLinkedIn(true) }}>
                <LinkedinFilled className="linked__icon" />
                <span>{t('odp-pu.signup.continue-in-with-linkedin')}</span>
              </button>
            </div>
            <span className="vertical"></span>
            <Formik
              initialValues={{
                email: null,
                password: null,
                confirmPassword: null,
                allow_newsletter: false,
                termsConditions: false,
              }}
              enableReinitialize={true}
              validationSchema={signUpValidatorSchema}
              onSubmit={submitSignUp}
              render={({ values, errors, isValid, touched }) => (
                <div className="right__form">
                  <Form onSubmit={handleSubmit}>
                    <>
                      <div className="input__wrapper">
                        <Input
                          placeholder={t('odp-pu.signup.email-address')}
                          name='email'
                          className={(errors.email && touched.email) && "inputError"}
                        />
                        {(errors.email && touched.email) && <p className="error-message">{t(errors.email)}</p>}
                      </div>
                      <div className="input__wrapper">
                        <Input.Password
                          placeholder={t('odp-pu.login.password')}
                          name='password'
                          className={(errors.password && touched.password) && "inputError"}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        {(errors.password && touched.password) && <p className="error-message">{t(errors.password)}</p>}
                      </div>
                      <div className="input__wrapper">
                        <Input.Password
                          placeholder={t('odp-pu.signup.confirm-password')}
                          name='confirmPassword'
                          className={(errors.confirmPassword && touched.confirmPassword) && "inputError"}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                        />
                        {(errors.confirmPassword && touched.confirmPassword) && <p className="error-message">{t(errors.confirmPassword)}</p>}
                      </div>

                      {/* <div className="checkbox-wrapper">
                      <Checkbox name='allow_newsletter'>
                        <span className={`checkbox__label textError}`}>
                          {t('odp-pu.signup.please-send-me-the-open-data-playground-')}
                        </span>
                      </Checkbox>
                    </div> */}
                      {/* <div className="checkbox-wrapper">
                        <Checkbox name='termsConditions' className='termsConditions'>
                          <span className={`checkbox__label ${(errors.termsConditions && touched.termsConditions) && "textError"}`}>
                            {t('odp-pu.signup.i-agree-to-the-open-data-playground-term')}
                          </span>
                        </Checkbox>
                      </div> */}
                      {/* <br /> */}

                      <div className="block">
                        <Button
                          type="accent"
                          className="btnSignUp"
                          disabled={loading === 'pending' ? true : false}
                          submitType="submit"
                        >
                          <span>{t('odp-pu.signup.sign-up-with-email')}</span>
                        </Button>
                      </div>
                    </>
                  </Form>
                </div>
              )}
            />
          </div>

          <div className="form__footer">
            <span className="pg-regular gray"> {t('odp-pu.signup.already-have-an-account-')}</span>
            <Link className="them__link" to="/login">
              Login
            </Link>
          </div>
        </div>
      </div>
      <Modal
        title={" "}
        visible={consentModal}
        // onOk={joinChallenge}
        onCancel={() => { setConsentModal(false); reset() }}
        width={850}
        centered
        footer={[
          <div >
            <Button
              type="accent"
              className="btnSignUp"
              key="submit"
              disabled={!hasAcceptedTerms}
              onClick={signupUser}
            >
              {loading === 'pending' ? (
                <Spin indicator={antIcon} />
              ) : (
                `Confirm`
              )}
            </Button>
          </div>,
        ]}
      >
        <div>
          <p>Cliccando su “Sign up with email/Continue in with Linkedin”, dichiari di aver preso visione e di accettare le <Link to={'/terms-and-conditions'} className="link-privacy-policy" target={"_blank"}>Condizioni Generali</Link> e il
            trattamento dei tuoi dati personali (dati identificativi e di contatto) per la creazione del Profilo e fruizione dei servizi offerti da OST,
            ai sensi del punto 3 lett. a) <Link to={'/privacy-policy'} className="link-privacy-policy" target={"_blank"}>Informativa Privacy</Link></p>

          <Check
            checked={hasAcceptedTerms}
            onChange={(e) => {
              setHasAcceptedTerms(e.target.checked);
            }}

            style={{ marginLeft: '8px' }}
          >
            Ai sensi e per gli effetti degli artt. 1341 e 1342 c.c. o degli artt. 33 e 34 del D.lgs. n. 206/2005 a seconda che tu agisca
            rispettivamente come Utente Professionista o come Utente Consumatore, dichiari di aver letto le <Link to={'/terms-and-conditions'} className="link-privacy-policy" target={"_blank"}>Condizioni Generali</Link> e di
            approvare espressamente i seguenti articoli: 4 (Caratteristiche e modalità di funzionamento della Piattaforma), 7 (Garanzia e
            limitazione di responsabilità), 9.1 (Sospensione), 9.2 (Manleva), 10.1 (Recesso), 10.2 (Clausola risolutiva espressa); 11 (Forza
            Maggiore), 12 (Modifica delle Condizioni Generali), 14.4 (Divieto di cessione), 15.2 (Foro Compente), 20.4 (Onere prova
            conformità).*
          </Check>

          <Check
            checked={hasAcceptedNoCoolingOff}
            onChange={(e) => {
              setHasAcceptedNoCoolingOff(e.target.checked);
            }}
            className="mt-3"
          >
            Dichiaro espressamente di voler ricevere i Servizi senza attendere il decorso del Periodo Ripensamento, ai sensi dell art. 17.2
            delle <Link to={'/terms-and-conditions'} className="link-privacy-policy" target={"_blank"}>Condizioni Generali</Link>.**
          </Check>

          <Check
            checked={hasAcceptedShareData}
            onChange={(e) => {
              setHasAcceptedShareData(e.target.checked);
            }}
            className="mt-3 mb-4"

          >
            Autorizzo OST a comunicare i miei dati personali (dati identificativi e di contatto) a soggetti terzi, partner di OST, per
            consentire a tali soggetti di inviarmi comunicazioni promozionali aventi ad oggetto i loro prodotti e servizi 
            (cfr. punto 3 lett. e) <Link to={'/privacy-policy'} className="link-privacy-policy" target={"_blank"}>Informativa Privacy</Link>).**

          </Check>


          <div >
            <p>
              *Consenso necessario. La mancata apposizione del flag non consente all’Utente di creare il Profilo e utilizzare i Servizi.
            </p>
            <p>**Consenso facoltativo. La mancata apposizione del flag non consente, rispettivamente, all’Utente Consumatore di utilizzare i Servizi (e.g. iscriversi a una
              Challenge) finché non sia decorso il termine di 14 giorni dalla data di sottoscrizione delle <Link to={'/terms-and-conditions'} className="link-privacy-policy" target={"_blank"}>Condizioni Generali</Link>, e/o agli Utenti di ricevere comunicazioni
              promozionali da parte dei soggetti terzi partner di OST.</p>
          </div>
        </div>

      </Modal>
    </>
  );
};

export default Index;
