import { createSlice } from '@reduxjs/toolkit';
import { loading } from '../../helpers/loading';

const initialState = {
  loading: loading.INIT,
  error: null,
};

export const userSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setStart: (state) => {
      state.loading = loading.PENDING;
    },
    setComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
    },
    setError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },
  },
});

export const { setStart, setComplete, setError } = userSlice.actions;

export default userSlice.reducer;
