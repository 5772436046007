export const SOLUTION = 'SOLUTION';
export const DATASET = 'DATASET';
export const PREDICTION = 'PREDICTION';

export const YOU_TUBE_URL = 'https://www.youtube.com/channel/UCG1I6lWjGbOz-7sEyvHYB3g'
export const LINKED_IN_URL = 'linkedin.com/company/open-data-playground'
export const INSTAGRAM_URL = 'https://www.instagram.com/opendataplayground/'


export const BASIC_FIELDS = [
    'competition_name',
    'competition_description',
    'company_id',
    'competition_category_id',
    'competition_type_id',
    'participation_type',
    'start_date',
    'end_date',
    'start_time',
    'end_time',
    'winning_prize',
    'competition_location_type',
    'competition_location_name',
    'competition_location_address',
]