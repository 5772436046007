import { Link } from 'react-router-dom';

import { ArrowRightOutlined } from '@ant-design/icons';
import TextLogo from '../../asset/images/footer/text-logo.svg';
import './secondryNav.scss';
const Index = () => {
  return (
    <div className="secondry__nav__wrapper">
      <nav className="upper__nav">
        <Link to="/">
          <img src={TextLogo} alt={TextLogo} className="text__logo" />
        </Link>

        <Link to="/" className="go__link">
          <span>Go to website</span>{' '}
          <ArrowRightOutlined className="arrow__icon" />
        </Link>
      </nav>
    </div>
  );
};

export default Index;
