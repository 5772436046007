import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, DatePicker, Select, InputNumber } from 'formik-antd';

import './userPrivateInfo.scss';

const { Option } = Select;
const UserPrivateInfo = ({ isCompanyAdmin, isAdmin }) => {
  const { t } = useTranslation();
  return (
    <div className="privateinfo__wrapper">
      <div className="left__skill">
        <h4>{t('odp-pu.user.profile.private-information')}</h4>
      </div>
      <div className="rigt__skill">
        <div className="upper__section">
          <div className="input__wrapper">
            <span className="inputLabels">{t('odp-pu.user.profile.name')}</span>
            <Input
              placeholder={t('odp-pu.user.profile.name')}
              name="userProfile.first_name"
              className="inputFields"
              disabled={isCompanyAdmin}
            />
          </div>
          <div className="input__wrapper ">
            <span className="inputLabels">{t('odp-pu.user.profile.surname')}</span>
            <Input
              placeholder={t('odp-pu.user.profile.surname')}
              name="userProfile.last_name"
              className="inputFields"
              disabled={isCompanyAdmin}
            />
          </div>
          <div className="input__wrapper calender__wrapper">
            <span className="inputLabels">{t('odp-pu.user.profile.date-of-birth')}</span>
            <DatePicker
              name="userProfile.date_of_birth"
              className="inputFields"
              disabled={isCompanyAdmin}
            />
          </div>
        </div>
        <div className="lower__section">
          {isAdmin && <div className="input__wrapper number__wrapper">
            <span className="inputLabels">{t('odp-pu.contact.email')}</span>

            <Input
              name="userProfile.email"
              className="inputFields"
              disabled
            />
            <span className="error-text"></span>
          </div>}
          <div className="input__wrapper">
            <span className="inputLabels">{t('odp-pu.user.profile.gender')}</span>
            <Select
              optionFilterProp="children"
              name="userProfile.gender"
              showSearch
              placeholder={t('odp-pu.user.profile.gender')}
              disabled={isCompanyAdmin}
              style={{
                width: 100 + '%',

                borderRadius: 5 + 'px',
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            >
              <Option value="male">Male</Option>;
              <Option value="female">Female</Option>;
            </Select>

            <span className="error-text"></span>
          </div>
          <div className="input__wrapper number__wrapper">
            <span className="inputLabels">{t('odp-pu.user.profile.phone-number')}</span>

            <InputNumber
              name="userProfile.phone"
              className="inputFields"
              disabled={isCompanyAdmin}
            />
            <span className="error-text"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPrivateInfo;
