export const setToken = (token) => {
    localStorage.setItem('odp_token', token);
};
export const getToken = () => {
    return localStorage.getItem('odp_token');
};
export const removeToken = () => {
    return localStorage.removeItem('odp_token');
};

export const setUserID = (user_id) => {
    localStorage.setItem('odp_user_id', user_id);
};
export const getUserID = () => {
    return localStorage.getItem('odp_user_id');
};
export const removeUserID = () => {
    return localStorage.removeItem('odp_user_id');
};

export const setUserProfile = ({ display_name, first_name, city }) => {
    localStorage.setItem('user_profile', JSON.stringify({ display_name, first_name, city }))
}

export const displayName = () => {
    if (localStorage.getItem('user_profile')) {
        let { display_name } = JSON.parse(localStorage.getItem('user_profile'));
        return display_name;
    }
}

export const clearStorage = () => {
    localStorage.clear();
}