export const SAVE_PROTECTED_FILE = 'SAVE_PROTECTED_FILE';
export const RESET_PROTECT_FILE = 'RESET_PROTECT_FILE';
export const GET_PROTECTED_FILE = 'GET_PROTECTED_FILE';

export const createProtectedFile = (request) => ({
    type: SAVE_PROTECTED_FILE,
    request
});

export const getProtectedFileAction = (request) => ({
    type: GET_PROTECTED_FILE,
    request
})

export const resetProtectedFile = () => ({
    type: RESET_PROTECT_FILE
})