import { loginEndPoint, forgotPasswordEndpoint, resetPasswordEndpoint } from '../../utils/endPoints';
import { axiosInstance, axiosInstanceWithoutAuth } from '../../configs/axois';
import { notify } from '../../theme/antNotify';

export const login = async ({ request }) => {
    try {
        const { data, status } = await axiosInstance.post(loginEndPoint, request);
        return data;
    } catch (err) {
        throw err;
    }
    
}

export const forgotPassword = async ({ request }) => {
    try {
        const { data, status } = await axiosInstanceWithoutAuth.put(forgotPasswordEndpoint, request);
        return data;
    } catch (err) {
        throw err;
    }
}

export const resetPassword = async ({ request }) => {
    try {
        const { data, status } = await axiosInstanceWithoutAuth.put(resetPasswordEndpoint(request.hash), request);
        return data;
    } catch (err) {
        throw err;
    }
}
