import React from "react";
import BackArrow from "../../asset/icons/backArrow.svg";
import Icon from "../../components/Icons";
import { useHistory } from "react-router-dom";
import Styles from "./back.module.scss";
import { useTranslation } from "react-i18next";
const Back = ({ onClick }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div onClick={onClick}>
      <a onClick={() => history.goBack()} className={Styles.back}>
        <Icon src={BackArrow} />{" "}
        <span>{t("odp-pu.general.labels.go-back")}</span>
      </a>
    </div>
  );
};

export default Back;
