
import HeroImg from '../../asset/images/hero-img.png';
import './hero.scss';
import { useTranslation } from 'react-i18next';
import Button from '../Button'

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const Hero = () => {
  const history = useHistory();
  const { user } = useSelector(state => state.userProfile)
  const { t } = useTranslation();

  const routingToPage = () => {
    let routing = '/signup';
    routing = user ? '/challenges' : routing;
    history.push(routing);
  }

  return (
    <div className="hero__wrapper">
      <div className='row align-items-center justify-content-between'>
        <div className='col-md-6'>
          <h1 className='hero-heading'>{t('odp-pu.homepage.enter-the-playground')}</h1>
          <p className='hero-content'>{t('odp-pu.homepage.discover-open-data-playground-the-hacka')}</p>
          <Button type='secondary' onClick={routingToPage} size="lg">{t('odp-pu.homepage.discover-more')}</Button>
        </div>
        <div className='col-md-6 text-right'>
          <img className='hero-img' src={HeroImg} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
