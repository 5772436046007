import React from 'react'
import Back from '../../../../components/Back';
import SponsorPreview from '../../../../pages/Sponser/sponsorPreview';
import Styles from './preview.module.scss';

const PreviewSponsor = () => {
    return (
        <div className={Styles.sponsorPreviewContainer}>
            <Back link={"/admin/challenges"} />
            <SponsorPreview isAdmin={true} />
        </div>
    )
}

export default PreviewSponsor