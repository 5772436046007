import { axiosInstance } from "../../configs/axois";
import { sponsorEndpoint } from "../../utils/endPoints";

export const createSponsor = async (payload) => {
    try {
        const { data, status } = await axiosInstance.post(
            sponsorEndpoint,
            payload
        );
        return data;
    } catch (err) {
        throw "Error Creating Sponsor";
    }
};

export const updateSponsor = async (payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `${sponsorEndpoint}/${payload.company_id}`,
            payload
        );
        return data;
    } catch (err) {
        throw "Error Creating Sponsor";
    }
};

export const getSponsorById = async (sponsorId) => {
    try {
        const { data } = await axiosInstance.get(
            `${sponsorEndpoint}/${sponsorId}`
        );
        return data;
    } catch (err) {
        throw 'Error'
    }
}

export const publishSponsor = async (sponsorId) => {
    try {
        const { data, status } = await axiosInstance.put(
            `${sponsorEndpoint}/${sponsorId}/publish`,
            null
        );
        return data;
    } catch (err) {
        throw "Error Creating Sponsor";
    }
};

export const unPublishSponsor = async (sponsorId) => {
    try {
        const { data, status } = await axiosInstance.put(
            `${sponsorEndpoint}/${sponsorId}/unpublish`,
            null
        );
        return data;
    } catch (err) {
        throw "Error Creating Sponsor";
    }
};

export const inviteContact = async (sponsorId, payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `${sponsorEndpoint}/${sponsorId}/contactInvitation`,
            payload
        );
        return data;
    } catch (err) {
        throw "Error Contact Invitation";
    }
};

export const getSponsorContacts = async (sponsorId) => {
    try {
        const { data, status } = await axiosInstance.get(
            `${sponsorEndpoint}/${sponsorId}/contacts`
        );
        return data;
    } catch (err) {
        throw "Error Get Contact";
    }
};

export const updateSponsorContact = async (sponsorId, contactId, payload) => {
    try {
        const { data, status } = await axiosInstance.put(
            `${sponsorEndpoint}/${sponsorId}/contacts/${contactId}`,
            payload
        );
        return data;
    } catch (err) {
        throw "Error Update Contact";
    }
};