import { BooleanConverter, NumberConverter, StringConverter } from '../../utils';

export class SponsorObject {

    company_id = null;
    company_name = null;
    company_description = null;
    logo_file_id = null;
    company_email = null;
    contact_email = null;
    company_website = null;
    privacy_policy_url = null;
    banner_file_id = null;
    is_published


    constructor() {
        this.company_name = null;
        this.company_description = null;
        this.logo_file_id = null;
        this.company_email = null;
        this.contact_email = null;
        this.company_website = null;
        this.privacy_policy_url = null;
        this.banner_file_id = null
        this.is_published = false

    }

    deserialize(model) {
        this.company_id = StringConverter(model.company_id)
        this.company_name = StringConverter(model.company_name)
        this.company_description = StringConverter(model.company_description)
        this.logo_file_id = StringConverter(model.logo_file_id)
        this.banner_file_id = StringConverter(model.banner_file_id)
        this.company_email = StringConverter(model.company_email)
        this.contact_email = StringConverter(model.contact_email)
        this.company_website = StringConverter(model.company_website)
        this.privacy_policy_url = StringConverter(model.privacy_policy_url)
        this.is_published = BooleanConverter(model.is_published)
        return this;
    }
}