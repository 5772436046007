export const GET_USER = "GET_USER";
export const SAVE_USER = "SAVE_USER";
export const GET_DOWNLOAD_DATA = "GET_DOWNLOAD_DATA";
export const REMOVE_DOWNLOAD_DATA = "REMOVE_DOWNLOAD_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_DISPLAY_NAME = "USER_DISPLAY_NAME";
export const REMOVE_USER = "REMOVE_USER";

export const getUserById = (userId) => ({
  type: GET_USER,
  userId,
});

export const saveUser = (payload) => ({
  type: SAVE_USER,
  payload,
});

export const downloadData = () => ({
  type: GET_DOWNLOAD_DATA,
});

export const removeDownloadData = () => ({
  type: REMOVE_DOWNLOAD_DATA,
});

export const clearStoreOnLogout = () => ({
  type: USER_LOGGED_OUT,
});

export const removeUser = () => ({
  type: REMOVE_USER
});

export const userDisplayName = (userDisplayName) => ({
  type: USER_DISPLAY_NAME,
  userDisplayName
});
