import Arrived from '../../asset/images/general/arrived.svg';

const Index = () => {
  return (
    <div className="development__wrapper">
      <h1>Verification</h1>
      <h3>Link has been sent</h3>
      <p className="pg-regular">
      We have sent an email link to your registered email. Kindly open the link for verification.
      </p>

      <img src={Arrived} alt={Arrived} />
    </div>
  );
};

export default Index;
