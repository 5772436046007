import React, { useState, useEffect } from 'react';
import Styles from './assets.module.scss';
import UserIcon from '../../../asset/images/team/card/user.svg';
import Icon from '../../../components/Icons';
import { Spin } from 'antd';
import { fetchChallengeData } from '../../../redux-store/services/Challenges';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Button from '../../../components/Button';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { clearStorage } from '../../../services/token';

const Assets = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [status, setStatus] = useState(1); //1 - Fetching, 2 - Success, 3 - Error
  const [documentUrl, setDocumentUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();
  const { pathname, search } = useLocation();
  const { challengeId } = useParams();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.get('type');
    downloadChallengeData(searchParam);
    return () => {};
  }, []);

  const downloadChallengeData = async (searchParam) => {
    try {
      setStatus(1);
      setShowLoading(true);
      setErrorMessage('');
      if (pathname.includes('admin/assets/')) {
        localStorage.setItem('_admin_asset', pathname + search);
      }
      const response = await fetchChallengeData({
        exportId: challengeId,
        type: searchParam
      });

      localStorage.removeItem('_admin_asset');
      if (response) {
        let mimeType;
        const fileExtension = response.fileExtension.replace('"', '');

        if (searchParam === 'cv') {
          mimeType =
            fileExtension === 'doc'
              ? 'application/msword'
              : fileExtension === 'docx'
              ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              : 'application/pdf';
        } else {
          mimeType = 'application/zip';
        }
        if (fileExtension !== 'pdf') {
          const blob = new Blob([response.data], { type: mimeType }); // replace 'application/pdf' with the correct MIME type for your file
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = response.fileName.replace(/"/g, '');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          const blob = new Blob([response.data], { type: mimeType }); // replace 'application/pdf' with the correct MIME type for your file
          const url = window.URL.createObjectURL(blob);
          setDocumentUrl(url);
          window.open(url, '_blank');
        }
      }
      setStatus(2);
      setShowLoading(false);
    } catch (err) {
      // localStorage.removeItem('admin_asset');
      setShowLoading(false);
      setStatus(3);
      setErrorMessage(err?.statusPhrase);
    }
  };

  return (
    <div className={Styles.assetContainer}>
      <div className={Styles.userCard}>
        <Icon src={UserIcon} />
        <div className={Styles.bars}>
          <span></span>
          <span></span>
        </div>
        <div
          className={`${Styles.verificationLoading} ${
            status === 2 ? Styles.success : status === 3 ? Styles.danger : ''
          }`}>
          {showLoading && (
            <Spin spinning={showLoading} className={`spinner ${Styles.spinIcon}`} size="small" />
          )}
          {status === 2 && <CheckCircleOutlined style={{ color: '#fff' }} />}
          {status === 3 && <ExclamationCircleOutlined style={{ color: '#fff' }} />}
        </div>
      </div>
      {status === 1 && (
        <>
          <h5>Verification is in process</h5>
          <p>please wait..</p>
        </>
      )}
      {status === 2 && (
        <>
          <h5>Successfully Verified</h5>
          <p className="mt-2">
            Verification has been Successfully performed, You can now download the file.
          </p>
          <Button
            size="sm"
            onClick={() => {
              window.open(documentUrl, '_blank');
            }}>
            Preview
          </Button>
        </>
      )}
      {status === 3 && (
        <>
          <h5>Verification failed</h5>
          <p className="mt-2">Unfortunately verification has been failed due to:</p>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </>
      )}
    </div>
  );
};

export default Assets;
