import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { verifyEmail } from "../../redux-store/actions/accountSettingsAction";

export const ResetEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyEmailChange = () => {
    const { reset_email_token } = queryString.parse(history.location.search);
    dispatch(verifyEmail(reset_email_token));
  };

  useEffect(() => {
    verifyEmailChange();
  }, []);
  return (
    <div className="text-center">
      <h1>Reset Email</h1>
    </div>
  );
};
