import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./resetPassword.module.scss";
import { AButton } from "../../theme/button";
import { Input } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "../../helpers/schemas";
import { resetPasswordAction } from "../../redux-store/actions/authenticationAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { notify } from "../../theme/antNotify";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { resetPasswordLoad, success } = useSelector(state => state.authentication)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(changePasswordSchema),
  });

  const confirmResetPassword = (requestObject) => {
    requestObject.hash = queryString.parse(
      history.location.search
    ).reset_password_token;
    dispatch(resetPasswordAction(requestObject));
  };

  // useEffect(() => {
  //   if (success) {
  //     notify("success", "Your Password has been Reset", "Redirecting you to Login");
  //     history.push('/login')
  //   }

  // }, [success])

  return (
    <div className={Styles.resetPassword}>
      <div className={Styles.resetPasswordForm}>
        <div className={Styles.header}>
          <h2>Change your password</h2>
        </div>

        <div className={Styles.body}>
          <div className={Styles.form}>
            <Input.Password
              type="password"
              name="password"
              placeholder="New Password"
              className={Styles.inputPassword}
              {...register("new_password")}
            />
            <ul>
              <li
                className={`${errors?.new_password?.message == "number"
                  ? Styles.validationError
                  : ""
                  }`}
              >
                Password must contain numbers
              </li>
              <li
                className={`${errors?.new_password?.message == "upperCase"
                  ? Styles.validationError
                  : ""
                  }`}
              >
                Password must contain uppercase letters
              </li>
              <li
                className={`${errors?.new_password?.type == "min"
                  ? Styles.validationError
                  : ""
                  }`}
              >
                Length must be greater than 8 characters
              </li>
            </ul>
            <Input.Password
              type="password"
              name="confirmPassword"
              placeholder="Confirm New Password"
              className={Styles.inputPassword}
            />

            <div className={Styles.buttonBlock}>
              <AButton
                onClick={handleSubmit(confirmResetPassword)}
                label="Change your password"
                type="regular"
                loading={resetPasswordLoad}
              ></AButton>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <span className="pg-regular gray">
            Already have login and password ?
          </span>
          <Link className={Styles.linkLabel} to="login">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
