import React, { useEffect } from 'react';
import OurServices from '../../components/OurServices/ourServices';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSponsorListings } from '../../redux-store/actions/sponsorAction';
import svg1 from '../../asset/icons/static-pages-svgs/5.svg';
import svg2 from '../../asset/icons/static-pages-svgs/10.svg';
import svg3 from '../../asset/icons/static-pages-svgs/4.svg';
import svg4 from '../../asset/icons/static-pages-svgs/18.svg';
import svg5 from '../../asset/icons/static-pages-svgs/14.svg';
import svg6 from '../../asset/icons/static-pages-svgs/13.svg';
import svg7 from '../../asset/icons/static-pages-svgs/15.svg';
import ContactForm from '../Contact/ContactForm/ContactForm';
import './ourSponsors.scss';
import { useHistory } from 'react-router-dom';
import { useIsDesktop } from '../../hooks/customHooks';

const OurSponsors = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const getSponsors = () => {
    dispatch(getSponsorListings({ is_published: true }));
  };

  useEffect(() => {
    getSponsors();
  }, []);

  const routeToPreview = (sponsorId) => {
    history.push(`sponsor/${sponsorId}`);
  };

  const { loading, listings, _metadata } = useSelector((state) => state.sponsors);

  const isMobile = useIsDesktop();

  const text = {
    cards: [
      {
        id: 1,
        title: svg1,
        description: `${t('odp-pu.about.attracted-about-corporate-sponsors-')}`
      },
      {
        id: 2,
        title: svg2,
        description: `${t('odp-pu.about.hosted-competitions-')}`
      },
      {
        id: 3,
        title: svg3,
        description: `${t('odp-pu.about.involved-more-than-players-')}`
      }
    ],
    cards1: [
      {
        id: 1,
        title: svg4,
        description: `${t('odp-pu.about.recruitment-challenges-and-graduate-prog')}`,
        description1: `${t('odp-pu.about.to-solve-concrete-business-problems-on-r')}`
      },
      {
        id: 2,
        title: svg5,
        description: `${t('odp-pu.about.hackathon-for-the-business-community')}`,
        description1: `${t('odp-pu.about.to-design-and-develop-projects-for-open-')}`
      },
      {
        id: 3,
        title: svg6,
        description: `${t('odp-pu.about.hackathon-in-house')}`,
        description1: `${t('odp-pu.about.for-re-skilling-up-skilling-projects-a')}`
      },
      {
        id: 4,
        title: svg7,
        description: `${t('odp-pu.about.academic-events')}`,
        description1: `${t('odp-pu.about.to-foster-contact-between-research-and-i')}`
      }
    ],
    cards2: []
  };

  return (
    <div className="development__wrapper">
      <div className="our_sponsor_heading">
        <h3 className="headingThree">{t('odp-pu.menu.footer.our-sponsors')}</h3>
        <p className="paragraph">{t('odp-pu.sponsor.find-the-different-entities-that-have-be')}</p>
      </div>

      <div className="row log_group">
        {listings &&
          listings.map((ele) => {
            return (
              <div className={`logo ${isMobile ? 'col-6' : 'col-3'}`}>
                <img
                  className={'sponsor-icon'}
                  onClick={() => routeToPreview(ele.company_id)}
                  src={ele ? process.env.REACT_APP_RESOURCES_URL + ele.logo_file_id : ''}></img>
              </div>
            );
          })}
      </div>
      <div className="about__ourservices">
        <OurServices text={text} titleOne={t('odp-pu.sponsor-page.why-become-our-sponsor-')} />
      </div>

      <div className="conatct_us">
        <h1>{t('odp-pu.contact.contact-us')}</h1>
        <p className="default__text conatct_us_p">
          {t('odp-pu.sponsor-page.enter-your-contact-details-in-the-form-')}
        </p>
        <ContactForm queryType={2} />
      </div>
    </div>
  );
};

export default OurSponsors;
