import React, { useEffect } from 'react';
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { supportiveLink, languages } from "../../helpers/links";
import i18n from "../../i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./upperNav.scss";
import { storeLanguage } from "../../services/token";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../redux-store/actions/utilsAction';
import { NavLink, useLocation, useHistory } from 'react-router-dom'
const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [defaultState, setDefaultState] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('currentLanguage') == "en-US" ? "ENG" : 'IT');

  const updateLanguage = (e) => {
    setCurrentLanguage(e.key);
    i18n.changeLanguage(e.key === "ENG" ? "en" : "it");
    storeLanguage(e.key === "ENG" ? "en-US" : "it-IT");
  };

  useEffect(() => {
    

    history.push(location.pathname + (location.search ? location.search : ''))

    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'en-US')
      setCurrentLanguage('ENG')
    }
    i18n.changeLanguage(localStorage.getItem('currentLanguage') == 'en-US' ? "en" : "it");
  }, [])

  const langMenu = (
    <Menu onClick={updateLanguage}>
      {languages.map(({ label, locale }) => {
        return (
          <Menu.Item key={label} locale={locale}>
            <span>{label}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className="upper__nav_wrapper">
      <nav className="upper__nav">
        <ul className="upper__nav__links">
          {supportiveLink.map((route) => {
            return (
              <li className="upper__nav__link" key={route.name}>
                <NavLink to={route.path} className={route.class}>
                  {t(route.name)}
                </NavLink>
              </li>
            );
          })}
          <li className="lanuage__wrapper">
            <Dropdown overlay={langMenu} placement="bottomLeft" trigger={['click']}>
              <span className="">
                {currentLanguage} <DownOutlined />
              </span>
            </Dropdown>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Index;
