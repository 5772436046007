import { axiosInstance, axiosInstanceWithoutAuth } from "../../configs/axois";
import { setToken, setUserID, setUserProfile } from "../token";
import { notify } from "../../theme/antNotify";
import { Redirect } from "react-router";
import history from "../../utils/history";
import {
  fetchStart,
  fetchComplete,
  fetchError,
} from "../../store/auth/userSlice";
import { setStart, setComplete, setError } from "../../store/auth/signupSlice";
import {
  getProfileTokenStart,
  getProfileTokenComplete,
  getProfileTokenError,
  updateLinkedInStatus,
} from "../../store/profile/";
import subscribeNotifications from "../../utils/subscribeNotifications";
import { getNotification } from "../../redux-store/actions/notificationActions";

/*login apis calling*/
export const login = async (dispatch, payload) => {
  try {
    dispatch(fetchStart());
    const response = await axiosInstance.post("login", { ...payload.data });
    dispatch(fetchComplete(response.data));
    setToken(response.data.access_token);
    setUserProfile(response.data.user.userProfile);
    setUserID(response.data.user.user_id);
    history.push(`/profile/${response.data.user.user_id}/profile`);
  } catch (error) {
    dispatch(fetchError(error));
  }
};

/*Signup apis calling*/
export const singup = async (dispatch, payload) => {
  try {
    dispatch(setStart());
    const response = await axiosInstance.post("signup", {
      email: payload.data.email,
      password: payload.data.password,
      allow_newsletter: payload.data.allow_newsletter,
      has_accepted_terms_of_use: payload.data.has_accepted_terms_of_use,
      has_accepted_no_cooling_off_period: payload.data.has_accepted_no_cooling_off_period,
      has_accepted_share_data_3rd_parties: payload.data.has_accepted_share_data_3rd_parties,
    });

    if (response.data.statusCode === 0) {
      notify(
        "success",
        "Signup Successfully",
        "kindly visit your email for further verification"
      );

      dispatch(setComplete(response.data));
      setTimeout(() => {
        history.push("/send-token");
      }, 500);
    }
  } catch (error) {
    dispatch(setError(error));
  }
};
/*Signup put token calling*/
export const verficationToken = async (dispatch, payload) => {
  try {
    dispatch(getProfileTokenStart());

    const response = await axiosInstance.put(
      `signup/${payload.data.hash}/validate`
    );

    if (response.data.statusCode === 0) {
      notify(
        "success",
        "verification has been successfully done",
      );
      dispatch(getProfileTokenComplete());
      setUserProfile(response.data.user.userProfile);
      setUserID(response.data.user.user_id);
      setToken(response.data.access_token);
      history.push(`/profile/${response.data.user.user_id}/profile`);
    }
  } catch (error) {
    notify("error", `Link not found or has been expired. Please try again.`);

    setTimeout(() => {
      history.push(`/signup`);
    }, 3000);

    dispatch(getProfileTokenError(error));
  }
};

export const LoginWithLinkedIn = async (dispatch, payload) => {
  try {
    dispatch(fetchStart());
    const response = await axiosInstance.post("login", { ...payload.data });
    dispatch(fetchComplete(response.data));
    setToken(response.data.access_token);

    setUserID(response.data.user.user_id);
    history.push(`/`);

    if (response.data.statusCode === 2307) {
      notify("error", response.data.statusPhrase, "Redirecting to Login");
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    }
  } catch (error) {
    notify("error", error.response.data.statusPhrase, "Redirecting to Login");
  }
};

export const SignUpWithLinkedIn = async (dispatch, payload) => {
  try {
    dispatch(fetchStart());
    const response = await axiosInstance.post("signup/linkedin", {
      ...payload.data,
    });

    if (response.data.statusCode === 0) {
      notify("success", "Successfully Authenticated with LinkedIn");
      dispatch(fetchComplete(response.data));
      response.data.access_token && setToken(response.data.access_token);
      response.data.user && setUserProfile(response.data.user.userProfile);
      response.data.user && setUserID(response.data.user.user_id);

      dispatch(updateLinkedInStatus(response.data));
      dispatch(getProfileTokenComplete());
      dispatch(getNotification(null));
      const notification = new subscribeNotifications();
      notification.start(dispatch);
      if (response.data.default_page === "profile") {
        history.push(`/profile/${response.data.user.user_id}/profile`);
      } else {
        history.push(`/profile/${response.data.user.user_id}/dashboard`);
      }
    } else if (response.data.statusCode === 2307) {

      notify("error", response.data.statusPhrase, "Redirecting to Login");
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    }
  } catch (error) {
    console.log('error');
    console.log(error);
    setTimeout(() => {
      history.push("/login");
    }, 5000);
  }
};
