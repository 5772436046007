import { Button, Card } from 'antd';
import CButton from '../../components/Button';
import React from 'react';
import Cross from '../../asset/icons/xsm.svg';
import Filter from '../../asset/icons/filter-white.svg';
import { useIsDesktop, useIsTablet } from '../../hooks/customHooks';
import Icon from '../../components/Icons';
import Styles from './challengefilter.module.scss';
import { toTitleCase } from '../../utils';

const ChallengeFilter = ({
  setShowFilters,
  onDoneFilters,
  filtersData,
  onFilterClick,
  onClearFilters
}) => {
  const isMobile = useIsDesktop();
  const isTablet = useIsTablet();
  return (
    <div key={2}>
      <div
        className={`d-flex ${!isTablet ? 'justify-content-end' : 'justify-content-start'}`}
        style={{
          marginBottom: -1,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          background: isTablet ? 'white' : ''
        }}>
        <div
          onClick={() => setShowFilters(false)}
          className="d-flex"
          style={{
            cursor: 'pointer',
            width: 90,
            alignItems: 'center',
            height: '40px',
            justifyContent: 'flex-end'
          }}>
          <img src={Cross} />
          <div
            style={{
              color: 'black',
              fontSize: 16,
              fontWeight: 'bold',
              marginLeft: 5
            }}>
            Filter
          </div>
        </div>
      </div>
      <Card
        className={Styles.filtersModal}
        style={isTablet ? { overflow: 'auto', maxHeight: '100vh' } : { width: '600px' }}>
        <h6>Status</h6>
        <div className="d-flex mt-3" style={{ flexWrap: isTablet ? 'wrap' : '' }}>
          {filtersData.status.map((item, index) => (
            <Button
              key={index}
              className={Styles.filterButton}
              onClick={() => onFilterClick('status', item, index)}
              style={{
                marginLeft: 5,
                marginBottom: 5,
                background: item.selected ? '#efefef' : ''
              }}>
              {item.label}
            </Button>
          ))}
        </div>
        <hr></hr>
        <h6>Type</h6>
        <div className="d-flex mt-3" style={{ flexWrap: isTablet ? 'wrap' : '' }}>
          {filtersData.type.map((item, index) => (
            <Button
              key={index}
              className={Styles.filterButton}
              style={{
                marginLeft: 5,
                background: item.selected ? '#efefef' : ''
              }}
              onClick={() => onFilterClick('type', item, index)}>
              {item.label}
            </Button>
          ))}
        </div>
        {filtersData.subject && filtersData.subject.length > 0 && <>
          <hr></hr>
          <h6>Subject</h6>
          <div style={{ flexDirection: 'column' }}>
            <div className="d-flex mt-3" style={{ flexWrap: 'wrap' }}>
              {filtersData.subject.map((item, index) => (
                <Button
                  key={index}
                  className={Styles.filterButton}
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    background: item.selected ? '#efefef' : ''
                  }}
                  onClick={() => onFilterClick('subject', item, index)}>
                  {toTitleCase(item.label)}
                </Button>
              ))}
            </div>
          </div>
        </>}
        {filtersData.accessibility && filtersData.accessibility.length > 0 && <>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <div>
              <h6>Accessibility</h6>
              <div className="d-flex mt-3" style={{ flexWrap: 'wrap' }}>
                {filtersData.accessibility.map((item, index) => (
                  <Button
                    key={index}
                    className={Styles.filterButton}
                    style={{
                      marginLeft: 5,
                      marginBottom: 5,
                      background: item.selected ? '#efefef' : ''
                    }}
                    onClick={() => onFilterClick('accessibility', item, index)}>
                    {item.label}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </>
        }
        <hr></hr>
        <h6>Location</h6>
        <div className="d-flex mt-3" style={{ flexWrap: isTablet ? 'wrap' : '' }}>
          {filtersData.location.map((item, index) => (
            <Button
              key={index}
              className={Styles.filterButton}
              style={{
                marginLeft: 5,
                marginBottom: 5,
                background: item.selected ? '#efefef' : ''
              }}
              onClick={() => onFilterClick('location', item, index)}>
              {item.label}
            </Button>
          ))}
        </div>
        <div
          style={{
            float: 'right',
            display: 'flex',
            alignItems: 'center',
            paddingBlock: isTablet ? 30 : 0
          }}>
          <CButton onClick={onClearFilters} as="link" className={Styles.clearButton}>
            Clear
          </CButton>
          <CButton
            onClick={() => onDoneFilters('done')}
            style={{ marginLeft: 20 }}
            type={'primary'}>
            <span>
              {' '}
              <Icon src={Filter} /> Done
            </span>
          </CButton>
        </div>
      </Card>
    </div >
  );
};

export default ChallengeFilter;
