import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Download from '../../asset/icons/downloadbtn.svg';
import termsAndConditionsPdf from './odp_terms_and_conditions.pdf';

import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  const sectionTwoRef = useRef(null);
  const scrollToSectionTwo = () =>
    sectionTwoRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  return (
    <div className="terms__and__conditions__container">
      <h5 className="bold__text">
        TERMINI E CONDIZIONI GENERALI DI UTILIZZO DELLA PIATTAFORMA “OPEN DATA PLAYGROUND”
      </h5>
      <h5 className="bold__text">SEZIONE I - PARTE GENERALE</h5>
      <h6 className="bold__text">ARTICOLO 1 - INTRODUZIONE</h6>
      <ol>
        <li>
          Open Search Tech S.r.l., con sede legale in Via G. Nicotera, 7, 00195, Roma, C.F., P.IVA e
          Registro delle Imprese di Roma n. 15951221009 (“<span className="bold__text">OST</span>” o
          “<span className="bold__text">Società</span>”) ha sviluppato ed è Ltolare della piaMaforma
          “Open Data Playground” (“<span className="bold__text">Piattaforma</span>”). Attraverso la
          Piattaforma OST - inter alia - organizza competizioni online/on site rivolte a “player”
          con competenze in ambito e-skill (Digital, STEM, innovation), come meglio descritte al
          successivo articolo 1.2 (“<span className="bold__text">Challenge</span>”), per conto di
          soggetti terzi - persone giuridiche (e.g. società di capitali, enti universitari, centri
          di ricerca e istituti di formazione professionale) interessati a individuare profili
          aventi determinate caratteristiche (“<span className="bold__text">Sponsor</span>”), e in
          generale presta a favore degli utenti i servizi di cui al successivo articolo 3
          (congiuntamente, “<span className="bold__text">Servizi</span>”).
        </li>
        <li>
          Le Challenge, organizzate, pubblicate sulla Piattaforma e gestite dalla Società per conto
          e sulla base delle indicazioni degli Sponsor, sono un processo competitivo, i cui
          destinatari, fasi, requisiti di ammissibilità, criteri di selezione, termini e condizioni
          sono di volta in volta descritti nel rispettivo regolamento della Challenge pubblicato
          all’interno della Piattaforma (“<span className="bold__text">Regolamento</span>”), il cui
          contenuto è stabilito – autonomamente e a propria esclusiva discrezione - dallo Sponsor
          per il quale OST organizza la relativa Challenge. Fermo restando quanto precede, in
          generale le Challenge:
          <ol>
            <li>identificano lo Sponsor che intende avviare tale Challenge;</li>
            <li>
              sono competizioni online/on site, pubblicamente accessibili a chiunque all’interno
              della Piattaforma ovvero rivolte a “player” singoli o a team privati “pre-selezionati”
              dallo Sponsor, previa in ogni caso la registrazione di tali partecipanti alla
              Piattaforma;
            </li>
            <li>
              possono avere finalità (e.g. accesso a un processo di recruitment, open innovation,
              CSR/ sociali, education, ecc.), contenuti, durata, requisiti di ammissione e modalità
              di partecipazione diversi, così come stabilito dallo Sponsor;
            </li>
            <li>
              prevedono la partecipazione dei partecipanti a gare e “prove tecniche” che comportano
              la consegna, tramite la Piattaforma, di soluzioni (e.g. modelli matematici, elaborati,
              file descrittivi, ecc. – “<span className="bold__text">Soluzioni</span>”) a
              determinati test, “problemi” o quesiti (e.g. problemi e test matematici, quesiti in
              materie umanistiche, ecc.) messi a disposizione all’interno della Challenge (“
              <span className="bold__text">Quesito</span>”);
            </li>
            <li>
              a seconda della maggiore o minor aderenza delle Soluzioni rispetto al Quesito, sulla
              base dei criteri di valutazione relativi a tale Challenge, gli Sponsor - autonomamente
              o con il supporto di soggetti terzi, partner della Società (tra cui, a titolo
              meramente esemplificativo e non esaustivo, altre società facenti parte del medesimo
              “gruppo societario” di cui la Società è parte – “
              <span className="bold__text">Partner</span>”) valutano le Soluzioni, stabiliscono una
              classifica dei partecipanti e riconoscono a favore dei partecipanti che sono risultati
              “vincitori” della Challenge premi di entità e natura diverse (e.g. possibilità di
              accedere a un processo di recruitment organizzato dallo Sponsor ed eventuali Partner
              in autonomia, somme di denaro, gift card, oﬀerte per l’acquisto di determinati beni o
              servizi, riconoscimenti pubblici, ecc. - “<span className="bold__text">Premi</span>”).
            </li>
          </ol>
        </li>
        <li>
          L’utente della Piattaforma (“<span className="bold__text">Utente</span>”) è una persona
          fisica (e.g. uno studente laureando o laureato, un professionista con esperienza, ovvero
          un lavoratore dipendente o collaboratore dello Sponsor), interessata a utilizzare la
          Piattaforma e a ricevere i Servizi. A seconda dell’attività svolta dall’Utente nonché alla
          luce delle caratteristiche della singola Challenge stabilite dallo Sponsor, gli Utenti
          possono agire sulla Piattaforma, alternativamente, in qualità di:
          <ol>
            <li>
              “consumatori”, così come definiti dall’articolo 3, comma 1, lett. a) del D.lgs. n.
              206/2005 (“<span className="bold__text">Codice del Consumo</span>”), ovverosia per
              scopi estranei all'attività imprenditoriale, commerciale, artigianale o professionale
              eventualmente svolta (“
              <span className="bold__text">Utenti-Consumatori</span>”). In aggiunta alle
              disposizioni della presente sezione I delle Condizioni Generali, agli Utenti-
              Consumatori si applicano altresì le disposizioni della{' '}
              <button className="link__button" onClick={scrollToSectionTwo}>
                {' '}
                sezione II delle Condizioni Generali
              </button>{' '}
              e la disciplina del Codice del Consumo, nei termini precisati al successivo articolo
              16;
            </li>
            <li>
              “professionisti”, ovverosia al fine di soddisfare interessi anche solo connessi o
              accessori rispetto all'attività imprenditoriale, commerciale, artigianale o
              professionale che gli Utenti eventualmente svolgono o intendono svolgere (“
              <span className="bold__text">Utenti-Professionisti</span>”).
            </li>
          </ol>
        </li>
        <li>
          Gli Utenti dichiarano di: (i) avere almeno 18 (diciotto) anni; e (ii) disporre della
          capacità d’agire.
        </li>
        <li>
          Le presenti condizioni generali (“<span className="bold__text">Condizioni Generali</span>
          ”) regolano il rapporto tra OST e l’Utente (definiti anche “
          <span className="bold__text">Parte/i</span>”) in merito all’utilizzo della Piattaforma da
          parte dell’Utente e alla prestazione dei Servizi oﬀerti da OST mediante la Piattaforma
          stessa, nei termini e alle condizioni che seguono.
        </li>
        <li>
          L’Utente è invitato a scaricare e memorizzare su supporto durevole le presenti Condizioni
          Generali e l’informativa privacy ex artt. 13 e 14 del Regolamento EU 2016/679 (“
          <span className="bold__text">GDPR</span>”), redatta da OST in qualità di titolare autonomo
          ex art. 24 del GDPR e avente ad oggetto i dati personali – così come definiti all’art. 4
          del GDPR (“
          <span className="bold__text">Dati Personali</span>”) - di volta in volta trattati da OST
          nell’ambito dei Servizi (“
          <Link to="/privacy-policy" className="bold__text">
            Informativa Privacy
          </Link>
          ”), all’atto della sottoscrizione dell’Informativa Privacy e delle Condizioni Generali.
        </li>
        <li>
          La Piattaforma è accessibile da qualunque dispositivo dotato di connessione internet, sia
          esso un computer, un tablet o uno smartphone. Pertanto, per poter accedere alla
          Piattaforma e acquistare i Servizi, ogni Utente è tenuto a disporre, a proprie cure e
          spese, di un dispositivo connesso a internet.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 2 – REGISTRAZIONE</h6>
      <ol>
        <li>
          Per poter utilizzare la Piattaforma, l’Utente è tenuto a creare, mediante il form
          disponibile sulla Piattaforma, un profilo personale e riservato (“
          <span className="bold__text">Profilo</span>”), seguendo la procedura di registrazione di
          cui al successivo articolo 2.2.
        </li>
        <li>
          La procedura di registrazione alla Piattaforma (“
          <span className="bold__text">Registrazione</span>”) si compone delle seguenti fasi: (i)
          creazione del Profilo, mediante – alternativamente: (a) inserimento dell’indirizzo e-mail
          dell’Utente, creazione della password di accesso e click sul link di verifica inviato da
          OST a tale indirizzo e-mail, ovvero mediante collegamento del Profilo al profilo LinkedIn
          dell’Utente alla Piattaforma (“<span className="bold__text">Profilo LinkedIn</span>”);
          (ii) sottoscrizione, mediante tecnica del “point and click” (i.e. con apposizione di “ﬂag”
          su caselle non preselezionate o su tasti interattivi – “
          <span className="bold__text">Point and Click</span>”), delle Condizioni Generali; (iii)
          sottoscrizione, mediante Point and Click, dei seguenti articoli, ai sensi e per gli eﬀetti
          degli artt. 1341 e 1342 c.c. o degli artt. 33 e 34 del Codice del Consumo a seconda –
          rispettivamente – che l’Utente agisca come Utente-Professionista o come
          Utente-Consumatore: 4 (Caratteristiche e modalità di funzionamento della Piattaforma), 7
          (Garanzia e limitazione di responsabilità), 9.1 (Sospensione), 9.2 (Manleva), 10.1
          (Recesso), 10.2 (Clausola risolutiva espressa); 11 (Forza Maggiore), 12 (Modifica delle
          Condizioni Generali), 14.4 (Divieto di cessione), 15.2 (Foro Compente), 20.4 (Onere prova
          conformità); (iv) accettazione, mediante Point and Click, dell’Informativa Privacy; (v)
          facoltativa prestazione del consenso, mediante Point and Click, ai trattamenti di dati
          personali per finalità non necessarie all’utilizzo dei Servizi previsti nell’Informativa
          Privacy; e (vi) facoltativa espressa richiesta, mediante Point and Click, di utilizzare i
          Servizi durante il Periodo di Ripensamento (ovverossia, senza attendere il decorso del
          Periodo in fase di Registrazione), come indicato al successivo articolo 17.2.
        </li>
        <li>
          Le Condizioni Generali si intendono concluse nel momento in cui l’Utente riceve conferma
          da OST, tramite e-mail, dell’avvenuta valida sottoscrizione del predetto documento (“
          <span className="bold__text">Data di Sottoscrizione</span>”), a conclusione della
          Registrazione.
        </li>
        <li>
          A seguito della creazione del Profilo, l’Utente prende atto e accetta che: (i) per potersi
          iscrivere e partecipare a una determinata Challenge, l’Utente potrà dover fornire alcune
          informazioni aggiuntive richieste dallo Sponsor e indicate nel relativo Regolamento della
          Challenge (quali, a titolo esemplificativo e non esaustivo, città e Paese di residenza,
          occupazione attualmente svolta, organizzazione per cui l’Utente presta la propria attività
          lavorativa/con cui collabora, Profilo LinkedIn, competenze professionali, curriculum
          vitae, esperienze lavorative pregresse, possesso di eventuali certificazioni, istruzione
          ricevuta, disponibilità a trovare un nuovo impiego, ecc.), alcune delle quali
          pubblicamente visibili da chiunque sulla Piattaforma all’interno del Profilo dell’Utente e
          altre visibili soltanto dallo Sponsor che organizza la Challenge. Tali ulteriori
          informazioni, laddove contenenti Dati Personali, saranno trattate dallo Sponsor in qualità
          di titolare del trattamento ex art. 4 del GDPR, in virtù di una propria informativa
          privacy ex artt. 13 e 14 del GDPR dallo stesso autonomamente predisposta e che verrà resa
          disponibile agli Utenti all’interno della pagina della Piattaforma dedicata alla relativa
          Challenge; e (ii) in caso di mancato inserimento nella Piattaforma/comunicazione allo
          Sponsor delle informazioni di cui al precedente punto (i) entro il termine indicato nel
          Regolamento e – in ogni caso – prima dell’invio della Soluzione definitiva da parte
          dell’Utente, la Soluzione trasmessa dall’Utente non verrà considerata ammessa e valutata
          ai fini della Challenge.
        </li>
        <li>
          A seguito della Registrazione, mediante la Password, l’Utente accede al Profilo o
          integra/modifica il Profilo e, in generale, fermo restando quanto indicato al precedente
          articolo 2.4, fruisce dei Servizi.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 3 – SERVIZI</h6>
      <ol>
        <li>
          Con la sottoscrizione delle presenti Condizioni Generali, OST concede all’Utente - in via
          non esclusiva e soltanto per la durata delle Condizioni Generali stesse - l’utilizzo in
          licenza della Piattaforma (“<span className="bold__text">Licenza</span>”), al fine di
          fornire all’Utente i Servizi, di natura prettamente tecnica e neutrale, che consentono
          all’Utente di:
          <ol>
            <li>
              esaminare tutte le Challenge pubblicate sulla Piattaforma in un determinato momento e
              accedere alle relative descrizioni;
            </li>
            <li>
              iscriversi a una determinata Challenge, previo completamento della procedura di
              iscrizione alla stessa e, in particolare, previa sottoscrizione - mediante Point and
              Click - del relativo Regolamento;
            </li>
            <li>
              consultare le Challenge a cui l’Utente si è iscritto, al fine di verificare la data di
              “attivazione” di tale Challenge e il termine finale entro il quale poter caricare la
              Soluzione definitiva sulla Piattaforma (“
              <span className="bold__text">Termine Finale</span>”);
            </li>
            <li>
              partecipare alle Challenge selezionate che risultano “attive”, mediante download del
              materiale che costituisce il Quesito e successiva consegna, tramite la Piattaforma,
              delle Soluzioni definitive (a prescindere dalle eventuali Soluzioni temporanee o
              parziali precedentemente sottoposte, laddove espressamente consentito da una
              determinata Challenge) entro il Termine Finale;
            </li>
            <li>
              accedere alla classifica temporanea della Challenge in “real time”, ovvero soltanto
              alla Classifica definitiva della Challenge, pubblicata a seguito del Termine Finale e
              del periodo per la valutazione delle Soluzioni, in conformità a quanto indicato nel
              Regolamento della Challenge;
            </li>
            <li>
              accedere alla Soluzione sottoposta al processo di valutazione svolto dallo Sponsor che
              ha pubblicato la Challenge a cui l’Utente si è iscritto, con possibilità di concorrere
              per l’ottenimento del Premio indicato nel Regolamento;
            </li>
            <li>
              a seguito del processo di valutazione, sulla base delle decisioni autonomamente
              adottate dallo Sponsor (con l’eventuale assistenza di Partner), richiedere e/o
              ricevere il Premio anche attraverso la Piattaforma e/o mediante i Partner;
            </li>
            <li>
              laddove previsto, creare ex novo o accedere a “team utenti” precedentemente creati da
              altri Utenti (“Team”), al fine di partecipare, insieme agli altri Utenti facenti parte
              del proprio Team, alle Challenge espressamente riservate ai Team;
            </li>
            <li>
              usufruire di ogni eventuale e futura funzionalità, integrazione o servizio della
              Piattaforma che OST, a propria discrezione, potrà mettere a disposizione degli Utenti.
            </li>
          </ol>
        </li>
        <li>
          L’Utente dichiara di essere consapevole che: (i) la Società fornisce la Licenza della
          Piattaforma all’Utente nella versione più recente disponibile al momento della conclusione
          delle Condizioni Generali, eventualmente corredata dalla documentazione tecnica relativa
          al funzionamento della Piattaforma e/o dei Servizi che la Società, a propria discrezione,
          ritiene utile mettere a disposizione dell’Utente; (ii) l’Utente potrà utilizzare la
          Piattaforma, visualizzare le Challenge pubblicate e – in generale – fruire dei ogni
          Servizio immediatamente a seguito della creazione del completamento della Registrazione.
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 4 – CARATTERISTICHE DEI SERVIZI E MODALITÀ DI FUNZIONAMENTO DELLA PIATTAFORMA
      </h6>
      <ol>
        <li>
          L’Utente dichiara di essere consapevole e accetta che:
          <ol>
            <li>
              la Piattaforma opera come uno “spazio virtuale” all’interno del quale gli Utenti,
              previa Registrazione, sotto la propria esclusiva responsabilità e a proprio rischio,
              possono usufruire dei Servizi;
            </li>
            <li>
              OST svolge un ruolo neutrale, facilitando la partecipazione dell’Utente alla Challenge
              ideata e organizzata dallo Sponsor nonché gestita da quest’ultimo con il supporto
              tecnico della Società;
            </li>
            <li>
              i requisiti di accesso e le caratteristiche di una singola Challenge, i termini e le
              condizioni del relativo Regolamento e i criteri di valutazione di una Soluzione sono
              autonomamente stabiliti dallo Sponsor a cui la Challenge si riferisce;
            </li>
            <li>
              la Soluzione caricata dall’Utente sulla Piattaforma (ovvero, l’ultima Soluzione
              caricata dall’Utente mediante la Piattaforma prima della scadenza del Termine Finale,
              laddove la Challenge preveda la possibilità di sottoporre Soluzioni temporanee) verrà
              considerata come definitiva e verrà esaminata dallo Sponsor, eventualmente in
              collaborazione con OST e/o con i Partner, al fine di verificarne la maggiore o minor
              aderenza rispetto al Quesito;
            </li>
            <li>
              la scelta dei vincitori della Challenge e l’assegnazione dei Premi viene stabilita
              esclusivamente dallo Sponsor in totale autonomia e sulla base di proprie valutazioni
              discrezionali, non essendo OST parte delle decisioni dello Sponsor in merito ai
              criteri di selezione, alla natura dei Premi e all’assegnazione degli stessi agli
              Utenti, fermo restando che – ai fini dell’erogazione dei Premi e della successiva
              fruizione degli stessi da parte degli Utenti vincitori – lo Sponsor potrà avvalersi
              della collaborazione di OST e/o dei Partner;
            </li>
            <li>
              i Servizi costituiscono servizi tecnici di mero supporto all’Utente nella
              partecipazione alle Challenge, invio delle Soluzioni ai Quesiti e messa in contatto
              con lo Sponsor, in quanto OST: (i) non valuta in alcun modo i Profili degli Utenti;
              (ii) non interviene in alcun eventuale processo di individuazione e selezione
              dell’Utente per posizioni lavorative presso lo Sponsor; (iii) in generale non presta,
              né a favore dello Sponsor né dell’Utente, alcuna attività di recruitment, headhunting,
              executive search, intermediazione, ricerca e selezione del personale o ricollocazione
              del personale, né svolge alcuna attività riservata a determinati soggetti e/o per la
              quale è richiesta l’iscrizione in appositi albi ai sensi della normativa vigente (tra
              cui, ma non solo, la L. 300/1970 e il D.lgs. n. 276/2003);
            </li>
            <li>
              ferma restando l’autonomia dello Sponsor nell’individuazione del contenuto di una
              Challenge e del relativo Regolamento, qualora le Challenge rientrino nella fattispecie
              di esclusione di cui all’art. 6, co. 1, lett. a) del D.P.R. n. 430/2001 (“Non si
              considerano concorsi e operazioni a premio i concorsi indetti per la produzione di
              opere letterarie, artistiche o scientiﬁche, nonché per la presentazione di progetti o
              studi in ambito commerciale o industriale, nei quali il conferimento del premio
              all'autore dell'opera prescelta ha carattere di corrispettivo di prestazione d'opera o
              rappresenta il riconoscimento del merito personale o un titolo d'incoraggiamento
              nell'interesse della collettività”), le stesse non costituiscono una manifestazione a
              premio ai sensi della predetta normativa.
            </li>
          </ol>
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 5 – IMPEGNI DELL’UTENTE – SOSPENSIONE DEI SERVIZI E MANLEVA
      </h6>
      <ol>
        <li>
          L’Utente si impegna a:
          <ol>
            <li>
              mantenere strettamente riservate e non rendere disponibili a terzi le credenziali di
              accesso alla Piattaforma, inclusa la password;
            </li>
            <li>
              non inserire nella Registrazione e in generale nella Piattaforma, né comunicare a OST,
              agli Sponsor e/o ai Partner, ai fini della partecipazione a una Challenge e in
              generale della fruizione dei Servizi, Dati Personali di terzi, dati falsi, di fantasia
              o comunque volutamente non corretti, completi o veritieri;
            </li>
            <li>
              sottoporre soltanto Soluzioni che siano state sviluppate autonomamente dall’Utente
              (eventualmente in collaborazione con il proprio Team) e, in generale, non fornire a
              OST e/o allo Sponsor né pubblicare sulla Piattaforma contenuti: (i) di cui l’Utente
              non dispone dei relativi diritti di proprietà intellettuale e industriale (“
              <span className="bold__text">Diritti IP</span>”); (ii) che contengono un marchio, logo
              o altro elemento protetto dal Diritto IP di titolarità di terzi, ad eccezione
              dell’ipotesi in cui, laddove ove esistano diritti di terzi, l’Utente abbia previamente
              ottenuto tutte le necessarie autorizzazioni e licenze da parte del relativo titolare;
              (iii) che violano i diritti di terzi, tra cui, a titolo meramente esemplificativo e
              non esaustivo, i Diritti IP e i diritti relativi al trattamento dei Dati Personali;
              (iv) che non contengono alcun contenuto di carattere pornografico o sessuale o
              discriminatorio in qualsivoglia modo (incluse specificamente la discriminazione basata
              su razza, sesso, orientamento sessuale, religione e/o credo politico di individui o
              gruppi), né contenuti che promuovano violenza o lesioni di diritti o qualunque altro
              contenuto oﬀensivo, osceno o inappropriato; (v) per quanto ragionevolmente possibile,
              che non contengano Dati Personali che rilevano l’origine razziale o etnica, le
              opinioni politiche, le convinzioni religiose o filosofiche, l’appartenenza sindacale,
              i dati genetici, i dati biometrici, i dati relativi alla salute, alla vita sessuale o
              all’orientamento sessuale, nonché dati relativi a condanne penali o reati relativi ai
              soggetti che partecipano alla Challenge;
            </li>
            <li>
              utilizzare la Piattaforma in conformità alla normativa applicabile vigente (ivi
              incluse, a titolo meramente esemplificativo e non esaustivo, le normative in materia
              di trattamento dei Dati Personali di cui al GDPR e al D.Lgs. n. 196/2003), alle
              presenti Condizioni Generali e alle eventuali ulteriori istruzioni fornite da OST
              (anche attraverso la Piattaforma) o da terzi (e.g. Sponsor o Partner, anche attraverso
              il Regolamento di una Challenge) prima dell’avvio dei Servizi;
            </li>
            <li>
              non utilizzare la Piattaforma in maniera fraudolenta e/o lesiva dei diritti e della
              reputazione commerciale di OST, degli Sponsor e/o di terzi (tra cui, ma non solo,
              altri Utenti);
            </li>
            <li>
              non porre in essere qualsiasi tipo di condotta, azione o comportamento potenzialmente
              idoneo a pregiudicare in qualunque modo il corretto funzionamento della Piattaforma;
            </li>
            <li>
              in caso di aggiudicazione del Premio di una Challenge, beneficiare del, o richiedere
              l’erogazione del, Premio sulla base di quanto indicato nel relativo Regolamento e/o
              diversamente comunicato dallo Sponsor (anche, eventualmente, per il tramite di OST o
              di Partner).
            </li>
          </ol>
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 6 - CORRISPETTIVO</h6>
      <ol>
        <li>
          Non è previsto il pagamento di alcun corrispettivo in denaro, da parte dell’Utente a
          favore di OST, a fronte della Licenza della Piattaforma e dell’utilizzo dei Servizi da
          parte dell’Utente.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 7 – GARANZIA E LIMITAZIONE DI RESPONSABILITÀ</h6>
      <ol>
        <li>
          Con la sottoscrizione delle Condizioni Generali, OST si impegna a garantire il sostanziale
          funzionamento della Piattaforma secondo gli standard di settore per la fornitura dei
          Servizi, fatto salvo il caso in cui il mancato funzionamento sia dipeso da cause non
          imputabili a OST per dolo o colpa grave e/o al di là del ragionevole controllo di
          quest’ultima. In ogni caso, l’Utente dichiara di essere consapevole e accetta che, in
          generale, è nella natura dei prodotti software (quale la Piattaforma) di non essere
          completamente esenti da errori. Conseguentemente, l’Utente espressamente accetta che
          l’eventuale esistenza di errori nella Piattaforma, per sé stessa, salvo che non derivino
          da dolo o colpa grave di OST ai sensi e per gli eﬀetti dell’art. 1229 c.c., non
          costituisce violazione delle obbligazioni assunte da quest’ultima in ragione e/o in
          connessione alle Condizioni Generali.
        </li>
        <li>
          Ai sensi delle presenti Condizioni Generali, OST ha ideato e gestisce la Piattaforma, di
          cui è titolare, e fornisce i Servizi. In nessun caso OST: (i) partecipa alle decisioni
          dell’Utente relative, ad esempio, alla scelta di fornire determinati documenti e
          informazioni, partecipare a una Challenge, avviare un rapporto con gli Sponsor e/o i
          relativi Partner e, in generale, all’utilizzo della Piattaforma e alla fruizione dei
          Servizi; (ii) è in alcun modo parte dei rapporti esistenti o che si instaurano tra gli
          Utenti ed eventuali Sponsor, Partner e/o altri Utenti, anche, ma non solo, in ragione
          dell’utilizzo della Piattaforma e/o dei Servizi.
        </li>
        <li>
          Ai sensi e per gli eﬀetti dell’articolo 1229 c.c., salvo dolo o colpa grave, OST non è
          responsabile per alcuna attività, fatto o atto eseguito dall’Utente tramite la
          Piattaforma. In particolare, l’Utente accede alla Piattaforma e sceglie i Servizi, in
          totale autonomia e sotto la propria esclusiva responsabilità. OST non è pertanto
          responsabile, a titolo contrattuale o extracontrattuale, per qualsiasi danno (diretto,
          indiretto) derivante da:
          <ol>
            <li>l’uso e/o dall'impossibilità di utilizzo della Piattaforma e/o dei Servizi;</li>
            <li>
              la scelta dell’Utente di partecipare a una Challenge e/o di avviare rapporti con lo
              Sponsor e/ o con i Partner;
            </li>
            <li>
              il mancato adempimento, da parte degli Sponsor, dei Partner e/o di altri Utenti, a
              determinati obblighi da questi ultimi assunti nei confronti di un Utente in virtù
              della normativa vigente, del Regolamento e/o di accordi tra gli stessi intercorsi;
            </li>
            <li>il contenuto delle Challenge e dei Regolamenti;</li>
            <li>
              eventuali errori, inesattezze od omissioni nella ricezione delle Soluzioni e nella
              valutazione delle stesse da parte degli Sponsor e/o, in generale, nella documentazione
              caricata dagli Sponsor, da altri Utenti e/o da altri soggetti terzi all’interno della
              Piattaforma.
            </li>
          </ol>
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 8 – DIRITTI DI PROPRIETÀ INTELLETTUALE</h6>
      <ol>
        <li>
          L’Utente riconosce che i Diritti IP e il know how relativi alla Piattaforma e/o ai Servizi
          sono di esclusiva titolarità e/o nella piena disponibilità di OST e rimarranno di
          esclusiva titolarità e/o nella piena disponibilità della stessa per tutta la durata delle
          Condizioni Generali e anche a seguito della cessazione delle stesse.
        </li>
        <li>
          L’Utente si impegna pertanto, in qualsiasi momento nel corso della durata delle Condizioni
          Generali, ovvero anche successivamente, direttamente o indirettamente, a:
          <ol>
            <li>
              utilizzare i Diritti IP unicamente per l’esecuzione di quanto previsto nelle
              Condizioni Generali e conformemente alle obbligazioni quivi assunte;
            </li>
            <li>
              utilizzare la massima diligenza per evitare a soggetti terzi di porre in essere azioni
              e/o atti che possano inficiare o limitare la validità dei Diritti IP;
            </li>
            <li>
              non contestare, direttamente o indirettamente, la validità di uno qualsiasi dei
              Diritti IP o il diritto, titolo ed interesse di OST relativamente a tali Diritti IP;
            </li>
            <li>
              non copiare, modificare, duplicare, riprodurre, adattare e/o eseguire operazioni di
              reverse engineering sulla Piattaforma e i connessi Diritti IP.
            </li>
          </ol>
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 9 – SOSPENSIONE E MANLEVA</h6>
      <ol>
        <li>
          In caso di presunto o eﬀettivo inadempimento dell’Utente a uno degli impegni di cui ai
          precedenti articoli 5.1 e 8.2, ovvero in caso di segnalazione di un possibile
          inadempimento da parte di qualunque terzo e/o di qualunque autorità competente, OST si
          riserva il diritto di sospendere l’utilizzo della Piattaforma e dei Servizi da parte
          dell’Utente, per un periodo di tempo necessario per verificare le segnalazioni ricevute
          e/o fino a quando l’Utente non ponga rimedio all’inadempimento e/o alla mancata conformità
          che ha causato la sospensione dei Servizi.
        </li>
        <li>
          In caso di inadempimento dell’Utente a uno qualsiasi degli impegni di cui agli articoli
          5.1 e 8.2, impregiudicato ogni ulteriore rimedio a favore di OST, l’Utente si impegna a
          manlevare e tenere indenne OST da ogni pretesa creditoria, risarcitoria, danno o eﬀetto
          pregiudizievole che possa derivare, sia direttamente che indirettamente, da pretese di
          terzi (tra cui, ma non solo, lo Sponsor, altri Utenti o eventuali autorità) in ragione di
          tale inadempimento.
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 10 - DURATA E RECESSO – CLAUSOLA RISOLUTIVA ESPRESSA - EFFETTI DELLA CESSAZIONE
      </h6>
      <ol>
        <li>
          Le presenti Condizioni Generali sono efficaci a partire dalla Data di Sottoscrizione e
          hanno una durata indeterminata, fatto salvo il diritto di ciascuna Parte di recedere dalle
          Condizioni Generali stesse in qualsiasi momento, dandone comunicazione all’altra Parte con
          un preavviso di almeno 30 (trenta) giorni, secondo le modalità indicate al successivo
          articolo 13.1.
        </li>
        <li>
          Impregiudicato ogni ulteriore diritto o rimedio previsto per legge o dalle presenti
          Condizioni Generali, OST ha diritto di risolvere con eﬀetto immediato le Condizioni
          Generali, ai sensi e per gli eﬀetti dell’art. 1456 c.c., mediante comunicazione scritta
          indirizzata all’Utente, contenente la dichiarazione di volersi avvalere della presente
          clausola risolutiva espressa, nelle modalità di cui al successivo articolo 13.1, in caso
          di:
          <ol>
            <li>
              inadempimento da parte dell’Utente a uno dei seguenti articoli: 5.1 (Impegni
              dell’Utente); 8.2 (Diritti IP);
            </li>
            <li>
              mancato rimedio dell’Utente ai sensi del precedente articolo 5.2 entro il termine di
              15 (quindici) giorni dalla sospensione dei Servizi.
            </li>
          </ol>
        </li>
        <li>
          Immediatamente a seguito della cessazione, per qualsiasi causa intervenuta, delle
          Condizioni Generali, l’Utente non potrà più accedere alla Piattaforma e/o al proprio
          Profilo né usufruire dei Servizi, e dovrà astenersi dall’utilizzare i Diritti IP di OST.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 11 – FORZA MAGGIORE</h6>
      <ol>
        <li>
          Fatto salvo quanto eventualmente diversamente previsto dalle Condizioni Generali, se OST
          fosse impossibilitata ad adempiere agli obblighi contrattuali, tra cui, in particolare, a
          garantire il funzionamento della Piattaforma e l’erogazione di Servizi, a causa di
          epidemie e pandemie, provvedimenti straordinari delle autorità competenti limitativi o
          impeditivi delle attività commerciali, incendi, guerre, scioperi, embargo,
          regolamentazioni governative o di altre autorità civili o militari, vandali o hacker,
          guasti o cattivo funzionamento dei network o delle tecnologie di soggetti terzi, degli
          apparati di telecomunicazione, website, software e hardware e in generale per qualsiasi
          circostanza indipendente dalla volontà e al di là del ragionevole controllo di OST (“
          <span className="bold__text">Forza Maggiore</span>”), il periodo per l’esecuzione delle
          prestazioni oggetto delle presenti Condizioni Generali sarà esteso per il periodo di
          ritardo o impossibilità di adempiere dovuta alle specifiche cause di Forza Maggiore. Resta
          inteso che il protrarsi di una situazione di Forza Maggiore per oltre 60 (sessanta) giorni
          consentirà all’Utente di risolvere le Condizioni Generali, fermo restando che in tal caso
          nessun risarcimento o indennità sarà dovuto da OST.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 12 - MODIFICA DELLE CONDIZIONI GENERALI</h6>
      <ol>
        <li>
          L’Utente prende atto e accetta che OST potrà in qualsiasi momento modificare le Condizioni
          Generali per sopravvenute esigenze tecniche (considerato che i Servizi vengono svolti con
          tecnologie in continua evoluzione), economiche e gestionali e per modifiche e riforme
          delle leggi e dei regolamenti applicabili (ivi incluso, a titolo esemplificativo e non
          esaustivo, i corrispettivi, la tipologia e la modalità di funzionamento della Piattaforma
          ed esecuzione dei Servizi.
        </li>
        <li>
          In caso di modifica di cui al precedente articolo 12.1, l’Utente prende atto e accetta
          che:
          <ol>
            <li>
              qualora la modifica abbia unicamente ad oggetto il funzionamento tecnico della
              Piattaforma, la stessa diverrà immediatamente efficace tra le Parti, senza che
              l’Utente possa eccepire alcunché;
            </li>
            <li>
              qualora la modifica sia richiesta, a pena di nullità o invalidità di una o più
              disposizioni delle Condizioni Generali, da riforme delle leggi e/o dei regolamenti
              applicabili, o sia volta a tutelare gli Utenti da frodi, malware, spam, violazioni dei
              dati o rischi per la sicurezza informatica, la stessa diverrà immediatamente efficace
              tra le Parti, senza che l’Utente possa eccepire alcunché;
            </li>
            <li>
              in tutti gli altri casi, la modifica proposta da OST diverrà efficace solo a seguito
              del decorso di un termine di 15 (quindici) giorni dalla comunicazione da parte di OST
              all’Utente, fermo restando il diritto di recedere dell’Utente da esercitare nel
              medesimo termine, a mezzo e- mail, senza alcun addebito e/o penale a proprio carico.
            </li>
          </ol>
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 13 – CONTATTI E SERVIZIO UTENTI</h6>
      <ol>
        <li>
          Salvo laddove diversamente indicato, ogni notifica o comunicazione tra le Parti
          concernente le Condizioni Generali deve essere eﬀettuata in forma scritta e inviata a
          mezzo raccomandata a.r., PEC o corriere, laddove possibile anticipata a mezzo e-mail, ai
          seguenti indirizzi:
          <ol>
            <li>
              OST: Open Search Tech S.r.l., con sede legale in Via G. Nicotera, 7, 00195, Roma; PEC:
              [
              <a href="mailto: opensearchtech@legalmail.it" rel="noreferrer" target="_blank">
                opensearchtech@legalmail.it
              </a>
              ]; e-mail: [
              <a href="mailto: info@opendataplayground.com" rel="noreferrer" target="_blank">
                info@opendataplayground.com
              </a>
              ];
            </li>
            <li>
              Utente: indirizzo e-mail/PEC comunicato in fase di sottoscrizione delle Condizioni
              Generali. È onere dell’Utente assicurare l’aggiornamento costante del proprio
              indirizzo e-mail/PEC.
            </li>
          </ol>
        </li>
        <li>
          Le comunicazioni relative ai Servizi di natura tecnica/operativa potranno essere
          validamente eseguite tra le Parti anche mediante e-mail ovvero notifiche o comunicazioni
          pubblicate sulla Piattaforma.
        </li>
        <li>
          Il servizio di assistenza e supporto di OST per l’accesso e il funzionamento della
          Piattaforma e dei Servizi è disponibile dal lunedì al venerdì, dalle ore 9:00 alle ore
          18:00 GMT, ai seguenti riferimenti ( e- mail:{' '}
          <a href="mailto: info@opendataplayground.com" rel="noreferrer" target="_blank">
            info@opendataplayground.com
          </a>
          ).
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 14 – MISCELLANEA</h6>
      <ol>
        <li>
          (Sopravvivenza) Se una o più disposizioni, o porzione delle stesse, delle Condizioni
          Generali sono per legge applicabile o per provvedimento giudiziale dichiarate invalide,
          illegali o altrimenti inapplicabili, in tutto o in parte, le rimanenti disposizioni, o
          porzioni delle stesse, rimangono, comunque, vincolanti e applicabili da e tra le Parti. In
          ogni caso, le Parti si impegnano a concordare in buona fede l’introduzione di una
          disposizione alternativa il più possibile simile e compatibile con quella risultata
          invalida, illegale o inapplicabile.
        </li>
        <li>
          (Tolleranza) L'eventuale tolleranza di comportamenti posti in essere in violazione delle
          disposizioni contenute nelle Condizioni Generali non costituisce rinuncia ai diritti
          derivanti dalle disposizioni violate, né al diritto di esigere l'esatto adempimento di
          tutti i termini e condizioni qui previsti.
        </li>
        <li>
          (Completezza) Le Condizioni Generali, in relazione a quanto in esse previsto,
          costituiscono l’unico accordo tra le Parti e sostituiscono tutti gli eventuali precedenti
          impegni, accordi, preventivi, negoziazioni, e intendimenti scritti o verbali fra le Parti.
        </li>
        <li>
          (Divieto di cessione) E’ fatto divieto all’Utente di cedere o trasferire a terzi, in tutto
          o in parte, a qualsiasi titolo o per qualsiasi motivo, le presenti Condizioni Generali
          ovvero qualsiasi diritto o obbligo da esse derivante, se non con il preventivo consenso
          scritto di OST.
        </li>
        <li>
          (Privacy) L’Utente prende atto e accetta che i propri Dati Personali comunicati a OST ai
          fini dell’utilizzo dei Servizi e/o inseriti all’interno di Piattaforma saranno trattati
          dalla Società in conformità all’Informativa Privacy e nel rispetto della normativa vigente
          (tra cui, a titolo meramente esemplificativo e non esaustivo, il GDPR).
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 15 - LEGGE APPLICABILE E FORO ESCLUSIVO</h6>
      <ol>
        <li>
          Le presenti Condizioni Generali sono disciplinate esclusivamente dalla legge italiana.
        </li>
        <li>
          Per qualsiasi controversia concernente le presenti Condizioni Generali, ivi comprese la
          sua esecuzione, interpretazione e/o cessazione a qualsiasi titolo sarà competente in via
          esclusiva l’autorità giudiziaria del foro di Milano, ogni altro foro eventualmente
          competente escluso.
        </li>
      </ol>
      <h5 ref={sectionTwoRef} className="bold__text text-center">
        SEZIONE II - PARTE SPECIALE RISERVATA AGLI UTENTI-CONSUMATORI
      </h5>
      <h6 className="bold__text">ARTICOLO 16 – AMBITO DI APPLICAZIONE</h6>
      <ol>
        <li>
          La presente sezione II delle Condizioni Generali e le disposizioni del Codice del Consumo
          si applicano soltanto ai rapporti che si instaurano tra la Società e gli Utenti che
          utilizzano la Piattaforma come Utenti-Consumatori. In particolare, i successivi articoli:
          <ol>
            <li>17 e 18, si applicano a tutti gli Utenti-Consumatori;</li>
            <li>
              da 19 a 23, si applicano solo nei confronti degli Utenti-Consumatori che forniscono
              Dati Personali alla Società, fatto salvo il caso in cui i Dati Personali forniti siano
              trattati esclusivamente dalla Società ai fini della Registrazione, della fornitura
              della Licenza e dei Servizi o dell’assolvimento degli obblighi di legge cui è soggetta
              la Società e quest’ultima non tratti tali Dati Personali per scopi diversi da quelli
              previsti.
            </li>
          </ol>
        </li>
        <li>
          In caso di eventuali contrasti tra le disposizioni della sezione I delle Condizioni
          Generali e la presente sezione II, queste ultime prevarranno sul contenuto delle prime
          soltanto riguardo ai rapporti tra la Società e gli Utenti-Consumatori.
        </li>
        <li>
          L’Utente-Consumatore è invitato a consultare la versione più aggiornata del{' '}
          <a
            href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legislativo:2005-09-06;206"
            target="_blank"
            rel="noreferrer">
            Codice del Consumo.
          </a>
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 17 – DIRITTO DI RECESSO “LIBERO”</h6>
      <ol>
        <li>
          Impregiudicato quanto previsto al precedente articolo 10.1, entro e non oltre il periodo
          di 14 (quattordici) giorni a partire dalla Data di Sottoscrizione (“
          <span className="bold__text">Periodo di Ripensamento</span>”), ll’Utente - Consumatore può
          recedere liberamente dalle presenti Condizioni Generali ai sensi e per gli eﬀetti degli
          art. 52 e ss. del Codice del Consumo (“
          <span className="bold__text">Diritto di Recesso</span>
          ”), mediante comunicazione alla Società nelle forme di cui al precedente articolo 13.1.
        </li>
        <li>
          Qualora l’Utente-Consumatore intenda utilizzare i Servizi immediatamente a seguito della
          Data di Sottoscrizione, ovverosia senza attendere la decorrenza del Periodo di
          Ripensamento, tale Utente- Consumatore dovrà farne espressa richiesta alla Società tramite
          sottoscrizione – mediante Point and Click – del relativo banner che comparirà sulla
          Piattaforma in fase di Registrazione, fermo restando che in tal caso l’Utente-Consumatore
          - ora per allora – dichiara di essere stato precedentemente informato circa la perdita, in
          caso di click sul predetto banner – del Diritto di Recesso.
        </li>
        <li>
          L’Utente-Consumatore prende in ogni caso atto e accetta che, in ragione della mancanza di
          un prezzo pagato dall’Utente-Consumatore per la Licenza e per i Servizi come indicato al
          precedente articolo 6.1, in caso di esercizio del Diritto di Recesso, non avrà diritto a
          ottenere dalla Società alcun rimborso.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 18 – FORO DELL’UTENTE-CONSUMATORE - ODR</h6>
      <ol>
        <li>
          In deroga a quanto previsto al articolo 15.2, per qualsiasi controversia riguardante
          l’Utente- Consumatore e concernente le presenti Condizioni Generali, ivi comprese la sua
          esecuzione, interpretazione e/o cessazione a qualsiasi titolo sarà competente in via
          esclusiva l’autorità giudiziaria del foro del luogo in cui l’Utente-Consumatore ha la
          propria residenza o il proprio domicilio elettivo, a meno che l’Utente-Consumatore stesso
          non scelga espressamente di aderire il foro esclusivo indicato dalla Società al precedente
          articolo 15.2.
        </li>
        <li>
          Impregiudicato quanto previsto al precedente articolo 18.1, la Società ricorda che, ai
          sensi dell’articolo 14 del Regolamento UE n. 524/2013, e senza alcun vincolo nei casi
          previsti dalla normativa nazionale applicabile, gli Utenti-Consumatori potranno trovare
          tutte le informazioni utili per poter accedere ai meccanismi di risoluzione on-line delle
          controversie (c.d. ODR) al seguente link:{' '}
          <a
            href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage"
            rel="noreferrer"
            target="_blank">
            https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage
          </a>
          .
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 19 – ACCESSO ALLA PIATTAFORMA E/O AI SERVIZI E RIMEDI</h6>
      <ol>
        <li>
          Qualora a seguito della Registrazione l’Utente-Consumatore non acceda alla Piattaforma né
          inizi ad utilizzare i Servizi nel termine indicato al precedente articolo 3.2,
          l’Utente-Consumatore è tenuto a informare immediatamente la Società, specificando se un
          termine specifico per l’accesso rispettivamente alla Piattaforma o ai Servizi sia
          essenziale per l’Utente-Consumatore (ad esempio, per partecipare a una determinata
          Challenge). La Società si impegna a consentire l’accesso alla Piattaforma o ai Servizi
          entro un termine congruo o nell’ulteriore termine eventualmente concordato tra la Società
          stessa e l’Utente-Consumatore. In mancanza di accesso alla Piattaforma o ai Servizi nei
          termini che precedono, l’Utente-Consumatore ha diritto di risolvere le Condizioni
          Generali.
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 20 - CONFORMITÀ E AGGIORNAMENTO DELLA LICENZA E DEI SERVIZI - RIMEDI
      </h6>
      <ol>
        <li>
          La Società si impegna a: i) fornire la Licenza della Piattaforma e i Servizi in maniera
          conforme ai requisiti, oggettivi e soggettivi, previsti dalle disposizioni del Codice del
          Consumo applicabili; e ii) aggiornare la Licenza e i Servizi, al fine di mantenerne la
          conformità ai sensi del punto i) che precede, previa comunicazione agli Utenti-Consumatori
          in merito a tali aggiornamenti e alle relative istruzioni di installazione (laddove
          previste), evidenziando le eventuali conseguenze in mancanza di installazione degli
          aggiornamenti (laddove prevista) da parte degli Utenti-Consumatori entro un congruo
          termine.
        </li>
        <li>
          La conformità di cui al precedente articolo 20.1 è garantita per i seguenti termini: i)
          con riferimento alla Licenza della Piattaforma, per il periodo di durata delle Condizioni
          Generali di cui all’articolo 10; e ii) con riferimento ai Servizi, per il periodo di
          fornitura del Servizio indicato di volta in volta sulla Piattaforma.
        </li>
        <li>
          In caso di difetto di conformità, l’Utente-Consumatore si impegna a informare la Società
          in forma scritta, nelle forme previste dall’articolo 13.2, fornendo ogni informazione
          idonea a descrivere il difetto riscontrato. L’azione diretta a far valere i difetti non
          dolosamente occultati dalla Società si prescrive nel termine di 26 (ventisei) mesi
          dall’ultimo utilizzo della Piattaforma o dei Servizi da parte dell’Utente-Consumatore
          interessato.
        </li>
        <li>
          L’Utente-Consumatore si impegna a collaborare con la Società, per quanto ragionevolmente
          possibile e necessario al fine di accertare se la causa del difetto di conformità della
          Piattaforma e/o dei Servizi risieda nell'ambiente digitale dell’Utente-Consumatore, e nei
          limiti dei mezzi tecnicamente disponibili che siano meno intrusivi per
          l’Utente-Consumatore. In caso di inadempimento, da parte dell’Utente- Consumatore, del
          presente obbligo, e tenuto conto di quanto indicato dalla Società al precedente articolo
          1.7, l'Utente-Consumatore prende atto che l’onere della prova riguardo l'esistenza del
          difetto di conformità sarà a suo esclusivo carico.
        </li>
        <li>
          In caso di difetto di conformità della Licenza o dei Servizi, l’Utente-Consumatore ha
          innanzitutto diritto di richiedere il ripristino della conformità senza spese o
          inconvenienti ed entro un termine congruo. Nel caso in cui: i) il ripristino della
          conformità fosse impossibile o eccessivamente oneroso, ii) la Società non provveda al
          ripristino e/o abbia dichiarato di non voler provvedervi, iii) nonostante i tentativi di
          ripristino della Società, il difetto di conformità persista o si ripresenti, o iv) il
          difetto di conformità sia talmente grave da giustificare l’immediata risoluzione delle
          Condizioni Generali; l’Utente-Consumatore avrà diritto unicamente alla risoluzione delle
          Condizioni Generali, non essendo previsto alcun prezzo per la fornitura della Licenza e
          dei Servizi.
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 21 - MODIFICHE DEL CONTENUTO DELLA LICENZA O DEI SERVIZI - RECESSO
      </h6>
      <ol>
        <li>
          La Società può modificare il contenuto della Licenza o dei Servizi, senza alcun costo per
          gli Utenti- Consumatori, per ragioni operative importanti (come, ad esempio, adattare la
          Licenza o i Servizi a un nuovo ambiente tecnico o a un numero maggiore di Utenti), previa
          comunicazione in forma scritta a mezzo e-mail agli Utenti-Consumatori stessi. Qualora tale
          modifica incida negativamente e in modo non trascurabile sull’utilizzo della Licenza o
          sull’accesso ai Servizi da parte dell’Utente-Consumatore, quest’ultimo ha diritto di
          recedere dalle Condizioni Generali gratuitamente entro il termine di 30 (trenta) giorni
          dalla comunicazione delle modifiche o nel maggior termine in cui la modifica diviene
          operativa sulla Piattaforma, salvo che la Società non abbia consentito l’accesso alla
          precedente versione della Licenza o del Servizio.
        </li>
      </ol>
      <h6 className="bold__text">ARTICOLO 22 - RIMBORSI ALL’UTENTE-CONSUMATORE</h6>
      <ol>
        <li>
          L’Utente-Consumatore prende atto e accetta che nessun rimborso è dovuto in caso di recesso
          o risoluzione delle Condizioni Generali ai sensi degli articoli 17.1, 20.5 e 21.1, in
          mancanza di un prezzo pagato dall’Utente-Consumatore alla Società per la fornitura della
          Licenza o dei Servizi.
        </li>
      </ol>
      <h6 className="bold__text">
        ARTICOLO 23 - ULTERIORI CONSEGUENZE IN CASO DI CESSAZIONE DELLE CONDIZIONI GENERALI
      </h6>
      <ol>
        <li>
          In ogni ipotesi di cessazione delle Condizioni Generali ai sensi della presente sezione
          II, la Società si asterrà dall’utilizzare ogni eventuale contenuto creato
          dall’Utente-Consumatore durante l’utilizzo della Licenza della Piattaforma e/o dei
          Servizi, ad eccezione dei casi in cui tale contenuto: a) sia privo di utilità al di fuori
          del contesto della Piattaforma o dei Servizi; ii) si riferisca solamente all’attività
          dell’Utente-Consumatore nell’utilizzo della Piattaforma o dei Servizi; iii) sia stato
          aggregato dalla Società ad altri dati e non possa essere disaggregato o comunque non senza
          uno sforzo sproporzionato; o iv) sia stato generato congiuntamente dall’Utente-Consumatore
          e da altre persone, e tali altre persone possono continuare a utilizzare il contenuto. In
          tutti i casi diversi dai precedenti punti da i) a iii), entro un termine di 30 (trenta)
          giorni, l’Utente-Consumatore ha diritto di richiedere alla Società, nelle forme di cui
          all’articolo 13, di recuperare gratuitamente i propri contenuti digitali in un formato di
          uso comune e leggibile da dispositivo automatico.
        </li>
        <li>
          L’Utente-Consumatore prende atto che rimangono impregiudicati i diritti relativi ai Dati
          Personali raccolti dalla Società previsti dall’Informativa Privacy, che la Società invita
          l’Utente-Consumatore a consultare.
        </li>
      </ol>
      <div className="tc__button">
        <Link
          className="secondy tc__pdf__download__button"
          to={termsAndConditionsPdf}
          target="_blank"
          download>
          <img src={Download} alt="download Terms and Conditions" />
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default TermsAndConditions;
