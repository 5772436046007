import React from 'react';
import { FieldArray } from 'formik';
import { Input, DatePicker, Checkbox } from 'formik-antd';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const WorkForm = ({ values, isCompanyAdmin }) => {
  const { t } = useTranslation();
  return (
    <FieldArray
      name="userEducationDetails"
      render={({ insert, remove, push }) => (
        <>
          {values?.userEducationDetails &&
            values?.userEducationDetails?.map((education, index) => (
              <div className="rigt__skill" style={{ marginBottom: '30px' }}>
                <div className="upper__section row">
                  <div className="col-12 col-md-6">
                    <span className="inputLabels">{t('odp-pu.user.profile.school')}</span>
                    <Input
                      label={t('odp-pu.user.profile.school')}
                      type="text"
                      name="education_name"
                      placeholder={`${t('odp-pu.user.profile.school')}`}
                      disabled={isCompanyAdmin}
                      name={`userEducationDetails.${index}.education_name`}
                      className="inputFields"
                    />
                    <span className="error-text"></span>
                  </div>

                  <div className="col-12 col-md-6">
                    <span className="inputLabels">{t('odp-pu.user.profile.degree')}</span>
                    <Input
                      label={t('odp-pu.user.profile.degree')}
                      type="text"
                      name="institute_name"
                      placeholder={`${t('odp-pu.user.profile.degree')}`}
                      disabled={isCompanyAdmin}
                      name={`userEducationDetails.${index}.institute_name`}
                      className="inputFields"
                    />
                    <span className="error-text"></span>
                  </div>
                </div>
                <div className="lower__section">
                  <div className="form__to">
                    <div className="form__inner">
                      <div className="input__wrapper calender__wrapper">
                        <span className="inputLabels">{t('odp-pu.user.profile.from-date')}</span>
                        <DatePicker
                          name={`userEducationDetails.${index}.start_date`}
                          disabled={isCompanyAdmin}
                          className="inputFields"
                        />
                      </div>
                      <div className="input__wrapper calender__wrapper">
                        <span className="inputLabels">{t('odp-pu.user.profile.to-date')}</span>
                        <DatePicker
                          name={`userEducationDetails.${index}.end_date`}
                          disabled={education.is_current_education || isCompanyAdmin}
                          className="inputFields"
                        />
                      </div>
                    </div>
                    <div className="checkbox-wrapper">
                      <Checkbox name={`userEducationDetails.${index}.is_current_education`} disabled={isCompanyAdmin}>
                        {t('odp-pu.label.profile.current')}
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="about">
                  <div className="input__wrapper">
                    <span className="inputLabels">{t('odp-pu.menu.footer.about')}</span>
                    <TextArea
                      name={`userEducationDetails.${index}.education_description`}
                      showCount
                      maxLength={1000}
                      disabled={isCompanyAdmin}
                      placeholder={`${t('odp-pu.user.profile.add-description')} ( 1000 max )`}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </div>
                </div>
                {values?.userEducationDetails.length > 1 && (
                  <div className="text-right mt-2">
                    {!isCompanyAdmin && <Button danger onClick={() => remove(index)} disabled={isCompanyAdmin}>
                      {t('odp-pu.user.profile.remove')}
                    </Button>}
                  </div>
                )}

                <span className="border"></span>
              </div>
            ))}

          {!isCompanyAdmin && <button
            onClick={(e) => {
              e.preventDefault();
              push({
                education_name: null,
                institute_name: null,
                education_description: null,
                is_current_education: false,
                start_date: null,
                end_date: null
              });
            }}
            disabled={isCompanyAdmin}
            className="link__btn float_right">
            <span>{t('odp-ad.challenge.create-content.add-new')}</span>
          </button>}
        </>
      )}
    />
  );
};

export default WorkForm;
