import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import SearchBox from "../../admin/Components/SearchBox";
import Styles from "./leaderboard.module.scss";
import TableHead from "../../admin/Components/Table/tableHead";
import TableBody from "../../admin/Components/Table/tableBody";
import Table from "../../admin/Components/Table";
import { getLeaderboardListings } from "../../redux-store/actions/ChallengeAction";
import { useDispatch, useSelector } from "react-redux";
import UserImage from "../../asset/images/user.svg";
import Icon from "../../components/Icons";
import trophy from "../../asset/icons/trophy.svg";
import points from "../../asset/icons/points.svg";
import User from "../../asset/icons/user.svg";
import Pagination from "../../components/Pagination";
import { buildParams, PaginationModel } from "../../utils";
import { Empty } from "antd";
import { Link } from "react-router-dom";
import UserStats from "../../components/UserStats";
import { getUserID } from "../../services/token";
import { useTranslation } from "react-i18next";
import SortHeader from "../../admin/Components/Table/SortHeader";

const Leaderboard = () => {
  const { t } = useTranslation();
  const leaderboardFilters = {};
  leaderboardFilters.pagination = new PaginationModel();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const { user } = useSelector((state) => state.userProfile);
  const SkeletonArr = [1, 2, 3];
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const { listings, loading, _metadata } = useSelector(
    (state) => state.leaderboardListings
  );

  useEffect(() => {
    getLeaderboard(leaderboardFilters);
  }, []);

  useEffect(() => {
    if (searchText == "") getLeaderboard(leaderboardFilters);
  }, [searchText]);

  useEffect(() => {
    if (isComponentMounted) {
      leaderboardFilters.pagination.pageNum = currentPage;
      getLeaderboard(leaderboardFilters);
    }
    setIsComponentMounted(true);
  }, [currentPage]);

  const getFilteredList = () => {
    leaderboardFilters.display_name = searchText;
    getLeaderboard(leaderboardFilters);
  };

  const getLeaderboard = (filters) => {
    let params = buildParams(filters);
    dispatch(getLeaderboardListings(params));
  };

  const sortColumn = () => {
    if (isComponentMounted) {
      leaderboardFilters.sortBy = sortOrder;
      leaderboardFilters.orderField = sortBy;
      getLeaderboard(leaderboardFilters);
    }
  };

  return (
    <div className={Styles.leaderboardContainer}>
      <div className={Styles.titleinfo}>
        <h1>{t("odp-pu.leaderboard.global-leaderboard")}</h1>
        <p className="pg-regular sub-title">
          {t("odp-pu.leaderboard.check-out-open-data-playground-s-ranking")}
        </p>
        {getUserID() && (
          <h2>{t("odp-pu.label.dashboard.your-global-score")}</h2>
        )}
      </div>

      {getUserID() && <UserStats user={user} />}

      <div className={Styles.searchBox}>
        <SearchBox
          placeHolder={t("odp-ad.user.filter-by-name.filter-by-name")}
          onEnter={getFilteredList}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className={Styles.tableContainer}>
        <Table>
          <TableHead>
            <SortHeader
              className="text-center"
              childClassName={`${Styles.iconHead} justify-content-center`}
              sortField="score_rank"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={trophy} />
              <span>Rank</span>
            </SortHeader>

            <SortHeader
              childClassName={`${Styles.iconHead}`}
              sortField="display_name"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={User} />
              <span>Name</span>
            </SortHeader>

            <SortHeader
              className="text-center"
              childClassName={`${Styles.iconHead} justify-content-center`}
              sortField="points"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={points} />
              <span>Points</span>
            </SortHeader>
          </TableHead>
          <TableBody>
            {!loading &&
              listings.length > 0 &&
              listings
                // .filter((leaderboard) => leaderboard?.display_name?.includes(searchText))
                .map(
                  ({
                    points,
                    display_name,
                    avatar_url,
                    score_rank,
                    user_id,
                  }) => (
                    <tr
                      key={user_id}
                      className={
                        user?.user_id === user_id ? Styles.highlightedRow : ""
                      }
                    >
                      <td className="text-center">{score_rank}</td>
                      <td>
                        <Link
                          className={Styles.userLink}
                          to={`user/profile/${user_id}`}
                        >
                          <img
                            src={
                              avatar_url
                                ? process.env.REACT_APP_RESOURCES_URL +
                                avatar_url
                                : UserImage
                            }
                            className={Styles.avatar}
                          />
                          {display_name || "Anonymous"}
                        </Link>
                      </td>
                      <td className="text-center">{points || 0}</td>
                    </tr>
                  )
                )}
            {loading &&
              SkeletonArr.map(() => (
                <tr>
                  <td style={{ width: "300px" }}>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
      </div>
      <Pagination
        pageSize={_metadata?.pageSize}
        totalRecord={_metadata?.totalResults}
        currentPage={currentPage}
        styles={{ marginTop: "40px" }}
        onPaginationChange={(page) => {
          setCurrentPage(page);
        }}
      />
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        {!loading && listings.length === 0 && <Empty />}
      </div>
    </div>
  );
};
export default Leaderboard;
