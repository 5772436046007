import Footer from '../../components/Footer';
import Nav from '../../components/Nav';
import SideNav from '../../components/Nav/sideNav';
import UpperNav from '../../components/upperNav';
import { useState } from 'react';
import './layout.scss';
import Newsletter from '../../components/Newsletter';
import { useSelector } from 'react-redux';

const Index = ({ children }) => {

  const { user } = useSelector(state => state.userProfile)
  const [isActive, setisActive] = useState(false);
  const setisMenuActive = (active) => {
    setisActive(active);
  };
  return (
    <div className="primary__layout">
      <div className="nav__wrapper">
        <UpperNav />
        <Nav setisMenuActive={setisMenuActive} isActive={isActive} />
        <SideNav setisMenuActive={setisMenuActive} isActive={isActive} />
      </div>
      {children}
      {!user && <Newsletter />}
      <Footer />
    </div>
  );
};

export default Index;
