import { NumberConverter } from '../../utils';

export class PrizePoints {
    from = null;
    to = null;
    value = null;

    constructor() {
        this.from = null
        this.to = null
        this.value = null
    }

    deserialize(model) {
        this.from = NumberConverter(model.from);
        this.to = NumberConverter(model.to);
        this.value = NumberConverter(model.value);
        return this;
    }
}