import React from "react";
import { Skeleton } from "antd";

const Overview = ({ overview, loading }) => {
  return !loading ? (
    <div dangerouslySetInnerHTML={{ __html: overview }}></div>
  ) : (
    <Skeleton paragraph={{ rows: 4 }} />
  );
};

export default Overview;
