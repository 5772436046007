import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Switch, Input } from "formik-antd";
import { Formik } from "formik";
import { Button, Spin, Avatar, Menu, Dropdown } from "antd";
import { Input as AntDesignInput } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch } from "react-redux";
import { MoreOutlined, UserOutlined } from '@ant-design/icons';
import Back from '../../../../components/Back';
import Styles from './sponsorCreate.module.scss';
import Eye from "../../../../asset/icons/eye.svg";
import Icon from "../../../../components/Icons";
import FileDragger from '../../../../components/FileDragger';
import { SponsorObject } from '../../../../models/sponsor/Sponsor';
import { sponsorSchema } from '../../../../helpers/schemas';
import { notify } from '../../../../theme/antNotify';
import { saveFile } from '../../../../services/fileupload/api';
import { bytesToMegaByte, EDITOR_CONFIG } from '../../../../utils';
import {
  createSponsor, getSponsorById, publishSponsor, unPublishSponsor, updateSponsor,
  inviteContact, getSponsorContacts, updateSponsorContact
} from '../../../../redux-store/services/Sponsor';
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 22 }} spin />;
const antIconBlue = (
  <LoadingOutlined style={{ fontSize: 28, color: "#5f76ff" }} spin />
);

const Sponsor = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [sponsorId, setSponsorId] = useState(params.sponsorId);
  const [sponsorDescription, setSponsorDescription] = useState(null);
  const [sponsorLogo, setSponsorLogo] = useState(null);
  const [sponsorBackground, setSponsorBackground] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [sponsorPayload, setSponsorPayload] = useState(new SponsorObject());
  const [publishState, setPublishState] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const [sponsorContacts, setSponsorContacts] = useState([]);
  const [sponsorEmails, setsponsorEmails] = useState(null);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(async () => {
    try {
      if (params && params.sponsorId) {
        setSponsorId(params.sponsorId);
        setSponsorLoading(true);
        const response = await getSponsorById(params.sponsorId);
        setSponsorLoading(false);
        setSponsorLogo(response.logo_file_id)
        setSponsorBackground(response.banner_file_id)
        setPublishState(response.is_published)
        setSponsorDescription(response.company_description)
        setSponsorPayload(response)
        const resp = await getSponsorContacts(sponsorId);
        setSponsorContacts(resp?.listings || []);

        const { app_perm } = response;
        const { company } = app_perm;
        const { can_update } = company;

        setCanUpdate(can_update);

      }
    } catch (err) {
      setSponsorLoading(false);
    }
  }, []);

  const routeToPreview = () => {
    history.push(`/admin/sponsor/${sponsorId}/preview`);
  };

  const submitSponsor = async (values, { setSubmitting }, errors) => {
    try {
      const sponsorRequestObject = new SponsorObject().deserialize(values);
      sponsorRequestObject.company_description = sponsorDescription;

      if (!sponsorLogo || !sponsorBackground) {
        notify("error", `Please add ${!sponsorLogo ? 'Logo' : !sponsorBackground ? 'Background' : ''} image`);
        return
      }

      if (!sponsorDescription) {
        notify('error', 'Please add sponsor description');
        return;
      }

      sponsorRequestObject.logo_file_id = sponsorLogo;
      sponsorRequestObject.banner_file_id = sponsorBackground;
      SetLoading(true);
      let response = {};


      if (!sponsorId) response = await createSponsor(sponsorRequestObject);
      else response = await updateSponsor(sponsorRequestObject);

      if (response) notify("success", `Sponsor successfully ${sponsorId ? 'updated' : 'created'}`);

      SetLoading(false);
      setSponsorId(response.company_id);
    } catch (err) {
      SetLoading(false);
    }
  }

  const handleSubmit = () => { };

  const onLogoImageSave = async (e) => {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;
    let fileExtension = files[0].name.match(/\.(.+)$/)[1];

    if (validateFile(fileExtension) && validateImageSize(files[0], 'thumbnail')) {
      const fileData = new FormData();
      fileData.append("upfile", files[0]);
      setLogoLoading(true)
      const fileID = await saveFile(dispatch, {
        data: {
          upfile: fileData,
        },
      });
      setLogoLoading(false);
      setSponsorLogo(fileID);
    } else {
    }
  };

  const onCoverImageSave = async (e) => {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;
    let fileExtension = files[0].name.match(/\.(.+)$/)[1];
    if (validateFile(fileExtension) && validateImageSize(files[0], 'cover')) {

      const fileData = new FormData();
      fileData.append("upfile", files[0]);
      setBackgroundLoading(true);
      const fileID = await saveFile(dispatch, {
        data: {
          upfile: fileData,
        },
      });
      setBackgroundLoading(false);
      setSponsorBackground(fileID);
    } else {
    }
  };


  const validateImageSize = (file, fileType) => {
    let fileSize = bytesToMegaByte(file.size);
    if (fileType == 'thumbnail') {
      if (fileSize > 5) {
        notify(
          "error",
          "Incorrect file size",
          "Please upload file upto 5 MB"
        )
      } else return true;
    }
    else if (fileType == 'cover') {
      if (fileSize > 10) {
        notify(
          "error",
          "Incorrect file size",
          "Please upload file upto 10 MB"
        )
      } else return true;
    }
  }

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case "jpg":
        return true;
      case "png":
        return true;
      default:
        notify(
          "error",
          "Incorrect format",
          "Please upload file with extension (.jpg or .png)"
        );
    }
  };

  const onSponsorStatusChange = async (isPublish) => {
    try {
      if (isPublish) {
        setPublishLoading(true);
        await publishSponsor(sponsorId)
        setPublishLoading(false);
        setPublishState(true);
      } else {
        setPublishLoading(true);
        await unPublishSponsor(sponsorId)
        setPublishLoading(false);
        setPublishState(false);
      }
    } catch (err) {
      setPublishLoading(false)
    }
  };

  const onInviteContactClicked = async () => {

    if (!sponsorEmails || sponsorEmails == "") {
      notify("error", `Please enter email addresses of the users to invite.`);
      return;
    }
    setInviteLoading(true);
    const emails = sponsorEmails.replace(/\s/g, '').split(",");
    const payload = {
      "invitee_emails": emails
    }
    const resp = await inviteContact(sponsorId, payload);

    if (resp.statusCode == 0) {
      const contacts = await getSponsorContacts(sponsorId);
      setSponsorContacts(contacts?.listings || []);
    }

    setsponsorEmails('');
    setInviteLoading(false);
  }

  const onUpdateContactClicked = async (user_id, action) => {
    const payload = {
      "action": action
    }
    const resp = await updateSponsorContact(sponsorId, user_id, payload);
    if (resp.statusCode == 0) {
      if (action == 'LEAVE_COMPANY') {
        history.push(`/admin/sponsors`);
      } else {
        const contacts = await getSponsorContacts(sponsorId);
        setSponsorContacts(contacts?.listings || []);
      }
    }
  }

  return (
    <div className={Styles.sponsorCrudPage}>
      <Formik
        initialValues={sponsorPayload}
        enableReinitialize={true}
        validationSchema={sponsorSchema}
        onSubmit={submitSponsor}
      >
        {({ values, errors, isValid, touched }) => (
          <>
            <div className={Styles.title}>
              <Back link={"/admin/sponsors"} />
              <h2>
                {params.sponsorId
                  ? 'Update Sponsor'
                  : 'Create Sponsor'
                }
              </h2>
              <div></div>
            </div>
            {sponsorLoading && (
              <div className="d-flex justify-content-center mt-3 position-absolute start-0 w-100">
                <Spin indicator={antIconBlue} />
              </div>
            )}
            {sponsorId && <div
              className={`d-flex justify-content-between mb-3 ${Styles.previewContainer}`}
            >
              <div className={`switch-wrapper ${Styles.switchContainer}`}>
                <span className={Styles.statusLabel}>
                  {t("odp-ad.sponsor.create.status")}
                </span>
                <span className="show">
                  {t("odp-ad.sponsor.create.draft")}
                </span>
                <Switch
                  name="is_published"
                  className={`mr-2 ${Styles.switch}`}
                  onChange={onSponsorStatusChange}
                  loading={publishLoading}
                  checked={publishState}
                />
                <span className="show">
                  {t("odp-ad.sponsor.create.online")}
                </span>
              </div>
              <Button
                className={Styles.btnPreview}
                onClick={routeToPreview}
                icon={<Icon src={Eye} />}
              >
                <span>Preview sponsor</span>
              </Button>
            </div>}
            <Form onSubmit={handleSubmit}>
              <div className={Styles.sponsorTabContainer}>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>Name</h4>
                  </div>
                  <div className={Styles.formGroup}>
                    <span>*Name</span>
                    <Input
                      name="company_name"
                      placeholder={t('odp-ad.challenge.create-basics-tab.write-the-title-of-your-sponsor')}
                      className={(errors.company_name && touched.company_name) && Styles.inputError}
                    />
                  </div>
                  <span className="error-text"></span>
                </div>

                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>Logo image</h4>
                  </div>
                  <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
                    <span>
                      *Upload one image to be used as a square logo image for your Company. The image must be a JPG or PNG file, up to 5 MB. For best results crop to 400x400 pixels
                    </span>
                    <FileDragger
                      id="sponsorLogo"
                      buttonText="Image"
                      fileExtensions=".jpg or .png"
                      fileSize="5 MB"
                      isImage={true}
                      imageDimensions="1:1 aspect ratio, 400 x 400 px"
                      style={{ width: '30%', backgroundSize: "cover", backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}
                      loading={logoLoading}
                      selectedFile={sponsorLogo}
                      label="Select image"
                      onFileDrop={onLogoImageSave}
                      removeFile={(e) => {
                        e.preventDefault();
                        setSponsorLogo(null);
                      }}
                    />
                  </div>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>Background image</h4>
                  </div>
                  <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
                    <span>
                      *Upload one image to be used in the header section of your Company page. The image must be a JPG or PNG file, up to 10 MB. For best results crop to 1128x191 pixels or use the LinkedIn cover image of your Company
                    </span>
                    <FileDragger
                      id="sponsorCoverImage"
                      buttonText="Image"
                      fileExtensions=".jpg or .png"
                      fileSize="5 MB"
                      isImage={true}
                      imageDimensions="1128 x 191 px"
                      style={{ width: 100 + "%", backgroundSize: "cover", backgroundPosition: "center" }}
                      loading={backgroundLoading}
                      selectedFile={sponsorBackground}
                      label="Select image"
                      onFileDrop={onCoverImageSave}
                      removeFile={(e) => {
                        e.preventDefault();
                        setSponsorBackground(null);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={Styles.sponsorTabContainer}>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>
                      Main description
                    </h4>
                  </div>
                  <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
                    <span>
                      *Describe your company and what makes it special
                    </span>
                    <CKEditor
                      editor={ClassicEditor}
                      data={sponsorDescription}
                      config={EDITOR_CONFIG}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setSponsorDescription(data);
                      }}
                    />
                  </div>
                  <span className="error-text"></span>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>Company Web site</h4>
                  </div>
                  <div className={Styles.formGroup}>
                    <span>*This must be the official web site address of the Company and will be public and displayed on site</span>
                    <Input
                      name="company_website"
                      placeholder='Company web site (eg. www.company.it)'
                      className={(errors.company_website && touched.company_website) && Styles.inputError}
                    />
                  </div>
                  <span className="error-text"></span>
                </div>
                <div className={Styles.inputContainer}>
                  <div className={Styles.inputTitle}>
                    <h4>Privacy Policy URL</h4>
                  </div>
                  <div className={Styles.formGroup}>
                    <span>*This must be the official privacy policy URL of Sponsor</span>
                    <Input
                      name="privacy_policy_url"
                      placeholder='Company web site (eg. www.company.it)'
                      className={(errors.privacy_policy_url && touched.privacy_policy_url) && Styles.inputError}
                    />
                  </div>
                  <span className="error-text"></span>
                </div>

                {canUpdate && <div className={Styles.actions}>
                  <button className="btn btn-primary" type="submit">
                    {!loading ? (
                      `${t("odp-pu.label.profile.save")}`
                    ) : (
                      <Spin indicator={antIcon} />
                    )}
                  </button>
                </div>}
              </div>
            </Form>
          </>
        )}
      </Formik>
      {sponsorId ? <div className={Styles.sponsorTabContainer}>
        <div className={Styles.inputContainer}>
          <div className={Styles.inputTitle}>
            <h4>{t("odp-ad.sponsor.edit.sponsor-administrators")}</h4>
          </div>
          <div className={Styles.formGroup}>
            <span>{t("odp-ad.sponsor.edit.insert-multiple-email-addresses-separate")}</span>
            <AntDesignInput
              placeholder={t("odp-ad.sponsor.edit.type-and-select-the-email-address-of-the")}
              id="contactEmailInput"
              onChange={(e) => setsponsorEmails(e.target.value)}
              value={sponsorEmails}
            />

            <div className={Styles.actions} style={{ marginTop: "25px" }}>
              <button className="btn btn-primary"
                disabled={!sponsorEmails}
                onClick={onInviteContactClicked}>
                {!inviteLoading ? (
                  `Invite`
                ) : (
                  <Spin indicator={antIcon} />
                )}

              </button>
            </div>
            <div className={Styles.sponsorAdminContainer}>
              {sponsorContacts && sponsorContacts.map(({ user_id, user, can_leave, can_remove }) => (
                <div className={Styles.sponsorAdmin}>
                  <div className={Styles.sponsorUserName}>
                    {
                      <Avatar src={process.env.REACT_APP_RESOURCES_URL +
                        user?.avatar_url} size={38}>
                        <span className={Styles.avatarInitials}>{user?.display_name[0].toUpperCase()}{user?.display_name[1].toUpperCase()}</span>
                      </Avatar>
                    }
                    <p className='mb-0 p-0'>{user?.display_name}</p>
                  </div>
                  <div className={Styles.sponsorDropDown}>
                    <Dropdown overlay={
                      <Menu>
                        {
                          can_remove ?
                            <Menu.Item key="1" className={Styles.dropDownItem}
                              onClick={() =>
                                onUpdateContactClicked(user_id, "REMOVE_CONTACT")
                              }>
                              {t("odp-ad.sponsor.edit.remove-admin")}
                            </Menu.Item> : <></>
                        }
                        {
                          can_leave ?
                            <Menu.Item key="3" className={Styles.dropDownItem}
                              onClick={() =>
                                onUpdateContactClicked(user_id, "LEAVE_COMPANY")
                              }>
                              {t("odp-ad.sponsor.edit.leave-sponsor")}</Menu.Item>
                            : <></>
                        }
                      </Menu>}
                      trigger={['click']}>
                      <MoreOutlined className={Styles.icon} />
                    </Dropdown>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <span className="error-text"></span>
        </div>
      </div> : <></>
      }

    </div>
  )
}

export default Sponsor