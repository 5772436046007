import { Button } from 'antd';

import Typography from '../theme/typography';
import Checkbox from '../theme/checkbox';
import { AButton, PButton, SButton } from '../theme/button';
import { CheckCircleOutlined } from '@ant-design/icons';

// import Input from '../theme/input';
import YupForm from '../components/yupForm';
import { Link } from 'react-router-dom';
const DesignSystem = () => {
  const run = (e) => {

  };
  const runt = (e) => {
  };
  return (
    <div className="desig__system">
      <YupForm />
      {/* <Input
        label="label"
        type="text"
        name="firstName"
        id="firstName"
        icon="<TwitterOutlined />"
        error="Error message"
      /> */}

      <Link to="/" className="float">
        <Button type="primary">Back</Button>
      </Link>
      <Typography />
      <Checkbox />

      <span className="hr" />

      <AButton label="button" onClick={run} id="1" type="regular">
        <CheckCircleOutlined />
      </AButton>

      <AButton label="button" onClick={run} id="1" type="big">
        <CheckCircleOutlined />
      </AButton>

      <PButton
        label="button"
        onClick={run}
        id="1"
        type="regular"
        className="block"
      >
        <CheckCircleOutlined />
      </PButton>

      <PButton label="button" onClick={run} id="1" type="big" className="block">
        <CheckCircleOutlined />
      </PButton>
      <SButton label="button" onClick={run} id="1" type="regular">
        <CheckCircleOutlined />
      </SButton>
      <SButton label="button" onClick={run} id="1" type="big">
        <CheckCircleOutlined />
      </SButton>
      <br />

      <SButton label="button" onClick={run} id="1" type="regular"></SButton>
      <SButton
        label="button"
        onClick={runt}
        id="2"
        type="regular"
        disabled={true}
      />

      <SButton label="button big" onClick={runt} id="2" type="big" />

      <SButton
        label="button big"
        onClick={runt}
        id="2"
        type="big"
        disabled={true}
      />

      <br />
      <PButton
        label="button"
        onClick={run}
        id="1"
        type="regular"
        className="block"
      ></PButton>
      <PButton label="button" onClick={runt} id="2" type="big" />
      <PButton
        label="button"
        onClick={runt}
        id="2"
        type="regular"
        disabled={true}
      />
      <PButton
        label="button"
        onClick={runt}
        id="2"
        type="big"
        disabled={true}
      />
      <br />
      <AButton label="button" onClick={run} id="1" type="regular"></AButton>
      <AButton label="button" onClick={runt} id="2" type="big" />
      <AButton
        label="button"
        onClick={runt}
        id="2"
        type="regular"
        disabled={true}
      />
      <AButton
        label="button"
        onClick={runt}
        id="2"
        type="big"
        disabled={true}
      />
    </div>
  );
};

export default DesignSystem;
