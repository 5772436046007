const Testing = () => {
  return (
    <div className="typo__wrapper">
      <h1 className="title">Desktop Typography</h1>

      <h1>Heading 1</h1>
      <span className="hr" />
      <h2>Heading 2</h2>
      <span className="hr" />
      <h3>Heading 3</h3>
      <span className="hr" />
      <h4>Heading 4</h4>
      <span className="hr" />
      <h5>Heading 5</h5>
      <span className="hr" />
      <h6>Heading 6</h6>
      <span className="hr" />
      <p className="btn-text">btn-text</p>
      <span className="hr" />
      <p className="pg-regular">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, sit!
      </p>
      <span className="hr" />
      <p className="pg-small">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloribus,
        tenetur?
      </p>
      <span className="hr" />

      
      <h1 className="title">Mobile Typography</h1>
      <p>
        fore mobile view kindly use insepect element and make small screen of
        your browser
      </p>
    </div>
  );
};

export default Testing;
