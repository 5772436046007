import React, { useRef } from 'react';
import { Collapse } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useIsDesktop } from '../../hooks/customHooks';

import './faq.scss';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;

function callback(key) {}

const Faq = () => {
  const { t } = useTranslation();
  const isMobile = useIsDesktop();

  const text = {
    textone: `${t('odp-pu.faq.category.-.-how-does-the-platform-work-')}`,
    texttwo: `${t('odp-pu.faq.category.-.-account')}`,
    textthree: `${t('odp-pu.faq.category.-.-participate-in-a-challenge')}`,
    textfour: `${t('odp-pu.faq.category.-.-participate-in-a-team-challenge')}`,
    textfive: `${t('odp-pu.faq.category.-.-how-to-sponsor-a-challenge')}`,
    textsix: `${t('odp-pu.faq.category.-.-any-other-questions-')}`,

    sectionOne: [
      {
        id: 1,
        header: `${t('odp-pu.faq.qa.what-is-open-data-playground-')}`,
        textOne: `${t('odp-pu.faq.qa.open-data-playground-is-the-hackathon-an')}`,
        textTwo: `${t('odp-pu.faq.qa.open-data-playground-is-the-springboard-')}`,
        textThree: `${t('odp-pu.faq.qa.companies-and-universities')}`
      },
      {
        id: 2,
        header: `${t('odp-pu.faq.qa.what-type-of-challenges-can-i-participat')}`,
        textOne: `${t('odp-pu.faq.qa.--graduate-programs-aimed-at-junior-prof')}`,
        textTwo: `${t('odp-pu.faq.qa.data-challenge-recruitment')}`,
        textThree: `${t('odp-pu.faq.qa.data-hackathon-for-the-business')}`,
        textFour: `${t('odp-pu.faq.qa.hackathon-in-house-for-re-skilling')}`,
        textFive: `${t('odp-pu.faq.qa.academics-events-to-facilitate')}`
      },
      {
        id: 3,
        header: `${t('odp-pu.faq.qa.can-i-use-challenge-data-for-external-i')}`,
        textOne: `${t('odp-pu.faq.qa.you-will-not-be-allowed-to-share-or-use-')}`
      },
      {
        id: 4,
        header: `${t('odp-pu.faq.qa.what-points-does-the-global-leaderboard-')}`,
        textOne: `${t('odp-pu.faq.qa.the-points-in-the-global-leaderboard-ste')}`
      }
    ],
    sectionTwo: [
      {
        id: 1,
        header: `${t('odp-pu.faq.qa.how-do-you-create-a-user-profile-')}`,
        textOne: `${t('odp-pu.faq.qa.click-on-login-this-will-open-a-sign-in')}`,
        textTwo: `${t('odp-pu.faq.qa.once-logged-in-you-can-set-your-username')}`,
        textThree: `${t('odp-pu.faq.qa.it-is-mandatory-to-connect')}`
      },
      {
        id: 2,
        header: `${t('odp-pu.faq.qa.i-would-like-to-change-my-username-or-ot')}`,
        textOne: `${t('odp-pu.faq.qa.all-you-have-to-do-is-click-on-your-user')}`
      },
      {
        id: 3,
        header: `${t('odp-pu.faq.qa.what-happens-with-my-intellectual-proper')}`,
        textOne: `${t('odp-pu.faq.qa.each-player-or-team-remains-fully-respon')}`,
        textTwo: `${t('odp-pu.faq.qa.the-organizers-sponsors-and')}`,
        textThree: `${t('odp-pu.faq.qa.in-any-case-the-treatment')}`
      },
      {
        id: 4,
        header: `${t('odp-pu.faq.qa.can-i-delete-my-profile-')}`,
        textOne: `${t('odp-pu.faq.qa.of-course-you-can-delete-your-profile-a')}`
      }
    ],
    sectionThree: [
      {
        id: 1,
        header: `${t('odp-pu.faq.qa.how-to-enter-challenges')}`,
        textOne: `${t('odp-pu.faq.qa.to-participate-in-the-challenges-all-yo')}`,
        textTwo: `${t('odp-pu.faq.qa.note-it-is-mandatory-to-connect-your-lin')}`
      },
      {
        id: 2,
        header: `${t('odp-pu.faq.qa.where-can-i-download-the-dataset-related')}`,
        textOne: `${t('odp-pu.faq.qa.you-can-find-the-dataset-in-the-data-tab')}`
      },
      {
        id: 3,
        header: `${t('odp-pu.faq.qa.what-prizes-can-be-won-')}`,
        textOne: `${t('odp-pu.faq.qa.each-challenge-has-a-different-prize-fo')}`
      },
      {
        id: 4,
        header: `${t('odp-pu.faq.qa.how-are-scores-assigned-to-the-entries-i')}`,
        textOne: `${t('odp-pu.faq.qa.scores-may-vary-according-to-the-type-of')}`
      },
      {
        id: 5,
        header: `${t('odp-pu.faq.qa.how-does-the-leaderboard-work-within-the')}`,
        textOne: `${t('odp-pu.faq.qa.for-each-submission-made-by-you-or-your-')}`
      },
      {
        id: 6,
        header: `${t('odp-pu.faq.qa.can-i-opt-out-of-appearing-on-the-leader')}`,
        textOne: `${t('odp-pu.faq.qa.no-you-cannot-participate-in-one-or-mor')}`
      },
      {
        id: 7,
        header: `${t('odp-pu.faq.qa.how-do-i-submit-an-entry-to-a-challenge-')}`,
        textOne: `${t('odp-pu.faq.qa.to-upload-a-solution-you-have-to-go-to-t')}`
      },
      {
        id: 8,
        header: `${t('odp-pu.faq.qa.how-many-entries-can-i-submit-per-challe')}`,
        textOne: `${t('odp-pu.faq.qa.there-is-no-maximum-number-of-submission')}`
      },
      {
        id: 9,
        header: `${t('odp-pu.faq.qa.if-i-upload-more-than-one-entry-which-on')}`,
        textOne: `${t('odp-pu.faq.qa.the-submission-with-the-highest-score-wi')}`
      },
      {
        id: 10,
        header: `${t('odp-pu.faq.qa.can-i-drop-a-challenge-i-signed-up-for-')}`,
        textOne: `${t('odp-pu.faq.qa.you-can-leave-a-challenge-you-have-signe')}`
      }
    ],
    sectionFour: [
      {
        id: 1,
        header: `${t('odp-pu.faq.qa.how-do-i-create-a-team-')}`,
        textOne: `${t('odp-pu.faq.qa.to-create-a-team-you-have-to-go-to-your-')}`,
        textTwo: `${t('odp-pu.faq.qa.as-a-team-creator-you-will-be-automatica')}`
      },
      {
        id: 2,
        header: `${t('odp-pu.faq.qa.can-i-edit-team-members-')}`,
        textOne: `${t('odp-pu.faq.qa.you-can-edit-team-members-only-if-you-ar')}`
      },
      {
        id: 3,
        header: `${t('odp-pu.faq.qa.what-can-i-do-as-my-team-s-admin-')}`,
        textOne: `${t('odp-pu.faq.qa.if-you-are-the-admin-of-the-team-you-ca')}`,
        textTwo: `${t('odp-pu.faq.qa.only-the-admin-can-register')}`
      },
      {
        id: 4,
        header: `${t('odp-pu.faq.qa.can-i-leave-the-team-')}`,
        textOne: `${t('odp-pu.faq.qa.you-can-only-leave-the-team-if-it-is-not')}`
      },
      {
        id: 5,
        header: `${t('odp-pu.faq.qa.can-i-participate-in-the-same-challenge-')}`,
        textOne: `${t('odp-pu.faq.qa.no-it-is-not-possible-to-participate-in')}`
      },
      {
        id: 6,
        header: `${t('odp-pu.faq.qa.how-is-the-challenge-score-awarded-to-ea')}`,
        textOne: `${t('odp-pu.faq.qa.the-same-points-are-distributed-to-each-')}`
      },
      {
        id: 7,
        header: `${t('odp-pu.faq.qa.can-i-participate-in-more-than-one-chall')}`,
        textOne: `${t('odp-pu.faq.qa.you-can-participate-in-more-than-one-cha')}`
      },
      {
        id: 8,
        header: `${t('odp-pu.faq.qa.i-would-like-to-participate-in-a-team-ch')}`,
        textOne: `${t('odp-pu.faq.qa.in-case-you-are-having-difficulty-findin')}`
      },
      {
        id: 9,
        header: `${t('odp-pu.faq.qa.can-i-modify-the-members-of-my-team')}`,
        textOne: `${t('odp-pu.faq.qa.team-member-modification-in-challenge')}`
      }
    ],
    sectionFive: [
      {
        id: 1,
        header: `${t('odp-pu.faq.qa.i-am-a-company-what-do-i-have-to-do-to-')}`,
        textOne: `${t('odp-pu.faq.qa.once-in-contact-with-the-open-data-playg')}`,
        textTwo: `${t('odp-pu.faq.qa.the-open-data-playground-team-will-work')}`,
        textThree: `${t('odp-pu.faq.qa.to-learn-more-visit-the-business')}`,
        link: 'https://www.opendataplayground.com/companies'
      },
      {
        id: 2,
        header: `${t('odp-pu.faq.qa.i-am-a-university-research-center-or-kn')}`,
        textOne: `${t('odp-pu.faq.qa.once-in-contact-with-our-team-you-will-')}`,
        textTwo: `${t('odp-pu.faq.qa.the-open-data-playground-team-will-work-')}`,
        textThree: `${t('odp-pu.faq.qa.to-learn-more-visit-the-education-sectio')}`,
        link: 'https://www.opendataplayground.com/educational'
      }
    ],
    sectionSix: [
      {
        id: 1,
        textOne: `${t('odp-pu.faq.qa.if-you-are-participating-in-a-challenge-')}`,
        textTwo: `${t('odp-pu.faq.qa.contact-us-by-filling-out-a-request-form')}`
      }
    ]
  };

  const titleOne = useRef();
  const titleTwo = useRef();
  const titleThree = useRef();
  const titleFour = useRef();
  const titleFive = useRef();
  const titleSix = useRef();
  const titleBlockOne = useRef();
  const titleBlockTwo = useRef();
  const titleBlockThree = useRef();
  const titleBlockFour = useRef();
  const titleBlockFive = useRef();
  const titleBlockSix = useRef();

  const showSectionOne = () => {
    titleOne.current.style.color = '#5f76ff';
    titleTwo.current.style.color = '#3b3d4a';
    titleThree.current.style.color = '#3b3d4a';
    titleFour.current.style.color = '#3b3d4a';
    titleFive.current.style.color = '#3b3d4a';
    titleSix.current.style.color = '#3b3d4a';

    titleBlockOne.current.style.display = 'block';
    titleBlockTwo.current.style.display = 'none';
    titleBlockThree.current.style.display = 'none';
    titleBlockFour.current.style.display = 'none';
    titleBlockFive.current.style.display = 'none';
    titleBlockSix.current.style.display = 'none';
  };

  const ShowSectionTwo = () => {
    titleOne.current.style.color = '#3b3d4a';
    titleTwo.current.style.color = '#5f76ff';
    titleThree.current.style.color = '#3b3d4a';
    titleFour.current.style.color = '#3b3d4a';
    titleFive.current.style.color = '#3b3d4a';
    titleSix.current.style.color = '#3b3d4a';

    titleBlockOne.current.style.display = 'none';
    titleBlockTwo.current.style.display = 'block';
    titleBlockThree.current.style.display = 'none';
    titleBlockFour.current.style.display = 'none';
    titleBlockFive.current.style.display = 'none';
    titleBlockSix.current.style.display = 'none';
  };
  const showSectionThree = () => {
    titleOne.current.style.color = '#3b3d4a';
    titleTwo.current.style.color = '#3b3d4a';
    titleThree.current.style.color = '#5f76ff';
    titleFour.current.style.color = '#3b3d4a';
    titleFive.current.style.color = '#3b3d4a';
    titleSix.current.style.color = '#3b3d4a';

    titleBlockOne.current.style.display = 'none';
    titleBlockTwo.current.style.display = 'none';
    titleBlockThree.current.style.display = 'block';
    titleBlockFour.current.style.display = 'none';
    titleBlockFive.current.style.display = 'none';
    titleBlockSix.current.style.display = 'none';
  };
  const showSectionFour = () => {
    titleOne.current.style.color = '#3b3d4a';
    titleTwo.current.style.color = '#3b3d4a';
    titleThree.current.style.color = '#3b3d4a';
    titleFour.current.style.color = '#5f76ff';
    titleFive.current.style.color = '#3b3d4a';
    titleSix.current.style.color = '#3b3d4a';

    titleBlockOne.current.style.display = 'none';
    titleBlockTwo.current.style.display = 'none';
    titleBlockThree.current.style.display = 'none';
    titleBlockFour.current.style.display = 'block';
    titleBlockFive.current.style.display = 'none';
    titleBlockSix.current.style.display = 'none';
  };
  const showSectionFive = () => {
    titleOne.current.style.color = '#3b3d4a';
    titleTwo.current.style.color = '#3b3d4a';
    titleThree.current.style.color = '#3b3d4a';
    titleFour.current.style.color = '#3b3d4a';
    titleFive.current.style.color = '#5f76ff';
    titleSix.current.style.color = '#3b3d4a';

    titleBlockOne.current.style.display = 'none';
    titleBlockTwo.current.style.display = 'none';
    titleBlockThree.current.style.display = 'none';
    titleBlockFour.current.style.display = 'none';
    titleBlockFive.current.style.display = 'block';
    titleBlockSix.current.style.display = 'none';
  };
  const showSectionSix = () => {
    titleOne.current.style.color = '#3b3d4a';
    titleTwo.current.style.color = '#3b3d4a';
    titleThree.current.style.color = '#3b3d4a';
    titleFour.current.style.color = '#3b3d4a';
    titleFive.current.style.color = '#3b3d4a';
    titleSix.current.style.color = '#5f76ff';

    titleBlockOne.current.style.display = 'none';
    titleBlockTwo.current.style.display = 'none';
    titleBlockThree.current.style.display = 'none';
    titleBlockFour.current.style.display = 'none';
    titleBlockFive.current.style.display = 'none';
    titleBlockSix.current.style.display = 'block';
  };

  return (
    <div className="development__wrapper">
      <div className="faq__wrapper">
        <h1>FAQ</h1>
        <p className="subtitle">Read the frequently asked questions</p>
        {!isMobile && (
          <div className="queries__wrapper">
            <div className="left-queries-section">
              <p ref={titleOne} onClick={showSectionOne}>
                {text.textone}
              </p>
              <p ref={titleTwo} onClick={ShowSectionTwo}>
                {text.texttwo}
              </p>
              <p ref={titleThree} onClick={showSectionThree}>
                {text.textthree}
              </p>
              <p ref={titleFour} onClick={showSectionFour}>
                {text.textfour}
              </p>
              <p ref={titleFive} onClick={showSectionFive}>
                {text.textfive}
              </p>
              <p ref={titleSix} onClick={showSectionSix}>
                {text.textsix}
              </p>
            </div>
            <div ref={titleBlockOne} className="right-queries-section section-one">
              <Collapse bordered={false} onChange={callback}>
                {text.sectionOne.map((text) => (
                  <Panel header={text.header} key={text.id}>
                    <p>{text.textOne}</p>
                    <p>{text.textTwo}</p>
                    <p>{text.textThree}</p>
                    <p>{text.textFour}</p>
                    <p>{text.textFive}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
            <div ref={titleBlockTwo} className="right-queries-section section-two">
              <Collapse bordered={false} onChange={callback}>
                {text.sectionTwo.map((text) => (
                  <Panel header={text.header} key={text.id}>
                    <p>{text.textOne}</p>
                    <p>{text.textTwo}</p>
                    <p>{text.textThree}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
            <div ref={titleBlockThree} className="right-queries-section section-three">
              <Collapse bordered={false} onChange={callback}>
                {text.sectionThree.map((text) => (
                  <Panel header={text.header} key={text.id}>
                    <p>{text.textOne}</p>
                    <p>{text.textTwo}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
            <div ref={titleBlockFour} className="right-queries-section section-four">
              <Collapse bordered={false} onChange={callback}>
                {text.sectionFour.map((text) => (
                  <Panel header={text.header} key={text.id}>
                    <p>{text.textOne}</p>
                    <p>{text.textTwo}</p>
                  </Panel>
                ))}
              </Collapse>
            </div>
            <div ref={titleBlockFive} className="right-queries-section section-five">
              <Collapse bordered={false} onChange={callback}>
                {text.sectionFive.map((text) => (
                  <Panel header={text.header} key={text.id}>
                    <p>{text.textOne}</p>
                    <p>{text.textTwo}</p>
                    <p>
                      {text.textThree} <a href={text.link}>{t('odp-pu.faq.qa.here')}</a>.
                    </p>
                  </Panel>
                ))}
              </Collapse>
            </div>
            <div ref={titleBlockSix} className="right-queries-section section-six">
              {text.sectionSix.map((text) => (
                <div key={text.id} className="section-six-text">
                  <p>
                    {text.textOne}
                    <a href="https://opendataplayground.slack.com">
                      https://opendataplayground.slack.com
                    </a>
                  </p>
                  <p>{text.textTwo}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {isMobile && (
          <div className="queries__wrapper">
            <div className="left-queries-section">
              <p ref={titleOne} onClick={showSectionOne}>
                {text.textone}
              </p>
              <div ref={titleBlockOne} className="right-queries-section section-one">
                <Collapse bordered={false} onChange={callback}>
                  {text.sectionOne.map((text) => (
                    <Panel header={text.header} key={text.id}>
                      <p>{text.textOne}</p>
                      <p>{text.textTwo}</p>
                      <p>{text.textThree}</p>
                      <p>{text.textFour}</p>
                      <p>{text.textFive}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <p ref={titleTwo} onClick={ShowSectionTwo}>
                {text.texttwo}
              </p>
              <div ref={titleBlockTwo} className="right-queries-section section-two">
                <Collapse bordered={false} onChange={callback}>
                  {text.sectionTwo.map((text) => (
                    <Panel header={text.header} key={text.id}>
                      <p>{text.textOne}</p>
                      <p>{text.textTwo}</p>
                      <p>{text.textThree}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <p ref={titleThree} onClick={showSectionThree}>
                {text.textthree}
              </p>
              <div ref={titleBlockThree} className="right-queries-section section-three">
                <Collapse bordered={false} onChange={callback}>
                  {text.sectionThree.map((text) => (
                    <Panel header={text.header} key={text.id}>
                      <p>{text.textOne}</p>
                      <p>{text.textTwo}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <p ref={titleFour} onClick={showSectionFour}>
                {text.textfour}
              </p>
              <div ref={titleBlockFour} className="right-queries-section section-four">
                <Collapse bordered={false} onChange={callback}>
                  {text.sectionFour.map((text) => (
                    <Panel header={text.header} key={text.id}>
                      <p>{text.textOne}</p>
                      <p>{text.textTwo}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <p ref={titleFive} onClick={showSectionFive}>
                {text.textfive}
              </p>
              <div ref={titleBlockFive} className="right-queries-section section-five">
                <Collapse bordered={false} onChange={callback}>
                  {text.sectionFive.map((text) => (
                    <Panel header={text.header} key={text.id}>
                      <p>{text.textOne}</p>
                      <p>{text.textTwo}</p>
                      <p>
                        {text.textThree} <a href={text.link}>{t('odp-pu.faq.qa.here')}</a>.
                      </p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <p ref={titleSix} onClick={showSectionSix}>
                {text.textsix}
              </p>
              <div ref={titleBlockSix} className="right-queries-section section-six">
                {text.sectionSix.map((text) => (
                  <div key={text.id} className="section-six-text">
                    <p>
                      {text.textOne}
                      <a href="https://opendataplayground.slack.com">
                        https://opendataplayground.slack.com
                      </a>
                    </p>
                    <p>{text.textTwo}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Faq;
