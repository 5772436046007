import { createSlice } from '@reduxjs/toolkit';
import { loading } from '../../helpers/loading';

const initialState = {
  loading: loading.INIT,
  error: null,

  UserProfileImage: null,
  userProfileCV: null,

  challengeThumbnailImage: null,
  challengeThumbnailImageLoading: loading.INIT,

  challengeCoverImage: null,
  challengeCoverImageLoading: loading.INIT,

  teamProfileFileId: null,
  teamProfileLoading: loading.INIT,

  protectedFileId: null,
  protectedFileLoading: loading.INIT
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    /*get token varify reducers*/
    sentFileStart: (state) => {
      state.loading = loading.PENDING;
    },
    sentFileComplete: (state, { payload }) => {
      state.loading = loading.SUCSUSS;
      if (payload.type === 'getfile') {
        state.UserProfileImage = payload.data;
      }
    },
    setUserProfileCV: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.userProfileCV = action.payload;
    },
    sentFileError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },

    /*get token varify reducers*/
    sentTeamFileStart: (state) => {
      state.teamProfileLoading = loading.PENDING;
    },
    sentTeamFileComplete: (state, { payload }) => {
      state.teamProfileLoading = loading.SUCSUSS;
      state.teamProfileFileId = payload;
    },

    sentTeamFileError: (state, action) => {
      state.teamProfileLoading = loading.ERROR;
      state.error = action.payload;
    },

    /*get token varify reducers*/
    sentProtectedFileStart: (state) => {
      state.protectedFileLoading = loading.PENDING;
    },
    sentProtectedFileComplete: (state, { payload }) => {
      state.protectedFileLoading = loading.SUCSUSS;
      state.protectedFileId = payload;
    },

    sentProtectedFileError: (state, action) => {
      state.protectedFileLoading = loading.ERROR;
      state.error = action.payload;
    },

    /*get token varify reducers*/
    sentChallengeThumbnailStart: (state) => {
      state.challengeThumbnailImageLoading = loading.PENDING;
    },
    sentChallengeThumbnailComplete: (state, { payload }) => {
      state.challengeThumbnailImageLoading = loading.SUCSUSS;
      state.teamProfileFileId = payload;
    },

    sentChallengeThumbnailError: (state, action) => {
      state.challengeThumbnailImageLoading = loading.ERROR;
      state.error = action.payload;
    },

    /*get token varify reducers*/
    sentChallengeCoverStart: (state) => {
      state.challengeCoverImageLoading = loading.PENDING;
    },
    sentChallengeCoverComplete: (state, { payload }) => {
      state.challengeCoverImageLoading = loading.SUCSUSS;
      state.teamProfileFileId = payload;
    },

    sentChallengeCoverError: (state, action) => {
      state.challengeCoverImageLoading = loading.ERROR;
      state.error = action.payload;
    },
  },
});

export const {
  sentFileStart,
  sentFileComplete,
  sentFileError,
  setUserProfileCV,

  /*get token varify reducers*/
  sentTeamFileStart,
  sentTeamFileComplete,
  sentTeamFileError,

  sentProtectedFileStart,
  sentProtectedFileComplete,
  sentProtectedFileError,

  sentChallengeCoverStart,
  sentChallengeCoverComplete,
  sentChallengeCoverError,

  sentChallengeThumbnailStart,
  sentChallengeThumbnailComplete,
  sentChallengeThumbnailError,
} = fileSlice.actions;

export default fileSlice.reducer;
