import { createSlice } from '@reduxjs/toolkit';
import { loading } from '../../helpers/loading';

const initialState = {
  user: null,
  loading: loading.INIT,
  error: [],
  testEmail: 'uxmi@gmail.com',
  testName: 'Usman Islam',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = loading.PENDING;
    },
    fetchComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.user = action.payload;
    },
    fetchError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },
  },
});

export const { fetchStart, fetchComplete, fetchError } = userSlice.actions;

export default userSlice.reducer;
