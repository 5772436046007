import moment from 'moment';
import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';
export class ChallengeProperty {

    competition_prop_id = null;
    competition_prop_name = null;
    start_date = null;
    end_date = null;
    start_time = null;
    end_time = null;
    competition_details = null;
    competition_rules_description = null;
    competition_evaluation_description = null;
    competition_prize_description = null;
    participation_message = null;
    competition_location_type = "1";
    competition_location_url = null;
    competition_location_name = null;
    competition_location_address = null;
    data_set_file_id = null;
    solution_file_id = null;
    maximum_daily_submissions = 0;
    delay_between_submissions = 0;
    scoring_metric = 0;
    competition_category_id = null;
    participation_type = null;
    privacy_mode = 1;
    private_domain = "";
    complexity = 0;
    winning_prize = null;
    is_limited_participation = false;
    limit_participation_file_name = null;
    limit_participation_for_days = null;

    constructor() {
        this.competition_prop_id = null;
        this.competition_prop_name = null;
        this.start_date = null;
        this.end_date = null;
        this.start_time = new Date().setHours(0, 0, 0, 0);
        this.end_time = new Date().setHours(0, 0, 0, 0);
        this.competition_details = null;
        this.competition_rules_description = null;
        this.competition_evaluation_description = null;
        this.competition_prize_description = null;
        this.participation_message = null;
        this.competition_location_type = "1";
        this.competition_location_url = null;
        this.competition_location_name = null;
        this.competition_location_address = null;
        this.data_set_file_id = null;
        this.solution_file_id = null;
        this.maximum_daily_submissions = null;
        this.delay_between_submissions = null;
        this.competition_category_id = null;
        this.participation_type = null;
        this.privacy_mode = 1;
        this.private_domain = "";
        this.complexity = null;
        this.winning_prize = null;
        this.is_limited_participation = false;
        this.limit_participation_file_name = null;
        this.limit_participation_for_days = null;
    }

    deserialize(model) {

        this.competition_prop_id = StringConverter(model.competition_prop_id);
        this.competition_prop_name = StringConverter(model.competition_prop_name);

        let startDate = moment(moment(model.start_date).format("YYYY/MM/DD") + ' ' + moment(model.start_time).format("hh:mm:ss a")).format();
        let endDate = moment(moment(model.end_date).format("YYYY/MM/DD") + ' ' + moment(model.end_time).format("hh:mm:ss a")).format();

        this.start_date = startDate;
        this.end_date = endDate;
        // this.start_date = startDate.replaceAll('/', "-");
        // this.end_date = endDate.replaceAll('/', "-");

        this.competition_details = model.competition_details;
        this.competition_rules_description = model.competition_rules_description;
        this.competition_evaluation_description = model.competition_evaluation_description;
        this.competition_prize_description = model.competition_prize_description;
        this.participation_message = model.participation_message;
        this.competition_location_type = NumberConverter(model.competition_location_type);
        this.competition_location_url = StringConverter(model.competition_location_url);
        this.competition_location_name = StringConverter(model.competition_location_name);
        this.competition_location_address = StringConverter(model.competition_location_address);
        this.data_set_file_id = StringConverter(model.data_set_file_id);
        this.solution_file_id = StringConverter(model.solution_file_id);
        this.maximum_daily_submissions = NumberConverter(model.maximum_daily_submissions);
        this.delay_between_submissions = NumberConverter(model.delay_between_submissions);
        this.competition_category_id = StringConverter(model.competition_category_id);
        this.participation_type = NumberConverter(model.participation_type);
        this.privacy_mode = NumberConverter(model.privacy_mode);
        this.private_domain = StringConverter(model.private_domain);
        this.complexity = NumberConverter(model.complexity);
        this.winning_prize = StringConverter(model.winning_prize);
        this.is_limited_participation = BooleanConverter(model.is_limited_participation);
        this.limit_participation_file_name = StringConverter(model.limit_participation_file_name);
        this.limit_participation_for_days = NumberConverter(model.limit_participation_for_days);

        return this;
    }
}