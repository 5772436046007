import { useParams, useLocation, useHistory } from "react-router-dom";
import AdminPanel from "../admin";
import LinkedIn from "../components/LinkedIn";
import RouterView from "../routes";
import PrimaryLayout from "./primaryLayout";
import SecondaryLayout from "./secondryLayout";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Index = () => {
  const { user } = useSelector((state) => state.userProfile);

  const history = useHistory();
  const { pathname, search } = useLocation();
  const { slug } = useParams();


  useEffect(() => {
    if (user) {
      if (localStorage.getItem('admin_asset')) {
        history.push(localStorage.getItem('admin_asset'))
        localStorage.removeItem('admin_asset')
      }
    }

  }, [user])

  const CheckAdminRights = () => {
    if (user?.is_admin || user?.is_company_admin) {
      return <AdminPanel />;
    } else {
      if (pathname.includes('admin/assets/')) {
        localStorage.setItem('admin_asset', pathname + search);
        history.push('/login')
      }
      else history.push("/");
    }
  };

  return (
    <div>
      {pathname === "/login" ||
        pathname === "/signup" ||
        pathname === "/forgot-password" ||
        pathname === "/reset-password" ||
        pathname === "/reset-email" ? (
        <SecondaryLayout>
          <RouterView />
        </SecondaryLayout>
      ) : pathname === "/linkedin" ? (
        <LinkedIn slug={slug} />
      ) : pathname.includes("/admin") ? (
        CheckAdminRights()
      ) : (
        <PrimaryLayout>
          <RouterView />
        </PrimaryLayout>
      )}
    </div>
  );
};

export default Index;
