import React, { useEffect, useState, useRef } from 'react';
import Styles from './sponsor.module.scss';
import enevalopPath from '../../../asset/icons/envelope.svg';
import screenPath from '../../../asset/icons/screen-alt.svg';
import Logo from '../../../asset/images/sponsors-logo/docebo.png';
import { getSponsorById } from '../../../redux-store/services/Sponsor';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { getChallengeListings } from '../../../redux-store/services/Challenges';
import ChallengeCardSmall from '../../../components/ChallengeCard/CallengeCardSmall/ChallengeCardSmall';
import ChallengeCard from '../../../components/ChallengeCard';
import ListingPagination from '../../../components/Pagination';
import { buildParams, PaginationModel } from '../../../utils';
import Button from '../../../components/Button';
import SkeletonReact from 'react-loading-skeleton';
import { useIsDesktop, useIsIpadPro } from '../../../hooks/customHooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import LeftArrow from '../../../asset/icons/prevslide.svg';
import RightArrow from '../../../asset/icons/nextslide.svg';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

SwiperCore.use([Pagination]);

const SponsorPreview = ({ isAdmin = false }) => {
  const [sponsor, setSponsor] = useState(null);
  const [sponsorId, setSponsorId] = useState(null);
  const [liveChallenges, setLiveChallenges] = useState([]);
  const [upcomingChallenges, setUpcomingChallenges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [challenges, setChallenges] = useState([]);
  const [challengeLoading, setChallengeLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const isMobile = useIsDesktop();
  const isIpadPro = useIsIpadPro();
  const params = useParams();
  const sponsorFilter = {};

  const [activeChallengeSlide, setActiveChallengeSlide] = useState(1);

  const prevRef = useRef({});
  const nextRef = useRef({});
  const prevRef2 = useRef({});
  const nextRef2 = useRef({});

  sponsorFilter.pagination = new PaginationModel();

  if (!isMobile) {
    sponsorFilter.pagination.pageSize = 5;
  }

  const prevRefLive = useRef({});
  const nextRefLive = useRef({});

  useEffect(async () => {
    if (params && params.sponsorId) {
      setSponsorId(params.sponsorId);
    }
  }, []);

  useEffect(async () => {
    try {
      let response;
      setShowLoading(true);
      if (sponsorId) response = await getSponsorById(sponsorId);
      setShowLoading(false);
      setSponsor(response);

      if (sponsorId) {
        const { listings } = await getChallengeListings({
          request: {
            company_ids: sponsorId,
            is_published: true,
            competition_statuses: 2,
            pageNum: 1
          }
        });
        setLiveChallenges(listings);

        const { listings: upcomingChallenges } = await getChallengeListings({
          request: {
            company_ids: sponsorId,
            is_published: true,
            competition_statuses: 1,
            pageNum: 1,
            pageSize: 3
          }
        });
        setUpcomingChallenges(upcomingChallenges);

        let filters = buildParams(sponsorFilter);
        filters.company_ids = sponsorId;
        setChallengeLoading(true);
        const challengeData = await getChallengeListings({ request: filters });
        setChallengeLoading(false);
        setChallenges(challengeData);
      }
    } catch (err) {
      setShowLoading(false);
    }
  }, [sponsorId]);

  useEffect(async () => {
    if (isComponentMounted) {
      sponsorFilter.pagination.pageNum = currentPage;
      let filters = buildParams(sponsorFilter);
      filters.company_ids = sponsorId;
      setChallengeLoading(true);
      const challengeData = await getChallengeListings({ request: filters });
      setChallengeLoading(false);
      setChallenges(challengeData);
    }
    setIsComponentMounted(true);
  }, [currentPage]);

  return (
    <>
      <div className={Styles.sponsorPreviewContainer}>
        <div
          className={Styles.sponsorBackground}
          style={{
            background: showLoading
              ? '#ddd'
              : sponsor?.banner_file_id
              ? `url(${process.env.REACT_APP_RESOURCES_URL}${sponsor?.banner_file_id})`
              : '#5F76FF'
          }}></div>
        <div className={Styles.sponsorDetail}>
          <div className={Styles.sponsorInfo}>
            <div
              className={Styles.logo}
              style={{
                background: showLoading
                  ? '#ddd'
                  : `url(${process.env.REACT_APP_RESOURCES_URL}${sponsor?.logo_file_id})`
              }}></div>

            <div className={Styles.publicInfo}>
              {/* {sponsor?.contact_email && <p className={Styles.email}>
                {!showLoading && <><img src={enevalopPath} alt="envalop" className="sponsorEnv" /> {sponsor?.contact_email}</>}
                <Skeleton paragraph={{ rows: 0 }} loading={showLoading} />
              </p>} */}
              {sponsor?.company_website && (
                <p className={Styles.website}>
                  {!showLoading && (
                    <a
                      className={Styles.link}
                      href={
                        sponsor?.company_website.includes('https://') ||
                        sponsor?.company_website.includes('http://')
                          ? `${sponsor?.company_website}`
                          : `https://${sponsor?.company_website}`
                      }
                      target="_blank"
                      rel="noreferrer">
                      <img src={screenPath} alt="screen-alt" className="sponsorScr" />{' '}
                      {sponsor?.company_website}
                    </a>
                  )}
                  <Skeleton paragraph={{ rows: 0 }} loading={showLoading} />
                </p>
              )}
            </div>
            {isAdmin && (
              <Button
                to={`/admin/sponsor/${sponsorId}`}
                className={Styles.editSponsor}
                as="route"
                type="secondary">
                Edit Sponsor
              </Button>
            )}
          </div>
        </div>
        {!showLoading ? (
          <div
            dangerouslySetInnerHTML={{ __html: sponsor?.company_description }}
            className={Styles.description}></div>
        ) : (
          <Skeleton paragraph={{ rows: 4 }} className={Styles.description} />
        )}
      </div>

      {(liveChallenges.length > 0 || upcomingChallenges.length > 0) && (
        <div className={Styles.sponsorPreviewContainer2}>
          <div className={Styles.sponsorChallenges}>
            <div className={Styles.doceboChallenge}>
              <h1>{sponsor?.company_name}'s Challenges</h1>
              <p>
                Sign up and participate in {sponsor?.company_name}'s competitions and win prizes!
              </p>
            </div>

            {liveChallenges && liveChallenges.length > 0 && (
              <div className={Styles.allChallenges}>
                <h3>Live now!</h3>
                <p className={Styles.description}>
                  Discover all the challenges organized by {sponsor?.company_name}
                </p>
                {!isMobile && (
                  <>
                    <div
                      className={`${!isMobile ? 'd-flex align-items-center' : ''} ${
                        liveChallenges.length > 3
                          ? 'justify-content-center'
                          : 'justify-content-around'
                      } ${Styles.challengeCards}`}>
                      {liveChallenges.length <= 3 &&
                        !isMobile &&
                        liveChallenges.map((challenge, index) => (
                          <div key={index} className={Styles.challengeCard}>
                            <SwiperSlide>
                              <ChallengeCardSmall
                                {...challenge}
                                previewChallenge={`/challenge/${challenge.competition_id}`}
                              />
                            </SwiperSlide>
                          </div>
                        ))}

                      {liveChallenges.length > 3 && (
                        <>
                          {' '}
                          {!isMobile && (
                            <div
                              ref={prevRefLive}
                              style={{
                                position: 'absolute',
                                left: !isIpadPro ? '5em' : '2em',
                                cursor: 'pointer',
                                zIndex: 2
                              }}>
                              <img src={LeftArrow} />
                            </div>
                          )}
                          <Swiper
                            mousewheel={{ sensitivity: 2 }}
                            slidesPerView={3}
                            freeMode={false}
                            // navigation={true}
                            pagination={{
                              clickable: true,
                              // dynamicBullets: true,
                              el: '.live-custom-pagination',
                              bulletActiveClass: Styles.customPaginationBulletActive,
                              renderBullet: (index, className) => {
                                return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                              }
                            }}
                            navigation={{
                              prevEl: prevRefLive.current,
                              nextEl: nextRefLive.current
                            }}
                            className="mySwiper"
                            breakpoints={{
                              320: { slidesPerView: 1, centeredSlides: true, slidesPerGroup: 1 },
                              768: {
                                slidesPerView: 2,
                                slidesPerGroup: 2
                              },
                              1024: {
                                slidesPerView: 3,
                                slidesPerGroup: 3
                              }
                            }}>
                            <div className="d-flex justify-content-between">
                              {liveChallenges &&
                                liveChallenges.length > 0 &&
                                liveChallenges.map((challenge, index) => (
                                  <div key={index} className={Styles.challengeCard}>
                                    <SwiperSlide>
                                      <ChallengeCardSmall
                                        {...challenge}
                                        previewChallenge={`/challenge/${challenge.competition_id}`}
                                      />
                                    </SwiperSlide>
                                  </div>
                                ))}
                            </div>
                          </Swiper>
                          {!isMobile && (
                            <div
                              id="nexRefLive"
                              ref={nextRefLive}
                              style={{
                                position: 'absolute',
                                right: !isIpadPro ? '5em' : '2em',
                                cursor: 'pointer',
                                zIndex: 2
                              }}>
                              <img src={RightArrow} />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className={`col-12 ${Styles.customPaginationContainer}`}>
                      <div
                        className={`live-custom-pagination ${Styles.customPagination}`}
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                          marginRight: '10px'
                        }}></div>
                    </div>
                  </>
                )}
                {isMobile && (
                  <>
                    <div className={Styles.challengeCardWrapper}>
                      {liveChallenges &&
                        liveChallenges.length <= 3 &&
                        liveChallenges.map((challenge, index) => (
                          <div key={index} className={Styles.challengeCard}>
                            <SwiperSlide>
                              <ChallengeCardSmall
                                {...challenge}
                                previewChallenge={`/challenge/${challenge.competition_id}`}
                              />
                            </SwiperSlide>
                          </div>
                        ))}
                      {liveChallenges && liveChallenges.length > 3 && (
                        <Swiper
                          id="testSwiperContainer"
                          className="testSwiperContainer"
                          slidesPerView={3}
                          breakpoints={{
                            320: { slidesPerView: 1, centeredSlides: true, slidesPerGroup: 1 },
                            768: { slidesPerView: 2, slidesPerGroup: 2 },
                            1024: { slidesPerView: 3, slidesPerGroup: 3 }
                          }}
                          pagination={{
                            clickable: true,
                            // dynamicBullets: true,
                            el: '.custom-pagination-2',
                            bulletActiveClass: Styles.customPaginationBulletActive,
                            renderBullet: (index, className) => {
                              return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                            }
                          }}
                          spaceBetween={10}>
                          {liveChallenges.map((challenge, index) => (
                            <div key={index} className={Styles.challengeCard}>
                              <SwiperSlide>
                                <ChallengeCardSmall
                                  {...challenge}
                                  previewChallenge={`/challenge/${challenge.competition_id}`}
                                />
                              </SwiperSlide>
                            </div>
                          ))}
                        </Swiper>
                      )}
                    </div>

                    <div className={`col-12 ${Styles.customPaginationContainer}`}>
                      <div
                        className={`custom-pagination-2 ${Styles.customPagination}`}
                        style={{
                          textAlign: 'center',
                          marginTop: 20,
                          marginRight: '10px'
                        }}></div>
                    </div>
                  </>
                )}
              </div>
            )}

            {upcomingChallenges && upcomingChallenges.length > 0 && (
              <div className={Styles.allChallenges}>
                <h3>Upcoming Challenges</h3>
                <p className={Styles.description}>
                  Discover all the challenges organized by {sponsor?.company_name}
                </p>
                <div className="row justify-content-center">
                  <div className="row col-md-10 justify-content-center d-flex">
                    {upcomingChallenges.map((challenge, index) => (
                      <div
                        className="col-md-4 justify-content-center align-items-center d-flex"
                        key={index}>
                        <div className={Styles.challengeCard}>
                          <ChallengeCardSmall
                            {...challenge}
                            previewChallenge={`/challenge/${challenge.competition_id}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={Styles.allChallenges}>
        <h2>All {sponsor?.company_name}'s challenges</h2>
        <p className={Styles.description}>
          Discover all the challenges organized by {sponsor?.company_name}
        </p>

        {!isMobile && !challengeLoading && challenges?.listings && challenges.listings.length > 0
          ? challenges.listings.map((challenge, index) => (
              <div key={index} className={Styles.challengeCard}>
                <ChallengeCard
                  {...challenge}
                  previewChallenge={`/challenge/${challenge.competition_id}`}
                />
              </div>
            ))
          : challenges.listings &&
            challenges.listings.length === 0 && (
              <div className="my-5 text-center ">
                <h4>No Record Found</h4>
              </div>
            )}
        {isMobile && (
          <div>
            <Swiper
              mousewheel={{ sensitivity: 2 }}
              onSlideChange={(e) => setActiveChallengeSlide(e.activeIndex + 1)}
              slidesPerView={1}
              navigation={{
                prevEl: prevRef2.current,
                nextEl: nextRef2.current
              }}
              className="mySwiper">
              <div className="d-flex justify-content-between">
                {!challengeLoading && challenges?.listings && challenges.listings.length > 0
                  ? challenges.listings.map((challenge, index) => (
                      <div key={index} className={Styles.challengeCard}>
                        <SwiperSlide>
                          <ChallengeCardSmall
                            {...challenge}
                            previewChallenge={`/challenge/${challenge.competition_id}`}
                          />
                        </SwiperSlide>
                      </div>
                    ))
                  : challenges.listings &&
                    challenges.listings.length === 0 && (
                      <div className="my-5 text-center ">
                        <h4>No Record Found</h4>
                      </div>
                    )}
              </div>
            </Swiper>
            <div className="d-flex justify-content-center align-items-center">
              {isMobile && (
                <>
                  <div ref={prevRef2} style={{ cursor: 'pointer' }}>
                    <img src={LeftArrow} />
                  </div>
                  <span className="px-3">
                    {activeChallengeSlide} of {challenges._metadata?.totalResults} results
                  </span>
                  <div ref={nextRef2} style={{ cursor: 'pointer' }}>
                    <img src={RightArrow} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {challengeLoading && (
          <>
            <SkeletonReact style={{ height: '250px', borderRadius: '10px' }} />
            <SkeletonReact
              style={{
                height: '250px',
                marginTop: '30px',
                borderRadius: '10px'
              }}
            />
          </>
        )}
        {!isMobile && (
          <ListingPagination
            pageSize={challenges._metadata?.pageSize}
            totalRecord={challenges._metadata?.totalResults}
            currentPage={currentPage}
            styles={{ marginTop: '40px' }}
            onPaginationChange={(page) => {
              setCurrentPage(page);
            }}
            showPageDifference={false}
          />
        )}
      </div>
    </>
  );
};

export default SponsorPreview;
