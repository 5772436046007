import { Collapse } from "antd";
import TeamCard from "./card";
import "./teamMember.scss";
const { Panel } = Collapse;
const Index = ({ team_users, index }) => {
  return (
    <div className="team__members__accordian">
      <div className="team__members__wrapper">
        <Collapse ghost expandIconPosition="right" defaultActiveKey={["0"]}>
          <Panel
            header={`Team members (${team_users?.length || 0
              })`}
            key={index}
          >
            {team_users &&
              team_users.map((member) => (
                <TeamCard {...member} key={member.avatar_url} />
              ))}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Index;
