import React, { useState } from 'react';
import Styles from './challengeTab.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import FileDragger from '../../../components/FileDragger';
import { notify } from '../../../theme/antNotify';
import { saveFile } from '../../../services/fileupload/api';
import { useTranslation } from 'react-i18next';

const Design = ({ setThumbnail, setCover, thumbnailImageId, coverImageId }) => {
  const { t } = useTranslation();
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  const dispatch = useDispatch();

  const { requestObject: challengeObject } = useSelector((state) => state.challengePayload);

  const onThumbnailImageSave = async (e) => {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;
    let fileExtension = files[0].name.match(/\.(.+)$/)[1];

    if (validateFile(fileExtension) && validateImageSize(files[0], 'thumbnail')) {
      const fileData = new FormData();
      fileData.append('upfile', files[0]);
      setThumbnailLoading(true);
      const fileID = await saveFile(dispatch, {
        data: {
          upfile: fileData
        }
      });
      setThumbnailLoading(false);
      setThumbnail(fileID);
      setThumbnailImage(fileID);
    } else {
    }
  };

  const onCoverImageSave = async (e) => {
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files;
    let fileExtension = files[0].name.match(/\.(.+)$/)[1];
    if (validateFile(fileExtension) && validateImageSize(files[0], 'cover')) {
      const fileData = new FormData();
      fileData.append('upfile', files[0]);
      setCoverLoading(true);
      const fileID = await saveFile(dispatch, {
        data: {
          upfile: fileData
        }
      });
      setCoverLoading(false);
      setCover(fileID);
      setCoverImage(fileID);
    } else {
    }
  };

  const validateImageSize = (file, fileType) => {
    let fileSize = bytesToMegaByte(file.size);
    if (fileType == 'thumbnail') {
      if (fileSize > 5) {
        notify('error', 'Incorrect file size', 'Please upload file upto 5 MB');
      } else return true;
    } else if (fileType == 'cover') {
      if (fileSize > 10) {
        notify('error', 'Incorrect file size', 'Please upload file upto 10 MB');
      } else return true;
    }
  };

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case 'jpg':
        return true;
      case 'png':
        return true;
      default:
        notify('error', 'Incorrect format', 'Please upload file with extension (.jpg or .png)');
    }
  };

  function bytesToMegaByte(bytes) {
    if (bytes === 0) return 'n/a';
    const i = parseFloat(bytes / 1024 / 1024);
    if (i === 0) return `${bytes}`;
    return i;
  }

  return (
    <div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.thumbnail-image')}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.-upload-one-image-to-be-used-as-a-square')}
          </span>
          <FileDragger
            id="thumbnailImage"
            buttonText="Image"
            fileExtensions=".jpg or .png"
            fileSize="5 MB"
            isImage={true}
            imageDimensions="400 x 400 px"
            loading={thumbnailLoading}
            selectedFile={thumbnailImageId || thumbnailImage}
            label="Select image"
            onFileDrop={onThumbnailImageSave}
            style={{
              maxWidth: '250px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
            removeFile={(e) => {
              e.preventDefault();
              setThumbnailImage(false);
              setThumbnail(null);
            }}
          />
        </div>
      </div>
      <div className={`${Styles.inputContainer} mt-5`}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.challenge-image')}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.imageUploadLabel}`}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.-upload-one-image-to-be-used-in-the-head')}
          </span>
          <div className={Styles.imagePickerBox} style={{ width: 100 + '%' }}></div>
          <FileDragger
            id="coverImage"
            buttonText="Image"
            fileExtensions=".jpg or .png"
            fileSize="10 MB"
            isImage={true}
            loading={coverLoading}
            label="Select image"
            imageDimensions="1200 x 900 px"
            style={{ width: 100 + '%', backgroundSize: 'cover', backgroundPosition: 'center' }}
            onFileDrop={onCoverImageSave}
            selectedFile={coverImageId || coverImage}
            removeFile={(e) => {
              e.preventDefault();
              setCover(null);
              setCoverImage(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Design;
