import React, { useState } from 'react';
import { useRouteMatch, Switch, NavLink } from "react-router-dom";
import ProtectedRoutes from "../../../routes/protectedRoutes";
// import AccountSetting from "../../AccountSetting";
import Challenges from "../../Challenges";
import Dashboard from "../../Dashboard";
import Profile from "../../profile";
import Team from "../../Team";
import WellcomeTeam from "../../Team/wellcomeTeam";
import CreateTeam from "../../Team/createTeam";
import { useIsMobile } from "../../../hooks/customHooks";
import "./profileRoutes.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
import { AccountSettings } from "../../../pages/AccountSettings";
import { useSelector } from "react-redux";
import { notify } from "../../../theme/antNotify";
import { useTranslation } from 'react-i18next';
const innerlinks = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },

  {
    name: "odp-pu.profile.profile-links-your-challenge",
    path: "/challenge",
  },

  {
    name: "Team",
    path: "/team",
  },

  {
    name: "odp-pu.profile.profile-links-profile",
    path: "/profile",
  },
  {
    name: "Account & Settings",
    path: "/account-settings",
  },
];

const innerRoutes = [
  {
    component: AccountSettings,
    path: "/account-settings",
    exact: true,
    auth: false,
  },
  {
    component: Challenges,
    path: "/challenge",
    exact: true,
    auth: false,
  },
  {
    component: Dashboard,
    path: "/dashboard",
    exact: true,
    auth: false,
  },
  {
    component: Profile,
    path: "/profile",
    exact: true,
    auth: false,
  },
  {
    component: Team,
    path: "/team",
    exact: true,
    auth: false,
  },

  {
    component: CreateTeam,
    path: "/team/create-team",
    exact: true,
    auth: false,
  },
  {
    component: CreateTeam,
    path: "/team/edit-team/:teamId",
    exact: true,
    auth: false,
  },
];

const ProfileRoutes = () => {
  let { url, path } = useRouteMatch();
  const { user } = useSelector((state) => state.publicProfile);
  const isMobile = useIsMobile();
  const [currentLink, setCurrentLink] = useState(null)

  const { t } = useTranslation();

  const onNavClick = (link) => {
    if (user?.profile_status == 2 && link.path != '/profile' && link.path != '/account-settings') {
      notify(
        "error",
        "Complete your profile",
        "You need to complete your profile to participate to the challenges or join a team."
      );
    }
  };
  return (
    <div>
      {isMobile && (
        <div className="profile-menu">
          <Swiper freeMode={true} slidesPerView={2.3}>
            {innerlinks.map((link) => (
              <SwiperSlide>
                <NavLink
                  to={(user?.profile_status != 2 || link.path == '/profile' || link.path == '/account-settings') && `${url}${link.path}`}
                  className={`link-items ${(user?.profile_status == 2 && (link.path != '/account-settings' && link.path != '/profile')) ? "disabled" : ''
                    }`}
                  activeClassName="selected"
                  onClick={() => { onNavClick(link) }}
                  key={link.name}
                >
                  {t(link.name)}
                </NavLink>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {!isMobile && (
        <div className="profile-menu">
          {innerlinks.map((link) => {
            return (
              <NavLink
                to={(user?.profile_status != 2 || link.path == '/profile' || link.path == '/account-settings') && `${url}${link.path}`}
                className={`link-items ${(user?.profile_status == 2 && (link.path != '/account-settings' && link.path != '/profile')) ? "disabled" : ''
                  }`}
                activeClassName="selected"
                onClick={() => { onNavClick(link) }}
                key={link.name}
              >
                {t(link.name)}
              </NavLink>
            );
          })}
        </div>
      )}
      <div className="profile__routes__wrapper">
        <Switch>
          {innerRoutes.map((component) => {
            return (
              <ProtectedRoutes
                exact
                path={`${path}${component.path}`}
                component={component.component}
                key={component.path}
              />
            );
          })}
        </Switch>
      </div>
    </div>
  );
};

export default ProfileRoutes;
