import React, { useEffect, useMemo, useState } from "react";
import "./challenges.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Icon from "../../../components/Icons";
import Eye from "../../../asset/icons/eyeBlue.svg";
import ArrowUp from "../../../asset/icons/up-small.svg";
import ArrowDown from "../../../asset/icons/down-small.svg";
import Sorting from "../../../asset/icons/sorting.svg";
import Edit from "../../../asset/icons/editBlue.svg";
import Table from "../../Components/Table";
import TableHead from "../../Components/Table/tableHead";
import Button from '../../../components/Button'
import TableBody from "../../Components/Table/tableBody";
import SearchBox from "../../Components/SearchBox";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  getChallengeById,
  getChallengeListings,
} from "../../../redux-store/actions/ChallengeAction";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { buildParams, PaginationModel } from "../../../utils";
import SortHeader from "../../Components/Table/SortHeader";
import { setScoringEvaluation } from "../../../redux-store/actions/utilsAction";
import { Empty } from "antd";

const PAGE_SIZE = 20;

const Challenges = () => {
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const challengeFilters = {};
  challengeFilters.pagination = new PaginationModel();
  challengeFilters.pagination.pageSize = PAGE_SIZE;
  const SkeletonArr = [1, 2, 3, 4, 5];
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchText, setSearchText] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [componentMounted, setComponentMounted] = useState(false);
  const routeToCreateChallenge = () => {
    history.push("/admin/challenge");
  };

  const { loading, listings, _metadata } = useSelector(
    (state) => state.challenge
  );

  useEffect(() => {
    getChallenges(challengeFilters);
    return () => {
    };
  }, []);

  useEffect(() => {
    if (searchText == "") getChallenges(challengeFilters);
    return () => { };
  }, [searchText]);

  useEffect(() => {
    if (componentMounted) {
      challengeFilters.pagination.pageNum = currentPage;
      getChallenges(challengeFilters);
    }
    setComponentMounted(true);
  }, [currentPage]);

  const getFilteredChallengeListings = () => {
    challengeFilters.searchBy = "competition_name";
    challengeFilters.searchText = searchText;
    getChallenges(challengeFilters);
  };

  const getChallenges = (filters) => {
    filters.is_my_company = true;
    let params = buildParams(filters);
    dispatch(getChallengeListings(params));
  };


  const sortColumn = () => {
    if (componentMounted) {
      challengeFilters.sortBy = sortOrder;
      challengeFilters.orderField = sortBy;
      getChallenges(challengeFilters);
    }
  }

  return (
    <div className="challengePage">
      <div className="title">
        <h2>{t("odp-ad.challenge.manage.manage-challenges")}</h2>
      </div>
      <div className="challengeDetailTitle">
        <p>
          {t("odp-ad.challenge.manage.total-challenges-")}{" "}
          {_metadata?.totalResults}
        </p>
        <Button
          type="accent"
          onClick={routeToCreateChallenge}
        >
          {t("odp-ad.challenge.manage.create-new-challenge")}
        </Button>
      </div>
      <SearchBox
        placeHolder={t("odp-ad.challenge.manage.filter-by-title")}
        onEnter={getFilteredChallengeListings}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className={'tableContainer'}>
        <Table>
          <TableHead>
            <SortHeader sortField="competition_name" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>{t("odp-ad.challenge.manage.title")}</SortHeader>
            <SortHeader sortField="competition_status" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>{t("odp-ad.challenge.manage.status")}</SortHeader>
            <SortHeader sortField="is_published" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>{t("odp-ad.challenge.manage.publish")}</SortHeader>
            <SortHeader sortField="company_name" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>{t("odp-ad.challenge.manage.sponsor")}</SortHeader>
            <SortHeader sortField="mod_date" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>{t("odp-ad.challenge.manage.last-update")}</SortHeader>
            <th colSpan="2">{t("odp-ad.challenge.manage.action")}</th>
          </TableHead>
          <TableBody>
            {!loading &&
              listings &&
              listings.map(
                ({
                  competition_id,
                  competition_name,
                  is_published,
                  mod_date,
                  competition_status,
                  company
                }) => (
                  <tr key={competition_id}>
                    <td>{competition_name}</td>
                    <td>
                      <span
                        className={
                          "badge rounded-pill " +
                          (competition_status == 1
                            ? "bg-primary"
                            : competition_status == 2
                              ? "bg-success"
                              : competition_status == 3
                                ? "bg-warning"
                                : "bg-dark")
                        }
                      >
                        {competition_status == 1
                          ? "Planned"
                          : competition_status == 2
                            ? "Live"
                            : competition_status == 3
                              ? "Under Review"
                              : "Closed"}
                      </span>
                    </td>
                    <td>
                      {is_published ? <span>Online</span> : <span>Draft</span>}
                    </td>
                    <td>
                      {company?.company_name}
                    </td>
                    <td>{moment(mod_date).format("DD/MM/YYYY")}</td>
                    <td>
                      <Link to={`/admin/challenge/${competition_id}/preview`}>
                        <Icon src={Eye} /> {t("odp-ad.user.preview.preview")}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admin/challenge/${competition_id}`}>
                        <Icon src={Edit} /> {t("odp-ad.user.edit.edit")}
                      </Link>
                    </td>
                  </tr>
                )
              )}
            {loading &&
              SkeletonArr.map((obj, index) => (
                <tr key={index}>
                  <td style={{ width: "590px" }}>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
      </div>
      {listings && listings.length > 0 && (
        <Pagination
          pageSize={_metadata?.pageSize}
          totalRecord={_metadata?.totalResults}
          currentPage={currentPage}
          styles={{ marginTop: "40px", marginBottom: "40px" }}
          onPaginationChange={(page) => {
            setCurrentPage(page);
          }}
          showPageDifference={false}
        />
      )}
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        {!loading && listings.length === 0 && <Empty />}
      </div>
    </div>
  );
};

export default Challenges;
