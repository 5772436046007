export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS";
export const GET_ACCOUNT_SETTINGS = "GET_ACCOUNT_SETTINGS";

export const changeEmail = (request) => ({
  type: CHANGE_EMAIL,
  request,
});

export const verifyEmail = (request) => ({
  type: VERIFY_EMAIL,
  request,
});

export const changePassword = (request) => ({
  type: CHANGE_PASSWORD,
  request,
});

export const accountSettings = (request) => ({
  type: ACCOUNT_SETTINGS,
  request,
});

export const getAccountSettings = (user_id) => ({
  type: GET_ACCOUNT_SETTINGS,
  user_id,
});
