import { axiosInstance } from "../../configs/axois";

import { notify } from "../../theme/antNotify";

import {
  /*create team  */
  createTeamStart,
  createTeamCompleted,
  createTeamError,

  /*Email Invitation  */
  sendInviteeEmailStart,
  sendInviteeEmailCompleted,
  sendInviteeEmailError,

  /*Get all team by user id*/
  getTeamsForUserIDStart,
  getTeamsForUserIDCompleted,
  getTeamsForUserIDError,
  getTeamMembersStart,
  getTeamMembersCompleted,
  getTeamMembersError,

  /*Get all challenge of Team  */
  getTeamsChallangesStart,
  getTeamsChallangesCompleted,
  getTeamsChallangesError,

  // Team member actions
  putTeamMembersActionStart,
  putTeamMembersActionCompleted,
  putTeamMembersActionError,
  editTeamStart,
  editTeamCompleted,
  editTeamError,
  getTeamByIdStart,
  getTeamByIdCompleted,
  getTeamByIdError,
} from "../../store/team";

export const createTeamApi = async (dispatch, payload, user_id) => {
  try {
    dispatch(createTeamStart());
    const response = await axiosInstance.post(`team`, payload);

    dispatch(createTeamCompleted(response.data.team_id));
    /*refresh getting all teams by userid*/
    getTeams(dispatch, user_id);
    getTeamMembers(dispatch, [
      {
        invitee_user_id: user_id.user_id,
        team_id: response.data.team_id,
      },
    ]);
    /*refresh getting all teams by userid*/
    notify(
      "success",
      "Team Created",
      "Now you can add team member by invitation through their emails"
    );
  } catch (error) {
    dispatch(createTeamError(error));
  }
};

export const editTeamApi = async (dispatch, payload, user_id, team_id) => {
  try {
    dispatch(editTeamStart());
    const response = await axiosInstance.put(`team/${team_id}`, payload);

    dispatch(editTeamCompleted(response.data.team_id));
    /*refresh getting all teams by userid*/
    getTeams(dispatch, user_id);
    getTeamById(dispatch, team_id);
    /*refresh getting all teams by userid*/
    notify(
      "success",
      "Team Edited",
      "Now you can add team member by invitation through their emails"
    );
  } catch (error) {
    dispatch(editTeamError(error));
  }
};

export const getTeamById = async (dispatch, team_id) => {
  try {
    dispatch(getTeamByIdStart());
    const response = await axiosInstance.get(`team/${team_id}`);

    dispatch(getTeamByIdCompleted(response.data));
    /*refresh getting all teams by userid*/
    // getTeams(dispatch, user_id);
    /*refresh getting all teams by userid*/
    // notify(
    //   "success",
    //   "Team Edited",
    //   "Now you can add team member by invitation thriugh their emails"
    // );
  } catch (error) {
    dispatch(getTeamByIdError(error));
  }
};

export const sentInvite = async (dispatch, payload) => {
  try {
    dispatch(sendInviteeEmailStart());
    const response = await axiosInstance.post(`teamInvitation`, payload);
    getTeamMembers(dispatch, payload);

    dispatch(sendInviteeEmailCompleted(response.data.team_id));
    notify(
      "success",
      "Team Created",
      "Now you can add team member by invitation through their emails"
    );
  } catch (error) {
    dispatch(sendInviteeEmailError(error));
  }
};
export const getTeams = async (dispatch, payload) => {
  try {
    dispatch(getTeamsForUserIDStart());
    const response = await axiosInstance.get(`user/${payload.user_id}/team`);

    dispatch(getTeamsForUserIDCompleted(response.data.listings));
  } catch (error) {
    dispatch(getTeamsForUserIDError(error));
  }
};

export const getTeamMembers = async (dispatch, payload) => {
  try {
    dispatch(getTeamMembersStart());
    const response = await axiosInstance.get(
      `user/${payload[0]?.invitee_user_id}/team/${payload[0]?.team_id}`
    );

    dispatch(getTeamMembersCompleted(response.data));
  } catch (error) {
    dispatch(getTeamMembersError(error));
  }
};

export const teamMemberActions = async (dispatch, payload, user_id) => {
  try {
    const data = {
      user_id: payload.user_id,
      action: payload.action,
    };
    dispatch(putTeamMembersActionStart());
    const response = await axiosInstance.put(
      `team/${payload.team_id}/action`,
      data
    );
    getTeamMembers(dispatch, [
      {
        invitee_user_id: user_id,
        team_id: payload.team_id,
      },
    ]);
    getTeams(dispatch, { user_id });

    dispatch(putTeamMembersActionCompleted(response.data));
  } catch (error) {
    dispatch(putTeamMembersActionError(error));
  }
};

export const getTeamChallanges = async (payload) => {
  try {
    const response = await axiosInstance.get(
      `team/${payload.team_id}/challenge`
    );

    return response;
  } catch (error) {}
};

export const respondInvite = async (payload, team_invitation_id) => {
  try {
    const response = await axiosInstance.put(
      `teamInvitation/${team_invitation_id}`,
      payload
    );

    notify(
      "success",
      "Invite updated",
      "Your invite response has been updated"
    );
  } catch (error) {
    notify("error", "Invite updated", "Your invite response cannot be updated");
  }
};
