import { axiosInstance } from '../../configs/axois';

import { notify } from '../../theme/antNotify';
import {
  sentFileStart,
  sentFileComplete,
  sentFileError,
  setUserProfileCV,

  /*get token varify reducers*/
  sentTeamFileStart,
  sentTeamFileComplete,
  sentTeamFileError,
  sentProtectedFileStart,
  sentProtectedFileComplete,
} from '../../store/fileupload/';

import { participantsEmailsCsvFileEndpoint } from '../../utils/endPoints';
import { saveProtectedFileEndpoint } from '../../utils/endPoints';

export const saveFile = async (dispatch, payload) => {
  try {
    dispatch(sentFileStart());

    const response = await axiosInstance.post(`file`, payload.data.upfile, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (response.data.file_id) {
      dispatch(sentFileComplete({ type: 'savefile' }));
      return response.data.file_id;
    }
  } catch (error) {
    notify(
      'error',
      'Upload Error ',
      'Some thing is happen wrong we cant get your data'
    );
    dispatch(sentFileError(error));
  }
};

export const saveProtectedFile = async (dispatch, payload) => {
  try {
    dispatch(sentProtectedFileStart());

    const configs = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    if (payload.evaluation_function_id) {
      configs.params = {
        evaluation_function_id: payload.evaluation_function_id
      }
    }

    const { data, status } = await axiosInstance.post(saveProtectedFileEndpoint(payload.challengeId), payload.upFile, configs);
    if (data.protected_file_id) {
      dispatch(sentProtectedFileComplete(data.protected_file_id));
      return data.protected_file_id;
    }
  } catch (error) {
    // notify(
    //   "error",
    //   error.response.data.statusPhrase,
    //   `${(error.response.data.errors && error.response.data.errors.length > 0) ? error.response.data.errors : ''}`
    // );
    dispatch(sentFileError(error));
    throw error;
  }
};
export const saveTeamFile = async (dispatch, payload) => {


  try {
    dispatch(sentTeamFileStart());
    const response = await axiosInstance.post(`file`, payload.data.upfile, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (response.data.file_id) {
      dispatch(sentTeamFileComplete(response.data.file_id));
      return response.data.file_id;
    }
  } catch (error) {
    dispatch(sentTeamFileError(error));
  }
};
export const getFile = async (dispatch, payload) => {
  try {
    dispatch(sentFileStart());
    const response = await axiosInstance.get(`file/${payload}`, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    let type = response.data.file_mime_type;

    if (
      type === 'image/png' ||
      type === 'image/gif' ||
      type === 'image/jpeg' ||
      type === 'image/webp'
    ) {
      dispatch(sentFileComplete({ type: 'getfile', data: response.data }));
    } else if (
      type === 'text/plain' ||
      type === 'application/pdf' ||
      type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      type === 'text/plain'
    ) {
      dispatch(setUserProfileCV(response.data));
    }
  } catch (error) {
    dispatch(sentFileError(error));
  }
};
export const saveParticipantsEmailsCSVFile = async (dispatch, payload) => {
  try {

    if (!payload.challengeId) {
      notify('error', 'Challenge must be saved before uploading file', '');
      return;
    }
    const { data, status } = await axiosInstance.post(participantsEmailsCsvFileEndpoint(payload.challengeId), payload.upFile, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (data.statusCode == 0) {
      return data;
    }
  } catch (error) {
    notify('error', 'File Upload Error', '');
  }
};
export const getParticipantsEmailsCSVFile = async (dispatch, payload) => {
  try {
    const { data, status } = await axiosInstance.get(participantsEmailsCsvFileEndpoint(payload.challengeId), { responseType: 'blob' }, {
      headers: {
        'content-type': 'multipart/form-data',
      }
    });
    if (status == 200) {
      return data;
    }
  } catch (error) {
    notify(
      'error',
      'File Upload Error',
      ''
    );
    dispatch(sentFileError(error));
  }
};
