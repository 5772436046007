import DesignSystem from '../pages/designSystem';
import Challenges from '../pages/challenge';
import ChallengePreview from '../pages/challenge/ChallengePreview';
import Companies from '../pages/Companies/companies';
import DataExpert from '../pages/DataExpert/dataExpert';
import Educational from '../pages/Educational/educational';
import Leaderboard from '../pages/leaderboard';
import UserProfile from '../pages/userProfile';
import Login from '../pages/login/';
import Signup from '../pages/signup/';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ValidateToken from '../pages/validateToken/';
import SendToken from '../pages/sendToken/';
import Profile from '../pages/profile/';
import About from '../pages/About/about';
import Faq from '../pages/Faq/faq';
import { ResetEmail } from '../pages/ResetEmail/Index';
import TeamProfile from '../pages/TeamProfile';
import Contact from '../pages/Contact/Contact';
import TechnicalSupport from '../pages/TechnicalSupport/TechnicalSupport';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import LandingPage from '../pages/LandingPage';
import TermsAndConditions from '../pages/TermsAndConditions';
import PageNotFound from '../pages/PagenotFound';
import OurSponsors from '../pages/OurSponsors/ourSponsors';
import SponsorPreview from '../pages/Sponser/sponsorPreview';
export const mobilelinks = [
  {
    key: 'mob1',
    name: 'odp-pu.menu.header.challenges',
    path: '/challenges',
    auth: false
  },
  {
    key: 'mob2',
    name: 'odp-pu.menu.header.leaderboard',
    path: '/leaderboard',
    auth: false
  },
  {
    key: 'mob3',
    name: 'odp-pu.menu.header.for-data-experts',
    path: '/data-expert',
    auth: false
  },
  {
    key: 'mob4',
    name: 'odp-pu.menu.header.for-companies',
    path: '/companies',
    auth: false
  },
  {
    key: 'mob5',
    name: 'odp-pu.menu.header.for-education',
    path: '/educational',
    auth: false
  },
  {
    key: 'mob6',
    name: 'odp-pu.menu.header.about',
    path: '/about',
    class: 'about'
  },
  {
    key: 'mob7',
    name: 'odp-pu.menu.header.faq',
    path: '/faq',
    class: 'faq'
  },
  {
    key: 'mob8',
    name: 'odp-pu.menu.footer.contact',
    path: '/contact-us',
    class: 'contact'
  },
  {
    key: 'mob9',
    name: 'odp-pu.menu.footer.technical-support',
    path: '/technical-support',
    class: 'technicalSupport'
  }
];

export const links = [
  {
    name: 'odp-pu.menu.header.challenges',
    path: '/challenges',
    auth: false
  },
  {
    name: 'odp-pu.menu.header.leaderboard',
    path: '/leaderboard',
    auth: false
  },
  {
    name: 'odp-pu.menu.header.for-data-experts',
    path: '/data-expert',
    auth: false
  },
  {
    name: 'odp-pu.menu.header.for-companies',
    path: '/companies',
    auth: false
  },
  {
    name: 'odp-pu.menu.header.for-education',
    path: '/educational',
    auth: false
  },
];

export const supportiveLink = [
  {
    name: 'odp-pu.menu.header.about',
    path: '/about',
    class: 'about'
  },
  {
    name: 'odp-pu.menu.header.faq',
    path: '/faq',
    class: 'faq'
  },
  {
    name: 'odp-pu.menu.footer.contact',
    path: '/contact-us',
    class: 'contact'
  },
  {
    name: 'odp-pu.menu.footer.technical-support',
    path: '/technical-support',
    class: 'technicalSupport'
  }
];

export const Routes = [
  {
    component: LandingPage,
    path: '/',
    exact: true,
    auth: false
  },
  {
    component: DesignSystem,
    path: '/design-system',
    exact: false,
    auth: false
  },
  {
    component: Challenges,
    path: '/challenges',
    exact: true,
    auth: false
  },
  {
    component: ChallengePreview,
    path: '/challenge/:challengeId',
    exact: true,
    auth: false
  },
  {
    component: Leaderboard,
    path: '/leaderboard',
    exact: false,
    auth: false
  },
  {
    component: DataExpert,
    path: '/data-expert',
    exact: false,
    auth: false
  },
  {
    component: Companies,
    path: '/companies',
    exact: false,
    auth: false
  },
  {
    component: Educational,
    path: '/educational',
    exact: false,
    auth: false
  },
  {
    component: Login,
    path: '/login',
    exact: false,
    auth: false
  },
  {
    component: Signup,
    path: '/signup',
    exact: false,
    auth: false
  },
  {
    component: ForgotPassword,
    path: '/forgot-password',
    exact: false,
    auth: false
  },
  {
    component: ResetPassword,
    path: '/reset-password',
    exact: false,
    auth: false
  },
  {
    component: ResetEmail,
    path: '/reset-email',
    exact: false,
    auth: false
  },
  {
    component: About,
    path: '/about',
    exact: false,
    auth: false
  },
  {
    component: OurSponsors,
    path: '/sponsors',
    exact: false,
    auth: false
  },
  {
    component: Faq,
    path: '/faq',
    exact: true,
    auth: false
  },
  {
    component: Contact,
    path: '/contact-us',
    exact: true,
    auth: false
  },
  {
    component: TechnicalSupport,
    path: '/technical-support',
    exact: true,
    auth: false
  },
  {
    component: PrivacyPolicy,
    path: '/privacy-policy',
    exact: true,
    auth: false
  },
  {
    component: TermsAndConditions,
    path: '/terms-and-conditions',
    exact: true,
    auth: false
  },
  {
    component: Profile,
    path: '/profile/:user_id',
    exact: false,
    auth: true
  },
  {
    component: ValidateToken,
    path: '/validate-token',
    exact: false,
    auth: true
  },
  {
    component: SendToken,
    path: '/send-token',
    exact: false,
    auth: true
  },
  {
    component: UserProfile,
    path: '/user/profile/:user_id',
    exact: false,
    auth: false
  },
  {
    component: TeamProfile,
    path: '/team/profile/:team_id',
    exact: false,
    auth: false
  },
  {
    component: SponsorPreview,
    path: '/sponsor/:sponsorId',
    exact: true,
    auth: false
  },
  {
    component: PageNotFound,
    path: '*',
    exact: true,
    auth: false
  }
];

export const languages = [
  {
    label: 'ENG',
    locale: 'en'
  },
  {
    label: 'IT',
    locale: 'it'
  }
];
