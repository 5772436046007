import "./skill.scss";
import { PlusOutlined } from "@ant-design/icons";
import Button from "../../Button";
import { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { fetchSkills } from "../../../services/skills/api";

import { notify } from "../../../theme/antNotify";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useIsMobile } from '../../../hooks/customHooks';

const { Option } = Select;

const NewSkill = ({ setSkillSet, skillSet, isCompanyAdmin }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { listings } = useSelector((state) => state.skills.setSkills);
  const { user } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();

  const [allSkills, setAllSkills] = useState([]);

  const [selectedSkills, setSelectedSkills] = useState(null);

  const initSkills = async () => {
    await fetchSkills(dispatch);
  };

  useEffect(() => {
    initSkills();
  }, []);

  useEffect(() => {
    if (listings) {
      setAllSkills([...listings]);
    }
  }, [listings]);

  function onChangeSkill(skillId) {
    if (skillId) {
      let selectedSkill = allSkills.find(
        (skill) => skill.skill_set_id == skillId
      );

      setSelectedSkills({
        skill_set_id: skillId,
        skill_set_name: selectedSkill.skill_set_name,
        skill_level: 0,
      });
    } else {
      setSelectedSkills(null);
    }
  }

  const addNewSkill = (event) => {
    event.preventDefault();
    let skillExist = skillSet.filter(
      (skill) => skill.skill_set_id == selectedSkills.skill_set_id
    );
    if (skillExist.length > 0) {
      notify("error", `${t("odp-pu.alert.message.skill-already-exist")}`, "");
      return;
    }

    setAllSkills(
      allSkills.filter(
        (skill) => skill.skill_set_id != selectedSkills.skill_set_id
      )
    );
    setSkillSet([...skillSet, selectedSkills]);
    setSelectedSkills(null);
  };

  const deleteSkill = (skill) => {
    let skillExist = allSkills.filter(
      (tempSkill) => tempSkill.skill_set_id == skill.skill_set_id
    );
    if (skillExist.length === 0) {
      setAllSkills([
        ...allSkills,
        {
          skill_set_id: skill.skill_set_id,
          skill_set_name: skill.skill_set_name,
          skill_level: 0,
        },
      ]);
    }

    setSkillSet(
      skillSet.filter((skillSet) => skillSet.skill_set_id != skill.skill_set_id)
    );
  };

  return (
    <div className="skill__wrapper">
      <div className="left__skill">
        <h4>{t("odp-pu.user-profile.skills")}</h4>
      </div>
      <div className="rigt__skill">
        <div className="input__wrapper ">
          <Select
            allowClear
            optionFilterProp="children"
            showSearch
            placeholder={t(
              "odp-pu.user.profile.type-and-choose-to-add-a-skill"
            )}
            value={selectedSkills?.skill_set_name}
            style={{
              width: 100 + "%",
              borderRadius: 5 + "px",
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(e) => {
              onChangeSkill(e);
            }}
            disabled={isCompanyAdmin}
          >
            {allSkills !== null &&
              allSkills.map((skill) => {
                return (
                  <Option value={skill.skill_set_id} key={skill.skill_set_id}>
                    {skill.skill_set_name}
                  </Option>
                );
              })}
          </Select>

          {allSkills !== null && !isMobile && (
            <div className="select_skills">
              <ul>
                {skillSet.map((skill) => {
                  return (
                    <li
                      className="selected__skill"
                      key={skill.skill_set_id}
                      onClick={() => deleteSkill(skill)}
                    >
                      {skill.skill_set_name} <CloseOutlined />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {!isCompanyAdmin && <Button
          type="secondary"
          className="btnSkillAdd"
          disabled={isCompanyAdmin || !selectedSkills}
          onClick={(e) => addNewSkill(e)}
        >
          <PlusOutlined />{" "}
          <span>{t("odp-ad.challenge.create-content.add-new")}</span>
        </Button>}
      </div>
      {allSkills !== null && isMobile && (
        <div className="select_skills">
          <ul>
            {skillSet.map((skill) => {
              return (
                <li
                  className="selected__skill"
                  key={skill.skill_set_id}
                  onClick={() => deleteSkill(skill)}
                >
                  {skill.skill_set_name} <CloseOutlined />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NewSkill;
