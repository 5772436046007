import { put, takeLatest, call } from "redux-saga/effects";
import { failure, request, success } from "../../utils/fetch";
import { setToken, setUserID, setUserProfile } from "../../utils/storage";
import {
  FORGOT_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
} from "../actions/authenticationAction";
import {
  forgotPassword,
  login,
  resetPassword,
} from "../services/authentication";
import history from "../../utils/history";
import { notify } from "../../theme/antNotify";

function* userLogin(requestPayload) {
  try {
    yield put({ type: request(LOGIN) });
    const response = yield login(requestPayload);
    yield put({ type: success(LOGIN), response });

    setToken(response.access_token);
    setUserProfile(response.user.userProfile);
    setUserID(response.user.user_id);

    history.push(`/profile/${response.user.user_id}/dashboard`);
    notify("success", "Successfully Logged In", "");
  } catch (error) {
    console.error("error", error);
    yield put({ type: failure(LOGIN), error });
  }
}

function* forgotPasswordSaga(requestPayload) {
  try {
    yield put({ type: request(FORGOT_PASSWORD) });
    const response = yield forgotPassword(requestPayload);
    yield put({ type: success(FORGOT_PASSWORD), response });

    if (response.statusCode === 2307) {
      notify("warning", response.statusPhrase, "Please enter registered Email");
    } else if (response.statusCode === 0) {
      notify("success", "We have emailed your password reset link!", "");
    }
  } catch (error) {
    yield put({ type: failure(FORGOT_PASSWORD), error });
  }
}

function* resetPasswordSaga(requestPayload) {
  try {
    yield put({ type: request(RESET_PASSWORD) });
    const response = yield resetPassword(requestPayload);
    yield put({ type: success(RESET_PASSWORD), response });
    const { statusCode, statusMessage } = response;
    statusCode == 0 && notify("success", statusMessage, "Redirecting you to Login");
    statusCode == 0 && history.push(`/login`);

  } catch (error) {
    yield put({ type: failure(RESET_PASSWORD), error });
  }
}

export default [
  takeLatest(LOGIN, userLogin),
  takeLatest(FORGOT_PASSWORD, forgotPasswordSaga),
  takeLatest(RESET_PASSWORD, resetPasswordSaga),
];
