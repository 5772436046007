import React from "react";
import AdvantagesSvg from "./advantages.svg";

import "./theAdvantages.scss";

const TheAdvantages = ({ text, title }) => {
  return (
    <div className="theAdvantages__wrapper">
      <h1 className="theAdvantages__heading">{title}</h1>
      <div className="theAdvantages__cards">
        {text.theAdvantages.map((advantages) => (
          <div className="theAdvantages__card" key={advantages.id}>
            <p className="theAdvantages__cardHeading">{advantages.title}</p>
            <p className="theAdvantages__cardDescription">
              {advantages.description}
            </p>
          </div>
        ))}
      </div>
      <div className="theAdvantages__svg">
        <img src={AdvantagesSvg} alt={AdvantagesSvg} />
      </div>
    </div>
  );
};

export default TheAdvantages;
