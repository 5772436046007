import { Link } from "react-router-dom";
import Placeholdersvg from "../../asset/images/profile/profilePlaceHolder.svg";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bufferToString } from "../../utils/imageUploader";
import {
  clearStorage,
  displayName,
  getUserID,
} from "../../services/token";
import { useHistory } from "react-router-dom";
import OutsideAlerter from '../OutsideClick';
import { logout } from "../../store/profile";
import { useTranslation } from "react-i18next";
import subscribeNotifications from "../../utils/subscribeNotifications";
import { clearStoreOnLogout } from "../../redux-store/actions/userAction";
import UserImage from "../../asset/images/user.svg";
import './profile.scss';

const ProfileDropDown = ({ className }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userProfile);
  const { currentUserName } = useSelector(
    (state) => state.currentUserDisplayName
  );
  const { UserProfileImage } = useSelector(
    (state) => state.FileUpload
  );
  const [avatar, setAvatar] = useState(Placeholdersvg);
  const [isprofileMenuActive, setisprofileMenuActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const LogOut = () => {
    let currentLanguage = localStorage.getItem('currentLanguage');
    clearStorage();
    dispatch(logout());
    dispatch(clearStoreOnLogout());
    localStorage.setItem('currentLanguage', currentLanguage);
    history.push("/");

    const notification = new subscribeNotifications();
    notification.clear();
  };

  useEffect(() => {
    const convertBinaryTOBase64 = async () => {
      if (UserProfileImage !== null) {
        let base64EncodedStr = await bufferToString(
          UserProfileImage.file_content.data
        );
        await setAvatar(`data:image/jpeg;base64,${base64EncodedStr}`);
      } else {
        setAvatar(Placeholdersvg);
      }
    };
    convertBinaryTOBase64();
  }, [UserProfileImage]);
  return (
    <div className={`profile__content ${className}`}>
      <div
        className="profile_picture"
        onClick={() => setisprofileMenuActive(!isprofileMenuActive)}
      >
        {getUserID() ? (
          <span className="username">{currentUserName || displayName()}</span>
        ) : (
          <span className="username">username</span>
        )}

        <div
          className={`profile__Image__box ${history.location.pathname.includes("/profile")
            ? "profile-radius-solid"
            : ""
            }`}
        >
          <img
            src={
              user?.userProfile?.avatar_url ? process.env.REACT_APP_RESOURCES_URL +
                user?.userProfile?.avatar_url : UserImage
            }
            alt="pforileImage"
          />
        </div>
      </div>
      <OutsideAlerter onClick={() => setisprofileMenuActive(false)}>
        <div
          className={
            isprofileMenuActive
              ? "profile__dropdown active "
              : "profile__dropdown"
          }
        >
          <ul>
            <li>
              <Link
                to={`/profile/${getUserID()}/profile`}
                onClick={() => setisprofileMenuActive(!isprofileMenuActive)}
              >
                <UserOutlined className="icon" />
                <span>{t("odp-pu.general.labels.your-account")}</span>
              </Link>
            </li>
            {(user?.is_admin || user?.is_company_admin) && (
              <li>
                <Link
                  to={`/admin/users`}
                  onClick={() => setisprofileMenuActive(!isprofileMenuActive)}
                >
                  <UserOutlined className="icon" />
                  <span>{t("odp-pu.general.labels.admin-panel")}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/" onClick={() => LogOut()}>
                <LogoutOutlined className="icon" />
                <span>{t("odp-pu.general.labels.log-out")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default ProfileDropDown;
