
import Styles from "./homeLeaderBoard.module.scss";
import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import TableHead from "../../admin/Components/Table/tableHead";
import TableBody from "../../admin/Components/Table/tableBody";
import Table from "../../admin/Components/Table";
import { getLeaderboardListings } from "../../redux-store/actions/ChallengeAction";
import { useDispatch, useSelector } from "react-redux";
import UserImage from "../../asset/images/user.svg";
import Icon from "../../components/Icons";
import trophy from "../../asset/icons/trophy.svg";
import points from "../../asset/icons/points.svg";
import User from "../../asset/icons/user.svg";
import { buildParams, PaginationModel } from "../../utils";
import { Link, useHistory } from "react-router-dom";
import Button from '../Button';


export const HomeLeaderBoard = ({ btnLabel }) => {
  const { t } = useTranslation();
  const leaderboardFilters = {};
  leaderboardFilters.pagination = new PaginationModel();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useSelector((state) => state.userProfile);
  const history = useHistory();
  const SkeletonArr = [1, 2, 3];
  const { listings, loading, _metadata } = useSelector(
    (state) => state.leaderboardListings
  );

  useEffect(() => {
    getLeaderboard(leaderboardFilters);
  }, []);

  useEffect(() => {
    if (searchText == "") getLeaderboard(leaderboardFilters);
  }, [searchText]);

  useEffect(() => {
    leaderboardFilters.pagination.pageNum = currentPage;
    getLeaderboard(leaderboardFilters);
  }, [currentPage]);


  const getFilteredList = () => {
    leaderboardFilters.display_name = searchText;
    getLeaderboard(leaderboardFilters);
  };

  const getLeaderboard = (filters) => {
    let params = buildParams(filters);
    dispatch(getLeaderboardListings(params));
  };

  return (
    <>
      {listings && listings.length > 0 && <div className={Styles.leaderBoardContainer}>
        <h1 className='main-heading'>{t('odp-pu.homepage.leaderboard')}</h1>
        <div className='row justify-content-center main-content'>
          <p className='col-md-7 text-center'>
            {t('odp-pu.homepage.check-out-open-data-playground-s-top-fiv')}
          </p>
        </div>
        <div className='row justify-content-center text-center'>
          <Table>
            <TableHead>
              <th className="text-center">
                <span className={`${Styles.iconHead} justify-content-center`}>
                  <Icon src={trophy} />
                  <span>Rank</span>
                </span>
              </th>
              <th>
                <span className={`${Styles.iconHead}`}>
                  <Icon src={User} />
                  <span>Name</span>
                </span>
              </th>
              <th className="text-center">
                <span className={`${Styles.iconHead} justify-content-center`}>
                  <Icon src={points} />
                  <span>Points</span>
                </span>
              </th>
            </TableHead>
            <TableBody>
              {!loading &&
                listings &&
                listings.length > 0 &&
                listings.slice(0, 5)
                  // .filter((leaderboard) => leaderboard?.display_name?.includes(searchText))
                  .map(
                    ({ points, display_name, avatar_url, score_rank, user_id }) => (
                      <tr
                        key={user_id}
                        className={
                          user?.user_id === user_id ? Styles.highlightedRow : ""
                        }
                      >
                        <td className="text-center">{score_rank}</td>
                        <td>
                          <Link
                            className={Styles.userLink}
                            to={`user/profile/${user_id}`}
                          >
                            <img
                              src={
                                avatar_url
                                  ? process.env.REACT_APP_RESOURCES_URL + avatar_url
                                  : UserImage
                              }
                              className={Styles.avatar}
                            />
                            {display_name || "Anonymous"}
                          </Link>
                        </td>
                        <td className="text-center">{points || 0}</td>
                      </tr>
                    )
                  )}
              {loading &&
                SkeletonArr.map((idx) => (
                  <tr key={idx}>
                    <td style={{ width: "300px" }}>
                      <Skeleton count={1} />
                    </td>
                    <td>
                      <Skeleton count={1} />
                    </td>
                    <td>
                      <Skeleton count={1} />
                    </td>
                  </tr>
                ))}
            </TableBody>
          </Table>

          <div className="d-flex justify-content-center col-md-12 text-center mt-5">
            <Button type="secondary" onClick={() => history.push('/leaderboard')}>{btnLabel}</Button>
          </div>

        </div>


      </div>}
    </>
  );
};

export default HomeLeaderBoard;
