import React from "react";
import { Link, NavLink } from "react-router-dom";
import TextLogo from "../../../asset/images/footer/text-logo.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import Styles from "./navbar.module.scss";
import ProfileDropDown from "../../../components/Nav/profileDropDown";
import Notification from "rc-notification/lib/Notification";
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { resetChallengeForm, resetChallengeId } from "../../../redux-store/actions/ChallengeAction";

const NavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resetStates = () => {
    dispatch(resetChallengeForm());
    dispatch(resetChallengeId());
  }
  return (
    <div className={Styles.adminNavbar}>
      <nav className={Styles.upperNav}>
        <Link to="/" onClick={resetStates}>
          <img src={TextLogo} alt={TextLogo} className={Styles.text__logo} />
        </Link>
        <div className={Styles.adminNavs}>
          <NavLink to="/admin/users" activeClassName={Styles.selected} onClick={resetStates}>
            {t('odp-ad.user.manage.manage-users')}
          </NavLink>
          <NavLink to="/admin/sponsors" activeClassName={Styles.selected} onClick={resetStates}>
            Manage Sponsors
          </NavLink>
          <NavLink to="/admin/challenges" activeClassName={Styles.selected} onClick={resetStates}>
            {t('odp-ad.challenge.manage.manage-challenges')}
          </NavLink>
        </div>
        <div className={`profile__notfiy`}>
          <ProfileDropDown />
          <Notification />
        </div>
      </nav>
      <Link to="/" className={Styles.linkToWebsite}>
        <span>Go to website</span>{" "}
        <ArrowRightOutlined className={Styles.arrow__icon} />
      </Link>
    </div>
  );
};

export default NavBar;
