import { axiosInstance } from '../../configs/axois';

import { notify } from '../../theme/antNotify';

import { getSkills, setSkills, errorSkills } from '../../store/skills';

export const fetchSkills = async (dispatch, payload) => {
  try {
    dispatch(getSkills());
    const response = await axiosInstance.get(`lookup/skill_set`);
    dispatch(setSkills(response.data));
  } catch (error) {
    dispatch(errorSkills(error));
  }
};
