import React, { useState, useEffect } from 'react'
import { CloseOutlined } from "@ant-design/icons";
import { mobilelinks } from "../../helpers/links";
import { AButton } from "../../theme/button";
import { NavLink, useHistory } from "react-router-dom";
import "./sideNav.scss";
import { useSelector, useDispatch } from "react-redux";
import { removeToken, removeUserID, storeLanguage } from "../../services/token";
import { logout } from "../../store/profile";
import LogoutIcon from "../../asset/images/profile/logoutIcon.svg";
import subscribeNotifications from "../../utils/subscribeNotifications";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import OutsideAlerter from '../OutsideClick';

const SideNav = ({ isActive, setisMenuActive }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userProfile);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('currentLanguage') == "en-US" ? "ENG" : 'ITA');

  const updateLanguage = (e) => {
    setCurrentLanguage(e.key === 'ENG' ? 'ITA' : 'ENG');
    i18n.changeLanguage(e.key === "ENG" ? "en" : "it");
    storeLanguage(e.key === "ENG" ? "en-US" : "it-IT");
  };

  useEffect(() => {
    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'en-US')
      setCurrentLanguage('ENG')
    }
    i18n.changeLanguage(localStorage.getItem('currentLanguage') == 'en-US' ? "en" : "it");
  }, [])

  const dispatch = useDispatch();
  const history = useHistory();

  const toLogin = () => {
    history.push("/login");
  };

  const tologout = () => {
    removeToken();
    removeUserID();
    history.push("/login");
    dispatch(logout());
    const notification = new subscribeNotifications();
    notification.clear();
  };

  return (
    <div className={isActive ? "sidenav__wrapper active" : "sidenav__wrapper"}>
      <OutsideAlerter onClick={() => setisMenuActive(false)}>
        <div className={isActive ? "sidenav active" : "sidenav"}>
          <section className="header">
            <CloseOutlined
              className="close__icon"
              onClick={() => setisMenuActive(!isActive)}
            />
          </section>
          <section className="links">
            <ul className="upper__nav__links">
              {mobilelinks.map((route, idx) => {
                return (
                  <li className="upper__nav__link" key={idx}>
                    <NavLink
                      to={{
                        pathname: route.path,
                        auth: route.auth,
                      }}
                      onClick={() => setisMenuActive(!isActive)}
                    >
                      {t(route.name)}
                    </NavLink>
                  </li>
                );
              })}
              <li className="upper__nav__link" >
                <a href="https://journal.opendataplayground.com/" target={"_blank"}>Journal</a>
              </li>
              <li className="upper__nav__link" key={'lang'}><a onClick={() => updateLanguage({ key: currentLanguage })}>{currentLanguage}</a></li>
            </ul>
          </section>
          <section className="footer">
            {user ? (
              <div className="logout__wrapper" onClick={() => tologout()}>
                <img src={LogoutIcon} alt={LogoutIcon} />
                <span className="logout">Log out</span>
              </div>
            ) : (
              <div className="login__btn">
                <AButton
                  label="LOGIN OR REGISTER"
                  onClick={toLogin}
                  type="regular"
                ></AButton>
              </div>
            )}
          </section>
        </div>
      </OutsideAlerter>
    </div>
  );
};

export default SideNav;
