export * from './conversions';
export * from './pagination';

export const textTrim = (text, characterSize = 30) => {
  if (text && text.length > 0) {
    let trimmedText = text;
    if (text.length > characterSize) {
      trimmedText = `${text.substr(0, characterSize)} ...`;
    }
    return trimmedText;
  }

};

export const removeSpaces = (text) => {
  if (text !== "") {
    return text.replace(/ /g, "");
  }
};

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}

export const getLinkedInUrl = (state, has_accepted_terms_of_use, has_accepted_no_cooling_off_period, has_accepted_share_data_3rd_parties) => {
  let termsObject = {
    has_accepted_terms_of_use,
    has_accepted_no_cooling_off_period,
    has_accepted_share_data_3rd_parties,
  }
  return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${JSON.stringify(termsObject)}&scope=r_liteprofile%20r_emailaddress&client_id=${process.env.REACT_APP_LINKED_IN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_LINKED_IN_APP_REDIRECT_URI}`
}
export const bytesToMegaByte = (bytes) => {
  if (bytes === 0) return 'n/a'
  const i = parseFloat((bytes / 1024) / 1024);
  if (i === 0) return `${bytes}`
  return i;
}

export const EDITOR_CONFIG = {
  toolbar: [
    '_',
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'code',
    'subscript',
    'superscript',
    'removeFormat',
    '|',
    'alignment:left',
    'alignment:right',
    'alignment:center',
    'alignment:justify',
    '|',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
    '|',
    'insertTable',
    '|',
    'undo',
    'redo'
  ],
  link: {
    addTargetToExternalLinks: true
  }
}