import React, { useState, useEffect } from 'react';
import Styles from './challengeTabs.module.scss';
import download from '../../asset/icons/download.svg';
import blueInfo from '../../asset/icons/blueInfo.png';
import { Checkbox, Empty } from 'antd';
import Button from '../Button';
import { useHistory } from 'react-router';
import { notify } from '../../theme/antNotify';
import NoData from '../../asset/images/noData.svg';
import { getProtectedFile } from '../../redux-store/services/File';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Data = ({
  isLoggedIn,
  dataSetFileId,
  challengeId,
  subscriptionStatus,
  joinChallenge,
  challengeStatus
}) => {
  const history = useHistory();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isChallengeJoined, setIsChallengeJoined] = useState(false);
  const { t } = useTranslation();
  const { loading, statusCode } = useSelector((state) => state.joinChallenge);
  const { user } = useSelector((state) => state.userProfile);

  const onAgreeTerms = (e) => {
    setAcceptTerms(e.target.checked);
  };

  const downloadDataSetFile = async () => {
    try {
      if (!dataSetFileId) {
        notify('warning', 'Data Set File does not exist', '');
      } else {
        setDownloading(true);
        const response = await getProtectedFile({
          challengeId,
          protectedFileId: dataSetFileId,
          is_url: true
        });
        if (response) {
          const url = response?.file_url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'data-set.zip'); //or any other extension
          document.body.appendChild(link);
          link.click();
          setDownloading(false);
          return;
        }
        setDownloading(false);
      }
    } catch (e) {
      setDownloading(false);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (statusCode === 1) {
      setIsChallengeJoined(true);
    } else {
      setIsChallengeJoined(false);
    }
  }, [statusCode]);

  const routeToLogin = () => {
    history.push({ pathname: '/login' });
  };

  const routeToProfile = () => {
    history.push(`/profile/${user.user_id}/profile`);
  };
  return (
    <div className={Styles.dataContainer}>
      {isLoggedIn &&
      user?.is_linkedin_verified &&
      (subscriptionStatus == 2 || isChallengeJoined) ? (
        <>
          <div className={Styles.downloadBox}>
            <img src={download} />
          </div>
          <p>
            By clicking “download” you agree to be bound to our competition rules, privacy policy
            and terms. Please check the rules under Home tab before participating
          </p>
          <Checkbox onChange={onAgreeTerms} style={{ marginBottom: '42px' }}>
            I agree to the Terms & Conditions
          </Checkbox>
          <Button
            className={`${Styles.buttonDownload}`}
            disabled={!acceptTerms || downloading}
            type="accent"
            onClick={downloadDataSetFile}>
            Download
          </Button>
        </>
      ) : isLoggedIn &&
        user?.is_linkedin_verified &&
        (subscriptionStatus == 3 || !isChallengeJoined) ? (
        <>
          <div className={Styles.warning}>
            <img src={blueInfo} />
            {(challengeStatus == 1 || challengeStatus == 2) && (
              <p>Join the challenge to download data or file.</p>
            )}
            {challengeStatus == 3 && (
              <p>Challenge is under review, you can not download the file now</p>
            )}
            {challengeStatus == 4 && <p>Challenge is closed, you can not download the file now</p>}
          </div>
          {challengeStatus == 1 ||
            (challengeStatus == 2 && (
              <p>
                After the subscription you will be able to download a challenge dataset or file.
              </p>
            ))}
          <Button
            className={Styles.buttonDownload}
            type={'accent'}
            onClick={joinChallenge}
            disabled={challengeStatus == 3 || challengeStatus == 4}>
            {challengeStatus == 3 || challengeStatus == 4 ? 'Download' : 'Join the Challenge'}
          </Button>
        </>
      ) : isLoggedIn && !user?.is_linkedin_verified ? (
        <>
          <div className={Styles.warning}>
            <img src={blueInfo} />
            <p>Please complete your profile, to join the challenge</p>
          </div>
          <p>After the completion of profile, you will be able to download a challenge dataset</p>
          <Button className={Styles.buttonDownload} onClick={routeToProfile}>
            Complete your profile
          </Button>
        </>
      ) : (
        <>
          <div className={Styles.warning}>
            <img src={blueInfo} />
            <p>Register or login and join the challenge to download data.</p>
          </div>
          <p>After the registration you will be able to download a challenge dataset</p>
          <Button className={Styles.buttonDownload} onClick={routeToLogin}>
            {t('odp-pu.challenge.login-join-challenge')}
          </Button>
        </>
      )}
    </div>
  );
};

export default Data;
