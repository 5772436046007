import "./teamskiil.scss";
import { useTranslation } from "react-i18next";

const Skill = ({ team_skills }) => {
  const { t } = useTranslation();
  return (
    <div className="team__skill">
      <h1 className="teamheading">{t("odp-pu.label.profile.skills")}</h1>

      <div className="team__skills">
        <ul>
          {team_skills?.map((skill) => {
            return <li key={skill}>{skill}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Skill;
