import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectMenuAction } from '../../../redux-store/actions/ChallengeAction';
import Styles from './ChallengeMenu.module.scss';
import ChallengeMenuItem from './ChallengeMenuItem';
import { useTranslation } from 'react-i18next';

const ChallengeMenu = ({
  challengeId,
  style,
  isContentHasError,
  isSubmissionHasError,
  isDesignHasError,
  isFormValid
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onChangeMenu = (menu) => {
    dispatch(selectMenuAction(menu));
  };
  const selectedMenu = useSelector((state) => state.challengeMenu.menu, shallowEqual);
  return (
    <div className={Styles.headerBar} style={style}>
      <div className="d-flex">
        {!isFormValid && <label className="me-1 text-danger">*</label>}
        <ChallengeMenuItem
          label={t('odp-ad.challenge.create-basics-tab.basics')}
          checked={selectedMenu === 'basic'}
          onClick={() => onChangeMenu('basic')}
          isFormValid={isFormValid}
          selectedMenu={selectedMenu}
        />
      </div>
      <ChallengeMenuItem
        label={t('odp-ad.challenge.create-basics-tab.design')}
        checked={selectedMenu === 'design'}
        onClick={() => onChangeMenu('design')}
        disabled={!challengeId ? true : false}
        designError={isDesignHasError}
        isFormValid={isFormValid}
        selectedMenu={selectedMenu}
      />
      <ChallengeMenuItem
        label={t('odp-ad.challenge.create-basics-tab.content')}
        checked={selectedMenu === 'content'}
        onClick={() => onChangeMenu('content')}
        disabled={!challengeId ? true : false}
        isFormValid={isFormValid}
        selectedMenu={selectedMenu}
      />
      <ChallengeMenuItem
        label={t('odp-ad.challenge.create-basics-tab.data-submission')}
        checked={selectedMenu === 'submission'}
        onClick={() => onChangeMenu('submission')}
        disabled={!challengeId ? true : false}
        submissionError={isSubmissionHasError}
        isFormValid={isFormValid}
        selectedMenu={selectedMenu}
      />
    </div>
  );
};

export default ChallengeMenu;
