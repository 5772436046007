import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';

import { Provider } from 'react-redux';
import './plugins';
import './asset/scss/style.scss';
import './i18n';
import WebFont from 'webfontloader';
import history from './utils/history';
import ScrollToTop from './components/ScrollToTop';
import { store, persister } from './store';
import GAListener from './components/GAListener';
import { PersistGate } from 'redux-persist/integration/react'

WebFont.load({
  custom: {
    families: ['Nunito-Regular']
  }
});

const Root = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <Router history={history}>
            <GAListener parameters={document.location.search}>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </GAListener>
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
