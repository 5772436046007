import React, { useEffect } from 'react';
import Layout from './layouts';

const App = () => {


  return (
    <div className="App">
      <Layout />
    </div>
  );
};

export default App;
