import { call, takeLatest, put } from "redux-saga/effects";
import { axiosInstance } from "../../configs/axois";
import { getProfileSettings } from "../../services/profile/api";
import { getProfileComplete } from "../../store/profile";
import { notify } from "../../theme/antNotify";
import history from "../../utils/history";
import {
  ACCOUNT_SETTINGS,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  getAccountSettings,
  GET_ACCOUNT_SETTINGS,
  VERIFY_EMAIL,
} from "../actions/accountSettingsAction";

function* changeEmail(payload) {
  let { request } = payload;
  const { user_id } = request;
  delete request.user_id;

  try {
    const changeEmailAction = yield call(
      axiosInstance.put,
      `user/${user_id}/changeEmail`,
      request
    );
    if (changeEmailAction.status === 200) {
      notify(
        "success",
        "Email Changed request processed",
        changeEmailAction.data.statusMessage
      );
    }
  } catch (error) { }
}
function* verifyEmail(payload) {
  const { request } = payload;
  const verifyEmailAction = yield call(
    axiosInstance.put,
    `user/${request}/verifyEmail`
  );
  if (verifyEmailAction.status === 200) {
    notify(
      "success",
      "Email Verification",
      verifyEmailAction.data.statusMessage
    );
    history.push("/login");
  }
  try {
  } catch (error) { }
}
function* changePassword(payload) {
  let { request } = payload;
  const { user_id } = request;
  delete request.user_id;
  try {
    const changePasswordAction = yield call(
      axiosInstance.put,
      `user/${user_id}/changePassword`,
      request
    );
    if (changePasswordAction.status === 200) {
      notify(
        "success",
        "Password Changed",
        changePasswordAction.data.statusMessage
      );
    }
  } catch (error) { }
}
function* accountSettings(payload) {
  let { request } = payload;
  const { user_id } = request;
  delete request.user_id;
  try {
    const changeSettingRequest = yield call(
      axiosInstance.put,
      `user/${user_id}/accountSettings`,
      request
    );
    if (changeSettingRequest.status === 200) {
      // const response = getProfileSettings(user_id);
      // yield put(getProfileComplete(response.data));
      yield put(getAccountSettings(user_id));

      notify(
        "success",
        "Settings Changed",
        changeSettingRequest.data.statusMessage
      );
    }
  } catch (error) { }
}

function* getUserAccountSettings(request) {
  const { user_id } = request;
  try {
    const userAccountSettings = yield call(
      axiosInstance.get,
      `user/${user_id}`
    );
    if (userAccountSettings.status === 200) {
      yield put(getProfileComplete(userAccountSettings.data));
    }
  } catch (error) { }
}

export default [
  takeLatest(CHANGE_EMAIL, changeEmail),
  takeLatest(VERIFY_EMAIL, verifyEmail),
  takeLatest(CHANGE_PASSWORD, changePassword),
  takeLatest(ACCOUNT_SETTINGS, accountSettings),
  takeLatest(GET_ACCOUNT_SETTINGS, getUserAccountSettings),
];
