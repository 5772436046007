import './input.scss';
import React from 'react';

export const InputWithOutChange = React.forwardRef(
  (
    {
      label = '',
      type = 'text',
      placeholder = '',
      name = 'name',
      id = 'name',
      icon = '',
      errorMsg = '',
      erroClassType = '',
      svg = '',
    },
    register
  ) => {
    return (
      <div className="input__wrapper">
        <label htmlFor="input__label" className="input__label">
          {label}
        </label>
        <input
          type={type}
          className={`input ${erroClassType}`}
          {...register(name)}
          placeholder={placeholder}
        />

        {svg && <img src={svg} alt={svg} />}
        {icon && <span className="input__icon">{icon}</span>}

        <span className="input__error">{errorMsg}</span>
      </div>
    );
  }
);
export const Input = React.forwardRef(
  (
    {
      label = '',
      type = 'text',
      placeholder = '',

      name = 'name',
      id = 'name',
      icon = '',
      errorMsg = '',
      erroClassType = '',
      onChange,
      onBlur,
    },
    register
  ) => {
    return (
      <div className="input__wrapper">
        <label htmlFor="input__label" className="input__label">
          {label}
        </label>
        <input
          type={type}
          className={`input ${erroClassType}`}
          {...register(name)}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
        <span className="input__icon">{icon}</span>
        <span className="input__error">{errorMsg}</span>
      </div>
    );
  }
);
