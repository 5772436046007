import "./wellcomeTeam.scss";
import { AButton } from "../../../theme/button";

import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetTeamStates } from "../../../store/team";

const WellcomeTeam = () => {
  let history = useHistory();
  let { url } = useRouteMatch();
  const dispatch = useDispatch();

  const toCreateNewTeam = () => {
    dispatch(resetTeamStates());
    history.push(`${url}/create-team`);
  };
  return (
    <div className="wellcome__team text-center">
      <AButton onClick={() => toCreateNewTeam()} label="Crea un nuovo team" />
    </div>
  );
};

export default WellcomeTeam;
