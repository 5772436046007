import update from "immutability-helper";
import { failure, request, success } from "../../utils/fetch";
import { GET_USER, REMOVE_DOWNLOAD_DATA, SAVE_USER, USER_DISPLAY_NAME, REMOVE_USER } from "../actions/userAction";
import { GET_DOWNLOAD_DATA } from "../actions/userAction";

const initialState = {
  statusCode: 0,
  error: {},
  user: null,
};

const userDisplayName = {
  currentUserName: null
}

const fileState = {
  statusCode: 0,
  error: null,
  fileContent: [],
  fileName: null,
  fileMimeType: null,
};

export function displayNameReducer(state = userDisplayName, action) {
  switch (action.type) {
    case USER_DISPLAY_NAME:
      return update(state, { currentUserName: { $set: action.userDisplayName } });
    default:
      return state;
  }
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return update(state, {
        user: { $set: action.request },
        loading: { $set: false },
        error: { $set: null },
      });
    case REMOVE_USER:
      return update(state, {
        user: { $set: action.request },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(GET_USER):
      return update(state, {
        loading: { $set: true },
        user: { $set: null },
        error: { $set: null },
      });
    case success(GET_USER):
      return update(state, {
        statusCode: { $set: 0 },
        user: { $set: action.response },
        loading: { $set: false },
      });
    case failure(GET_USER):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function personalProfileReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER:
      return update(state, {
        user: { $set: action.request },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(SAVE_USER):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(SAVE_USER):
      return update(state, {
        statusCode: { $set: 0 },
        user: { $set: action.response },
        loading: { $set: false },
      });
    case failure(SAVE_USER):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function downloadDataReducer(state = fileState, action) {
  switch (action.type) {
    case GET_DOWNLOAD_DATA:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case REMOVE_DOWNLOAD_DATA:
      return update(state, {
        loading: { $set: false },
        fileContent: { $set: [] },
        error: { $set: null },
      });
    case request(GET_DOWNLOAD_DATA):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(GET_DOWNLOAD_DATA):
      const { fileName, mimeType, content } = action.response;
      return update(state, {
        statusCode: { $set: 0 },
        fileContent: { $set: content },
        fileName: { $set: fileName },
        fileMimeType: { $set: mimeType },
        loading: { $set: false },
      });
    case failure(GET_DOWNLOAD_DATA):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}
