import update from 'immutability-helper';
import { FETCH_CHALLENGES, SELECT_MENU } from '../actions/ChallengeAction';
import { failure, request, success } from "../../utils/fetch";
import { FETCH_COMPETITION_CAT, FETCH_COMPETITION_TYPES, FETCH_PARTICIPATION_TYPES, FETCH_SCORING_METRIC } from '../actions/lookUpAction';

const initialState = {
    statusCode: 0,
    error: {},
    listings: [],
    _metadata: {
        totalPages: 0,
        totalResults: 0,
        currentPage: 0,
        pageSize: 10
    },
    loading: false,
}

export function competitionCategoriesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMPETITION_CAT:
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: null }
            });
        case request(FETCH_COMPETITION_CAT):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: true },
                error: { $set: null }
            });
        case success(FETCH_COMPETITION_CAT):
            const { listings, statusCode, _metadata } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                listings: { $set: listings },
                _metadata: { $set: _metadata },
                loading: { $set: false }
            });
        case failure(FETCH_COMPETITION_CAT):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}

export function competitionTypesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_COMPETITION_TYPES:
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: null }
            });
        case request(FETCH_COMPETITION_TYPES):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: true },
                error: { $set: null }
            });
        case success(FETCH_COMPETITION_TYPES):
            const { listings, statusCode, _metadata } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                listings: { $set: listings },
                _metadata: { $set: _metadata },
                loading: { $set: false }
            });
        case failure(FETCH_COMPETITION_TYPES):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}

export function competitionParticipationTypesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PARTICIPATION_TYPES:
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: null }
            });
        case request(FETCH_PARTICIPATION_TYPES):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: true },
                error: { $set: null }
            });
        case success(FETCH_PARTICIPATION_TYPES):
            const { listings, statusCode, _metadata } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                listings: { $set: listings },
                _metadata: { $set: _metadata },
                loading: { $set: false }
            });
        case failure(FETCH_PARTICIPATION_TYPES):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}

export function scoringMetricReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SCORING_METRIC:
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: null }
            });
        case request(FETCH_SCORING_METRIC):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: true },
                error: { $set: null }
            });
        case success(FETCH_SCORING_METRIC):
            const { listings, statusCode, _metadata } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                listings: { $set: listings },
                _metadata: { $set: _metadata },
                loading: { $set: false }
            });
        case failure(FETCH_SCORING_METRIC):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}