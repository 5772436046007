import { createSlice } from "@reduxjs/toolkit";
import { loading } from "../../helpers/loading";

const initialState = {
  accountSettings: [],
  loading: loading.INIT,
  error: {},
};

export const accountSettingsSlice = createSlice({
  name: "AccountSettings",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = loading.PENDING;
    },
    fetchComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.accountSettings = action.payload;
    },
    fetchError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },
  },
});

export const { fetchStart, fetchComplete, fetchError } =
  accountSettingsSlice.actions;

export default accountSettingsSlice.reducer;
