import React from "react";
import Styles from "./table.module.scss";
const TableBody = ({ children }) => {
  return (
    <>
      <tbody className={Styles.tableBody}>{children}</tbody>
    </>
  );
};

export default TableBody;
