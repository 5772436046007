import Info from "./info";
import Skill from "./skill";
import "./teamCard.scss";

const Card = (team) => {
  return (
    <div className="team__Card">
      <Info {...team} />
      <Skill team_skills={team.team_skills} />
    </div>
  );
};

export default Card;
