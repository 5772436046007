import update from 'immutability-helper';
import { FORGOT_PASSWORD, LOGIN, RESET_PASSWORD, SIGN_UP } from '../actions/authenticationAction';
import { failure, request, success } from "../../utils/fetch";
import { loading } from '../../helpers/loading';

const initialState = {
    statusCode: 0,
    token: {},
    user: null,
    loading: false,
    forgotPasswordLoad: false,
    resetPasswordLoad: false,
    success: false,
    error: null,
}

const generalState = {
    statusCode: 0,
    loading: false,
    error: null,
}

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return update(state, {
                loading: { $set: false },
                error: { $set: null }
            });
        case request(LOGIN):
            return update(state, {
                loading: { $set: true },
                error: { $set: null }
            });
        case success(LOGIN):
            const { access_token, refresh_token, user } = action.response;
            return update(state, {
                statusCode: { $set: 0 },
                token: { $set: { access_token, refresh_token } },
                user: { $set: user },
                loading: { $set: false }
            });
        case failure(LOGIN):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error }
            });
        case FORGOT_PASSWORD:
            return update(state, {
                forgotPasswordLoad: { $set: false },
                error: { $set: null }
            });
        case request(FORGOT_PASSWORD):
            return update(state, {
                forgotPasswordLoad: { $set: true },
                error: { $set: null }
            });
        case success(FORGOT_PASSWORD):
            return update(state, {
                statusCode: { $set: 0 },
                success: { $set: true },
                forgotPasswordLoad: { $set: false }
            });
        case failure(FORGOT_PASSWORD):
            return update(state, {
                success: { $set: false },
                forgotPasswordLoad: { $set: false },
                error: { $set: action.error }
            });
        case RESET_PASSWORD:
            return update(state, {
                resetPasswordLoad: { $set: false },
                error: { $set: null }
            });
        case request(RESET_PASSWORD):
            return update(state, {
                resetPasswordLoad: { $set: true },
                error: { $set: null }
            });
        case success(RESET_PASSWORD):
            return update(state, {
                statusCode: { $set: 0 },
                success: { $set: true },
                resetPasswordLoad: { $set: false }
            });
        case failure(RESET_PASSWORD):
            return update(state, {
                success: { $set: false },
                resetPasswordLoad: { $set: false },
                error: { $set: action.error }
            });
        default:
            return state;
    }
}

export const SignUpReducer = (state = generalState, action) => {
    switch (action.type) {
        case SIGN_UP:
            return update(state, { menu: { $set: action.menu } });
        default:
            return state;
    }
}

// export function ChallengeReducers(state = initialState, action) {
//     switch (action.type) {
//         case FETCH_CHALLENGES:
//             return update(state, {
//                 listings: { $set: [] },
//                 loading: { $set: false },
//                 error: { $set: null }
//             });
//         case request(FETCH_CHALLENGES):
//             return update(state, {
//                 listings: { $set: [] },
//                 loading: { $set: true },
//                 error: { $set: null }
//             });
//         case success(FETCH_CHALLENGES):
//             const { listings, statusCode, _metadata } = action.response;
//             return update(state, {
//                 statusCode: { $set: statusCode },
//                 listings: { $set: listings },
//                 _metadata: { $set: _metadata },
//                 loading: { $set: false }
//             });
//         case failure(FETCH_CHALLENGES):
//             return update(state, {
//                 listings: { $set: [] },
//                 loading: { $set: false },
//                 error: { $set: action.error }
//             });
//         default:
//             return state;
//     }
// }