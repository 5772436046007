import update from "immutability-helper";
import { failure, request, success } from "../../utils/fetch";
import { SET_CURRENT_LANGUAGE, SET_SCORING_EVALUATION } from "../actions/utilsAction";

const initialState = {
    scoringEvaluation: 'auto',
    currentLanguage: 'ENG'
};


export function utilsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SCORING_EVALUATION:
            return update(state, { scoringEvaluation: { $set: action.scoringEvaluation } });
        case SET_CURRENT_LANGUAGE:
            return update(state, { currentLanguage: { $set: action.language } });
        default:
            return state;
    }
}