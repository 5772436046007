import { Checkbox, Switch } from 'antd';
const CheckBox = () => {
  function onChange(e) {
  }
  return (
    <div className="checkbox">
      <h1 className="title">Checkboxs</h1>
      <span className="hr" />
      <p>Widthout label</p>
      <div className="checkbox-wrapper">
        <Checkbox onChange={onChange}></Checkbox>
      </div>
      <span className="hr" />
      <p>Widt label</p>
      <div className="checkbox-wrapper">
        <Checkbox onChange={onChange}>Checkbox</Checkbox>
      </div>
      <span className="hr" />
      <p>Disable</p>
      <div className="checkbox-wrapper">
        <Checkbox defaultChecked disabled />
      </div>
      <div className="checkbox-wrapper">
        <Checkbox defaultChecked={false} disabled />
      </div>

      <h1 className="title">Toggle</h1>
      <span className="hr" />
      <p>Widthout label</p>

      <div className="switch-wrapper">
        <Switch defaultChecked onChange={onChange} />
      </div>

      <span className="hr" />
      <p>width label</p>
      <div className="switch-wrapper">
        <Switch defaultChecked onChange={onChange} /> <span>Label</span>
      </div>
      <span className="hr" />
      <p>Small</p>
      <div className="switch-wrapper">
        <Switch size="small" defaultChecked onChange={onChange} />
        <span>Label</span>
      </div>
    </div>
  );
};

export default CheckBox;
