import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Progress from '../../../components/ProgressBar';
import BreifcaseIcon from '../../../asset/images/team/card/breifcase.svg';
import LogoutIcon from '../../../asset/images/team/card/logout.svg';
import TeamIcon from '../../../asset/images/team/card/team.svg';
import TrophIicon from '../../../asset/images/team/card/trophy.svg';
import UserIcon from '../../../asset/images/team/card/user.svg';
import TeamImg from '../../../asset/images/team.jpg';
// import ChallangeImg from "../../asset/images/team/card/challange.jpg";
// import Compnaylogo from "../../asset/images/team/card/compnaylogo.jpg";
import './challengeCardSmall.scss';
import { textTrim } from '../../../utils';
import { useTranslation } from "react-i18next";
const dateFormat = 'YYYY-MM-DD';
const AWS_IMAGE_URL = 'https://odp-resources.s3.amazonaws.com/';
const ChallengeCardSmall = ({
  competition_name,
  competition_description,
  competition_type_name,
  thumbnail_image_id,
  competition_status,
  challengeproperty,
  score,
  previewChallenge,
  company
}) => {

  const { t } = useTranslation()
  const findmore = () => { };

  return (
    < div className="challange__card_sm" >
      <Link to={challengeproperty?.competition_location_url ? `/challenge/${challengeproperty?.competition_location_url}` : previewChallenge} className="challange__content">
        <div className="challange__header">
          {competition_status === 1 && <span className="tag planned">Planned</span>}
          {competition_status === 2 && <span className="tag live">Live</span>}
          {competition_status === 3 && <span className="tag under">Under Evaluation</span>}
          {competition_status === 4 && <span className="tag finished">Closed</span>}

          <div className="challange__imgBox">
            <img src={`${AWS_IMAGE_URL}${thumbnail_image_id}`} alt="Challenge Cover" />
          </div>

          <div className="company__logo">
            <img src={`${AWS_IMAGE_URL}${company?.logo_file_id}`} alt="Challenge Thumbnail" />
          </div>
        </div>

        <div className="challange__body">
          <div className="upper__body">
            {challengeproperty?.participation_type.length > 0 && (
              <ul className="middle">
                <li className="gray_xt">
                  <img src={TeamIcon} alt={TeamIcon} />{' '}
                  {challengeproperty?.participation_type === 1 && 'Teams'}
                </li>
              </ul>
            )}

            {score !== null ? (
              <div className="right">
                <div className="right_body">
                  <ul className="right__left">
                    <li className="accent_blue">{score?.ui_display_name}</li>
                    <li className="gray_text">Rank Challenge:</li>
                  </ul>
                  <ul className="right__right">
                    <li className="img__box">
                      <img
                        src={`${score?.avatar_url
                          ? process.env.REACT_APP_RESOURCES_URL + score?.avatar_url
                          : TeamImg
                          }`}
                        alt={TeamImg}
                      />
                    </li>
                    <li className="rang accent_blue">{score?.score_rank}</li>
                  </ul>
                </div>

                <ul>
                  <Link className="accent_blue gray_text leave" to="/">
                    <img src={LogoutIcon} alt={LogoutIcon} /> Leave the challenge
                  </Link>
                </ul>
              </div>
            ) : (
              ''
            )}
            <ul className="left" style={{ marginTop: score ? '0px' : '20px' }}>
              <li className="accent_blue">
                <img src={TrophIicon} alt={TrophIicon} /> {challengeproperty.winning_prize}
              </li>
              <li className="accent_blue">{challengeproperty?.competition_category_name}</li>
              <li className="left_down participationType">
                <span className="Senior">
                  <img src={UserIcon} alt={UserIcon} />
                  {/* TODO: bind it to data */}
                  {challengeproperty?.participation_type === 1 &&
                    "Individual"}
                  {challengeproperty?.participation_type === 2 && "Teams"}
                  {challengeproperty?.participation_type === 3 &&
                    `${t(
                      "odp-ad.challenge.competition-rules.teams-individual"
                    )}`}
                </span>
                {competition_type_name && (
                  <span className="Recruiting">
                    <img src={BreifcaseIcon} alt={BreifcaseIcon} />
                    {competition_type_name}
                  </span>
                )}
              </li>
            </ul>
          </div>

          <div className="middle__body">
            <p className="despction">{textTrim(competition_name, 50)}</p>
          </div>
          <div className="lower__body">
            {challengeproperty.start_date && (
              <p className="time">
                {moment(challengeproperty.start_date, dateFormat).format('ll')} &nbsp; - &nbsp;
                {moment(challengeproperty.end_date, dateFormat).format('ll')}
              </p>
            )}
            <div className="lower__body__footer">
              <div className="body__bar">
                <Progress percentage={challengeproperty?.competition_progress} />
              </div>
              <Link to={challengeproperty?.competition_location_url ? `/challenge/${challengeproperty?.competition_location_url}` : previewChallenge} className="accent_blue ">
                Discover more
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div >
  );
};

export default ChallengeCardSmall;
