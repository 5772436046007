import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ChallengeCard from '../../components/ChallengeCard';
import {
  getChallengeListings,
  getLiveChallenges,
  getSubjectLookup,
  getTypeLookup,
  getUpcomingChallenges
} from '../../redux-store/actions/ChallengeAction';
import Styles from './challenge.module.scss';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper/core';
import ChallengeCardSmall from '../../components/ChallengeCard/CallengeCardSmall/ChallengeCardSmall';
import { Input, Select } from 'antd';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import Filter from '../../asset/icons/filter.svg';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ListingPagination from '../../components/Pagination';
import LeftArrow from '../../asset/icons/prevslide.svg';
import RightArrow from '../../asset/icons/nextslide.svg';
import ChallengeFilter from './ChallengeFilter';
import Cross from '../../asset/icons/xsm.svg';
import { PaginationModel, buildParams } from '../../utils';
import { useIsDesktop, useIsIpadPro, useIsTablet } from '../../hooks/customHooks';
import { getSponsorListings } from '../../redux-store/actions/sponsorAction';
import { getLookupListings } from '../../redux-store/services/Lookup';

// import "swiper/navigation.scss";
const { Option } = Select;
SwiperCore.use([EffectFade, Navigation, Pagination]);
const Challenge = () => {
  const challengeFilters = {};
  const isMobile = useIsDesktop();
  const isIpadPro = useIsIpadPro();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState(null);
  // const [sponsoredBy, setSponsoredBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [filterBadges, setFilterBadges] = useState([]);
  const [reflectFilters, setReflectFilters] = useState(false);
  const [activeChallengeSlide, setActiveChallengeSlide] = useState(1);
  const [sponsorId, setSponsorId] = useState(null);
  const [advFilters, setAdvFilters] = useState({
    status: [],
    type: [],
    subject: [],
    target: [],
    accessibility: [],
    location: []
  });
  const [filtersData, setFiltersData] = useState({
    status: [
      { id: 1, label: 'Planned', selected: false },
      { id: 3, label: 'Under evaluation', selected: false },
      { id: 2, label: 'Live', selected: false },
      { id: 4, label: 'Closed', selected: false }
    ],
    type: [],
    subject: [],
    accessibility: [
      { id: 1, label: 'Individual', selected: false },
      { id: 2, label: 'Team', selected: false },
      { id: 3, label: 'Teams & Individual', selected: false }
    ],
    location: [
      { id: 1, label: 'Online', selected: false },
      { id: 2, label: 'In person', selected: false }
    ]
  });
  const {
    loading1,
    loading2,
    loading3,
    listings: challengeListings,
    _metadata,
    pageDetails,
    liveChallenges,
    upComingChallenges,
    typeLookup,
    subjectLookup
  } = useSelector((state) => state.challenge);

  const { listings: sponsors } = useSelector((state) => state.sponsors);

  challengeFilters.pagination = new PaginationModel();
  challengeFilters.pagination.pageSize = 5;

  const prevRef = useRef({});
  const nextRef = useRef({});
  const prevRefLive = useRef({});
  const nextRefLive = useRef({});
  const prevRef2 = useRef({});
  const nextRef2 = useRef({});

  useEffect(async () => {
    if (isMobile) delete challengeFilters.pagination;
    let filters = buildParams(challengeFilters);
    getChallenges(filters);
    dispatch(getSponsorListings({ is_published: true }));

    const { listings: competition_types } = await getLookupListings('competition_types');
    competition_types.forEach((res) => {
      res.selected = false;
      res.id = res.competition_type_id;
      res.label = res.competition_type_name;
    })

    const { listings: competition_categories } = await getLookupListings('competition_categories');
    competition_categories.forEach((res) => {
      res.selected = false;
      res.id = res.competition_category_id;
      res.label = res.competition_category_name;
    })
    setFiltersData({ ...filtersData, type: competition_types, subject: competition_categories })
  }, []);

  useEffect(() => {
    if (sortBy) {
      challengeFilters.orderField = sortBy;
      challengeFilters.sortBy = 'desc';
    }
    if (isMobile) delete challengeFilters.pagination;
    challengeFilters.advFilters = advFilters;
    let filters = buildParams(challengeFilters);
    filters.pageNum = currentPage;
    filters.is_published = true;
    dispatch(getChallengeListings(filters));
  }, [currentPage]);

  useEffect(() => {
    if (searchText === '') {
      applyFilters();
    }
  }, [searchText]);

  useEffect(() => {
    applyFilters();
    setCurrentPage(1);
  }, [sortBy]);

  useEffect(() => {
    applyFilters();
  }, [sponsorId]);

  useEffect(() => {
    updateFiltersDataWithLookup();
  }, [typeLookup, subjectLookup]);

  const sortByOptions = [
    {
      id: 1,
      value: 'create_date',
      option: t('odp-pu.challenges.date-most-recent-')
    },
    {
      id: 2,
      value: 'competition_prize_description',
      option: t('odp-pu.challenges.prize-amount')
    }
  ];

  const applyFilters = async (from = null) => {
    challengeFilters.advFilters = advFilters;
    challengeFilters.searchText = searchText;
    challengeFilters.searchBy = 'competition_description';
    if (isMobile) delete challengeFilters.pagination;
    if (sortBy) {
      challengeFilters.orderField = sortBy;
      challengeFilters.sortBy = 'desc';
      setCurrentPage(1);
    }
    if (sponsorId) {
      challengeFilters.company_ids = sponsorId;
    }
    let filters = buildParams(challengeFilters);
    // filters.pageNum = 1;
    filters.is_published = true;
    dispatch(getChallengeListings(filters));
    setShowFilters(false);
    if (from === 'done') {
      setCurrentPage(1);
      setReflectFilters(true);
    }
  };

  const getChallenges = (filters) => {
    filters.is_published = true;
    dispatch(getChallengeListings(filters));
    dispatch(getUpcomingChallenges());
    dispatch(getLiveChallenges());
    dispatch(getSubjectLookup());
    dispatch(getTypeLookup());
  };

  const updateFiltersDataWithLookup = () => {
    let data = filtersData;
    data.type.map((item) =>
      typeLookup.forEach((type) => {
        if (item.label === type.competition_type_name) {
          item.id = type.competition_type_id;
        }
      })
    );

    data.subject.map((item) =>
      subjectLookup.forEach((subject) => {
        if (item.label.toUpperCase() === subject.competition_category_name) {
          item.id = subject.competition_category_id;
        }
      })
    );
    setFiltersData(data);
  };

  const updateFilterTags = async (key, item, index, closeTag) => {
    const { id, label } = item;
    let filters = [...filterBadges];
    let indexDelete = '';

    for (let i = 0; i < filters.length; i++) {
      if (filters[i]?.id === id && filters[i]?.label === label) {
        indexDelete = i;
        break;
      }
    }

    if (indexDelete !== '') {
      filters.splice(indexDelete, 1);
    } else {
      filters.push({ key, id, label, index });
    }

    setFilterBadges(filters);
    if (closeTag) {
      applyFilters();
    }
  };

  const onFilterClick = (key, item, index, closeTag = false) => {
    const { id } = item;
    let array = advFilters[key];
    let filtersDataUpdate = filtersData[key];
    if (advFilters[key].includes(id)) {
      filtersDataUpdate[index].selected = false;
      setFiltersData((prevState) => ({
        ...prevState,
        [key]: filtersDataUpdate
      }));

      array.splice(array.indexOf(id), 1);

      setAdvFilters((prevState) => ({
        ...prevState,
        [key]: array
      }));
    } else {
      filtersDataUpdate[index].selected = true;
      setFiltersData((prevState) => ({
        ...prevState,
        [key]: filtersDataUpdate
      }));
      array.push(id);
      setAdvFilters((prevState) => ({
        ...prevState,
        [key]: array
      }));
    }
    updateFilterTags(key, item, index, closeTag);
  };

  const onClearFilters = () => {
    setAdvFilters({
      status: [],
      type: [],
      subject: [],
      target: [],
      accessibility: [],
      location: []
    });
    setFilterBadges([]);
    setSortBy('');
    setSearchText('');
    setShowFilters(false);
    setReflectFilters(false);
    resetFilterSelection();
    let challengeFilters = {};
    challengeFilters.pagination = new PaginationModel();
    challengeFilters.pagination.pageSize = 5;

    let filters = buildParams(challengeFilters);
    filters.is_published = true;
    setCurrentPage(1);
    dispatch(getChallengeListings(filters));
  };

  const resetFilterSelection = () => {
    let data = filtersData;
    let keys = Object.keys(data);

    for (const key of keys) {
      data[key].forEach((item) => (item.selected = false));
    }
    setFiltersData(data);
  };

  return (
    <>
      {liveChallenges && liveChallenges.length > 0 && (
        <div className={Styles.liveChallengeContainer}>
          <h1>{t('odp-pu.challenges.live-now-')}</h1>
          <div className="d-flex justify-content-center align-items-center">
            <p className="text-center w-75 sub-title">
              {t('odp-pu.challenges.check-out-the-latest-live-competitions-')}
            </p>
          </div>
          {loading1 && (
            <>
              <Skeleton style={{ height: '250px', borderRadius: '10px' }} />
              <Skeleton
                style={{
                  height: '250px',
                  marginTop: '30px',
                  borderRadius: '10px'
                }}
              />
            </>
          )}
          {!isMobile && (
            <>
              <div
                className={`${!isMobile ? 'd-flex align-items-center' : ''} ${liveChallenges.length > 3 ? 'justify-content-center' : 'justify-content-around'
                  } ${Styles.challengeCards}`}>
                {liveChallenges.length <= 3 &&
                  !isMobile &&
                  liveChallenges.map((challenge) => (
                    <div className={Styles.challengeCard}>
                      <SwiperSlide>
                        <ChallengeCardSmall
                          {...challenge}
                          previewChallenge={`/challenge/${challenge.competition_id}`}
                        />
                      </SwiperSlide>
                    </div>
                  ))}

                {(liveChallenges.length > 3 || isMobile) && (
                  <>
                    {' '}
                    {!isMobile && (
                      <div
                        ref={prevRefLive}
                        style={{
                          position: 'absolute',
                          left: !isIpadPro ? '5em' : '2em',
                          cursor: 'pointer',
                          zIndex: 2
                        }}>
                        <img src={LeftArrow} />
                      </div>
                    )}
                    <Swiper
                      mousewheel={{ sensitivity: 2 }}
                      slidesPerView={3}
                      freeMode={false}
                      centeredSlides={isMobile ? true : false}
                      // navigation={true}
                      pagination={{
                        clickable: true,
                        // dynamicBullets: true,
                        el: '.live-custom-pagination',
                        bulletActiveClass: Styles.customPaginationBulletActive,
                        renderBullet: (index, className) => {
                          return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                        }
                      }}
                      navigation={{
                        prevEl: prevRefLive.current,
                        nextEl: nextRefLive.current
                      }}
                      className="mySwiper"
                      breakpoints={{
                        320: { slidesPerView: 1, centeredSlides: true, slidesPerGroup: 1 },
                        768: {
                          slidesPerView: 2,
                          slidesPerGroup: 2
                        },
                        1024: {
                          slidesPerView: 3,
                          slidesPerGroup: 3
                        }
                      }}>
                      <div className="d-flex justify-content-between">
                        {liveChallenges &&
                          liveChallenges.length > 0 &&
                          liveChallenges.map((challenge) => (
                            <div className={Styles.challengeCard}>
                              <SwiperSlide>
                                <ChallengeCardSmall
                                  {...challenge}
                                  previewChallenge={`/challenge/${challenge.competition_id}`}
                                />
                              </SwiperSlide>
                            </div>
                          ))}
                      </div>
                    </Swiper>
                    {!isMobile && (
                      <div
                        id="nexRefLive"
                        ref={nextRefLive}
                        style={{
                          position: 'absolute',
                          right: !isIpadPro ? '5em' : '2em',
                          cursor: 'pointer',
                          zIndex: 2
                        }}>
                        <img src={RightArrow} />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={`col-12 ${Styles.customPaginationContainer}`}>
                <div
                  className={`live-custom-pagination ${Styles.customPagination}`}
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                    marginRight: '10px'
                  }}></div>
              </div>
            </>
          )}
          {isMobile && (
            <Swiper
              slidesPerView={3}
              breakpoints={{
                320: { slidesPerView: 1, centeredSlides: true },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 }
              }}
              pagination={{
                clickable: true,
                // dynamicBullets: true,
                el: '.custom-pagination-1',
                bulletActiveClass: Styles.customPaginationBulletActive,
                renderBullet: (index, className) => {
                  return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                }
              }}>
              {liveChallenges &&
                liveChallenges.length > 0 &&
                liveChallenges.map((challenge) => (
                  <div className={Styles.challengeCard}>
                    <SwiperSlide>
                      <ChallengeCardSmall
                        {...challenge}
                        previewChallenge={`/challenge/${challenge.competition_id}`}
                      />
                    </SwiperSlide>
                  </div>
                ))}
              {isMobile && (
                <div className="col-12 ">
                  <div
                    className="custom-pagination-1"
                    style={{
                      textAlign: 'center',
                      marginTop: 20,
                      marginRight: '10px'
                    }}></div>
                </div>
              )}
            </Swiper>
          )}
        </div>
      )}

      {upComingChallenges && upComingChallenges.length > 0 && (
        <div className={Styles.wrapperone}>
          <div className={Styles.upcomingChallengeContainer}>
            <h1>{t('odp-pu.challenges.upcoming-challenges')}</h1>
            <p className={`text-center ${Styles.para}`}>
              {t('odp-pu.challenges.check-out-new-challenges-on-data-science')}
            </p>
            {loading2 && (
              <>
                <Skeleton style={{ height: '250px', borderRadius: '10px' }} />
                <Skeleton
                  style={{
                    height: '250px',
                    marginTop: '30px',
                    borderRadius: '10px'
                  }}
                />
              </>
            )}
            <div
              className={`${!isMobile ? 'd-flex align-items-center' : ''} ${upComingChallenges.length > 3 ? 'justify-content-center' : 'justify-content-around'
                } ${Styles.challengeCards}`}>
              {upComingChallenges.length <= 3 &&
                !isMobile &&
                upComingChallenges.map((challenge) => (
                  <div className={Styles.challengeCard}>
                    <SwiperSlide>
                      <ChallengeCardSmall
                        {...challenge}
                        previewChallenge={`/challenge/${challenge.competition_id}`}
                      />
                    </SwiperSlide>
                  </div>
                ))}

              {(upComingChallenges.length > 3 || isMobile) && (
                <>
                  {' '}
                  {!isMobile && (
                    <div
                      ref={prevRef}
                      style={{
                        position: 'absolute',
                        left: !isIpadPro ? '5em' : '2em',
                        cursor: 'pointer'
                      }}>
                      <img src={LeftArrow} />
                    </div>
                  )}
                  <Swiper
                    mousewheel={{ sensitivity: 2 }}
                    slidesPerView={3}
                    freeMode={false}
                    // navigation={true}
                    pagination={{
                      clickable: true,
                      // dynamicBullets: true,
                      el: '.custom-pagination',
                      bulletActiveClass: Styles.customPaginationBulletActive,
                      renderBullet: (index, className) => {
                        return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                      }
                    }}
                    navigation={{
                      prevEl: prevRef.current,
                      nextEl: nextRef.current
                    }}
                    className="mySwiper"
                    breakpoints={{
                      320: { slidesPerView: 1, centeredSlides: true, slidesPerGroup: 1 },
                      768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2
                      },
                      1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3
                      }
                    }}>
                    <div className="d-flex justify-content-between">
                      {upComingChallenges &&
                        upComingChallenges.length > 0 &&
                        upComingChallenges.map((challenge) => (
                          <div className={Styles.challengeCard}>
                            <SwiperSlide>
                              <ChallengeCardSmall
                                {...challenge}
                                previewChallenge={`/challenge/${challenge.competition_id}`}
                              />
                            </SwiperSlide>
                          </div>
                        ))}
                    </div>
                  </Swiper>
                  {!isMobile && (
                    <div
                      ref={nextRef}
                      style={{
                        position: 'absolute',
                        right: !isIpadPro ? '5em' : '2em',
                        cursor: 'pointer'
                      }}>
                      <img src={RightArrow} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={`col-12 ${Styles.customPaginationContainer}`}>
              <div
                className={`custom-pagination ${Styles.customPagination}`}
                style={{
                  textAlign: 'center',
                  marginTop: 20,
                  marginRight: '10px'
                }}></div>
            </div>
          </div>
        </div>
      )}

      <div className={Styles.challengeContainer}>
        <h1>{t('odp-pu.challenges.all-challenges')}</h1>
        <div className="d-flex flex-column flex-lg-row justify-content-between">
          <div className="col-12 col-lg-3">
            <Input
              prefix={<SearchOutlined />}
              suffix={searchText && <CloseCircleOutlined onClick={() => setSearchText('')} />}
              placeholder={t('odp-pu.challenges.search')}
              style={{
                height: 40,
                borderRadius: 5 + 'px'
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') applyFilters();
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div
            className={`${!isTablet ? 'col-12 col-md-9 d-flex align-items-center justify-content-end' : ''
              }`}
            style={{ position: 'relative' }}>
            <div className={`${isTablet ? 'mt-2 ' : ''}challengesSortBy`}>
              <div
                style={{
                  marginLeft: !isTablet ? 12 : 0,
                  marginTop: !isTablet ? -25 : 0,
                  marginBottom: 5
                }}>
                <h6>{t('odp-pu.challenges.sort-by')}</h6>
              </div>
              <Select
                style={{
                  width: 100 + '%',
                  borderRadius: 5 + 'px',
                  marginLeft: !isTablet ? 10 : 0
                }}
                placeholder="Select sort by"

                allowClear={true}
                value={sortBy}
                onChange={(value) => {
                  setSortBy(value);
                }}>
                {sortByOptions.map((item) => (
                  <Option value={item.value}> {item.option} </Option>
                ))}
              </Select>
            </div>
            <div className={`${isTablet ? 'mt-2' : ''}`} style={{ marginLeft: !isTablet ? 12 : 0 }}>
              <div
                style={{
                  marginLeft: !isTablet ? '12px' : 0,
                  marginTop: !isTablet ? -25 : 0,
                  marginBottom: 5
                }}>
                <h6>Sponsored By</h6>
              </div>
              <Select
                style={{
                  width: 100 + '%',
                  borderRadius: 5 + 'px',
                  marginLeft: !isTablet ? 10 : 0
                }}
                placeholder="Select Sponsor"
                allowClear={true}
                value={sponsorId}
                onChange={(value) => {
                  setSponsorId(value);
                }}>
                {sponsors.map(({ company_id, company_name }) => (
                  <Option value={company_id}> {company_name} </Option>
                ))}
              </Select>
            </div>
            <div
              className="d-flex"
              style={{
                width: !isTablet ? '112px' : '',
                justifyContent: isTablet ? 'flex-start' : 'flex-end'
              }}>
              {showFilters ? (
                <div
                  style={
                    isTablet
                      ? {
                        position: 'fixed',
                        zIndex: 100,
                        inset: 0
                      }
                      : {
                        marginLeft: 10,
                        position: 'absolute',
                        zIndex: 2,
                        right: 0,
                        top: 0
                      }
                  }>
                  <ChallengeFilter
                    setShowFilters={setShowFilters}
                    onDoneFilters={applyFilters}
                    filtersData={filtersData}
                    onFilterClick={onFilterClick}
                    onClearFilters={onClearFilters}
                  />
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginLeft: !isTablet ? '30px' : 0,
                    cursor: 'pointer',
                    height: '40px'
                  }}
                  onClick={() => setShowFilters(true)}>
                  <img src={Filter} />
                  <div
                    style={{
                      color: 'black',
                      fontSize: 16,
                      fontWeight: 'bold',
                      marginLeft: 10
                    }}>
                    Filter
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginLeft: 10 }}>
          {!isMobile && (
            // <h6>
            //   Showing: {(Array.isArray(challengeListings) && challengeListings.length) || 0} of{' '}
            //   {_metadata?.totalResults || 0} challenges{' '}
            // </h6>

            <h6>
              Showing {pageDetails?.currentPage} - {pageDetails?.pageSize} of{' '}
              {pageDetails?.totalResults}
            </h6>
          )}
          <div className="d-flex" style={{ marginTop: 10, flexWrap: 'wrap' }}>
            {reflectFilters &&
              filterBadges.map((item, index) => (
                <div className={Styles.filterTags}>
                  <span>{item.label}</span>
                  <img
                    src={Cross}
                    style={{ cursor: 'pointer', height: 20, width: 20 }}
                    onClick={() => onFilterClick(item.key, item, item.index, true)}
                  />
                </div>
              ))}
          </div>
        </div>
        {loading3 && (
          <>
            <Skeleton style={{ height: '250px', borderRadius: '10px' }} />
            <Skeleton
              style={{
                height: '250px',
                marginTop: '30px',
                borderRadius: '10px'
              }}
            />
          </>
        )}
        {!isMobile ? (
          <>
            {challengeListings && challengeListings.length > 0
              ? challengeListings.map((challenge) => (
                <div className={Styles.challengeCard}>
                  <ChallengeCard
                    {...challenge}
                    previewChallenge={`/challenge/${challenge.competition_id}`}
                  />
                </div>
              ))
              : !loading3 &&
              challengeListings &&
              challengeListings.length === 0 && (
                <div className="my-5 text-center ">
                  <h4>No Record Found</h4>
                </div>
              )}
            <ListingPagination
              pageSize={_metadata?.pageSize}
              totalRecord={_metadata?.totalResults}
              currentPage={currentPage}
              styles={{ marginTop: '40px' }}
              onPaginationChange={(page) => {
                setCurrentPage(page);
              }}
              showPageDifference={false}
            />
          </>
        ) : (
          <div>
            <Swiper
              mousewheel={{ sensitivity: 2 }}
              onSlideChange={(e) => setActiveChallengeSlide(e.activeIndex + 1)}
              slidesPerView={1}
              navigation={{
                prevEl: prevRef2.current,
                nextEl: nextRef2.current
              }}
              className="mySwiper">
              <div className="d-flex justify-content-between">
                {challengeListings &&
                  challengeListings.length > 0 &&
                  challengeListings.map((challenge) => (
                    <div className={Styles.challengeCard}>
                      <SwiperSlide>
                        <ChallengeCardSmall
                          {...challenge}
                          previewChallenge={`/challenge/${challenge.competition_id}`}
                        />
                      </SwiperSlide>
                    </div>
                  ))}
              </div>
            </Swiper>
            <div className="d-flex justify-content-center align-items-center">
              {isMobile && (
                <>
                  <div ref={prevRef2} style={{ cursor: 'pointer' }}>
                    <img src={LeftArrow} />
                  </div>
                  <span className="px-3">
                    {activeChallengeSlide} of {_metadata.totalResults} results
                  </span>
                  <div ref={nextRef2} style={{ cursor: 'pointer' }}>
                    <img src={RightArrow} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <br></br>
      </div>
    </>
  );
};

export default Challenge;
