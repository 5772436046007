import React, { useEffect, useMemo, useState } from 'react';
import ChallengeDetail from '../../../components/ChallnegeDetail';
import Styles from './challengePreview.module.scss';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  getChallengeById,
  joinChallengeAction,
  resetChallengeForm,
  resetJoinChallengeAction,
  selectMenuAction
} from '../../../redux-store/actions/ChallengeAction';
import { useHistory, useParams } from 'react-router';
import Overview from '../../../components/ChallengePreviewTabs/Overview';
import Rules from '../../../components/ChallengePreviewTabs/Rules';
import Evaluation from '../../../components/ChallengePreviewTabs/Evaluation';
import Prize from '../../../components/ChallengePreviewTabs/Prize';
import Data from '../../../components/ChallengePreviewTabs/Data';
import Leaderboard from '../../../components/ChallengePreviewTabs/Leaderboard';
import Submission from '../../../components/ChallengePreviewTabs/Submission';
import ChallengeMenuItem from '../../../admin/Components/ChallengeMenu/ChallengeMenuItem';
import downArrow from '../../../asset/icons/downArrow.svg';
import Icon from '../../../components/Icons';
import { useIsMobile } from '../../../hooks/customHooks';
import { Button, Checkbox, Modal, Radio, Select, Space, Spin, Skeleton } from 'antd';
import { getTeams } from '../../../services/team/api';
import { LoadingOutlined } from '@ant-design/icons';
import { notify } from '../../../theme/antNotify';
import { Swiper, SwiperSlide } from 'swiper/react';
import validator from 'validator';
import 'swiper/swiper.scss';
import { createProtectedFile } from '../../../redux-store/actions/protectedFileAction';
import { PREDICTION } from '../../../utils/constants';
import Participant from '../../../components/ChallengePreviewTabs/Participant';
import { useTranslation } from 'react-i18next';
import { getChallengeListings } from '../../../redux-store/services/Challenges';
import { axiosInstance } from '../../../configs/axois';
import { setUploadStatus } from '../../../redux-store/actions/protectedFileUploadingAction';
const { v4: uuidv4 } = require('uuid');

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const ChallengePreview = ({ isAdmin }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { challengeId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { allTeams } = useSelector((state) => state.Team);
  const [showJoinChallengeModal, setShowJoinChallengeModal] = useState(false);
  const [isChallengeJoined, setIsChallengeJoined] = useState(false);
  const [isTOS, setIsTOS] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [participationType, setParticipationType] = useState(1);
  const [participationCount, setParticipationCount] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState(0);
  const { user } = useSelector((state) => state.userProfile);
  const { user: loggedInUser } = useSelector((state) => state.userProfile);
  const { loading, statusCode } = useSelector((state) => state.joinChallenge);

  const [uploadSubmissionLoading, setUploadSubmissionLoading] = useState(false);

  useEffect(() => {
    dispatch(selectMenuAction('overview'));
    if (validator.isUUID(challengeId)) {
      dispatch(getChallengeById(challengeId));
    } else {
      getChallengeByURL(challengeId);
    }
    return () => {
      dispatch(resetJoinChallengeAction());
      dispatch(resetChallengeForm());
    };
  }, []);

  const getChallengeByURL = async (challengeURL) => {
    try {
      const { listings } = await getChallengeListings({
        request: { competition_location_url: challengeURL }
      });
      if (listings && listings.length > 0) {
        let challengeId = listings[0].competition_id;

        if (challengeId) {
          dispatch(getChallengeById(challengeId));
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (user && allTeams.length === 0) getTeams(dispatch, { user_id: user?.user_id });
  }, [user]);

  useEffect(() => {
    if (statusCode === 1) {
      handleCancel();
      setIsChallengeJoined(true);
      setParticipationCount(parseInt(participationCount || 0) + 1);
      setSubscriptionType(2);
    } else {
      setIsChallengeJoined(false);
    }
  }, [statusCode]);

  const { requestObject: challenge, loading: isChallengeLoaded } = useSelector(
    (state) => state.challengePayload
  );

  useEffect(() => {
    if (!loading) {
      setParticipationCount(challenge?.participant_stats?.participant_count);
      setParticipationType(
        challenge?.challengeproperty?.participation_type === 3
          ? 1
          : challenge?.challengeproperty?.participation_type
      );
    }
  }, [challenge]);

  const selectedMenu = useSelector((state) => state.challengeMenu.menu, shallowEqual);

  const onChangeMenu = (menu) => {
    dispatch(selectMenuAction(menu));
  };

  const handleOk = () => {
    setShowJoinChallengeModal(false);
  };

  const handleCancel = () => {
    setTeamId(null);
    setIsTOS(false);
    setShowJoinChallengeModal(false);
  };

  const joinChallenge = () => {
    let joinChallengePayload = {
      type: participationType == 1 ? 'individual' : 'team',
      tos_accepted: isTOS,
      has_accepted_challenge_terms: isTOS,
      challengeId: challenge?.competition_id
    };

    if (participationType == 1) {
      joinChallengePayload.user_id = user?.user_id;
    } else if (participationType == 2) {
      joinChallengePayload.team_id = teamId;
    }
    dispatch(joinChallengeAction(joinChallengePayload));
  };

  const onUploadFile = (event) => {
    // event.preventDefault();
    // const files = event.target.files;
    // console.log('hello');
    // let fileExtension = files[0].name.match(/\.(.+)$/)[1];
    // if (validateFile(fileExtension)) {
    //   // setSelectedFile(files[0]);

    //   const fileData = new FormData();
    //   fileData.append('upfile', files[0]);
    //   fileData.append('file_type', PREDICTION);
    //   let payload = {
    //     upFile: fileData,
    //     challengeId: challenge?.competition_id
    //   };
    //   dispatch(createProtectedFile(payload));
    // }

    // --------------------------

    try {
      event.preventDefault();
      const files = event.target.files;
      let fileExtension = files[0].name.match(/\.(.+)$/)[1];
      const uuid = uuidv4();
      if (validateFile(fileExtension)) {
        dispatch(setUploadStatus({ loading: true, progress: 0 }));
        const chunkSize = 4 * 1024 * 1024; // 4MB chunk size
        const totalChunks = Math.ceil(files[0].size / chunkSize);
        let currentChunk = 0;

        const uploadChunk = (start, end) => {
          const formData = new FormData();
          console.log('upfile', files[0].slice(start, end));
          formData.append('upfile', files[0].slice(start, end));
          formData.append('file_type', PREDICTION);

          axiosInstance
            .post(`challenge/${challenge?.competition_id}/resources/protectedfiles`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'X-Chunk-Number': currentChunk,
                'X-Total-Chunks': totalChunks,
                'X-file-uuid': uuid
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  ((currentChunk * chunkSize + progressEvent.loaded) * 100) / files[0].size
                );
                console.log('first', progress);
                // setUploadProgress(progress);
                dispatch(setUploadStatus({ loading: true, progress: progress }));
              }
            })
            .then((response) => {
              // Chunk uploaded successfully, proceed to next chunk
              currentChunk++;
              if (currentChunk < totalChunks) {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize;
                uploadChunk(start, end);
              } else {
                // All chunks uploaded
                console.log('response?.protected_file_id', response?.data?.protected_file_id);
                dispatch(
                  setUploadStatus({
                    loading: false,
                    progress: 0,
                    protectedFileId: response?.data?.protected_file_id
                  })
                );
                console.log('File upload complete!');
              }
            })
            .catch((error) => {
              dispatch(setUploadStatus({ loading: false, progress: 0 }));
              console.error('Error uploading chunk:', error);
            });

          // Start uploading the first chunk

          // const dataFileId = await saveProtectedDataSetFile(dispatch, payload);
        };
        // Start uploading the first chunk
        const start = currentChunk * chunkSize;
        const end = start + chunkSize;

        dispatch(setUploadStatus({ loading: true, progress: 0 }));
        uploadChunk(start, end);
        // setUploadProgress(0);
      }
    } catch (err) {
      dispatch(setUploadStatus({ loading: false, progress: 0 }));
    }
  };

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case 'zip':
        return true;
      default:
        notify('error', 'Invalid File', 'File with extension (.zip) is allowed');
    }
  };

  const challengeAgreement = (
    <p>
      Cliccando sul tasto CONFIRM REGISTRATION dichiari di aver preso visione e di accettare il
      <a
        onClick={() => {
          onChangeMenu('rules');
          handleCancel();
        }}
        className={Styles.link}>
        Regolamento
      </a>{' '}
      e il trattamento dei <strong>tuoi dati personali</strong> da parte di{' '}
      {challenge?.company?.company_name} (come identificato nel Regolamento) per{' '}
      <strong>partecipare alla Challenge</strong> ai sensi dell’
      {challenge?.company?.privacy_policy_url && (
        <a
          href={
            challenge?.company?.privacy_policy_url.includes('https://') ||
            challenge?.company?.privacy_policy_url.includes('http://')
              ? `${challenge?.company?.privacy_policy_url}`
              : `https://${challenge?.company?.privacy_policy_url}`
          }
          target="_blank"
          className={Styles.link}>
          {' '}
          Informativa Privacy
        </a>
      )}
      .*
    </p>
  );

  const onJoinChallenge = () => {
    const subscriptionStatus = challenge?.subscription_status;
    if (subscriptionStatus == 1) {
      history.push({ pathname: '/login' });
    } else if (subscriptionStatus == 2 || isChallengeJoined) {
      // TODO: Submit Solution
    } else if (subscriptionStatus == 3 || !isChallengeJoined) {
      // TODO: Join the challenge
      if (!loggedInUser.is_linkedin_verified) {
        history.push(`/profile/${loggedInUser.user_id}/profile`);
        notify('info', 'Please complete your profile to join the challenge!', '');
        return;
      }
      setShowJoinChallengeModal(true);
    } else if (subscriptionStatus == 4) {
      history.push({ pathname: '/login' });
    }
  };

  const challengeTab = useMemo(() => {
    switch (selectedMenu) {
      case 'overview':
        return (
          <Overview
            overview={challenge?.challengeproperty?.competition_details}
            loading={isChallengeLoaded}
          />
        );
      case 'rules':
        return <Rules rules={challenge?.challengeproperty?.competition_rules_description} />;
      case 'evaluation':
        return (
          <Evaluation
            evaluation={challenge?.challengeproperty?.competition_evaluation_description}
          />
        );
      case 'prize':
        return <Prize prize={challenge?.challengeproperty?.competition_prize_description} />;
      case 'data':
        return (
          <Data
            isLoggedIn={user?.user_id}
            dataSetFileId={challenge?.challengeproperty?.data_set_file_id}
            challengeId={challenge?.competition_id}
            challengeStatus={challenge?.competition_status}
            subscriptionStatus={challenge?.subscription_status}
            joinChallenge={onJoinChallenge}
            isChallengeJoined={isChallengeJoined}
          />
        );
      case 'leaderboard':
        return (
          <Leaderboard
            challengeId={challenge?.competition_id}
            isLoggedIn={user?.user_id}
            isAdmin={isAdmin}
            challengeStatus={challenge?.competition_status}
          />
        );
      case 'participants':
        return (
          <Participant
            challengeId={challenge?.competition_id}
            isLoggedIn={user?.user_id}
            isAdmin={isAdmin}
          />
        );
      case 'submission':
        return (
          <Submission
            isScoringManual={!challenge?.is_auto_calculate}
            challengeId={challenge?.competition_id}
            submissionLoading={uploadSubmissionLoading}
            isLoggedIn={user?.user_id}
            isAdmin={isAdmin}
            competitionStatus={challenge?.competition_status}
          />
        );
      default:
        return <></>;
    }
  }, [selectedMenu, challenge]);

  return (
    <div className={Styles.challengeContainer}>
      <ChallengeDetail
        {...challenge}
        isLoggedIn={user?.user_id}
        onJoinChallenge={onJoinChallenge}
        isChallengeJoined={isChallengeJoined}
        loading={isChallengeLoaded}
        participationCount={participationCount}
        setParticipationCount={setParticipationCount}
        onUploadFile={onUploadFile}
        subscriptionType={subscriptionType}
        isAdmin={isAdmin}
      />

      <div className={Styles.tabsMenu}>
        {!isMobile && (
          <>
            <ChallengeMenuItem
              label={t('Overview')}
              checked={selectedMenu === 'overview'}
              onClick={() => onChangeMenu('overview')}
            />
            <ChallengeMenuItem
              label={t('odp-pu.challenge-.overview.rules')}
              checked={selectedMenu === 'rules'}
              onClick={() => onChangeMenu('rules')}
            />
            <ChallengeMenuItem
              label={t('odp-pu.challenge-.overview.evaluation')}
              checked={selectedMenu === 'evaluation'}
              onClick={() => onChangeMenu('evaluation')}
            />
            <ChallengeMenuItem
              label={t('odp-pu.challenge-.overview.prize')}
              checked={selectedMenu === 'prize'}
              onClick={() => onChangeMenu('prize')}
            />
            <ChallengeMenuItem
              label={t('odp-pu.challenge-.overview.data')}
              checked={selectedMenu === 'data'}
              onClick={() => onChangeMenu('data')}
            />
            <ChallengeMenuItem
              label={t('odp-pu.challenge-.overview.leaderboard')}
              checked={selectedMenu === 'leaderboard'}
              onClick={() => onChangeMenu('leaderboard')}
            />
          </>
        )}
        {!isMobile && isAdmin && user?.user_id && (
          <ChallengeMenuItem
            label={'Participants'}
            checked={selectedMenu === 'participants'}
            onClick={() => onChangeMenu('participants')}
          />
        )}
        {!isMobile && user?.user_id && (
          <ChallengeMenuItem
            label={'Submission'}
            checked={selectedMenu === 'submission'}
            onClick={() => onChangeMenu('submission')}
          />
        )}
        {isMobile && (
          <Swiper
            freeMode={true}
            slidesPerView={2.8}
            spaceBetween={21}
            style={{ marginLeft: '28px' }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}>
            <SwiperSlide>
              <ChallengeMenuItem
                label={'Overview'}
                checked={selectedMenu === 'overview'}
                onClick={() => onChangeMenu('overview')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ChallengeMenuItem
                label={'Rules'}
                checked={selectedMenu === 'rules'}
                onClick={() => onChangeMenu('rules')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ChallengeMenuItem
                label={'Prize'}
                checked={selectedMenu === 'prize'}
                onClick={() => onChangeMenu('prize')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ChallengeMenuItem
                label={'Data'}
                checked={selectedMenu === 'data'}
                onClick={() => onChangeMenu('data')}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ChallengeMenuItem
                label={'Leaderboard'}
                checked={selectedMenu === 'leaderboard'}
                onClick={() => onChangeMenu('leaderboard')}
              />
            </SwiperSlide>
            {user?.user_id && (
              <SwiperSlide>
                <ChallengeMenuItem
                  label={'Submission'}
                  checked={selectedMenu === 'submission'}
                  onClick={() => onChangeMenu('submission')}
                />
              </SwiperSlide>
            )}
          </Swiper>
        )}
      </div>

      <div className={Styles.tabs}>{challengeTab}</div>

      {challenge?.challengeproperty?.participation_type == 1 && (
        <Modal
          title={t('odp-ad.challenge.competition-rules.competition-rules')}
          visible={showJoinChallengeModal}
          onOk={joinChallenge}
          onCancel={handleCancel}
          centered
          footer={[
            <div className={Styles.rulesFooter}>
              <div>
                <Button key="back" onClick={handleCancel}>
                  {t('odp-ad.challenge.leaderboard-participants-submission.cancel')}
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  className={Styles.registrationBtn}
                  disabled={!isTOS}
                  onClick={joinChallenge}>
                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    `${t('odp-ad.challenge.competition-rules.confirm-registration')}`
                  )}
                </Button>
              </div>
              <div className={`mt-3 ${Styles.consentText}`}>
                <small>
                  *Consenso necessario. Il consenso può essere revocato in qualunque momento
                  scrivendo allo Sponsor, ai recapiti indicati nel Regolamento. La revoca non
                  pregiudica la liceità dei trattamenti basati sul consenso precedentemente
                  prestato, ma ti impedisce di partecipare alla Challenge.
                </small>
              </div>
            </div>
          ]}>
          {challengeAgreement}
          <div style={{ width: 'auto', height: '23px' }}>
            <Checkbox
              checked={isTOS}
              onChange={(e) => {
                setIsTOS(e.target.checked);
              }}>
              {t('odp-ad.challenge.competition-rules.i-agree-to-the-odp-terms-conditions')}
            </Checkbox>
          </div>
        </Modal>
      )}
      {challenge?.challengeproperty?.participation_type == 2 && (
        <Modal
          title={t('odp-ad.challenge.competition-rules.competition-rules')}
          visible={showJoinChallengeModal}
          onOk={joinChallenge}
          onCancel={handleCancel}
          centered
          footer={[
            <div className={Styles.rulesFooter}>
              <div>
                <Button key="back" onClick={handleCancel}>
                  {t('odp-ad.challenge.leaderboard-participants-submission.cancel')}
                </Button>
                ,
                <Button
                  key="submit"
                  type="primary"
                  onClick={joinChallenge}
                  disabled={!teamId || !isTOS}
                  className={Styles.registrationBtn}>
                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    `${t('odp-ad.challenge.competition-rules.confirm-registration')}`
                  )}
                </Button>
              </div>
              <div className={`mt-3 ${Styles.consentText}`}>
                <small>
                  *Consenso necessario. Il consenso può essere revocato in qualunque momento
                  scrivendo allo Sponsor, ai recapiti indicati nel Regolamento. La revoca non
                  pregiudica la liceità dei trattamenti basati sul consenso precedentemente
                  prestato, ma ti impedisce di partecipare alla Challenge.
                </small>
              </div>
            </div>
          ]}>
          {challengeAgreement}

          <Checkbox
            checked={isTOS}
            onChange={(e) => {
              setIsTOS(e.target.checked);
            }}>
            {t('odp-ad.challenge.competition-rules.i-agree-to-the-odp-terms-conditions')}
          </Checkbox>

          <div className={Styles.bar}></div>

          <h5>Only Teams</h5>

          <div className={Styles.formGroup}>
            <p>{t('odp-ad.challenge.competition-rules.select-existing-team')}</p>
            <Select
              onChange={(teamId) => {
                setTeamId(teamId);
              }}
              value={teamId}
              style={{
                width: '55%',
                borderRadius: 5 + 'px'
              }}
              // className={
              //   errors?.challengeproperty?.competition_category_id
              //     ? Styles.inputError
              //     : ""
              // }
              // value={subject}
              // onBlur={updateChallengeInfo}
              suffixIcon={<Icon src={downArrow} />}
              placeholder={t('odp-ad.challenge.competition-rules.select-team')}>
              {allTeams.map(({ team_id, team_name }) => (
                <Option key={team_id} value={team_id}>
                  {team_name}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      )}
      {challenge?.challengeproperty?.participation_type == 3 && (
        <Modal
          title={t('odp-ad.challenge.competition-rules.competition-rules')}
          visible={showJoinChallengeModal}
          onOk={joinChallenge}
          onCancel={handleCancel}
          centered
          footer={[
            <div className={Styles.rulesFooter}>
              <div>
                <Button key="back" onClick={handleCancel}>
                  {t('odp-ad.challenge.leaderboard-participants-submission.cancel')}
                </Button>
                ,
                <Button
                  key="submit"
                  type="primary"
                  onClick={joinChallenge}
                  disabled={(participationType == 2 && !teamId) || !isTOS}
                  className={Styles.registrationBtn}>
                  {loading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    `${t('odp-ad.challenge.competition-rules.confirm-registration')}`
                  )}
                </Button>
              </div>
              <div className={`mt-3 ${Styles.consentText}`}>
                <small>
                  *Consenso necessario. Il consenso può essere revocato in qualunque momento
                  scrivendo allo Sponsor, ai recapiti indicati nel Regolamento. La revoca non
                  pregiudica la liceità dei trattamenti basati sul consenso precedentemente
                  prestato, ma ti impedisce di partecipare alla Challenge.
                </small>
              </div>
            </div>
          ]}>
          {challengeAgreement}
          <Checkbox
            checked={isTOS}
            onChange={(e) => {
              setIsTOS(e.target.checked);
            }}>
            {t('odp-ad.challenge.competition-rules.i-agree-to-the-odp-terms-conditions')}
          </Checkbox>

          <div className={Styles.bar}></div>

          <h5>{t('odp-ad.challenge.competition-rules.teams-individual')}</h5>

          <p>{t('odp-pu.challenge.modal-label-team-individual.challenge')}</p>

          <div className={Styles.formGroup}>
            <p>{t('odp-ad.challenge.competition-rules.select-how-you-want-to-participate')}</p>
            <div className={Styles.radioGroup}>
              <Radio.Group
                value={participationType}
                onChange={(e) => {
                  setParticipationType(e.target.value);
                }}>
                <Space direction="vertical">
                  <Radio value={1} checked>
                    {t('odp-ad.challenge.competition-rules.individually')}
                  </Radio>
                  <Radio value={2}>{t('odp-ad.challenge.competition-rules.in-team')}</Radio>
                </Space>
              </Radio.Group>
            </div>
          </div>

          <div className={Styles.formGroup}>
            <p>{t('odp-ad.challenge.competition-rules.select-existing-team')}</p>
            <Select
              onChange={(teamId) => {
                setTeamId(teamId);
              }}
              disabled={participationType == 1}
              value={teamId}
              style={{
                width: '55%',
                borderRadius: 5 + 'px'
              }}
              suffixIcon={<Icon src={downArrow} />}
              placeholder={t('odp-ad.challenge.competition-rules.select-team')}>
              {allTeams.map(({ team_id, team_name }) => (
                <Option key={team_id} value={team_id}>
                  {team_name}
                </Option>
              ))}
            </Select>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChallengePreview;
