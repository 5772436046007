export const FETCH_COMPETITION_CAT = 'FETCH_COMPETITION_CAT';
export const FETCH_COMPETITION_TYPES = 'FETCH_COMPETITION_TYPES';
export const FETCH_PARTICIPATION_TYPES = 'FETCH_PARTICIPATION_TYPES';
export const FETCH_SCORING_METRIC = 'FETCH_SCORING_METRIC';

export const getCompetitionCategories = () => ({
    type: FETCH_COMPETITION_CAT
})

export const getCompetitionTypes = () => ({
    type: FETCH_COMPETITION_TYPES
})

export const getParticipationTypes = () => ({
    type: FETCH_PARTICIPATION_TYPES
})

export const getScoringMetrics = () => ({
    type: FETCH_SCORING_METRIC
})