import { Route, Redirect } from "react-router-dom";
import { getToken, getUserID } from "../services/token";

const secureRoute = [
  "/profile",
  "/profile/account-settings",
  "/profile/challange",
  "/profile/profile",
  "/profile/dashboard",
  "/profile/team",
];
const ProtectedRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const token = getToken();
        const odp_user_id = getUserID();

        if (
          props.location.pathname === secureRoute[0] ||
          props.location.pathname === secureRoute[1] ||
          props.location.pathname === secureRoute[2] ||
          props.location.pathname === secureRoute[3] ||
          props.location.pathname === secureRoute[4] ||
          props.location.pathname === secureRoute[5]
        ) {
          if (token !== null) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        } else if (props.location.pathname === "/login") {
          if (token === null) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: `/profile/${odp_user_id}/profile`,
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default ProtectedRoutes;
