import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';

export class UserSkillSet {
    user_skill_set_id = null;
    user_id = null;
    skill_set_id = null;
    skill_level = null;
    constructor() {
        this.user_skill_set_id = null;
        this.user_id = null;
        this.skill_set_id = null;
        this.skill_level = null;
    }

    deserialize(model) {
        this.user_skill_set_id = StringConverter(model.user_skill_set_id);
        this.user_id = StringConverter(model.user_id);
        this.skill_set_id = StringConverter(model.skill_set_id);
        this.skill_level = NumberConverter(model.skill_level);
        return this;
    }

}