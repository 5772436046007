export const LOGIN = 'LOGIN';
export const SIGN_UP = 'SIGN_UP';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const loginAction = request => ({
    type: LOGIN,
    request
});

export const signUpAction = (request) => ({
    type: SIGN_UP,
    request
});

export const forgotPasswordAction = (request) => ({
    type: FORGOT_PASSWORD,
    request
});

export const resetPasswordAction = (request) => ({
    type: RESET_PASSWORD,
    request
});
