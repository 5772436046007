import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { failure, request, success } from "../../utils/fetch";
import { FETCH_CHALLENGES } from "../actions/ChallengeAction";
import {
  FETCH_COMPETITION_CAT,
  FETCH_COMPETITION_TYPES,
  FETCH_PARTICIPATION_TYPES,
  FETCH_SCORING_METRIC,
} from "../actions/lookUpAction";
import { getLookupListings } from "../services/Lookup";

function* fetchCompetitionCategories() {
  try {
    yield put({ type: request(FETCH_COMPETITION_CAT) });
    const response = yield getLookupListings("competition_categories");
    yield put({ type: success(FETCH_COMPETITION_CAT), response });
  } catch (error) {

    yield put({ type: failure(FETCH_COMPETITION_CAT), error });
  }
}

function* fetchCompetitionTypes() {
  try {
    yield put({ type: request(FETCH_COMPETITION_TYPES) });
    const response = yield getLookupListings("competition_types");
    yield put({ type: success(FETCH_COMPETITION_TYPES), response });
  } catch (error) {

    yield put({ type: failure(FETCH_COMPETITION_TYPES), error });
  }
}

function* fetchParticipationTypes() {
  try {
    yield put({ type: request(FETCH_PARTICIPATION_TYPES) });
    const response = yield getLookupListings("participation_types");
    yield put({ type: success(FETCH_PARTICIPATION_TYPES), response });
  } catch (error) {

    yield put({ type: failure(FETCH_PARTICIPATION_TYPES), error });
  }
}

function* fetchScoringMetric() {
  try {
    yield put({ type: request(FETCH_SCORING_METRIC) });
    const response = yield getLookupListings("evaluation_functions");
    yield put({ type: success(FETCH_SCORING_METRIC), response });
  } catch (error) {

    yield put({ type: failure(FETCH_SCORING_METRIC), error });
  }
}

export default [
  takeLatest(FETCH_COMPETITION_CAT, fetchCompetitionCategories),
  takeLatest(FETCH_COMPETITION_TYPES, fetchCompetitionTypes),
  takeLatest(FETCH_PARTICIPATION_TYPES, fetchParticipationTypes),
  takeLatest(FETCH_SCORING_METRIC, fetchScoringMetric),
];
