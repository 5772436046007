import HeroSection from '../../components/HeroSection/heroSection';
import OurEvents from '../../components/OurEvents/ourEvents';
import OurServices from '../../components/OurServices/ourServices';
import QuotesSection from '../../components/QuotesSection/quotesSection';
import { useTranslation } from 'react-i18next';
import Sponsors from '../../components/Sponsors/sponsors';
import TheAdvantages from '../../components/TheAdvantages/theAdvantages';
import sponnsor1 from '../../asset/images/sponsors-logo/educational-sponsors/1.jpg';
import sponnsor2 from '../../asset/images/sponsors-logo/educational-sponsors/2.jpg';
import sponnsor3 from '../../asset/images/sponsors-logo/educational-sponsors/3.jpg';
import sponnsor4 from '../../asset/images/sponsors-logo/educational-sponsors/4.jpg';
import sponnsor5 from '../../asset/images/sponsors-logo/educational-sponsors/5.jpg';
import sponnsor6 from '../../asset/images/sponsors-logo/educational-sponsors/6.jpg';
import sponnsor7 from '../../asset/images/sponsors-logo/educational-sponsors/7.jpg';
import sponnsor8 from '../../asset/images/sponsors-logo/educational-sponsors/8.jpg';
import sponnsor9 from '../../asset/images/sponsors-logo/educational-sponsors/9.jpg';
import sponnsor10 from '../../asset/images/sponsors-logo/educational-sponsors/10.jpg';
import sponnsor11 from '../../asset/images/sponsors-logo/educational-sponsors/11.jpg';
import sponnsor12 from '../../asset/images/sponsors-logo/educational-sponsors/12.jpg';
import sponnsor13 from '../../asset/images/sponsors-logo/educational-sponsors/13.jpg';
import sponnsor14 from '../../asset/images/sponsors-logo/educational-sponsors/14.png';
import sponnsor15 from '../../asset/images/sponsors-logo/educational-sponsors/15.jpg';
import svg1 from '../../asset/icons/static-pages-svgs/17.svg';
import svg2 from '../../asset/icons/static-pages-svgs/12.svg';
import svg3 from '../../asset/icons/static-pages-svgs/18.svg';
import svg4 from '../../asset/icons/static-pages-svgs/15.svg';
import svg5 from '../../asset/icons/static-pages-svgs/3.svg';
import svg6 from '../../asset/icons/static-pages-svgs/2.svg';
import svg7 from '../../asset/icons/static-pages-svgs/1.svg';

import './educational.scss';
import Contact from '../Contact/Contact';

const openPage = (e) => {
  e.preventDefault();
  window.open('/contact-us', '_self');
};

const Educational = () => {
  const { t } = useTranslation();

  const text = {
    quotesDescription1: `${t('odp-pu.for-educational.today-the-working-world-is-seeking-more')}`,
    quotesDescription2: `${t('odp-pu.for-educational.it-is-now-clear')}`,
    quotesDescription3: `${t('odp-pu.for-educational.thanks-to-open-data')}`,
    cards: [
      {
        id: 1,
        title: svg1,
        description: `${t('odp-pu.for-educational.organize-free-events-at-universities-hi')}`
      },
      {
        id: 2,
        title: svg2,
        description: `${t('odp-pu.for-educational.obtain-highly-specialized-products-such-')}`
      },
      {
        id: 3,
        title: svg3,
        description: `${t('odp-pu.for-educational.create-free-contests-to-test-your-studen')}`
      },
      {
        id: 4,
        title: svg4,
        description: `${t('odp-pu.for-educational.present-the-skills-of-your-university-co')}`
      }
    ],
    cards1: [
      {
        id: 1,
        title: svg5,
        description: `${t('odp-pu.for-educational.once-in-contact-with-our-team-you-will-')}`
        // description1:
        //   "You will have a customizable platform that will adapt to your and your students' needs.",
      },
      {
        id: 2,
        title: svg6,
        description: `${t('odp-pu.for-educational.once-the-event-is-created-students-will')}`
      },
      {
        id: 3,
        title: svg7,
        description: `${t('odp-pu.for-educational.competing-talents-will-thus-be-able-to-t')}`
      }
    ],
    cards2: [],
    theAdvantages: [
      {
        id: 1,
        title: `${t('odp-pu.for-educational.develop-data-philanthropy-projects')}`,
        description: `${t('odp-pu.for-educational.organize-hackathons-focused-on-innovatio')}`
      },
      {
        id: 2,
        title: `${t('odp-pu.for-educational.place-your-university-in-the-world-of-in')}`,
        description: `${t('odp-pu.for-educational.increase-both-the-value-of-your-course-a')}`
      },
      {
        id: 3,
        title: `${t('odp-pu.for-educational.find-the-best-talent-for-your-research-p')}`,
        description: `${t('odp-pu.for-educational.faced-with-a-world-without-borders-rese')}`
      },
      {
        id: 4,
        title: `${t('odp-pu.for-educational.get-advanced-hr-analytics-products')}`,
        description: `${t('odp-pu.for-educational.enter-open-data-playground-to-get-highly')}`
      }
    ],
    sponsors: [
      {
        id: 1,
        image: sponnsor1
      },
      {
        id: 2,
        image: sponnsor2
      },
      {
        id: 3,
        image: sponnsor3
      },
      {
        id: 4,
        image: sponnsor4
      },
      {
        id: 5,
        image: sponnsor5
      },
      {
        id: 6,
        image: sponnsor6
      },
      {
        id: 7,
        image: sponnsor7
      },
      {
        id: 8,
        image: sponnsor8
      },
      {
        id: 9,
        image: sponnsor9
      },
      {
        id: 10,
        image: sponnsor10
      },
      {
        id: 11,
        image: sponnsor11
      },
      {
        id: 12,
        image: sponnsor12
      },
      {
        id: 13,
        image: sponnsor13
      },
      {
        id: 14,
        image: sponnsor14
      },
      {
        id: 15,
        image: sponnsor15
      }
    ]
  };

  return (
    <div className="development__wrapper">
      <div className="educational__herosection">
        <HeroSection
          title={t('odp-pu.for-educational.do-you-work-for-a-university-a-research')}
          subTitle={t('odp-pu.for-educational.train-your-students-on-open-data-playgro')}
          descriptiopnOne={t('odp-pu.for-educational.transform-and-innovate-the-university-wo')}
          label={t('odp-pu.for-educational.contact-us')}
          openPage={openPage}
        />
      </div>
      <div className="educational__ourServices">
        <OurServices
          text={text}
          title={t('odp-pu.for-educational.attract-engage-and-train-your-students')}
          subtitle={t('odp-pu.for-educational.with-open-data-playground-you-can-')}
          description={t('odp-pu.for-educational.thanks-to-open-data-playground-you-can-r')}
        />
      </div>
      <OurEvents
        title={t('odp-pu.for-educational.our-educational-events-')}
        description={t('odp-pu.for-educational.discover-the-live-events-organized-for-i')}
      />
      <QuotesSection
        text={text}
        title={t('odp-pu.for-educational.why-organize-an-educational-challenge-on')}
        quote={t('odp-pu.for-educational.for-companies-the-possession-of-skills-')}
        author={t('odp-pu.for-educational.source-unioncamere---anpal-excelsior-i')}
      />
      <div className="educational__organizechallenge">
        <OurServices
          text={text}
          titleOne={t('odp-pu.for-educational.how-to-organize-a-challenge-on-open-data')}
        />
      </div>
      <TheAdvantages text={text} title={t('odp-pu.for-educational.the-advantages-')} />
      <div className="educational__sponsors">
        <Sponsors
          details={text}
          title={t('odp-pu.for-educational.data-ecosystem')}
          description={t('odp-pu.for-educational.discover-our-network-of-data-experts')}
        />
      </div>
      <div className="educational__contactWrapper">
        <Contact
          description={t('odp-pu.for-educational.enter-your-contact-details-in-the-form-')}
        />
      </div>
    </div>
  );
};

export default Educational;
