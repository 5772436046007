import React from "react";
import { Menu, Dropdown } from "antd";
import MenuIcon from "../../asset/images/team/more-vertical.svg";
import { teamMemberActions } from "../../services/team/api";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

export const CustomDropDown = ({ menuList, team, teamMembers }) => {
  const dispatch = useDispatch();

  let { user_id } = useParams();

  const menu = (
    <Menu>
      {menuList.map((menuItem, index) => (
        <>
          <Menu.Item
            key={index}
            disabled={menuItem.disabled}
            onClick={() =>
              teamMemberActions(
                dispatch,
                {
                  team_id: teamMembers?.team_id,
                  user_id: team?.user_id,
                  action: menuItem.action,
                },
                user_id
              )
            }
          >
            <span>
              <img src={menuItem.icon} style={{ marginRight: 5 }} />
              {menuItem.label}
            </span>
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link"
        onClick={(e) => e.preventDefault()}
        style={{ marginLeft: "auto" }}
      >
        <img src={MenuIcon} alt={MenuIcon} />
      </a>
    </Dropdown>
  );
};
