import React, { useEffect, useState } from "react";
import PersonalInfo from "./personalnfo";
import Skill from "./newSkill";
import UserAbout from "./userAbout";
import UserPrivateInfo from "./userPrivateInfo";
import SkillAssesment from "./skillAssesment";
import Cv from "./cv";
import WorkExperince from "./workExperince";
import Education from "./education";
import ManageVisibility from "./manageVisibility";
import { Form, SubmitButton } from "formik-antd";
import { Formik } from "formik";
import Notify from "../../theme/notifications";
import { AButton } from "../../theme/button";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries, setProfile } from "../../services/profile/api";
import { User } from "../../models/user";
import { Button, Spin, Switch } from "antd";
import "./profileInner.scss";
import {
  getUserById,
  removeUser,
  saveUser,
  userDisplayName,
} from "../../redux-store/actions/userAction";
import { useParams } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { setUserProfile } from "../../services/token";
import UserStats from "../UserStats";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconBlue = (
  <LoadingOutlined style={{ fontSize: 28, color: "#5f76ff" }} spin />
);

const Index = ({ userId, isAdmin = false, setUserName }) => {
  const { t } = useTranslation();

  const [skillSet, setSkillSet] = useState([]);

  const [userImageId, setUserImageId] = useState(null);
  const [cvFileId, setCVFileId] = useState(null);
  const { user_id } = useParams();
  const dispatch = useDispatch();

  let userPayload = {};

  const { user, loading: userProfileLoading } = useSelector(
    (state) => state.publicProfile
  );
  const { loading, user: userProfileSaved } = useSelector(
    (state) => state.personalProfile
  );

  const { user: _self } = useSelector((state) => state.userProfile);

  const submitUserProfile = (values, { setSubmitting }) => {
    const userPayload = new User().deserialize(values);

    userPayload.userProfile.avatar_url =
      userImageId || values.userProfile.avatar_url;

    userPayload.cv_file_id = cvFileId || userPayload.cv_file_id;

    userPayload.userSkillSets = skillSet;

    if (userPayload.userExperienceDetails.length === 1) {
      if (
        userPayload.userExperienceDetails[0].company_name == "" ||
        userPayload.userExperienceDetails[0].company_name == null
      ) {
        delete userPayload.userExperienceDetails[0];
      }
    }
    if (userPayload.userEducationDetails.length === 1) {
      if (
        userPayload.userEducationDetails[0].institute_name == "" ||
        userPayload.userEducationDetails[0].institute_name == null
      ) {
        delete userPayload.userEducationDetails[0];
      }
    }
    dispatch(saveUser(userPayload));
  };

  useEffect(() => {
    dispatch(getUserById(userId || user_id));
    initCountries()

    return () => {
      dispatch(removeUser());
    }
  }, []);


  useEffect(() => {
    if (userProfileSaved && userProfileSaved.userProfile) {
      dispatch(userDisplayName(userProfileSaved.userProfile.display_name));
      setUserProfile(userProfileSaved.userProfile);
    }
  }, [userProfileSaved]);

  const initCountries = async () => {
    await fetchCountries(dispatch);
  };

  useEffect(() => {
    if (user) {
      setUserImageId(user?.userProfile?.avatar_url);
      if (user.userEducationDetails && user.userEducationDetails.length == 0) {
        user.userEducationDetails.push({
          user_education_detail_id: null,
          education_name: null,
          institute_name: null,
          education_description: null,
          is_current_education: false,
          start_date: null,
          end_date: null,
        });
      }
      if (
        user.userExperienceDetails &&
        user.userExperienceDetails.length == 0
      ) {
        user.userExperienceDetails.push({
          user_experience_detail_id: null,
          job_title: null,
          company_name: null,
          job_description: null,
          is_current_job: false,
          start_date: null,
          end_date: null,
        });
      }
      if (user.userSkillSets && user.userSkillSets.length > 0) {
        setSkillSet(user.userSkillSets);
      }
      if (isAdmin) setUserName(user?.userProfile?.display_name);
      userPayload = { ...user };
    }
  }, [user]);

  const handleSubmit = () => { };

  return (
    <div className="profile__inner__container">

      {isAdmin && <UserStats user={user} userLoading={userProfileLoading} />}
      {!isAdmin && (
        <div className="text-center profile-title">
          <h1>{t("odp-pu.user.profile.label-your-profile")}</h1>
        </div>
      )}
      {userProfileLoading && (
        <div className="text-center">
          <Spin indicator={antIconBlue} />
        </div>
      )}

      {!isAdmin && user?.profile_status == 2 && (
        <Notify
          msg={t("odp-pu.user.profile.complete-your-profile")}
          className="error"
          twoToneColor="#eb2f2f"
        />
      )}
      <Formik
        initialValues={user}
        enableReinitialize={true}
        onSubmit={submitUserProfile}
        render={({ values }) => (
          <Form onSubmit={handleSubmit} >
            <div className="profile__inner__wrapper">
              <PersonalInfo
                setUserImage={setUserImageId}
                userImageId={userImageId}
                isAdmin={isAdmin}
                isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin}
              />
              <Skill setSkillSet={setSkillSet} skillSet={skillSet} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} />
              <div className="button__wrappper"></div>
              {isAdmin && !_self?.is_admin ? '' : <div className="text-right">
                <Button htmlType="submit" className="submitButton" disabled={isAdmin && !_self.is_admin && _self?.is_company_admin}>
                  {!loading ? (
                    `${t("odp-pu.label.profile.save")}`
                  ) : (
                    <Spin indicator={antIcon} />
                  )}
                </Button>
              </div>}
            </div>

            <div className="profile__inner__wrapper">
              <ManageVisibility isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} />
              <UserAbout isAdmin={isAdmin} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} userValue={values} />
              <span className="dashed"></span>
              <Cv setCVFile={setCVFileId} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} />
              <span className="dashed"></span>
              <UserPrivateInfo isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} isAdmin={isAdmin} />
              <span className="dashed"></span>
              <SkillAssesment setSkillSet={setSkillSet} skillSet={skillSet} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} />
              {/* <span className="dashed"></span>
              <WorkExperince values={values} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} /> */}
              {/* <span className="dashed"></span>
              <Education values={values} isCompanyAdmin={isAdmin && !_self.is_admin && _self?.is_company_admin} /> */}
              {isAdmin && !_self?.is_admin ? '' : <div className="button__wrappper">
                <Button htmlType="submit" className="submitButton" >
                  {!loading ? (
                    `${t("odp-pu.label.profile.save")}`
                  ) : (
                    <Spin indicator={antIcon} />
                  )}
                </Button>
              </div>}
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default Index;
