import React, { useState, useRef, useEffect } from 'react';
import { Space } from 'antd';
import { Input, DatePicker, TimePicker, Radio, Select, Checkbox } from 'formik-antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Styles from './challengeTab.module.scss';
import Icon from '../../../components/Icons';
import downArrow from '../../../asset/icons/downArrow.svg';
import Calendar from '../../../asset/icons/calendar.svg';
import Clock from '../../../asset/icons/clock.svg';
import { useTranslation } from 'react-i18next';
import { SButton } from '../../../theme/button';
import { Spin } from 'antd';
import { DATASET } from '../../../utils/constants';
import { notify } from '../../../theme/antNotify';
import {
  saveParticipantsEmailsCSVFile,
  getParticipantsEmailsCSVFile
} from '../../../services/fileupload/api';

const { Option } = Select;
const CHAR_SIZE = 80;
const dateFormat = 'YYYY-MM-DD';
const Basic = ({ errors, touched, challenge, values, evaluationFunctionId, setFieldValue }) => {
  const dispatch = useDispatch();
  const [challengeLocation, setChallengeLocation] = useState(1);
  const [prizeTextCharacters, setPrizeTextCharacters] = useState(CHAR_SIZE);
  const [locationNameCharacters, setLocationNameCharacters] = useState(CHAR_SIZE);
  const [locationAddressCharacters, setLocationAddressCharacters] = useState(CHAR_SIZE);

  const { requestObject: challengeObject } = useSelector((state) => state.challengePayload);

  const { listings: competitionTypes } = useSelector((state) => state.competitionTypes);
  const { listings: competitionCategories } = useSelector((state) => state.competitionCategories);
  const { listings: participationTypes } = useSelector((state) => state.participationTypes);
  const { listings: sponsors } = useSelector((state) => state.sponsors);

  const { listings: scoringMetrics } = useSelector((state) => state.scoringMetrics);

  useEffect(() => {
    if (evaluationFunctionId) {
      const scoringMetric = scoringMetrics.filter(
        ({ evaluation_function_id }) => evaluationFunctionId == evaluation_function_id
      )[0];
      setFieldValue('scoring_metric_temp', scoringMetric?.evaluation_function_name);
    }
  }, [evaluationFunctionId]);

  const onLocationChange = (event) => {
    setChallengeLocation(event.target.value);
  };
  const { t } = useTranslation();
  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = (current) => {
    const start = moment(values.challengeproperty.start_date, 'YYYY-MM-DD');
    return current && current < moment(start, 'YYYY-MM-DD');
  };

  const [load, setload] = useState(false);
  const [download, setdownload] = useState(null);
  const fileInput = useRef();

  const clickTrigger = () => {
    fileInput.current.click();
  };

  const onCSVFileUpload = async (e) => {
    if (fileInput.current.files[0] !== null || fileInput.current.files[0] !== undefined) {
      const files = fileInput.current.files;
      if (files.length >= 1) {
        setload(true);
        let fileExtension = files[0].name.match(/\.(.+)$/)[1];
        if (validateFile(fileExtension)) {
          const fileData = new FormData();
          fileData.append('upfile', files[0]);
          fileData.append('file_type', DATASET);

          let payload = {
            challengeId: challengeObject?.competition_id,
            upFile: fileData,
            fileType: DATASET
          };
          const response = await saveParticipantsEmailsCSVFile(dispatch, payload);
          if (response && response.limit_participation_file_name) {
            challengeObject.challengeproperty.limit_participation_file_name =
              response.limit_participation_file_name;
            challenge.challengeproperty.limit_participation_file_name =
              response.limit_participation_file_name;
            setdownload(true);
          }
        }
        setload(false);
      }
    }
    e.target.value = '';
  };

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case 'csv':
        return true;
      default:
        notify('error', 'Invalid File', 'File with extension (.csv) is allowed');
    }
  };

  const downloadParticipantsCSVFile = async () => {
    if (challenge?.challengeproperty?.is_limited_participation == true) {
      let payload = {
        challengeId: challengeObject?.competition_id
      };
      const response = await getParticipantsEmailsCSVFile(dispatch, payload);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          challengeObject.challengeproperty.limit_participation_file_name
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        return;
      }
    }
  };

  return (
    <div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.title')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>{t('odp-ad.challenge.create-basics-tab.-name')}</span>
          <Input
            name="competition_name"
            placeholder={t('odp-ad.challenge.create-basics-tab.write-the-title-of-your-challenge')}
            className={errors.competition_name && touched.competition_name && Styles.inputError}
          />
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.short-description-tagline')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>{t('odp-ad.challenge.create-basics-tab.-short-description')}</span>
          <Input
            name="competition_description"
            placeholder={t(
              'odp-ad.challenge.create-basics-tab.write-the-short-description-of-your-chal'
            )}
            className={
              errors.competition_description && touched.competition_description && Styles.inputError
            }
          />
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{'Sponsor'}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>{'*Select the organization Sponsor of the challenge'}</span>
          <Select
            style={{
              width: 100 + '%',
              borderRadius: 5 + 'px'
            }}
            allowClear
            className={errors.company_id && touched.company_id && Styles.inputError}
            name="company_id"
            suffixIcon={<Icon src={downArrow} />}
            placeholder={'Select Sponsor'}>
            {sponsors.map(({ company_id, company_name }) => (
              <Option key={company_id} value={company_id}>
                {company_name}
              </Option>
            ))}
          </Select>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.subject')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.-select-the-subject-of-the-challenge')}
          </span>
          <Select
            style={{
              width: 100 + '%',
              borderRadius: 5 + 'px'
            }}
            allowClear
            className={
              errors.challengeproperty?.competition_category_id &&
              touched.challengeproperty?.competition_category_id &&
              Styles.inputError
            }
            name="challengeproperty.competition_category_id"
            suffixIcon={<Icon src={downArrow} />}
            placeholder={t('odp-ad.challenge.create-basics-tab.select-subject')}>
            {competitionCategories.map(({ competition_category_id, competition_category_name }) => (
              <Option key={competition_category_id} value={competition_category_id}>
                {competition_category_name}
              </Option>
            ))}
          </Select>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.type')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>{t('odp-ad.challenge.create-basics-tab.-select-the-type-of-the-challenge')}</span>
          <Select
            name="competition_type_id"
            style={{
              width: 100 + '%',
              borderRadius: 5 + 'px'
            }}
            className={
              errors?.competition_type_id && touched?.competition_type_id ? Styles.inputError : ''
            }
            suffixIcon={<Icon src={downArrow} />}
            placeholder={t('odp-ad.challenge.create-basics-tab.select-type')}>
            {competitionTypes.map(({ competition_type_id, competition_type_name }) => (
              <Option value={competition_type_id} key={competition_type_id}>
                {competition_type_name}
              </Option>
            ))}
          </Select>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.eligibility')}</h4>
          <p>{t('odp-ad.challenge.create-basics-tab.select-the-challenge-s-participant-type')}</p>
        </div>
        <div className={Styles.formGroup}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.is-your-challenge-public-or-reserved-')}
          </span>
          <div className="row">
            <div className={Styles.checkBoxForm}>
              <Radio.Group name="challengeproperty.privacy_mode">
                <Space direction="vertical">
                  <Radio value={1}>
                    {t('odp-ad.challenge.create-basics-tab.public-challenge')}
                  </Radio>
                  <Radio value={2}>{t('odp-ad.challenge.create-basics-tab.by-domain-only')}</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className={Styles.checkBoxInput}>
              <Input
                placeholder="domain.com"
                addonBefore="@"
                name="challengeproperty.private_domain"
                disabled={challenge?.challengeproperty?.privacy_mode == 1}
                className={
                  errors?.challengeproperty?.private_domain &&
                  touched?.challengeproperty?.private_domain &&
                  Styles.inputError
                }
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className={Styles.checkBoxForm}>
              <Checkbox name="challengeproperty.is_limited_participation"> By csv file</Checkbox>
            </div>
          </div>

          <div className="row">
            <div className={Styles.csvUpload}>
              <Spin spinning={load} className="spinner" />
              <div>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  name="profilePicture"
                  id="profilePicture"
                  ref={fileInput}
                  onChange={(e) => onCSVFileUpload(e)}
                />
              </div>

              <div className={Styles.uploader__wrapper}>
                <SButton
                  label={`${t('odp-pu.label.profile.upload-file')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    clickTrigger();
                  }}
                  disabled={
                    load || challenge?.challengeproperty?.is_limited_participation == false
                      ? true
                      : false
                  }></SButton>
                <span className={Styles.file__extention}> .csv</span>
              </div>
              <div>
                {download ||
                (challengeObject &&
                  challengeObject.challengeproperty &&
                  challengeObject.challengeproperty.limit_participation_file_name) ? (
                  <span className={Styles.filename}>
                    <a
                      disabled={challenge?.challengeproperty?.is_limited_participation == false}
                      href={download}
                      download={challengeObject.challengeproperty.limit_participation_file_name}
                      onClick={downloadParticipantsCSVFile}>
                      {challengeObject.challengeproperty.limit_participation_file_name}
                    </a>
                  </span>
                ) : (
                  <span className="filename">No file uploaded</span>
                )}
              </div>
            </div>
          </div>

          <div>
            <span style={{ color: '#3B3D4A', fontWeight: 300 }}>
              {t('odp-ad.challenge.create-basics-tab.prepare-an-excel-file-with-one-email-eac')}
            </span>
          </div>

          <div className="mt-4 pt-1">
            <span className={Styles.daysText}>
              {t('odp-ad.challenge.create-basics-tab.please-indicate-after-how-many-days-from')}
            </span>

            <div className="mt-2">
              <Input
                style={{
                  width: 97 + 'px'
                }}
                placeholder="N° of days"
                name="challengeproperty.limit_participation_for_days"
                disabled={challenge?.challengeproperty?.is_limited_participation == false}
                className={
                  errors?.challengeproperty?.limit_participation_for_days &&
                  touched?.challengeproperty?.limit_participation_for_days &&
                  Styles.inputError
                }
              />
            </div>
          </div>

          <div className={`${Styles.formGroup} ${Styles.teamIndividual}`}>
            <span>{t('odp-ad.challenge.create-basics-tab.teams-individuals-')}</span>
            <Select
              name="challengeproperty.participation_type"
              style={{
                width: 100 + '%',
                borderRadius: 5 + 'px'
              }}
              suffixIcon={<Icon src={downArrow} />}
              placeholder={t('odp-ad.challenge.create-basics-tab.select')}
              className={
                errors?.challengeproperty?.participation_type &&
                touched?.challengeproperty?.participation_type &&
                Styles.inputError
              }>
              {participationTypes.map(({ participation_type_id, participation_type_name }) => (
                <Option key={participation_type_id} value={participation_type_id}>
                  {participation_type_name}
                </Option>
              ))}
            </Select>
            <div className={Styles.eligibilityRequirements}>
              <h6>{t('odp-ad.challenge.create-basics-tab.additional-eligibility-requirements')}</h6>
              <p>
                {t('odp-ad.challenge.create-basics-tab.please-describe-any-additional-participa')}{' '}
                <a>Challenge Rules</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.challenge-period')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>{t('odp-ad.challenge.create-basics-tab.start')}</span>
          <div className={Styles.dateTimePicker}>
            <DatePicker
              name="challengeproperty.start_date"
              placeholder={dateFormat}
              suffixIcon={<Icon src={Calendar} />}
              disabledDate={disabledDate}
              format={dateFormat}
              className={
                errors?.challengeproperty?.start_date && touched?.challengeproperty?.start_date
                  ? Styles.inputError
                  : ''
              }
            />
            <TimePicker
              name="challengeproperty.start_time"
              placeholder="HH:mm:ss"
              suffixIcon={<Icon src={Clock} />}
              className={
                errors?.challengeproperty?.start_time && touched?.challengeproperty?.start_time
                  ? Styles.inputError
                  : ''
              }
            />
          </div>
          <span>{t('odp-ad.challenge.create-basics-tab.deadline')}</span>
          <div className={Styles.dateTimePicker}>
            <DatePicker
              name="challengeproperty.end_date"
              placeholder={dateFormat}
              disabledDate={disabledEndDate}
              disabled={values.challengeproperty.start_date == null}
              format={dateFormat}
              suffixIcon={<Icon src={Calendar} />}
              className={
                errors?.challengeproperty?.end_date && touched?.challengeproperty?.end_date
                  ? Styles.inputError
                  : ''
              }
            />
            <TimePicker
              name="challengeproperty.end_time"
              placeholder="HH:mm:ss"
              disabled={values.challengeproperty.start_date == null}
              format="HH:mm:ss"
              suffixIcon={<Icon src={Clock} />}
              className={
                errors?.challengeproperty?.end_time && touched?.challengeproperty?.end_time
                  ? Styles.inputError
                  : ''
              }
            />
          </div>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.winning-prize')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.-winning-prize-title-short-description-')}
          </span>
          <Input
            placeholder={t(
              'odp-ad.challenge.create-basics-tab.write-a-short-description-of-your-winnin'
            )}
            maxLength={CHAR_SIZE}
            name="challengeproperty.winning_prize"
            onChange={(e) => {
              setPrizeTextCharacters(CHAR_SIZE - e.target.value.length);
            }}
            className={
              errors?.challengeproperty?.winning_prize && touched?.challengeproperty?.winning_prize
                ? Styles.inputError
                : ''
            }
          />
          <span className="float_right">{prizeTextCharacters} characters left</span>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>Slug</h4>
        </div>
        <div className={Styles.formGroup}>
          <span>
            {t('odp-ad.challenge.create-basics-tab.choose-a-custom-url-link-for-your-challe')}
          </span>
          <Input
            name="challengeproperty.competition_location_url"
            placeholder="opendataplayground.com/challenge/…"
            className={
              errors?.challengeproperty?.competition_location_url &&
              touched?.challengeproperty?.competition_location_url
                ? Styles.inputError
                : ''
            }
          />
          <span className="float_right">{prizeTextCharacters} characters left</span>
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-basics-tab.challenge-location')}</h4>
        </div>
        <div className={Styles.formGroup}>
          <div className={Styles.location}>
            <div className={Styles.checkBoxInputLocation}>
              <div className={Styles.radioFormGroup}>
                <Input
                  type="radio"
                  id="remote"
                  className={Styles.radioButton}
                  name="challengeproperty.competition_location_type"
                  value={1}
                  checked={challengeLocation == 1}
                  onChange={onLocationChange}
                />
                <label htmlFor="remote">{t('odp-ad.challenge.create-basics-tab.remote')}</label>
              </div>
              <div className={Styles.radioFormGroup}>
                <Input
                  type="radio"
                  id="inPerson"
                  className={Styles.radioButton}
                  name="challengeproperty.competition_location_type"
                  checked={challengeLocation == 2}
                  value={2}
                  onChange={onLocationChange}
                />
                <label htmlFor="inPerson">
                  {t('odp-ad.challenge.create-basics-tab.in-person')}
                </label>
              </div>
              {/* <span>{t('odp-ad.challenge.create-basics-tab.location-name')}</span>
              <Input
                name="challengeproperty.competition_location_name"
                placeholder="e.g. University of Milan"
                disabled={challengeLocation == 1}
                maxLength={CHAR_SIZE}
                className={
                  (errors?.challengeproperty?.competition_location_name && touched?.challengeproperty?.competition_location_name) && Styles.inputError
                }
                onChange={(e) => {
                  setLocationNameCharacters(CHAR_SIZE - e.target.value.length);
                }}
              />
              <span className="text-right">
                {locationNameCharacters} characters left
              </span> */}
            </div>
            {/* <div className={Styles.checkBoxInputLocation}>
              <span>{t('odp-ad.challenge.create-basics-tab.-location-address')}</span>
              <Input
                name="challengeproperty.competition_location_address"
                disabled={challengeLocation == 1}
                maxLength={CHAR_SIZE}
                placeholder="e.g. Via Roma 1, Milan"
                className={
                  errors?.challengeproperty?.competition_location_address &&
                  touched?.challengeproperty?.competition_location_address &&
                  Styles.inputError
                }
                onChange={(e) => {
                  setLocationAddressCharacters(CHAR_SIZE - e.target.value.length);
                }}
              />
              <span className="text-right">{locationAddressCharacters} characters left</span>
            </div> */}
          </div>
        </div>
        <span className="error-text"></span>
      </div>
    </div>
  );
};

export default Basic;
