import React from "react";
import { SButton } from "../../theme/button";
import "./ourServices.scss";

const OurServices = ({
  text,
  title,
  titleOne,
  titleTwo,
  subtitle,
  subtitleOne,
  subtitleTwo,
  description,
  descriptionOne,
  descriptionTwo,
  label,
  paragraph,
  openPage,
}) => {
  return (
    <div className="ourServices__wrapper">
      <div className="ourServices__container">
        <h1 className="headingOneServices heading">{title}</h1>
        <h1 className="headingOneServices heading1">{titleOne}</h1>
        <h1 className="headingOneServices heading2">{titleTwo}</h1>
        <p className="paragraphOneServices paragraph">{description}</p>
        <p className="paragraphOneServices paragraph1">{descriptionOne}</p>
        <p className="paragraphOneServices paragraph2">{descriptionTwo}</p>
        <h3 className="headingTwoServices headingtwo">{subtitle}</h3>
        <h3 className="headingTwoServices headingtwo1">{subtitleOne}</h3>
        <h3 className="headingTwoServices headingtwo2">{subtitleTwo}</h3>
        <div className="ourServices__cards">
          {text.cards.map((card) => (
            <div className="ourServices__card" key={card.id}>
              <img src={card.title} alt={card.id} className="cardLogo" />
              <p className="card__description">{card.description}</p>
              <p className="card__description1">{card.description1}</p>
            </div>
          ))}
        </div>
        <div className="ourServices__cards1">
          {text.cards1.map((card) => (
            <div className="ourServices__card1" key={card.id}>
              <img src={card.title} alt={card.id} className="cardLogo" />
              <p className="card__description">{card.description}</p>
              <p className="card__description1">{card.description1}</p>
            </div>
          ))}
        </div>
        <div className="ourServices__cards2">
          {text.cards2.map((card) => (
            <div className="ourServices__card2" key={card.id}>
              <img src={card.title} alt={card.id} className="cardLogo" />
              <p className="card__description">{card.description}</p>
              <p className="card__description1">{card.description1}</p>
            </div>
          ))}
        </div>
        <p className="paragraphTwoServices">{paragraph}</p>
        <SButton
          type="regular"
          label={label}
          id="button__light"
          onClick={openPage}
        />
      </div>
    </div>
  );
};

export default OurServices;
