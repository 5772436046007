import HeroSection from '../../components/HeroSection/heroSection';
import OurEvents from '../../components/OurEvents/ourEvents';
import OurServices from '../../components/OurServices/ourServices';
import QuotesSection from '../../components/QuotesSection/quotesSection';
import { useTranslation } from 'react-i18next';
import Sponsors from '../../components/Sponsors/sponsors';
import TheAdvantages from '../../components/TheAdvantages/theAdvantages';
import sponnsor1 from '../../asset/images/sponsors-logo/nexi.jpg';
import sponnsor2 from '../../asset/images/sponsors-logo/sap.jpg';
import sponnsor3 from '../../asset/images/sponsors-logo/illimity.jpg';
import sponnsor4 from '../../asset/images/sponsors-logo/axa.jpg';
import sponnsor5 from '../../asset/images/sponsors-logo/credimi.jpg';
import sponnsor6 from '../../asset/images/sponsors-logo/osn.jpg';
import sponnsor7 from '../../asset/images/sponsors-logo/docebo.jpg';
import sponnsor8 from '../../asset/images/sponsors-logo/rgi.jpg';
import sponnsor9 from '../../asset/images/sponsors-logo/cuebiq.jpg';
import sponnsor10 from '../../asset/images/sponsors-logo/cellularline.jpg';
import sponnsor11 from '../../asset/images/sponsors-logo/conTe.jpg';
import sponnsor12 from '../../asset/images/sponsors-logo/venchi.jpg';
import sponnsor13 from '../../asset/images/sponsors-logo/minsait.jpg';
import sponnsor14 from '../../asset/images/sponsors-logo/bee.jpg';
import svg1 from '../../asset/icons/static-pages-svgs/18.svg';
import svg2 from '../../asset/icons/static-pages-svgs/15.svg';
import svg3 from '../../asset/icons/static-pages-svgs/12.svg';
import svg4 from '../../asset/icons/static-pages-svgs/17.svg';
import svg5 from '../../asset/icons/static-pages-svgs/14.svg';
import svg6 from '../../asset/icons/static-pages-svgs/13.svg';
import svg7 from '../../asset/icons/static-pages-svgs/10.svg';
import svg8 from '../../asset/icons/static-pages-svgs/9.svg';
import svg9 from '../../asset/icons/static-pages-svgs/11.svg';
import svg10 from '../../asset/icons/static-pages-svgs/7.svg';
import svg11 from '../../asset/icons/static-pages-svgs/8.svg';
import svg12 from '../../asset/icons/static-pages-svgs/6.svg';
import svg13 from '../../asset/icons/static-pages-svgs/3.svg';
import svg14 from '../../asset/icons/static-pages-svgs/2.svg';
import svg15 from '../../asset/icons/static-pages-svgs/1.svg';

import './companies.scss';
import Contact from '../Contact/Contact';

const openPage = (e) => {
  e.preventDefault();
  window.open('/contact-us', '_self');
};

const Companies = () => {
  const { t } = useTranslation();

  const text = {
    quotesDescription1: `${t('odp-pu.for-companies.hiring-the-right-profiles-for-your-compa')}`,
    quotesDescription2: `${t('odp-pu.for-companies.thanks-to-the-solutions')}`,
    quotesDescription3: `${t('odp-pu.for-companies.thanks-to-the-success')}`,
    cards: [
      {
        id: 1,
        title: svg1,
        description: `${t('odp-pu.for-companies.organize-recruitment-competitions-to-att')}`
      },
      {
        id: 2,
        title: svg2,
        description: `${t('odp-pu.for-companies.design-graduate-programs-to-hire-the-bri')}`
      },
      {
        id: 3,
        title: svg3,
        description: `${t('odp-pu.for-companies.get-to-grips-with-a-highly-specialized-h')}`
      },
      {
        id: 4,
        title: svg4,
        description: `${t('odp-pu.for-companies.organize-events-at-universities-higher-')}`
      },
      {
        id: 5,
        title: svg5,
        description: `${t('odp-pu.for-companies.define-open-innovation-and-corporate-soc')}`
      },
      {
        id: 6,
        title: svg6,
        description: `${t('odp-pu.for-companies.improve-the-employer-brand-proposition-')}`
      }
    ],
    cards1: [
      {
        id: 1,
        title: svg7,
        description: `${t('odp-pu.for-companies.hire-the-best-italian-talents-at-nationa')}`
      },
      {
        id: 2,
        title: svg8,
        description: `${t('odp-pu.for-companies.use-open-innovation-to-solve-business-pr')}`
      },
      {
        id: 3,
        title: svg9,
        description: `${t('odp-pu.for-companies.improve-your-brand-attraction-among-the-')}`
      },
      {
        id: 4,
        title: svg10,
        description: `${t('odp-pu.for-companies.create-internal-training-courses-for-you')}`
      },
      {
        id: 5,
        title: svg11,
        description: `${t('odp-pu.for-companies.develop-re-skilling-and-up-skilling-prog')}`
      },
      {
        id: 6,
        title: svg12,
        description: `${t('odp-pu.for-companies.make-your-business-data-available-to-sol')}`
      }
    ],
    cards2: [
      {
        id: 1,
        title: svg13,
        description: `${t('odp-pu.for-companies.once-in-contact-with-the-open-data-playg')}`
        // description1: `${t("")}`,
        //   "You will have a customizable platform that will adapt to your needs, whether you have to manage hundreds of positions or just a few high value-added projects.",
      },
      {
        id: 2,
        title: svg14,
        description: `${t('odp-pu.for-companies.once-the-event-is-created-you-can-share')}`
        // description1: `${t("")}`,
        //   "With highly personalized and tailor-made challenges you can accelerate the recruitment process, concretely test the skills of candidates and find the best person for your team.",
      },
      {
        id: 3,
        title: svg15,
        description: `${t('odp-pu.for-companies.the-competing-talents-will-then-be-invol')}`
      }
    ],
    theAdvantages: [
      {
        id: 1,
        title: `${t('odp-pu.for-companies.attract-the-best-talent-on-the-market')}`,
        description: `${t('odp-pu.for-companies.engage-highly-skilled-and-creative-talen')}`
      },
      {
        id: 2,
        title: `${t('odp-pu.for-companies.increase-your-brand-awareness')}`,
        description: `${t('odp-pu.for-companies.introduce-your-company-and-organization-')}`
      },
      {
        id: 3,
        title: `${t('odp-pu.for-companies.carry-out-csr-projects')}`,
        description: `${t('odp-pu.for-companies.organize-hackathons-focused-on-innovatio')}`
      },
      {
        id: 4,
        title: `${t('odp-pu.for-companies.innovate-your-company')}`,
        description: `${t('odp-pu.for-companies.with-internal-hackathons-your-employees-')}`
      }
    ],
    sponsors: [
      {
        id: 1,
        image: sponnsor1
      },
      {
        id: 2,
        image: sponnsor2
      },
      {
        id: 3,
        image: sponnsor3
      },
      {
        id: 4,
        image: sponnsor4
      },
      {
        id: 5,
        image: sponnsor5
      },
      {
        id: 6,
        image: sponnsor6
      },
      {
        id: 7,
        image: sponnsor7
      },
      {
        id: 8,
        image: sponnsor8
      },
      {
        id: 9,
        image: sponnsor9
      },
      {
        id: 10,
        image: sponnsor10
      },
      {
        id: 11,
        image: sponnsor11
      },
      {
        id: 12,
        image: sponnsor12
      },
      {
        id: 13,
        image: sponnsor13
      },
      {
        id: 14,
        image: sponnsor14
      }
    ]
  };

  return (
    <div className="development__wrapper">
      <div className="companies__herosection">
        <HeroSection
          title={t('odp-pu.for-companies.are-you-an-innovative-company-')}
          subTitle={t('odp-pu.for-companies.organize-a-challenge-in-open-data-playgr')}
          descriptiopnOne={t('odp-pu.for-companies.engage-with-the-largest-community-of-pro')}
          label={t('odp-pu.for-companies.contact-us')}
          openPage={openPage}
        />
      </div>
      <div className="companies__ourServices">
        <OurServices
          text={text}
          title={t('odp-pu.for-companies.attract-engage-and-hire-the-profile-th')}
          subtitle={t('odp-pu.for-companies.with-open-data-playground-you-can-')}
          description={t('odp-pu.for-companies.thanks-to-open-data-playground-you-can-r')}
        />
      </div>
      <OurEvents
        title={t('odp-pu.for-companies.our-events')}
        description={t('odp-pu.for-companies.discover-live-events-organized-by-our-cu')}
      />
      <QuotesSection
        text={text}
        title={t('odp-pu.for-companies.why-organize-a-challenge-on-open-data-pl')}
        quote={t('odp-pu.for-companies.the-mismatch-between-job-supply-and-dema')}
        author={t('odp-pu.for-companies.source-unioncamere---anpal-excelsior-i')}
      />
      <div className="companies__allowsyou">
        <OurServices
          text={text}
          subtitle={t('odp-pu.for-companies.open-data-playground-allows-you-to-')}
        />
      </div>
      <div className="companies__organizechallenge">
        <OurServices
          text={text}
          titleTwo={t('odp-pu.for-companies.how-to-organize-a-challenge-on-open-data')}
        />
      </div>
      <TheAdvantages text={text} title={t('odp-pu.for-companies.the-advantages')} />
      <div className="companies__sponsors">
        <Sponsors
          details={text}
          title={t('odp-pu.for-companies.our-sponsors')}
          description={t('odp-pu.for-companies.find-the-different-entities-that-believe')}
        />
      </div>
      <div className="companies__contactWrapper">
        <Contact description={t('odp-pu.for-companies.enter-your-contact-details-in-the-form-')} />
      </div>
    </div>
  );
};

export default Companies;
