import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { notify } from '../../theme/antNotify';
import { failure, request, success } from "../../utils/fetch";
import { GET_PROTECTED_FILE, SAVE_PROTECTED_FILE } from '../actions/protectedFileAction';
import { getProtectedFile, saveProtectedFile } from '../services/File';
import { getLookupListings } from '../services/Lookup';

function* saveProtectFileSaga(requestPayload) {
    try {
        yield put({ type: request(SAVE_PROTECTED_FILE) });
        const response = yield saveProtectedFile(requestPayload);
        yield put({ type: success(SAVE_PROTECTED_FILE), response });
    } catch (error) {

        yield put({ type: failure(SAVE_PROTECTED_FILE), error })
    }
}

function* getProtectedFileSaga({ request: paramsValue }) {
    try {
        yield put({ type: request(GET_PROTECTED_FILE) });
        const response = yield getProtectedFile(paramsValue);
        response.fileType = paramsValue.fileType;
        yield put({ type: success(GET_PROTECTED_FILE), response })
    } catch (error) {
        yield put({ type: failure(GET_PROTECTED_FILE), error })
    }
}
export default [
    takeLatest(SAVE_PROTECTED_FILE, saveProtectFileSaga),
    takeLatest(GET_PROTECTED_FILE, getProtectedFileSaga),
];
