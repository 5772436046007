import React from "react";
import ContactForm from "../Contact/ContactForm/ContactForm";
import { useTranslation } from "react-i18next";

import "./TechnicalSupport.scss";

const TechnicalSupport = () => {
  const { t } = useTranslation();

  return (
    <div className="development__wrapper">
      <div className="heading__wrapper">
        <h1>{t("odp-pu.technical-support.technical-support")}</h1>
        <p>{t("odp-pu.technical-support.ask-for-technical-support")}</p>
      </div>
      <ContactForm queryType={2} />
    </div>
  );
};

export default TechnicalSupport;
