import { isArray } from "@craco/craco/lib/utils";
import { notification } from "antd";
export const notify = (type, message = "message", description) => {
  let descArray = description && description.includes(',') && description.split(',');
  
  notification[type]({
    message,
    description: (
      description && <>
        {isArray(descArray) ? descArray.map(desc => (
          <p className="p-0 m-0">{desc}.</p>
        )) : <p className="p-0 m-0">{description}.</p>}
      </>
    ),
  });
};
