import { combineReducers } from "redux";
import { LoginReducer, SignUpReducer } from "./authenticationReducer";
import {
  ChallengeMenu,
  ChallengeReducers,
  challengeSubmission,
  challengeSubmissionHistories,
  createChallengeReducer,
  JoinChallengeReducer,
  LeaderBoardListingsReducers,
  LeaderBoardReducers,
  UpdateChallengeReducer,
  UsersListingsReducers,
  userChallengeReducer,
  yourChallengesReducer,
  publishChallenge,
  closeChallengeSaga,
  participantsListingsSaga,
  downloadParticipantReducer,
  updateErrorTab,
} from "./ChallengeReducer";
// import login from '../../store/auth/userSlice';
import signup from "../../store/auth/signupSlice";
import userProfile from "../../store/profile";
import countries from "../../store/profile/countries";
import FileUpload from "../../store/fileupload";
import Team from "../../store/team";
import skills from "../../store/skills";
import {
  competitionCategoriesReducer,
  competitionTypesReducer,
  competitionParticipationTypesReducer,
  scoringMetricReducer,
} from "./lookUpReducer";
import Notification from "./notificationReducer";
import AccountSettings from "./accountSettings";
import { createProtectedFileReducer } from "./protectedFile";
import {
  displayNameReducer,
  downloadDataReducer,
  personalProfileReducer,
  userReducer,
} from "./userReducer";
import { teamReducer } from "./TeamReducer";
import { USER_LOGGED_OUT } from "../actions/userAction";
import { utilsReducer } from "./utilsReducer";
import { SponsorReducers } from "./sponsorReducer";
import { updateProtectedFileUploadStatus } from "./protectedFileUploadingReducer";

const appReducer = combineReducers({
  challengeMenu: ChallengeMenu,
  challenge: ChallengeReducers,
  authentication: LoginReducer,
  competitionTypes: competitionTypesReducer,
  competitionCategories: competitionCategoriesReducer,
  participationTypes: competitionParticipationTypesReducer,
  challengePayload: UpdateChallengeReducer,
  createChallenge: createChallengeReducer,
  scoringMetrics: scoringMetricReducer,
  leaderBoard: LeaderBoardReducers,
  joinChallenge: JoinChallengeReducer,
  challengeSubmissionHistories: challengeSubmissionHistories,
  protectedFiles: createProtectedFileReducer,
  challengeSubmission: challengeSubmission,
  leaderboardListings: LeaderBoardListingsReducers,
  usersListings: UsersListingsReducers,
  publicProfile: userReducer,
  userChallenge: userChallengeReducer,
  yourChallenges: yourChallengesReducer,
  userDownloadedData: downloadDataReducer,
  teamProfile: teamReducer,
  personalProfile: personalProfileReducer,
  publishChallenge: publishChallenge,
  closedChallenge: closeChallengeSaga,
  participants: participantsListingsSaga,
  currentUserDisplayName: displayNameReducer,
  utils: utilsReducer,
  sponsors: SponsorReducers,
  challengeTabError: updateErrorTab,
  protectedFileUploadStatus: updateProtectedFileUploadStatus,
  signup,
  userProfile,
  countries,
  skills,
  FileUpload,
  Team,
  Notification,
  AccountSettings,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === USER_LOGGED_OUT) {
    state.authentication = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
