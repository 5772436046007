import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';

export class UserEducation {
    user_education_detail_id = null;
    education_name = null;
    institute_name = null;
    education_description = null;
    is_current_education = false;
    start_date = null;
    end_date = null;
    constructor() {
        this.user_education_detail_id = null;
        this.education_name = null;
        this.institute_name = null;
        this.education_description = null;
        this.is_current_education = false;
        this.start_date = null;
        this.end_date = null;
    }

    deserialize(model) {
        this.user_education_detail_id = StringConverter(model.user_education_detail_id || null);
        this.education_name = StringConverter(model.education_name);
        this.institute_name = StringConverter(model.institute_name);
        this.education_description = StringConverter(model.education_description);
        this.is_current_education = BooleanConverter(model.is_current_education);
        this.start_date = new Date(StringConverter(model.start_date)).toISOString();
        this.end_date = new Date(StringConverter(model.end_date)).toISOString();
        return this;
    }

}