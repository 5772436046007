
import './stats.scss';
import { useTranslation } from 'react-i18next';
export const Stats = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="statsContainer">
        <h1 className='main-heading'>{t('odp-pu.homepage.our-numbers')}</h1>
        <div className='row justify-content-center main-content'>
          <p className='col-md-7 text-center'>
            {t('odp-pu.homepage.established-in-beta-in-many-differ')}
          </p>
        </div>
        <div className='row justify-content-center text-center'>
          <div className='col-md-10 row justify-content-center'>
            <div className='col-md-3 col-6'>
              <h1 className='stats-count'>31</h1>
              <h6 className='stats-title'>Challenges</h6>
            </div>
            <div className='col-md-3 col-6'>
              <h1 className='stats-count'>586</h1>
              <h6 className='stats-title'>Users</h6>
            </div>
            <div className='col-md-3 col-6'>
              <h1 className='stats-count'>100+</h1>
              <h6 className='stats-title'>Teams</h6>
            </div>
            <div className='col-md-2 col-6'>
              <h1 className='stats-count'>29</h1>
              <h6 className='stats-title'>Sponsors</h6>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default Stats;
