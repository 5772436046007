import Card from "./card";
import Header from "./header";

import TeamMembers from "./teamMembers/";
import TeamChallanges from "./teamChallanges";
import "./teamComponent.scss";
const Teams = ({ allTeams }) => {
  return (
    <div className="single__Team">
      <Header totalTeams={allTeams.length} />
      {allTeams.map((team, index) => {
        return (
          <div
            key={team.team_id + "card" + team.team_name}
            className="team__vieer_wrapper"
          >
            <Card {...team} key={team.team_id} />
            <TeamMembers
              {...team}
              key={team.team_id + "member" + team.team_name}
              index={index}
            />
            <TeamChallanges
              {...team}
              key={team.team_id + "challange" + team.team_name}
              index={index}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Teams;
