import { createSlice } from "@reduxjs/toolkit";
import { loading } from "../../helpers/loading";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
const initialState = {
  loading: loading.INIT,
  error: null,
  /* user profile object*/
  user: null,
  avatar_url: null,
  team_avatar_url: null,
  /*full loading and updating varible*/
  userUpdateloading: loading.INIT,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    /*get token varify reducers*/
    getProfileTokenStart: (state) => {
      state.loading = loading.PENDING;
    },
    getProfileTokenComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
    },
    getProfileTokenError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },

    /*store fileID of CV*/

    SaveFileIdOfCVFile: (state, { payload }) => {
      state.user.cv_file_id = payload.fileID;
    },

    /*update profile image id by api file_id*/
    updateProfileImageId: (state, { payload }) => {
      state.avatar_url = payload.fileID;
      state.user.userProfile.avatar_url = payload.fileID;
    },
    /*update profile image id by api file_id*/
    updateTeamProfileImageId: (state, { payload }) => {
      // state.team_avatar_url = payload.fileID;
      state.team_avatar_url = payload.fileID;
    },
    /*get user profile varify reducers*/
    getProfileStart: (state) => {
      state.loading = loading.PENDING;
    },
    getProfileComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.user = action.payload;
    },
    getProfileError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },

    /*localy change store in new ski add*/
    updateStoreSkills: (state, action) => {
      state.user.userSkillSets = [...state.user.userSkillSets, action.payload];
    },

    /*localy change store in new ski add*/
    deleteStoreSkills: (state, action) => {
      let id = action.payload;

      let deleteSkill = (state.user.userSkillSets =
        state.user.userSkillSets.filter((skill) => {
          return skill.skill_set_id !== id;
        }));
      state.user.userSkillSets = [...deleteSkill];
    },
    /*localy change store in new ski add*/
    updatePersonalInfo: (state, { payload }) => {
      let { display_name, country_id, organization, occupation, city } =
        payload.userProfile;

      state.user.userProfile.display_name = display_name;
      state.user.userProfile.country_id = country_id;
      state.user.userProfile.organization = organization;
      state.user.userProfile.occupation = occupation;
      state.user.userProfile.city = city;
    },

    updateLinkedInStatus: (state, { payload }) => {
      let { linkedin_verification } = payload

      state.user.is_linkedin_verified = linkedin_verification;
    },

    /*localy change store in new about add*/
    updateAboutInfo: (state, { payload }) => {
      state.user.userProfile.about = payload.data.about;
      state.user.userProfile.linkedin_url = payload.data.linkedin_url;
      state.user.userProfile.github_url = payload.data.github_url;
      state.user.userProfile.twitter_url = payload.data.twitter_url;
      state.user.userProfile.link_url = payload.data.link_url;
    },
    /*localy change store in new preivate add*/

    updatePrivateInfo: (state, { payload }) => {
      state.user.userProfile.first_name = payload.data.first_name;
      state.user.userProfile.middle_name = payload.data.middle_name;
      state.user.userProfile.mobile = payload.data.mobile;
      state.user.userProfile.date_of_birth = payload.data.date_of_birth;
      state.user.userProfile.gender = payload.data.gender;
    },
    /*localy change store in new about add*/
    addNewEmptyWorkExperince: (state, { payload }) => {
      let newWorkObject = {
        order: state.user.userExperienceDetails.length + 1,
        user_experience_detail_id: null,
        company_name: " ",
        job_title: " ",
        start_date: moment(),
        end_date: moment(),
        job_description: null,
        is_current_job: false,
        job_location_id: null,
      };
      state.user.userExperienceDetails = [
        ...state.user.userExperienceDetails,
        newWorkObject,
      ];
    },

    /*Update userskill assessment*/
    updateUserSkillsAssesment: (state, { payload }) => {
      state.user.userSkillSets = payload.updateSkillScore;
    },

    updateWorkExperincee: (state, { payload }) => {
      state.user.userExperienceDetails = payload.updateWorkExperinceArray;
    },
    deleteEmptyWorkExperince: (state, action) => {
      state.user.userExperienceDetails = action.payload.deleteWorkExperince;
    },

    /*localy change store in new about add*/
    addNewEmptyWorkEducation: (state, { payload }) => {
      let newWorkObject = {
        user_education_detail_id: null,
        education_name: null,
        institute_name: null,
        start_date: moment(),
        end_date: moment(),
        study_description: null,
        percentage: null,
        cgpa: null,
        is_current_study: false,
      };
      state.user.userEducationDetails = [
        ...state.user.userEducationDetails,
        newWorkObject,
      ];
    },

    deleteEmptyWorkEducation: (state, { payload }) => {
      state.user.userEducationDetails = payload.deleteWorkEducation;
    },

    updateWorkEducatione: (state, action) => {
      state.user.userEducationDetails = action.payload.updateWorkEducationArray;
    },

    /*update user profile api set*/
    updateUserStart: (state) => {
      state.userUpdateloading = loading.PENDING;
    },
    updateUserComplete: (state) => {
      state.userUpdateloading = loading.SUCSUSS;
    },
    updateUserError: (state) => {
      state.userUpdateloading = loading.ERROR;
    },

    /*update visibility*/

    updateManageVisibilty: (state, { payload }) => {
      state.user.userProfile.manage_visibility = payload;
    },

    /*update OpenToWork*/
    updateIsOpenToWork: (state, { payload }) => {
      state.user.userProfile.is_open_to_work = payload;
    },

    /*logout*/
    logout: (state) => {
      state.user = null;
    },
  },

  extraReducers: {},
});

export const {
  getProfileTokenStart,
  getProfileTokenComplete,
  getProfileTokenError,
  getProfileStart,
  getProfileComplete,
  getProfileError,
  updateStoreSkills,
  updateLinkedInStatus,

  deleteStoreSkills,
  updatePersonalInfo,
  updateAboutInfo,

  /*update image file_id*/
  updateProfileImageId,
  updateTeamProfileImageId,


  /*actions for user work experince*/
  addNewEmptyWorkExperince,
  deleteEmptyWorkExperince,
  updateWorkExperincee,

  /*actions for user education*/
  addNewEmptyWorkEducation,
  deleteEmptyWorkEducation,
  updateWorkEducatione,

  /*update user whole*/

  updateUserStart,
  updateUserComplete,
  updateUserError,

  /*update visibility*/

  updateManageVisibilty,

  /*update updateIsOpenToWork*/

  updateIsOpenToWork,

  /*update updatePrivateInfo*/

  updatePrivateInfo,

  /*store fileID of CV*/
  SaveFileIdOfCVFile,

  /*Update userskill assessment*/
  updateUserSkillsAssesment,

  /*logout*/
  logout,
} = profileSlice.actions;

export default profileSlice.reducer;
