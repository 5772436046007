import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Styles from "./usersDetails.module.scss";
import Profile from "../../../../components/profile";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Back from "../../../../components/Back";

const UsersDetails = () => {
  const { userId } = useParams();
  const [userName, setUserName] = useState(null);
  return (
    <div className={Styles.UsersDetailsContainer}>
      <div className={Styles.title}>
        <Back link={"/admin/users"} />

        <h2>Admin dashboard / Users / {userName}</h2>
        <div></div>
      </div>
      <div className={Styles.UsersDetailsContent}>
        <Profile userId={userId} isAdmin={true} setUserName={setUserName} />
      </div>
    </div>
  );
};

export default UsersDetails;
