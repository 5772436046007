import React from "react";
import Styles from "./progress.module.scss";
const Progress = ({ percentage = 0 }) => {
  return (
    <div className={Styles.progressBar}>
      <div
        className={Styles.progress}
        style={{ width: percentage + "%" }}
      ></div>
    </div>
  );
};

export default Progress;
