import React, { useState } from 'react';
import Styles from './fileDragger.module.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../Button';
import Progress from '../ProgressBar';
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000000' }} spin />;

const FileDragger = ({
  buttonText,
  fileExtensions,
  fileSize,
  isImage = false,
  imageDimensions,
  style,
  onFileDrop,
  selectedFile,
  removeFile,
  id,
  loading,
  downloadFile,
  label = 'Select',
  error,
  showProgress = false,
  progress
}) => {
  const [dragOver, setDragOver] = useState(false);
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    if (e) {
      setDragOver(true);
    }
  };

  const dragLeave = (e) => {
    e.preventDefault();
    if (e) {
      setDragOver(false);
    }
  };
  return (
    <div
      className={Styles.imagePickerBox}
      onDragOver={onDragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={onFileDrop}
      style={{
        backgroundImage: 'url(' + process.env.REACT_APP_RESOURCES_URL + selectedFile + ')',
        ...style
      }}>
      {(!selectedFile || error) && (
        <>
          <div className={`file-selector text-center btn btn-secondary ${Styles.btnSelect}`}>
            <input
              id={id}
              type="file"
              className={`${Styles.customFileInput} d-none`}
              onChange={onFileDrop}
            />
            {!loading ? (
              <label htmlFor={id}>
                <i className="fa fa-cloud-upload"></i> {label}
              </label>
            ) : (
              <Spin indicator={antIcon} />
            )}
          </div>

          <p>
            {!dragOver && <span>Or drag and </span>}
            <span>drop your file</span>
          </p>
          <span>
            {fileExtensions} file, up to {fileSize}
          </span>
          {isImage && <span>{imageDimensions}</span>}
        </>
      )}
      {!isImage && selectedFile ? (
        selectedFile?.name ? (
          <>
            {!loading && !error && (
              <>
                <p>{selectedFile?.name}</p>
                <p>
                  <span>{selectedFile?.size / 1000} kb</span>
                </p>
              </>
            )}
            {loading && <Spin indicator={antIcon} />}

            {showProgress && loading && progress > 0 && (
              <div style={{ width: '500px', marginTop: '40px' }}>
                <Progress percentage={progress} />
              </div>
            )}
          </>
        ) : !loading ? (
          <Button as="link" onClick={downloadFile}>
            Download File
          </Button>
        ) : (
          <Spin indicator={antIcon} />
        )
      ) : (
        ''
      )}
      {isImage && selectedFile && (
        <button className={`btn btn-primary ${Styles.btnRemoveImage}`} onClick={removeFile}>
          Remove Image
        </button>
      )}
      {!isImage && selectedFile && !loading && !error && (
        <button className={`btn btn-primary ${Styles.btnRemoveImage}`} onClick={removeFile}>
          Remove File
        </button>
      )}
    </div>
  );
};

export default FileDragger;
