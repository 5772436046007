import axios from "axios";
import configs from "./configs";
import { clearStorage, getLanguage, getToken } from "../services/token";
import { notify } from "../theme/antNotify";
import history from "../utils/history";
import subscribeNotifications from "../utils/subscribeNotifications";
import { store } from "../store";
import { clearStoreOnLogout } from "../redux-store/actions/userAction";
import { logout } from "../store/profile";

export const axiosInstanceWithoutAuth = axios.create({
  baseURL: configs.BASE_URL,
});

export const axiosInstance = axios.create({
  baseURL: configs.BASE_URL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = getToken()
      ? `Bearer ${getToken()}`
      : configs.BASIC_AUTH;
    config.headers["Accept-Language"] = getLanguage() || "en-US";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.data.statusCode === 2051 ||
      error.response.data.statusCode === 2052
    ) {
      clearStorage();
      const notification = new subscribeNotifications();
      notification.clear();
      history.push("/login");
      store.dispatch(logout());
      store.dispatch(clearStoreOnLogout());
    } else {

      let statusPhrase = error.response.data.statusPhrase
      if (typeof statusPhrase == 'object') {
        if (statusPhrase.name == 'UniqueViolationError') {
          let uniqueField = statusPhrase.columns[0];
          notify(
            "error",
            uniqueField.replace('_', ' ') + ' already exist'
          )
        }
      } else {
        if (error.response?.config?.responseType !== 'blob') {
          notify(
            "error",
            statusPhrase,
            `${(error.response.data.errors && error.response.data.errors.length > 0) ? error.response.data.errors : ''}`
          );
        }
      }
      if (error.response?.config?.responseType === 'blob') {

        const reader = new FileReader();
        reader.readAsText(error.response.data);
        await new Promise((resolve) => reader.onload = resolve);
        const errorResponse = JSON.parse(reader.result);
        console.log('errorResponse', errorResponse)

        if (errorResponse?.statusCode === 2051 || errorResponse?.statusCode === 2052) {

          const adminAsset = localStorage.getItem('_admin_asset');
          clearStorage();
          const notification = new subscribeNotifications();
          notification.clear();
          history.push("/login");
          store.dispatch(logout());
          store.dispatch(clearStoreOnLogout());
          if (adminAsset) {
            localStorage.setItem('admin_asset', adminAsset);
          }
        }

        notify(
          "error",
          errorResponse?.statusPhrase
        );
        throw errorResponse;
      }
      throw error.response.data;
    }
  }
);

axiosInstanceWithoutAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (
      error.response.data.statusCode === 2051 ||
      error.response.data.statusCode === 2052
    ) {
      clearStorage();
      const notification = new subscribeNotifications();
      notification.clear();
      history.push("/login");
    } else {

      notify(
        "error",
        error.response.data.statusPhrase,
        `${(error.response.data.errors && error.response.data.errors.length > 0) ? error.response.data.errors : ''}`
      );
    }
  }
);
