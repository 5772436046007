import ProfileRoutes from '../../components/profile/profileRoutes/';
import './profile.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
const Index = () => {
  let { push } = useHistory();
  let { user_id } = useParams();
  useEffect(() => {
    push(`/profile/${user_id}/profile`);
    // push(`/profile/${user_id}/team`);
    // push(`/profile/${user_id}/team/create-team`);
    // push(`/profile/${user_id}/challange`);
  }, []);
  return (
    <div className="profile__wrapper">
      <ProfileRoutes />
    </div>
  );
};

export default Index;
