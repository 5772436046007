import { NumberConverter, StringConverter } from ".";

export class PaginationModel {
  totalPages = 0;
  pageNum = 0;
  currentPage = 0;
  pageSize = 0;
  totalResults = 0;
  sortBy = "";
  sortOrder = "";
  fitler = "";

  constructor(data = {}) {
    this.totalPages = NumberConverter(data.totalPages || 1);
    this.currentPage = NumberConverter(data.currentPage || 1);
    this.pageNum = NumberConverter(data.pageNum || this.currentPage);
    this.pageSize = NumberConverter(data.pageSize || 20);
    this.totalResults = NumberConverter(data.totalResults || 0);
    this.sortBy = StringConverter(data.sortBy || "");
    this.sortOrder = StringConverter(data.sortOrder || "asc");
    this.fitler = StringConverter(data.fitler || "");
  }
}

export const buildParams = (filters) => {
  let queryParams = {};

  if (filters) {
    if (filters.searchText && filters.searchText != "") {
      if (filters.searchBy != "") queryParams.search_key = filters.searchBy;
      queryParams.search_value = filters.searchText;
    }

    if (filters.challengeId) {
      queryParams.challengeId = filters.challengeId;
    }

    if (filters.display_name) {
      queryParams.display_name = filters.display_name;
    }

    if (filters.is_my_company) {
      queryParams.is_my_company = filters.is_my_company;
    }

    if (filters.user_type_name) {
      queryParams.user_type_name = filters.user_type_name;
    }

    if (filters.company_id) {
      queryParams.company_id = filters.company_id;
    }
    if (filters.company_ids) {
      queryParams.company_ids = filters.company_ids;
    }

    if (filters.challengeStatus && filters.challengeStatus.length > 0) {
      queryParams.competition_statuses = filters.challengeStatus.join(",");
    }

    if (filters.advFilters) {
      const { accessibility, location, status, subject, target, type } =
        filters.advFilters;
      if (accessibility.length > 0) {
        queryParams.participation_types = accessibility.join(",");
      }
      if (location.length > 0) {
        queryParams.competition_location_types = location.join(",");
      }
      if (status.length > 0) {
        queryParams.competition_statuses = status.join(",");
      }
      if (subject.length > 0) {
        queryParams.competition_category_ids = subject.join(",");
      }
      if (target.length > 0) {
        queryParams.complexity = target.join(",");
      }
      if (type.length > 0) {
        queryParams.competition_type_ids = type.join(",");
      }
    }


    if (filters.pagination) {
      queryParams.pageNum = filters.pagination.pageNum;
      queryParams.pageSize = filters.pagination.pageSize;
    }

    if (filters.sortBy) {
      queryParams.order_field = filters.orderField;
      queryParams.order_direction = filters.sortBy || "desc";
    }
  }
  return queryParams;
};
