import React from 'react';
import Styles from './table.module.scss';
const TableHead = ({ children }) => {
  return (
    <>
      <thead className={Styles.tableHead}>
        <tr>
          {children}
        </tr>
      </thead>
    </>
  );
};

export default TableHead;
