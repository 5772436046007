import en from '../asset/locales/en-US/translation.json';
import fr from '../asset/locales/it-IT/translation.json';
import it from '../asset/locales/it-IT/translation.json';

export const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
    it: {
        translation: it
    }
}