import { createSlice } from "@reduxjs/toolkit";
import { loading } from "../../helpers/loading";

const initialState = {
  notifications: [],
  loading: loading.INIT,
  error: {},
};

export const notificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.loading = loading.PENDING;
    },
    fetchComplete: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.notifications = action.payload;
    },
    fetchError: (state, action) => {
      state.loading = loading.ERROR;
      state.error = action.payload;
    },
  },
});

export const { fetchStart, fetchComplete, fetchError } =
  notificationSlice.actions;

export default notificationSlice.reducer;
