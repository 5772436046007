import React from "react";
import ContactForm from "./ContactForm/ContactForm";
import { LinkedinFilled, YoutubeFilled, InstagramFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "./contact.scss";
import { INSTAGRAM_URL, LINKED_IN_URL, YOU_TUBE_URL } from "../../utils/constants";

const Contact = ({ description }) => {
  const { t } = useTranslation();

  return (
    <div className="development__wrapper">
      <div className="heading__wrapper">
        <h1>{t("odp-pu.contact.contact-us")}</h1>
        <p className="default__text">
          {t("odp-pu.contact.contact-us-at-customer-service-or-our-so")}
        </p>
        <p className="dynamic__text">{description}</p>
      </div>
      <div className="address__wrapper">
        <p>Open Search Tech Srl - Innovative Startup</p>
        <p>Via G. Nicotera 7</p>
        <p>00195, Rome - Italy</p>
        <div className="d-flex">
          <a href={`https://${localStorage.getItem('currentLanguage') == 'en-US' ? 'www' : 'it'
            }.${LINKED_IN_URL}`} target={"_blank"} className="linkedIn-link"><LinkedinFilled className="linkedin__svg" /></a>
          <a href={YOU_TUBE_URL} target={"_blank"} className="linkedIn-link ms-2"><YoutubeFilled className="linkedin__svg" /></a>
          <a href={INSTAGRAM_URL} target={"_blank"} className="linkedIn-link ms-2"><InstagramFilled className="linkedin__svg" /></a>
        </div>
      </div>
      <ContactForm queryType={1} />
    </div >
  );
};

export default Contact;
