import { LinkedinFilled, YoutubeFilled, YoutubeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TextLogo from '../../asset/images/footer/text-logo.svg';
import DigitalLogo from '../../asset/images/footer/digital-magics-logo-bianco.png';
import InnovaLogo from '../../asset/images/footer/lazio-innova-logo-bianco.png';
import Banner from '../../asset/images/footer/banner.jpg';
import Youtube from '../../asset/icons/youtube.svg';
import Instagram from '../../asset/icons/instagram.svg';
import './footer.scss';
import { useIsMobile } from '../../hooks/customHooks';
import Icon from '../Icons';
import { INSTAGRAM_URL, LINKED_IN_URL, YOU_TUBE_URL } from '../../utils/constants';

const Index = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  (function (w, d) {
    var loader = function () {
      var s = d.createElement('script'),
        tag = d.getElementsByTagName('script')[0];
      s.src = 'https://cdn.iubenda.com/iubenda.js';
      tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
      w.addEventListener('load', loader, false);
    } else if (w.attachEvent) {
      w.attachEvent('onload', loader);
    } else {
      w.onload = loader;
    }
  })(window, document);

  const bannerLogo = (
    <div className="footer-banner-logo row">
      <div className="col-6">
        <img src={DigitalLogo} alt={DigitalLogo} className="footer__digital__logo" />
        <img src={InnovaLogo} alt={InnovaLogo} className="footer__digital__logo" />
      </div>
      <div className="banner-container col-6">
        <img src={Banner} className="banner" />
        <div>
          <p>Open Data Playground</p>
          <p>FESR Fondo Europeo di Sviluppo Regionale Programma Operativo regionale del Lazio</p>
          <p>Platform and HR analytics development</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="footer__wrapper">
      <footer className="footer">
        <div className="footer__inner">
          <div className="left__footer">
            <img src={TextLogo} alt={TextLogo} className="footer__text__logo" />
            <div className="pt-3">
              <a className="email" href="mailto:info@opendataplayground.com">
                info@opendataplayground.com
              </a>
            </div>
            {isMobile && bannerLogo}
          </div>
          <div className="middle__footer">
            <div className="footer__links">
              <p className="footer__heading">{t('odp-pu.menu.footer.company')}</p>
              <Link to="/about" className="link">
                {t('odp-pu.menu.header.about')}
              </Link>
              <Link to="/contact-us" className="link">
                {t('odp-pu.menu.footer.contact')}
              </Link>
              <Link to="/sponsors" className="link">
                {t('odp-pu.menu.footer.our-sponsors')}
              </Link>
            </div>
            <div className="footer__links">
              <p className="footer__heading">BUSINESS</p>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/70925246/admin"
                className="link">
                {t('odp-pu.menu.footer.join-our-community')}
              </a>
              <Link to="/companies" className="link">
                {t('odp-pu.menu.footer.organize-a-challenge')}
              </Link>
              <Link to="/educational" className="link">
                {t('odp-pu.menu.footer.train-your-students')}
              </Link>
            </div>
            <div className="footer__links">
              <p className="footer__heading   faq">
                {t('odp-pu.menu.footer.support-and-assistance')}
              </p>
              <Link to="/faq" className="link">
                FAQ
              </Link>
              <Link to="/technical-support" className="link">
                {t('odp-pu.menu.footer.technical-support')}
              </Link>
            </div>
            <div className="footer__links">
              <p className="footer__heading">{t('odp-pu.menu.footer.resources')}</p>
              <a
                href="https://journal.opendataplayground.com/"
                target="_blank"
                class="link"
                title="Privacy Policy">
                Journal
              </a>
              <a
                href="https://ec.europa.eu/regional_policy/en/funding/erdf/"
                target="_blank"
                class="link"
                title="Privacy Policy">
                European Regional Development Fund
              </a>
            </div>
          </div>
          <div className="right__footer">
            <a
              href={`https://${localStorage.getItem('currentLanguage') == 'en-US' ? 'www' : 'it'
                }.${LINKED_IN_URL}`}
              target={'_blank'}>
              <LinkedinFilled />
            </a>
            <a
              href={YOU_TUBE_URL}
              className="ms-4"
              target={'_blank'}>
              <span className="anticon">
                <Icon src={Youtube} style={{ width: '29px' }} />
              </span>
            </a>
            <a
              href={INSTAGRAM_URL}
              className="ms-4"
              target={'_blank'}>
              <span className="anticon">
                <Icon src={Instagram} style={{ width: '29px' }} />
              </span>
            </a>
          </div>
        </div>

        {!isMobile && bannerLogo}
        <hr></hr>
        <div>
          <p className="footer_lower_text">
            Open Search Tech Srl, Via G. Nicotera 7, 00195 - Roma (Italy) |{' '}
            <Link to="/privacy-policy" className="link">
              Privacy Policy
            </Link>{' '}
            |{' '}
            <a
              href="https://www.iubenda.com/privacy-policy/42858618/cookie-policy"
              class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe link"
              title="Cookie Policy">
              Cookie Policy
            </a>{' '}
            |{' '}
            <Link to="/terms-and-conditions" className="link">
              Terms & Conditions
            </Link>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Index;
