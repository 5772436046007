import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectMenuAction } from "../../redux-store/actions/ChallengeAction";
import Styles from "./challengeDetail.module.scss";
import Cover from "../../asset/images/cover.png";
import Share from "../../asset/icons/share.svg";
import BriefcaseIcon from "../../asset/images/team/card/breifcase.svg";
import LogoutIcon from "../../asset/images/team/card/logout.svg";
import TeamIcon from "../../asset/images/team/card/team.svg";
import TrophyIcon from "../../asset/images/team/card/trophy.svg";
import UserIcon from "../../asset/images/team/card/user.svg";
import TeamImg from "../../asset/images/team.jpg";
import users from "../../asset/icons/users.svg";
import onlineStatus from "../../asset/icons/online.svg";
import { Skeleton, Tooltip } from "antd";
import Button from "../Button";
import SkeletonDiv from "react-loading-skeleton";
import Progress from "../ProgressBar";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ChallengeTabItem from "./ChallengeTabItem";
import { textTrim } from "../../utils";
import skeletonImage from "../../asset/images/skeletonImage.jpg";
import Icon from "../Icons";
import Pencil from "../../asset/icons/pencil.svg";
import { useIsMobile } from "../../hooks/customHooks";
import { notify } from "../../theme/antNotify";
import { useTranslation } from "react-i18next";
const dateFormat = "YYYY-MM-DD";
const ChallengeDetail = ({
  competition_id,
  competition_name,
  is_published,
  competition_description,
  cover_image_id,
  competition_status,
  challengeproperty,
  competition_type_name,
  participant_stats,
  score,
  isLoggedIn,
  company,
  onJoinChallenge,
  subscription_status,
  isChallengeJoined,
  loading,
  participationCount,
  onUploadFile,
  subscriptionType,
  isAdmin,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const histroy = useHistory();
  const { t } = useTranslation();

  const onInputClick = (event) => {
    event.target.value = "";
    dispatch(selectMenuAction("submission"));
  };

  const routeToSponsorPreview = () => {
    company?.is_published && histroy.push(`/sponsor/${company?.company_id}`)
  }

  const { challengeId: closeChallengeId, loading: closeChallengeLoading } =
    useSelector((state) => state.closedChallenge);

  const copyURLtoClipboard = () => {
    let url = challengeproperty?.competition_location_url ? `${window.location.origin}/challenge/${challengeproperty?.competition_location_url}`
      : `${window.location.origin}/challenge/${competition_id}`
    const el = document.createElement("input");
    el.value =
      competition_id && url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notify(
      "success",
      "Challenge public URL copied to clipboard, you can share it!",
      ""
    );
  };
  return (
    <div className={Styles.challengeDetail}>
      {
        <div
          className={Styles.coverContainer}
          style={{
            background: loading
              ? "#dddddd"
              : "url(" +
              process.env.REACT_APP_RESOURCES_URL +
              cover_image_id +
              ")",
          }}
        >
          {!loading &&
            (!closeChallengeId ? (
              <div
                className={`badge rounded-pill ${Styles.challengeStatus} ${competition_status === 1
                  ? Styles.planned
                  : competition_status === 2
                    ? Styles.live
                    : competition_status === 3
                      ? Styles.under
                      : Styles.closed
                  }`}
              >
                {competition_status === 1 && <label>Planned</label>}
                {competition_status === 2 && <label>Live</label>}
                {competition_status === 3 && <label>Under Evaluation</label>}
                {competition_status === 4 && <label>Closed</label>}
              </div>
            ) : (
              <div
                className={`badge rounded-pill ${Styles.challengeStatus} ${Styles.closed}`}
              >
                <label>Closed</label>
              </div>
            ))}
          {!loading &&
            (is_published ? (
              <div className={Styles.shareIcon} onClick={copyURLtoClipboard}>
                <img src={Share} />
              </div>
            ) : (
              <Tooltip title="Challenge can't be shared, as it isn't published">
                <div className={`${Styles.shareIcon} ${Styles.disabledShare}`}>
                  <img src={Share} />
                </div>
              </Tooltip>
            ))}

          <div
            className={Styles.thumbnailImage}
            onClick={routeToSponsorPreview}
            style={{
              background: loading
                ? "#dddddd"
                : `url(${process.env.REACT_APP_RESOURCES_URL}${company?.logo_file_id})`,
              cursor: company?.is_published ? 'pointer' : ''
            }}
          ></div>
          <div className={Styles.attributesList}>
            {!loading && (
              <ul>
                <li>
                  {challengeproperty?.winning_prize && (
                    <span>
                      <img src={TrophyIcon} alt={UserIcon} />
                      {challengeproperty?.winning_prize}
                    </span>
                  )}
                </li>
                <li>
                  {challengeproperty?.participation_type > 0 && (
                    <span>
                      <img src={TeamIcon} alt={UserIcon} />
                      {challengeproperty?.participation_type === 1 &&
                        "Individual"}
                      {challengeproperty?.participation_type === 2 && "Teams"}
                      {challengeproperty?.participation_type === 3 &&
                        `${t(
                          "odp-ad.challenge.competition-rules.teams-individual"
                        )}`}
                    </span>
                  )}
                </li>
              </ul>
            )}
            {!loading && (
              <ul>
                {/* <li>
                  <span>
                    <img src={UserIcon} alt={UserIcon} />
                    Senior
                  </span>
                </li> */}
                <li>
                  <span>
                    <img src={BriefcaseIcon} alt={UserIcon} />
                    {competition_type_name}
                  </span>
                </li>
                <div className={Styles.divider}></div>
                <li>
                  <span>
                    <img src={onlineStatus} alt={UserIcon} />
                    {challengeproperty?.competition_location_type == 1 ? 'Online' : 'In person'}
                  </span>
                </li>
                <div className={Styles.divider}></div>
                <li>
                  <span>
                    <img src={users} alt={UserIcon} />
                    {participationCount || "0"}
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>
      }
      <div className={Styles.challengeTitleInfo}>
        {!loading && <p>{challengeproperty?.competition_category_name}</p>}
        <div className={Styles.title}>
          {!loading && <h2>{textTrim(competition_name, 100)}</h2>}
          <Skeleton paragraph={{ rows: 2 }} loading={loading} />
          <div className={`d-flex flex-column ${Styles.btnInfo}`}>
            {!isAdmin && !isMobile && score && (
              <div className={Styles.scoreInfo}>
                <p>
                  {score?.display_name || score?.team_name}
                  <img
                    className={Styles.teamImage}
                    src={
                      process.env.REACT_APP_RESOURCES_URL +
                      (score?.team_file_id || score?.avatar_url)
                    }
                  />
                </p>
                <p>
                  Rank Challenge: {score?.score_rank ? score?.score_rank : "--"}
                </p>
                <p>
                  Entries:{" "}
                  {score?.submission_count ? score?.submission_count : 0}
                </p>
              </div>
            )}
            {!isAdmin && !loading && (
              <div className={Styles.btnContainer}>
                {subscription_status != 2 && subscriptionType != 2 && (
                  <Button
                    className={Styles.btn}
                    onClick={onJoinChallenge}
                    disabled={
                      competition_status === 4 || competition_status === 3
                    }
                    type="accent"
                  >
                    {subscription_status == 1
                      ? t("odp-pu.challenge.login-join-challenge")
                      : subscription_status == 3 || !isChallengeJoined
                        ? t("odp-pu.challenge.join-challenge")
                        : subscription_status == 4
                          ? t("odp-pu.challenge.login-join-challenge")
                          : ""}
                  </Button>
                )}
                {(subscriptionType == 2 ||
                  isChallengeJoined ||
                  subscription_status == 2) && (
                    <div
                      className={`file-selector text-center ${Styles.btnSelect} ${competition_status !== 2 ? Styles.disabledSubmission : ""
                        }`}
                    >
                      <input
                        type="file"
                        className="custom-file-input d-none"
                        id="inputGroupFile04"
                        onChange={onUploadFile}
                        onClick={(e) => onInputClick(e)}
                        disabled={competition_status !== 2}
                      />
                      <label
                        htmlFor="inputGroupFile04"
                        style={{
                          cursor:
                            competition_status !== 2 ? "not-allowed" : "pointer",
                        }}
                      >
                        <i className="fa fa-cloud-upload"></i> {t('odp-pu.challenge.submit-solutions')}
                      </label>
                    </div>
                  )}
                {isMobile && score && (
                  <div className={Styles.scoreInfo}>
                    <p>
                      {score?.display_name || score?.team_name}
                      <img
                        className={Styles.teamImage}
                        src={
                          process.env.REACT_APP_RESOURCES_URL +
                          (score?.team_file_id || score?.avatar_url)
                        }
                      />
                    </p>
                    <p>
                      Rank Challenge:{" "}
                      {score?.score_rank ? score?.score_rank : "--"}
                    </p>
                    <p>
                      Entries:{" "}
                      {score?.submission_count ? score?.submission_count : 0}
                    </p>
                  </div>
                )}
              </div>
            )}
            {isAdmin && !loading && (
              <Button
                to={`/admin/challenge/${competition_id}`}
                as="route"
                type="secondary"
              >
                <Icon src={Pencil} /> <span>Edit Challenge</span>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.timeLine}>
        {!loading && challengeproperty?.start_date && (
          <p className={Styles.duration}>
            {moment(challengeproperty?.start_date, dateFormat).format("ll")}{" "}
            &nbsp; - &nbsp;
            {moment(challengeproperty?.end_date, dateFormat).format("ll")}
          </p>
        )}
        <Skeleton paragraph={{ rows: 1 }} loading={loading} />
        <Progress
          percentage={
            !loading ? challengeproperty?.competition_progress || 0 : 0
          }
        />
      </div>
    </div>
  );
};

export default ChallengeDetail;
