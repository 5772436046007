import { NumberConverter } from '../../utils';
export class PrizeRanking {
    position = null;
    value = null;

    constructor() {
        this.position = null;
        this.value = null;
    }

    deserialize(model) {
        this.position = NumberConverter(model.position);
        this.value = NumberConverter(model.value);
        return this;
    }
}