import React from "react";
import Style from "../HeroInfoCard/heroInfoCard.module.scss";
import Button from '../../components/Button';
const HeroCard = ({ title, paragraph, btnLabel, onClick, style = {}, Image, isSecond, children }) => {
  return (
    <div className={Style.cardContainer}>
      <div className="row" style={style}>
        <div className="col-12 col-md-6 d-flex" style={{ justifyContent: isSecond ? 'flex-end' : '' }}>
          <img src={Image} alt="Image" className={Style.infoCardImage} />
        </div>

        <div className="col-12 col-md-6">
          <h2 className={Style.title}> {title}  </h2>
          {children}
          <Button type="secondary" onClick={onClick}>{btnLabel}</Button>
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
