export const SET_SCORING_EVALUATION = "SET_SCORING_EVALUATION";
export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";

export const setScoringEvaluation = (scoringEvaluation) => ({
    type: SET_SCORING_EVALUATION,
    scoringEvaluation,
});

export const setLanguage = (language) => ({
    type: SET_CURRENT_LANGUAGE,
    language
})