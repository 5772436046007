import React from "react";
import Styles from "./profileCard.module.scss";
import { Empty, Skeleton } from "antd";
import Edit from "../../asset/icons/edit.svg";

import image from "../../asset/images/profile/profilePlaceHolder.svg";
import { useHistory } from "react-router";
import { useParams } from "react-router";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const ProfileCard = ({ user, userLoading, isDashboardView = false }) => {
  const history = useHistory();
  const { user_id } = useParams();
  const RouteToProfile = () => {
    history.push(`/profile/${user_id}/profile`);
  };
  const { t } = useTranslation();

  return (
    <div className={`row ${Styles.userCard}`}>
      {!userLoading && (
        <>
          <div className={`col-5 ${Styles.userPersonalInfo}`}>
            <img
              src={
                user?.userProfile?.avatar_url
                  ? `${process.env.REACT_APP_RESOURCES_URL}${user?.userProfile?.avatar_url}`
                  : image
              }
              className={Styles.userAvatar}
            />
            <div className={Styles.userTitle}>
              <p className={Styles.userName}>
                {user?.userProfile?.display_name}
              </p>
              <p className={Styles.userOccupation}>
                {user?.userProfile?.occupation || "-"}
              </p>
              <p>{user?.userProfile?.organization || "-"}</p>
              <div className={Styles.userAddress}>
                <p>{user?.userProfile?.city || "-"}</p>
                <p>{user?.userProfile?.country_name || "-"}</p>
              </div>
            </div>
          </div>
          <div className={`col-7 ${Styles.userSkillContainer}`}>
            <h5>{t("odp-pu.label.profile.skills")}</h5>
            {user?.skill_names?.length > 0 ? (
              <div className={Styles.userSkills}>
                {user?.skill_names.map((skill) => (
                  <label className={Styles.skill}>{skill}</label>
                ))}
              </div>
            ) : (
              "No Skills"
            )}
          </div>
        </>
      )}
      {userLoading && <Skeleton avatar paragraph={{ rows: 2 }} />}

      {isDashboardView && !userLoading && (
        <Button
          onClick={RouteToProfile}
          className={Styles.editBtn}
          type="secondary"
        >
          <img src={Edit} alt="" /> <span> Edit</span>
        </Button>
      )}
    </div>
  );
};

export default ProfileCard;
