import React, { useState, useEffect } from "react";
import Button from "../../../components/Button";
import Styles from "./challengeTab.module.scss";
import { Select, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../components/Icons";
import downArrow from "../../../asset/icons/downArrow.svg";
import bin from "../../../asset/icons/bin.svg";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { updateChallenge } from "../../../redux-store/actions/ChallengeAction";
import { useTranslation } from "react-i18next";
import { FieldArray, FastField } from "formik";
import { InputNumber, Input } from "formik-antd";
import { EDITOR_CONFIG } from "../../../utils";
const Content = ({
  values,
  setMainDescription,
  setRules,
  setEvaluation,
  setPrizeDescription,
  setParticipationMessage,
  participationMessage,
  mainDescription,
  rules,
  evaluation,
  prizeDescription,
}) => {
  const { prizeSet } = values;
  const { prizes } = prizeSet;

  const { t } = useTranslation();
  const { requestObject: challengeObject } = useSelector(
    (state) => state.challengePayload
  );

  return (
    <div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>
            {t("odp-ad.challenge.create-content.overview-main-description")}
          </h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <span>
            {t(
              "odp-ad.challenge.create-content.-describe-your-challenge-and-what-makes-"
            )}
          </span>
          <CKEditor
            editor={ClassicEditor}
            data={mainDescription}
            config={EDITOR_CONFIG}
            onChange={(event, editor) => {
              const data = editor.getData();
              setMainDescription(data);
            }}
          />
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t("odp-ad.challenge.create-content.rules")}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <span>
            {t(
              "odp-ad.challenge.create-content.-describe-the-rules-for-your-challenge.-"
            )}
          </span>
          <CKEditor
            editor={ClassicEditor}
            data={
              rules
            }
            config={EDITOR_CONFIG}
            onChange={(event, editor) => {
              const data = editor.getData();
              setRules(data);
            }}
          />
        </div>
        <span className="error-text"></span>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t("odp-ad.challenge.create-content.evaluation")}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <span>
            {t(
              "odp-ad.challenge.create-content.-the-evaluation-pages-describes-how-the-"
            )}
          </span>
          <CKEditor
            editor={ClassicEditor}
            data={
              evaluation
            }
            config={EDITOR_CONFIG}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEvaluation(data);
            }}
          />
        </div>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-content.prize')}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <span>
            {t('odp-ad.challenge.create-content.add-your-prize-here.-prizes-can-be-monet')}
          </span>
          <h5 className="mt-4">{t('odp-ad.challenge.create-content.winning-prize-description')}</h5>
          <span>
            {t('odp-ad.challenge.create-content.-describe-the-details-of-your-prize-the')}
          </span>
          <CKEditor
            editor={ClassicEditor}
            data={
              prizeDescription
            }
            config={EDITOR_CONFIG}
            onChange={(event, editor) => {
              const data = editor.getData();
              setPrizeDescription(data);
            }}
          />
        </div>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4>{t('odp-ad.challenge.create-content.job-notification')}</h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <span>
            {t('odp-ad.challenge.create-content.job-description')}
          </span>
          <CKEditor
            editor={ClassicEditor}
            data={
              participationMessage
            }
            config={EDITOR_CONFIG}
            onChange={(event, editor) => {
              const data = editor.getData();
              setParticipationMessage(data);
            }}
          />
        </div>
      </div>
      <div className={Styles.inputContainer}>
        <div className={Styles.inputTitle}>
          <h4></h4>
        </div>
        <div className={`${Styles.formGroup} ${Styles.richTextArea}`}>
          <h5 className="mt-4">Winning Ranking and Points</h5>
          <p>
            Insert the number of winning projects and the points for each one.
          </p>
          <>
            <FieldArray
              name="prizeSet.prizes.prize_ranking"
              render={({ insert, remove, push }) => (
                <>
                  {prizes?.prize_ranking &&
                    prizes?.prize_ranking.length > 0 &&
                    prizes?.prize_ranking.map((obj, index) => (
                      <div className={Styles.rankingInputs}>
                        <h6>#{index + 1}</h6>
                        <Input
                          placeholder="0"
                          type="number"
                          name={`prizeSet.prizes.prize_ranking.${index}.value`}
                          min="0" />
                        {prizes?.prize_ranking.length > 1 && <span onClick={(e) => { e.preventDefault(); remove(index) }}><Icon src={bin} /></span>}
                      </div>
                    ))}
                  <Button as="link" onClick={(e) => { e.preventDefault(); push({ position: null, value: null }) }} className={Styles.addNew}>Add New</Button>
                </>
              )}
            />

            {<>
              <FieldArray
                name="prizeSet.prizes.prize_points"
                render={({ insert, remove, push }) => (
                  <div>
                    {prizes?.prize_points &&
                      prizes?.prize_points.length > 0 &&
                      prizes?.prize_points.map((obj, index) => (
                        <div className={Styles.pointsInput}>
                          <span>from</span>
                          <Input
                            min="0"
                            placeholder="0"
                            name={`prizeSet.prizes.prize_points.${index}.from`}
                            type="number" />
                          <span>to</span>
                          <Input
                            min="0"
                            placeholder="0"
                            name={`prizeSet.prizes.prize_points.${index}.to`}
                            type="number" />
                          <Input
                            min="0"
                            placeholder="0"
                            name={`prizeSet.prizes.prize_points.${index}.value`}
                            type="number" />
                          {prizes?.prize_points.length > 1 && <span onClick={(e) => { e.preventDefault(); remove(index) }}><Icon src={bin} /></span>}
                        </div>
                      ))}
                    <Button as="link" onClick={(e) => { e.preventDefault(); push({ from: null, to: null, value: null }) }}>Add New</Button>
                  </div>

                )} />
            </>}
          </>
        </div>
      </div>
    </div>
  );
};

export default Content;
