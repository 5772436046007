import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Formik } from "formik";
import { Form, Input, Checkbox } from "formik-antd";
import { Spin } from "antd";
import { LinkedinFilled } from "@ant-design/icons";
import { loginSchema } from "../../helpers/schemas";
import Styles from "./login.module.scss";
import { loginAction } from "../../redux-store/actions/authenticationAction";
import { getNotification } from "../../redux-store/actions/notificationActions";
import subscribeNotifications from "../../utils/subscribeNotifications";
import { useTranslation } from "react-i18next";
import { getLinkedInUrl } from "../../utils";
import Button from "../../components/Button";

const Login = () => {
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [grant_type] = useState("password");
  const history = useHistory()

  const { user } = useSelector((state) => state.userProfile);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });



  const validateFields = (data) => {
    dispatch(
      loginAction({
        grant_type,
        username: data.email,
        password: data.password,
      })
    );

    setTimeout(() => {
      if (localStorage.getItem("odp_token") !== null) {
        dispatch(getNotification(null));
        const notifications = new subscribeNotifications();
        notifications.start(dispatch);
      }
    }, 1000);

  };

  const submitLogin = (values, { setSubmitting }, errors) => {
    dispatch(
      loginAction(values)
    );


    setTimeout(() => {
      if (localStorage.getItem("odp_token") !== null) {
        dispatch(getNotification(null));
        const notifications = new subscribeNotifications();
        notifications.start(dispatch);
      }
    }, 1000);
  }

  const onLinkedIn = () => {
    window.location.href = getLinkedInUrl('login_linkedIn');
  };

  return (
    <div className={Styles.login__wrapper}>
      <div className={Styles.login__form}>
        <div className={Styles.form__header}>
          <h1>{t('odp-pu.login.login-to-open-data-playground')}</h1>
          <p className="pg-regular">
            {t('odp-pu.login.welcome-back-log-in-to-participate-in-n')}
          </p>
        </div>

        <div className={Styles.form__body}>
          <div className={Styles.left__form}>
            <button className={Styles.linkedin__btn} onClick={onLinkedIn}>
              <LinkedinFilled className={Styles.linked__icon} />
              <span>{t('odp-pu.login.continue-in-with-linkedin')}</span>
            </button>
          </div>
          <span className={Styles.vertical}></span>
          <Formik
            initialValues={{
              username: null,
              password: null,
              grant_type: 'password'
            }}
            enableReinitialize={true}
            onSubmit={submitLogin}
            validationSchema={loginSchema}
            render={({ values, errors, isValid, touched, handleSubmit }) => (
              <div className={Styles.right__form}>
                <Form onSubmit={handleSubmit} onKeyUp={(e) => e.key == 'Enter' && handleSubmit}>
                  <div className="input__wrapper">
                    <Input
                      type="text"
                      name="username"
                      placeholder={t('odp-pu.login.email-address')}
                      className={(errors.username && touched.username) && "inputError"}
                    />
                    {(errors.username && touched.username) && <p className="error-message">{t(errors.username)}</p>}
                  </div>

                  <div className="input__wrapper">
                    <Input
                      type="password"
                      name="password"
                      placeholder={t('odp-pu.login.password')}
                      className={(errors.password && touched.password) && "inputError"}
                    />
                    {(errors.password && touched.password) && <p className="error-message">{t(errors.password)}</p>}
                  </div>

                  <Link to="/forgot-password" className={Styles.them__link}>
                    {t('odp-pu.login.forgot-your-password-')}
                  </Link>

                  <div className={Styles.buttonBlock}>
                    <Button
                      type="accent"
                      className="btnSignUp"
                      disabled={loading}
                      submitType="submit"
                    >{t('odp-pu.login.login-with-email')}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          />
        </div>

        <div className={Styles.form__footer}>
          <span className="pg-regular gray text-decoration-none">{t('odp-pu.login.don-t-have-an-account-')}</span>
          <Link className={Styles.them__link} to="signup">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
