import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';
import { UserEducation } from './UserEducation';
import { UserProfile } from './UserProfile';
import { UserSkillSet } from './UserSkillSet';
import { UserWorkExperience } from './UserWorkExperience';

export class User {
    user_name = null;
    role_id = null;
    is_admin = false;
    user_profile_id = null;
    has_cv = false;
    cv_file_id = null;
    userProfile = {}
    userEducationDetails = []
    userExperienceDetails = []
    userSkillSets = []
    constructor() {
        this.user_id = null;
        this.user_name = null;
        this.role_id = null;
        this.is_admin = false;
        this.user_profile_id = null;
        this.has_cv = false;
        this.cv_file_id = null;
        this.userProfile = UserProfile;
        this.userEducationDetails = [];
        this.userExperienceDetails = [];
        this.userSkillSets = [];
    }

    deserialize(model) {
        this.user_id = StringConverter(model.user_id);
        this.user_name = StringConverter(model.user_name);
        this.role_id = StringConverter(model.role_id);
        this.is_admin = BooleanConverter(model.is_admin);
        this.user_profile_id = StringConverter(model.user_profile_id);
        this.has_cv = BooleanConverter(model.has_cv);
        this.cv_file_id = StringConverter(model.cv_file_id);

        if (model.userProfile) {
            this.userProfile = new UserProfile().deserialize(model.userProfile);
        }

        if (model.userEducationDetails && model.userEducationDetails.length > 0) {
            for (let index = 0; index < model.userEducationDetails.length; index++) {
                this.userEducationDetails.push(new UserEducation().deserialize(model.userEducationDetails[index]));
            }
        }


        if (model.userExperienceDetails && model.userExperienceDetails.length > 0) {
            for (let index = 0; index < model.userExperienceDetails.length; index++) {
                this.userExperienceDetails.push(new UserWorkExperience().deserialize(model.userExperienceDetails[index]));
            }
        }

        if (model.userSkillSets && model.userSkillSets.length > 0) {
            for (let index = 0; index < model.userSkillSets.length; index++) {
                this.userSkillSets.push(new UserSkillSet().deserialize(model.userSkillSets[index]));
            }
        }

        return this;
    }
}