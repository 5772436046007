
//Authentication Endpoints:
export const loginEndPoint = 'login';
export const signUpEndPoint = 'signup';
export const signUpValidateHashEndPoint = (hash) => `signup​/${hash}​/validate`;
export const resetPasswordEndpoint = (hash) => `/user/${hash}/verifyPassword`;
export const signUpLinkedInEndPoint = 'signup/linkedin';
export const forgotPasswordEndpoint = 'user/forgotPassword';

//user Endpoints:
export const userEndpoint = 'user';
export const userEndpointParam = (userId) => `user/${userId}`;
export const userChallengesEndpoint = (userId) => `user/${userId}/challenge`;

//File Endpoint
export const fileTokenGenerate = 'file​/token​/generate';
export const fileEndPoint = 'file​';
export const fileIdEndPoint = (fileId) => `file​/${fileId}`;
export const saveProtectedFileEndpoint = (challengeId) => `challenge/${challengeId}/resources/protectedfiles`;
export const getProtectedFileEndpoint = (challengeId, protectedFileId) => `challenge/${challengeId}/resources/protectedfiles/${protectedFileId}`;
export const downloadParticipantsEndpoint = `/challenge/participants/download`;
export const participantsEmailsCsvFileEndpoint = (challengeId) => `challenge/${challengeId}/resources/eligibleparticipants`;

//Challenge Endpoint
export const challengeEndpoint = 'challenge';
export const challengeIdEndpoint = (challengeId) => `challenge/${challengeId}`;
export const publishChallengeEndpoint = (challengeId) => `challenge/${challengeId}/publish`;
export const unPublishChallengeEndpoint = (challengeId) => `challenge/${challengeId}/unpublish`;
export const subscribeChallengeEndpoint = (challengeId) => `challenge/${challengeId}/subscribe`;
export const UnsubscribeChallengeEndpoint = (challengeId) => `challenge/${challengeId}/unsubscribe`;
export const challengeSolutionFileEndpoint = (challengeId) => `challenge/${challengeId}/solutions/file`;
export const submitChallengeSolutionEndpoint = (challengeId) => `challenge/${challengeId}/solutions/submit`;
export const challengeSolutionSubmissionsEndpoint = (challengeId) => `challenge/${challengeId}/solutions/submissions`;
export const challengeSolutionLeaderBoardEndpoint = (challengeId) => `challenge/${challengeId}/solutions/leaderboard`;
export const challengeLeaderBoardEndpoint = (challengeId) => `challenge/${challengeId}/leaderboard`;
export const globalLeaderBoardEndpoint = `global/leaderboard`;

//Team Endpoint
export const teamEndpoint = 'team';
export const teamEndPointId = (teamId) => `team/${teamId}`


//Lookup Endpoint
export const lookUpEndpoint = (lookupName) => `lookup/${lookupName}`;

//Sponsor Endpoint
export const sponsorEndpoint = 'companies';

