import { Collapse } from "antd";
import ChallangeCard from "./card";
import "./teamMember.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTeamChallanges } from "../../../../services/team/api";
import { Pagination } from "antd";
import { useIsDesktop } from "../../../../hooks/customHooks";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.scss";
const { Panel } = Collapse;

const Index = ({ team_id, index }) => {
  const [challanges, setChallange] = useState([]);
  const isMobile = useIsDesktop();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const dispatch = useDispatch();

  useEffect(() => {
    const getChallanges = async () => {
      const getchallanges = await getTeamChallanges({
        team_id,
      });

      if (getchallanges !== null) setChallange(getchallanges?.data?.listings);
    };
    getChallanges();
  }, []);

  const paginate = (page) => setCurrentPage(page);
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <Link className="pagination__arrows previous">
          <ArrowLeftOutlined /> <span>Previous</span>
        </Link>
      );
    }
    if (type === "next") {
      return (
        <Link className="pagination__arrows next">
          <span>Next</span> <ArrowRightOutlined />
        </Link>
      );
    }
    return originalElement;
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = challanges?.slice(indexOfFirstPost, indexOfLastPost);
  return (
    <div className="team__members__accordian p-0">
      <div className="team__members__wrapper">
        <Collapse
          ghost
          expandIconPosition="right"
          className="p-0"
          defaultActiveKey={["0"]}
        >
          <Panel
            header={`Challenges (${challanges?.length})`}
            className="p-0"
            key={index}
          >
            {!isMobile &&
              challanges?.length > 0 &&
              currentPosts?.map((challange) => {
                return <ChallangeCard {...challange} />;
              })}
            {!isMobile && challanges?.length > 0 && (
              <div className="pagination_wrapper">
                <Pagination
                  current={currentPage}
                  defaultCurrent={1}
                  pageSize={postsPerPage}
                  total={challanges?.length}
                  itemRender={itemRender}
                  onChange={paginate}
                  responsive
                />
              </div>
            )}
            {isMobile && (
              <Swiper freeMode={false} slidesPerView={1} spaceBetween={20}>
                {challanges?.length > 0 &&
                  challanges?.map((challange) => {
                    return (
                      <SwiperSlide>
                        <ChallangeCard {...challange} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Index;
