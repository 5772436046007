import React from "react";
import Button from "../Button";
import HeroSvg1 from "../../asset/images/join-hero.png";
import HeroSvg2 from "../../asset/images/organize-hero.png";
import HeroSvg3 from "../../asset/images/train-hero.png";
import ODPWaterMark from "../../asset/images/odp-watermark.png";

import "./heroSection.scss";

const HeroSection = ({ title, subTitle, descriptiopnOne, label, openPage }) => {
  return (
    <div className="heroSection__wrapper">
      <div className="heroSection__container">
        <h3 className="headingThree">{title}</h3>
        <h1 className="headingOne">{subTitle}</h1>
        <p className="paragraph">{descriptiopnOne}</p>
        <Button
          id="buttonChallenges"
          label={label}
          type="secondary"
          onClick={openPage}
        >{label}</Button>
      </div>
      <div className="heroSection__svg heroSection__svg1">
        <img src={HeroSvg1} alt="HeroSvg1" />
      </div>
      <div className="heroSection__svg heroSection__svg2">
        <img src={HeroSvg2} alt="HeroSvg2" />
      </div>
      <div className="heroSection__svg heroSection__svg3">
        <img src={HeroSvg3} alt="HeroSvg3" />
      </div>

      <div>
        <img src={ODPWaterMark} className="odpWaterMark" />
      </div>
    </div>
  );
};

export default HeroSection;
