import { useState, useRef, useEffect } from "react";
import { notify } from "../antNotify";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { updateProfileImageId, updateTeamProfileImageId } from "../../store/profile/index";
import PlaceholderImg from "../../asset/images/team/placeholder.svg";
import "./imageUploader.scss";
import { saveTeamFile } from "../../services/fileupload/api";

const Index = ({ imageId }) => {
  const { UserProfileImage, loading } = useSelector(
    (state) => state.FileUpload
  );

  const dispatch = useDispatch();
  const fileInput = useRef(null);

  const [imgPreview, setimgPreview] = useState(null);

  useEffect(() => {
    if (imageId) {
      setimgPreview(process.env.REACT_APP_RESOURCES_URL + imageId);
    }
  }, [imageId]);

  const imageSelected = async () => {
    if (fileInput.current.files.length >= 1) {
      if (
        fileInput.current.files[0].type === "image/png" ||
        fileInput.current.files[0].type === "image/gif" ||
        fileInput.current.files[0].type === "image/jpeg" ||
        fileInput.current.files[0].type === "image/webp"
      ) {
        setimgPreview(null);
        setimgPreview(URL.createObjectURL(fileInput.current.files[0]));
        const fileData = new FormData();
        fileData.append("upfile", fileInput.current.files[0]);
        const fileID = await saveTeamFile(dispatch, {
          data: {
            upfile: fileData,
          },
        });
        await dispatch(updateTeamProfileImageId({ fileID }));
        notify(
          "success",
          "Picture Successfully uploaded",
          "Now you can save now or continue editing profile"
        );
      } else {
        notify("error", "Picture Upload Error", "Only picture can upload here");
      }
    }
  };

  // useEffect(() => {
  //   const convertBinaryTOBase64 = async () => {
  //     if (UserProfileImage !== null) {
  //       let base64EncodedStr = await bufferToString(
  //         UserProfileImage.file_content.data
  //       );
  //       await setimgPreview(`data:image/jpeg;base64,${base64EncodedStr}`);
  //     } else {
  //       setimgPreview(null);
  //     }
  //   };
  //   convertBinaryTOBase64();
  // }, [UserProfileImage]);
  return (
    <div>
      <div className="picture__uploader__wrapper">
        <div className="img__wrapper">
          {loading === "pending" ? (
            <div>
              <Spin className="spinner" />
              <img
                src={PlaceholderImg}
                alt={PlaceholderImg}
                className="avatar one "
              />
            </div>
          ) : imgPreview !== null ? (
            <img src={imgPreview} alt={imgPreview} className="checkone " />
          ) : (
            <div>
              <img
                src={PlaceholderImg}
                alt={PlaceholderImg}
                className="avatar two "
              />
            </div>
          )}
        </div>
        <button
          className="accent__upload__btn__round"
          onClick={(e) => {
            fileInput.current.click();
          }}
          disabled={loading === "pending" ? true : false}
        >
          <PlusOutlined />
        </button>
        <input
          type="file"
          name="profilePicture"
          id="profilePicture"
          ref={fileInput}
          accept="image/png, image/gif, image/jpeg , image/webp "
          onChange={(e) => imageSelected()}
          className="input__profile"
        />
      </div>
    </div>
  );
};

export default Index;
