import { axiosInstance } from "../../configs/axois";

import { notify } from "../../theme/antNotify";

import {
  getProfileStart,
  getProfileComplete,
  getProfileError,
  updateUserComplete,
  updateUserStart,
  updateUserError,
} from "../../store/profile/";

import {
  getCountries,
  setCountries,
  errorCountries,
} from "../../store/profile/countries";

export const getProfile = async (dispatch, payload) => {
  try {
    dispatch(getProfileStart());
    let { user_id } = { ...payload.data };
    const response = await axiosInstance.get(`user/${user_id}`);
    dispatch(getProfileComplete(response.data));
  } catch (error) {
    dispatch(getProfileError(error));
  }
};
export const setProfile = async (dispatch, payload) => {
  try {
    dispatch(updateUserStart());

    const response = await axiosInstance.patch(`user`, payload);

    if (response.data) {
      dispatch(updateUserComplete());
      notify("success", "User Profile Saved", "New updates has been saved");
    } else {
      dispatch(updateUserError());
    }
  } catch (error) {
    dispatch(updateUserError(error));
  }
};
export const fetchCountries = async (dispatch) => {
  try {
    dispatch(getCountries());
    const response = await axiosInstance.get(`lookup/countries`);
    dispatch(setCountries(response.data));
  } catch (error) {
    dispatch(errorCountries(error));
  }
};
