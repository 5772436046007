import './cv.scss';
import { SButton } from '../../../theme/button';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from '../../../theme/antNotify';
import { Spin } from 'antd';
import { useRef, useState, useEffect } from 'react';
import { bufferToString } from '../../../utils/imageUploader';
import { SaveFileIdOfCVFile } from '../../../store/profile';
import { saveFile, getFile } from '../../../services/fileupload/api';
import { useTranslation } from 'react-i18next';
import { downloadFile } from '../../../redux-store/services/File';

const CV = ({ setCVFile, isCompanyAdmin }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.publicProfile);
  const { userProfileCV } = useSelector((state) => state.FileUpload);
  const [load, setload] = useState(false);
  const [download, setdownload] = useState(null);
  const [filename, setfilename] = useState('');
  const [cvFileName, setCVFileName] = useState(null);
  const fileInput = useRef();
  const { t } = useTranslation();

  const selectedCV = async () => {
    if (fileInput.current.files[0] !== null || fileInput.current.files[0] !== undefined) {
      if (fileInput.current.files.length >= 1) {
        setfilename(fileInput.current.files[0].name);
        if (
          true
          // fileInput.current.files[0].type === 'text/plain' ||
          // fileInput.current.files[0].type === 'application/docx' ||
          // fileInput.current.files[0].type === 'application/pdf' ||
          // fileInput.current.files[0].type === 'application/doc'
        ) {
          setload(true);

          const fileData = new FormData();
          fileData.append('upfile', fileInput.current.files[0]);
          const fileID = await saveFile(dispatch, {
            data: {
              upfile: fileData
            }
          });
          const response = await dispatch(SaveFileIdOfCVFile({ fileID }));
          setCVFile(fileID);
          setload(false);
          setdownload(true)
          const { tempPublicURL, file_name } = await downloadFile(fileID);
          setdownload(tempPublicURL)
          setCVFileName(file_name)
        } else {
          notify('error', 'CV Upload Error', 'Only cv or document files can upload here');
        }
      }
    }
  };
  const clickTrigger = () => {
    fileInput.current.click();
  };

  useEffect(async () => {
    if (user) {
      let { cv_file_id } = user;
      if (cv_file_id !== null) {
        getFile(dispatch, cv_file_id);
        const { tempPublicURL, file_name } = await downloadFile(cv_file_id);

        setdownload(tempPublicURL)
        setCVFileName(file_name)
      } else {
      }
    }
  }, [user]);

  useEffect(() => {
    const convert = async () => {
      if (userProfileCV) {
      }
    };
    convert();
  }, [userProfileCV]);

  return (
    <div className="cv__wrapper">
      <Spin spinning={load} className="spinner" />
      <div className="left__skill">
        <input
          type="file"
          name="profilePicture"
          id="profilePicture"
          ref={fileInput}
          onChange={(e) => selectedCV()}
          className="input__profile"
        />
        <h4>{t('odp-pu.label.profile.curriculum-vitae')}</h4>
      </div>

      <div className="rigt__skill">
        <div className="uploader__wrapper">
          {!isCompanyAdmin && <div className="button__wrapper">
            <SButton
              label={`${t('odp-pu.label.profile.upload-file')}`}
              onClick={(e) => {
                e.preventDefault();
                clickTrigger();
              }}
              disabled={load ? true : false}></SButton>
            <span className="file__extention">.pdf, .doc</span>
          </div>}

          {download ? (
            <span className="filename">
              <a href={`${download}`} >
                {cvFileName}
              </a>
            </span>
          ) : (
            <span className="filename">No file uploaded</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default CV;
