import "./teamCard.scss";
import Team from "../../../../asset/images/team.jpg";
import { useHistory } from "react-router";
import UserImage from "../../../../asset/images/user.svg";

const Card = ({ user_id, avatar_url, display_name, is_team_lead }) => {
  const history = useHistory();
  const routeToUser = () => {
    history.push(`/user/profile/${user_id}`)
  }
  return (
    <div className="teamCard">
      <div className="teamCard_content" onClick={routeToUser}>
        <div className="teamCard_imgbox">
          <img
            src={`${avatar_url ? process.env.REACT_APP_RESOURCES_URL + avatar_url : UserImage}`}
            alt={Team}
          />
        </div>
        <div className="teamCard_body" >
          <p className="member_name"> {display_name}</p>
          {is_team_lead && <p className="isAdmin"> Administrator</p>}
        </div>
      </div>
    </div>
  );
};

export default Card;
