import Challenges from '../pages/Challenges';
import Sponsors from '../pages/Sponsors/listing';
import Challenge from '../pages/Challenges/Challenge';
import Users from '../pages/Users';
import UsersDetails from '../pages/Users/UsersDetails';
import PreviewChallenge from '../pages/Challenges/PreviewChallenge';
import Sponsor from '../pages/Sponsors/create';
import SponsorPreview from '../../pages/Sponser/sponsorPreview';
import PreviewSponsor from '../pages/Sponsors/preview';
import Assets from '../pages/Assets';

export const routes = [
  { path: '/admin/challenges', Component: Challenges },
  { path: '/admin/challenge', Component: Challenge },
  { path: '/admin/challenge/:challengeId', Component: Challenge },
  { path: '/admin/challenge/:challengeId/preview', Component: PreviewChallenge },
  { path: '/admin/sponsors', Component: Sponsors },
  { path: '/admin/sponsor', Component: Sponsor },
  { path: '/admin/sponsor/:sponsorId', Component: Sponsor },
  { path: '/admin/sponsor/:sponsorId/preview', Component: PreviewSponsor },
  { path: '/admin/users', Component: Users },
  { path: '/admin/assets/:challengeId', Component: Assets },
  { path: '/admin/user/:userId', Component: UsersDetails }
];
