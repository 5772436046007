import React from "react";
import Footer from "../Footer";
import NavBar from "../Navbar";
import Styles from "./app.module.scss";

const AdminApp = ({ children }) => {
  return (
    <>
      <div>
        <NavBar />
        <div className={Styles.adminPage}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default AdminApp;
