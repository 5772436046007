import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../components/Icons";
import Eye from "../../../../asset/icons/eyeBlue.svg";
import Edit from "../../../../asset/icons/editBlue.svg";
import Table from "../../../Components/Table";
import TableHead from "../../../Components/Table/tableHead";
import Button from '../../../../components/Button'
import TableBody from "../../../Components/Table/tableBody";
import SearchBox from "../../../Components/SearchBox";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  getChallengeById,
  getChallengeListings,
} from "../../../../redux-store/actions/ChallengeAction";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { buildParams, PaginationModel } from "../../../../utils";
import SortHeader from "../../../Components/Table/SortHeader";
import { setScoringEvaluation } from "../../../../redux-store/actions/utilsAction";

import Styles from './sponsor.module.scss';
import { getSponsorListings } from "../../../../redux-store/actions/sponsorAction";
import { Empty } from "antd";

const PAGE_SIZE = 20;

const Sponsors = () => {
  const { t } = useTranslation();
  const SkeletonArr = [1, 2, 3, 4, 5];
  const sponsorFilters = {};
  sponsorFilters.pagination = new PaginationModel();
  sponsorFilters.pagination.pageSize = PAGE_SIZE;
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(null);
  const [componentMounted, setComponentMounted] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const { loading, listings, _metadata } = useSelector(
    (state) => state.sponsors
  );


  const { user } = useSelector((state) => state.userProfile);

  const getFilteredSponsors = () => {
    sponsorFilters.searchBy = "company_name";
    sponsorFilters.searchText = searchText;
    getSponsors(sponsorFilters);
  }

  const sortColumn = () => {
    if (componentMounted) {
      sponsorFilters.sortBy = sortOrder;
      sponsorFilters.orderField = sortBy;
      getSponsors(sponsorFilters);
    }
  }

  useEffect(() => {
    getSponsors(sponsorFilters);
    return () => {
    };
  }, []);

  useEffect(() => {
    if (componentMounted) {
      sponsorFilters.pagination.pageNum = currentPage;
      getSponsors(sponsorFilters);
    }
    setComponentMounted(true)
  }, [currentPage]);


  const getSponsors = (filters) => {
    filters.is_my_company = true;
    let params = buildParams(filters);
    params.permission = {
      is_admin: user?.is_admin,
      is_company_admin: user?.is_company_admin,
      dispatch
    }

    dispatch(getSponsorListings(params));
  };

  const routeToCreateSponsor = () => {
    history.push("/admin/sponsor");
  };


  return (
    <div className="challengePage">
      <div className="title">
        <h2>Manage Sponsors</h2>
      </div>
      <div className="challengeDetailTitle">
        <p>
          Total Sponsors: {_metadata?.totalResults}
        </p>
        {user?.is_admin && <Button
          type="accent"
          onClick={routeToCreateSponsor}
        >
          Create new sponsor
        </Button>}
      </div>
      <SearchBox
        placeHolder={t("odp-ad.challenge.manage.filter-by-title")}
        onEnter={getFilteredSponsors}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className={'tableContainer'}>
        <Table>
          <TableHead>
            <SortHeader sortField="company_name" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>Name</SortHeader>
            <SortHeader sortField="is_published" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>Publish</SortHeader>
            <SortHeader className={'text-center'} sortField="total_active_competitions" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>Active Challenges</SortHeader>
            <SortHeader className={'text-center'} sortField="total_competitions" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>Total Challenges</SortHeader>
            <SortHeader sortField="mod_date" sortBy={sortBy} setSortBy={setSortBy} setSortOrder={setSortOrder} onSortColumn={sortColumn}>Last Update</SortHeader>
            <th colSpan="2">Action</th>
          </TableHead>
          <TableBody>
            {!loading &&
              listings &&
              listings.map(
                ({
                  company_id,
                  company_name,
                  is_published,
                  competition_stats,
                  mod_date
                }) => (
                  <tr key={company_id}>
                    <td>{company_name}</td>
                    <td>
                      {is_published ? <span>Online</span> : <span>Draft</span>}
                    </td>
                    <td className="text-center"><span>{competition_stats?.total_active_competitions || '-'}</span></td>
                    <td className="text-center"><span>{competition_stats?.total_competitions || '-'}</span></td>
                    <td>{moment(mod_date).format("DD/MM/YYYY")}</td>
                    <td>
                      <Link to={`/admin/sponsor/${company_id}/preview`}>
                        <Icon src={Eye} /> {t("odp-ad.user.preview.preview")}
                      </Link>
                    </td>
                    <td>
                      <Link to={`/admin/sponsor/${company_id}`}>
                        <Icon src={Edit} /> {t("odp-ad.user.edit.edit")}
                      </Link>
                    </td>
                  </tr>
                )
              )}
            {loading &&
              SkeletonArr.map((obj, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
      </div>
      {listings && listings.length > 0 && (
        <Pagination
          pageSize={_metadata?.pageSize}
          totalRecord={_metadata?.totalResults}
          currentPage={currentPage}
          styles={{ marginTop: "40px", marginBottom: "40px" }}
          onPaginationChange={(page) => {
            setCurrentPage(page);
          }}
          showPageDifference={false}
        />
      )}
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        {!loading && listings.length === 0 && <Empty />}
      </div>
    </div>
  );
};

export default Sponsors;
