import React, { useState, useEffect } from 'react'
import Icon from '../../../components/Icons';
import ArrowUp from "../../../asset/icons/up-small.svg";
import ArrowDown from "../../../asset/icons/down-small.svg";
import Sorting from "../../../asset/icons/sorting.svg";

const SortHeader = ({ sortField, sortBy, setSortBy, setSortOrder, onSortColumn = () => { }, children, className, childClassName, colSpan }) => {
    const [sortDirection, setSortDirection] = useState(null);

    const sortColumn = () => {
        let sortingOrder = sortDirection;
        if (sortingOrder == 'desc') {
            sortingOrder = 'asc';
        } else if (sortingOrder === null) {
            sortingOrder = 'desc';
        } else if (sortingOrder == 'asc') {
            sortingOrder = null;
        }
        setSortDirection(sortingOrder);
        setSortBy(sortField);
        setSortOrder(sortingOrder);

    }

    useEffect(() => {
        onSortColumn()
    }, [sortDirection])

    return (
        <th onClick={sortColumn} className={className} colSpan={colSpan}>
            <span className={childClassName}>
                {children} {sortField == sortBy ? < Icon src={sortDirection === 'asc' ? ArrowUp : sortDirection === 'desc' ? ArrowDown : Sorting} /> : <Icon src={Sorting} />}
            </span>
        </th>
    )
}

export default SortHeader
