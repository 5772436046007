import { createSlice } from '@reduxjs/toolkit';
import { loading } from '../../helpers/loading';

const initialState = {
  loading: loading.INIT,
  error: null,
  setSkills: [],
};

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    /*get and set skill  */
    getSkills: (state) => {
      state.loading = loading.PENDING;
    },
    setSkills: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.setSkills = action.payload;
    },
    errorSkills: (state) => {
      state.loading = loading.PENDING;
    },
  },
});

export const { getSkills, setSkills, errorSkills } = skillsSlice.actions;

export default skillsSlice.reducer;
