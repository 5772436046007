import update from "immutability-helper";
import { failure, request, success } from "../../utils/fetch";
import { FETCH_TEAM_BY_ID } from "../actions/teamAction";

const initialState = {
    statusCode: 0,
    error: {},
    team: {},
};

export function teamReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_TEAM_BY_ID:
            return update(state, {
                team: { $set: action.request },
                loading: { $set: false },
                error: { $set: null },
            });
        case request(FETCH_TEAM_BY_ID):
            return update(state, {
                loading: { $set: true },
                error: { $set: null },
            });
        case success(FETCH_TEAM_BY_ID):
            return update(state, {
                statusCode: { $set: 0 },
                team: { $set: action.response },
                loading: { $set: false },
            });
        case failure(FETCH_TEAM_BY_ID):
            return update(state, {
                loading: { $set: false },
                error: { $set: action.error },
            });
        default:
            return state;
    }
}