import './skillAssesment.scss';
const Skill = ({
  skill_set_id,
  skill_level,
  skill_set_name,
  updateSkillLevel,
}) => {
  let active = [];
  let nonActive = [];

  for (let index = 0; index < parseInt(skill_level); index++) {
    active.push(
      <li
        onClick={() => updateSkillLevel({ skill_set_id, level: index })}
        className="circle active"
      ></li>
    );
  }
  for (let index = parseInt(skill_level) + 1; index <= 5; index++) {
    nonActive.push(
      <li
        onClick={() => updateSkillLevel({ skill_set_id, level: index })}
        className="circle "
      ></li>
    );
  }
  return (
    <div className="single__skill">
      <div className="skill__content">
        <p className="skill_set_name">{skill_set_name}</p>
        <ul>
          {active}
          {nonActive}
        </ul>
      </div>
    </div>
  );
};

export default Skill;
