import HeroSection from '../../components/HeroSection/heroSection';
import OurEvents from '../../components/OurEvents/ourEvents';
import OurServices from '../../components/OurServices/ourServices';
import QuotesSection from '../../components/QuotesSection/quotesSection';
import { useTranslation } from 'react-i18next';
import Sponsors from '../../components/Sponsors/sponsors';
import TheAdvantages from '../../components/TheAdvantages/theAdvantages';
import { SButton } from '../../theme/button';
import Button from '../../components/Button';
import sponnsor1 from '../../asset/images/sponsors-logo/nexi.jpg';
import sponnsor2 from '../../asset/images/sponsors-logo/sap.jpg';
import sponnsor3 from '../../asset/images/sponsors-logo/illimity.jpg';
import sponnsor4 from '../../asset/images/sponsors-logo/axa.jpg';
import sponnsor5 from '../../asset/images/sponsors-logo/credimi.jpg';
import sponnsor6 from '../../asset/images/sponsors-logo/osn.jpg';
import sponnsor7 from '../../asset/images/sponsors-logo/docebo.jpg';
import sponnsor8 from '../../asset/images/sponsors-logo/rgi.jpg';
import sponnsor9 from '../../asset/images/sponsors-logo/cuebiq.jpg';
import sponnsor10 from '../../asset/images/sponsors-logo/cellularline.jpg';
import sponnsor11 from '../../asset/images/sponsors-logo/conTe.jpg';
import sponnsor12 from '../../asset/images/sponsors-logo/venchi.jpg';
import sponnsor13 from '../../asset/images/sponsors-logo/minsait.jpg';
import sponnsor14 from '../../asset/images/sponsors-logo/bee.jpg';
import svg1 from '../../asset/icons/static-pages-svgs/15.svg';
import svg2 from '../../asset/icons/static-pages-svgs/16.svg';
import svg3 from '../../asset/icons/static-pages-svgs/14.svg';

import './dataExpert.scss';

const openPage = (e) => {
  e.preventDefault();
  window.open('/challenges', '_self');
};

const DataExpert = () => {
  const { t } = useTranslation();

  const text = {
    quotesDescription1: `${t('odp-pu.for-data-expert.at-the-core-of-open-data-playground-the')}`,
    quotesDescription2: `${t('odp-pu.for-data-expert.open-data-playground-therefore-favors')}`,
    quotesDescription3: `${t('odp-pu.for-data-expert.open-data-playground-acts-as')}`,
    quotesDescription4: `${t('odp-pu.for-data-expert.remember-even-if-you')}`,
    cards: [
      {
        id: 1,
        title: svg1,
        description: `${t('odp-pu.for-data-expert.graduate-program-aimed-at-junior-profile')}`
      },
      {
        id: 2,
        title: svg2,
        description: `${t('odp-pu.for-data-expert.data-recruitment-challenge-aimed-at-seni')}`
      },
      {
        id: 3,
        title: svg3,
        description: `${t('odp-pu.for-data-expert.data-hackathon-for-solving-business-or-s')}`
      }
    ],
    cards1: [],
    cards2: [],
    theAdvantages: [
      {
        id: 1,
        title: `${t('odp-pu.for-data-expert.find-the-job-that-s-right-for-you')}`,
        description: `${t('odp-pu.for-data-expert.participate-in-competitions-and-make-you')}`
      },
      {
        id: 2,
        title: `${t('odp-pu.for-data-expert.help-improve-the-world')}`,
        description: `${t('odp-pu.for-data-expert.in-csr-themed-hackathons-you-can-help-in')}`
      },
      {
        id: 3,
        title: `${t('odp-pu.for-data-expert.promote-yourself')}`,
        description: `${t('odp-pu.for-data-expert.stand-out-from-others-and-make-your-resu')}`
      },
      {
        id: 4,
        title: `${t('odp-pu.for-data-expert.develop-new-skills')}`,
        description: `${t('odp-pu.for-data-expert.update-your-knowledge-develop-your-crit')}`
      }
    ],
    sponsors: [
      {
        id: 1,
        image: sponnsor1
      },
      {
        id: 2,
        image: sponnsor2
      },
      {
        id: 3,
        image: sponnsor3
      },
      {
        id: 4,
        image: sponnsor4
      },
      {
        id: 5,
        image: sponnsor5
      },
      {
        id: 6,
        image: sponnsor6
      },
      {
        id: 7,
        image: sponnsor7
      },
      {
        id: 8,
        image: sponnsor8
      },
      {
        id: 9,
        image: sponnsor9
      },
      {
        id: 10,
        image: sponnsor10
      },
      {
        id: 11,
        image: sponnsor11
      },
      {
        id: 12,
        image: sponnsor12
      },
      {
        id: 13,
        image: sponnsor13
      },
      {
        id: 14,
        image: sponnsor14
      }
    ]
  };

  return (
    <div className="development__wrapper">
      <div className="dataexpert__herosection">
        <HeroSection
          title={t('odp-pu.for-data-expert.are-you-a-data-expert-')}
          subTitle={t('odp-pu.for-data-expert.enter-the-open-data-playground-to-test-a')}
          descriptiopnOne={t('odp-pu.for-data-expert.check-out-the-competitions-on-data-scien')}
          label={t('odp-pu.about.join-the-challenges')}
          openPage={openPage}
        />
      </div>
      <div className="dataExpertOurServices">
        <OurServices
          text={text}
          title={t('odp-pu.for-data-expert.increase-your-digital-stem-and-innovati')}
          subtitle={t('odp-pu.for-data-expert.which-kind-of-competitions-can-you-parti')}
          description={t('odp-pu.for-data-expert.in-open-data-playground-you-can-not-only')}
          paragraph={t('odp-pu.for-data-expert.enter-the-playground-and-show-off-your-t')}
          label={t('odp-pu.about.join-the-challenges')}
          openPage={openPage}
        />
      </div>
      <OurEvents
        title={t('odp-pu.for-data-expert.our-events')}
        description={t('odp-pu.for-data-expert.find-the-different-entities-that-believe')}
      />
      <QuotesSection
        text={text}
        title={t('odp-pu.for-data-expert.why-open-data-playground')}
        quote={t('odp-pu.for-data-expert.the-analysis-of-the-responses-provided-b')}
        author={t('odp-pu.for-data-expert.source-unioncamere---anpal-excelsior-i')}
      />
      <TheAdvantages text={text} title={t('odp-pu.for-data-expert.the-advantages')} />
      <div className="dataexpert__sponsors">
        <Sponsors
          details={text}
          title={t('odp-pu.for-data-expert.competition-sponsors')}
          description={t('odp-pu.for-data-expert.find-the-different-entities-that-believe')}
        />
      </div>
      <div className="enterTheOpenDataPlayground__wrapper">
        <h1 className="enterTheOpenDataPlayground__heading">
          {t('odp-pu.for-data-expert.enter-the-open-data-playground')}
        </h1>
        <p className="enterTheOpenDataPlayground__description">
          {t('odp-pu.for-data-expert.get-first-place-on-the-leaderboard')}
        </p>
        <Button onClick={openPage} type={'secondary'}>
          {t('odp-pu.for-data-expert.join-the-challenges')}
        </Button>
      </div>
    </div>
  );
};

export default DataExpert;
