import React from "react";
import TableHead from "./tableHead";
import TableBody from "./tableBody";
import Styles from "./table.module.scss";
const Table = ({ children }) => {
  return (
    <>
      <table className={Styles.table}>{children}</table>
    </>
  );
};

export default Table;
