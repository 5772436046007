import React from 'react';
import { useTranslation } from 'react-i18next';

import Styles from './newsletter.module.scss';
import NewsletterSubscribe from './NewsletterSubscribe';

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div className={Styles.newsletterContainer}>
      <div className={Styles.newsletterContent}>
        <h2>{t('odp-pu.homepage.subscribe-to-our-newsletter')}</h2>
        <p>{t('odp-pu.homepage.don-t-miss-the-latest-news-new-upcoming')}</p>
      </div>
      <NewsletterSubscribe />
    </div>
  );
};

export default Newsletter;
