import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Styles from './challengeTabs.module.scss';
import SearchBox from '../../admin/Components/SearchBox';
import Table from '../../admin/Components/Table';
import TableBody from '../../admin/Components/Table/tableBody';
import TableHead from '../../admin/Components/Table/tableHead';
import Pagination from '../Pagination';
import { getParticipantsAction } from '../../redux-store/actions/ChallengeAction';

import { Empty } from 'antd';
import Button from '../Button';
import UserImage from '../../asset/images/user.svg';
import { buildParams, PaginationModel } from '../../utils';
import Icon from '../Icons';
import user from '../../asset/icons/user.svg';
import timer from '../../asset/icons/timer.svg';
import entries from '../../asset/icons/entries.svg';
import users from '../../asset/icons/users.svg';
import bookmark from '../../asset/icons/bookmark.svg';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import { downloadParticipantsFile } from '../../redux-store/services/File';
import SortHeader from '../../admin/Components/Table/SortHeader';

const PAGE_SIZE = 20;
const Participant = ({ challengeId, isLoggedIn, isAdmin, challengeStatus }) => {
  const participantsFilters = {};
  const dispatch = useDispatch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  participantsFilters.pagination = new PaginationModel();
  participantsFilters.pagination.pageSize = PAGE_SIZE;
  participantsFilters.challengeId = challengeId;
  const [currentPage, setCurrentPage] = useState(1);
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const SkeletonArr = [1, 2, 3, 4, 5];
  const [searchText, setSearchText] = useState('');

  const { listings, loading, _metadata } = useSelector((state) => state.participants);

  useEffect(() => {
    getParticipants(participantsFilters);
  }, []);

  useEffect(() => {
    if (isComponentMounted) {
      participantsFilters.pagination.pageNum = currentPage;
      getParticipants(participantsFilters);
    }
    setIsComponentMounted(true);
  }, [currentPage]);

  const filterParticipants = () => {
    participantsFilters.searchText = searchText;
    participantsFilters.searchBy = 'display_name';
    getParticipants(participantsFilters);
  };

  const getParticipants = (filters) => {
    let params = buildParams(filters);
    dispatch(getParticipantsAction(params));
  };

  const sortColumn = () => {
    if (isComponentMounted) {
      participantsFilters.sortBy = sortOrder;
      participantsFilters.orderField = sortBy;
      getParticipants(participantsFilters);
    }
  };

  const downloadParticipants = async () => {
    try {
      setDownloadLoading(true);
      const response = await downloadParticipantsFile(challengeId);

      if (response) {
        setDownloadLoading(false);

        const fileExtension = response.fileExtension.replace('"', '');

        const fileType =
          fileExtension === 'xlsx'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : fileExtension === 'xls'
            ? 'application/vnd.ms-excel'
            : 'text/csv';

        const url = window.URL.createObjectURL(new Blob([response?.data], { type: fileType }));

        const link = document.createElement('a');
        link.href = url;

        // remove one or more underscores from start and end of filename
        const filename = response?.fileName.split('.')[0];

        link.setAttribute('download', filename.replace(/"/g, '')); //or any other extension

        document.body.appendChild(link);
        link.click();

        return;
      }
    } catch (e) {
      setDownloadLoading(false);
    }
  };
  return (
    <div className={Styles.leaderBoardContainer}>
      {isAdmin && (
        <div className={Styles.btnDownloadParticipants}>
          <Button
            onClick={downloadParticipants}
            type="secondary"
            disabled={downloadLoading || listings.length == 0}>
            <span> Download list of all participants</span>
          </Button>
        </div>
      )}
      <SearchBox
        placeHolder={'Filter by name...'}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (!e.target.value) getParticipants(participantsFilters);
        }}
        onEnter={filterParticipants}
      />
      <Table>
        <TableHead>
          <SortHeader
            childClassName={`${Styles.iconHead}`}
            sortField="display_name"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={user} />
            <span>Name</span>
          </SortHeader>
          <SortHeader
            childClassName={`${Styles.iconHead}`}
            sortField="joining_date"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={timer} />
            <span>Subscribe Date</span>
          </SortHeader>
          <SortHeader
            childClassName={`${Styles.iconHead}`}
            sortField="last_submission_date"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={timer} />
            <span>Last Submission</span>
          </SortHeader>

          <SortHeader
            className="text-center"
            childClassName={`${Styles.iconHead} justify-content-center`}
            sortField="total_success_submissions"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={entries} />
            <span>Entries</span>
          </SortHeader>
          <SortHeader
            className="text-center"
            childClassName={`${Styles.iconHead} justify-content-center`}
            sortField="participant_type"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={bookmark} />
            <span>Role</span>
          </SortHeader>
          <SortHeader
            childClassName={`${Styles.iconHead}`}
            sortField="team_name"
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortOrder={setSortOrder}
            onSortColumn={sortColumn}>
            <Icon src={users} />
            <span>Team Name</span>
          </SortHeader>
        </TableHead>
        <TableBody>
          {!loading &&
            listings.length > 0 &&
            listings.map(
              (
                {
                  record_count,
                  display_name,
                  joining_date,
                  last_submission_date,
                  avatar_url,
                  participant_type,
                  top_score,
                  top_rank,
                  total_count,
                  total_success_submissions,
                  team_id,
                  user_id,
                  team_file_id,
                  team_name
                },
                idx
              ) => (
                <tr key={user_id}>
                  <td>
                    <Link
                      className={Styles.userLink}
                      to={
                        participant_type == 1
                          ? `/admin/user/${user_id}`
                          : `/team/profile/${team_id}`
                      }>
                      {avatar_url ? (
                        <img
                          src={process.env.REACT_APP_RESOURCES_URL + avatar_url}
                          className={Styles.avatar}
                        />
                      ) : (
                        <img src={UserImage} className={Styles.avatar} />
                      )}
                      {display_name}
                    </Link>
                  </td>
                  <td>{joining_date ? moment(joining_date).format('DD/MM/YYYY') : '-'}</td>
                  <td>
                    {last_submission_date ? moment(last_submission_date).format('DD/MM/YYYY') : '-'}
                  </td>
                  <td className="text-center">{total_success_submissions || '0'}</td>
                  <td className="text-center">
                    {participant_type == 1
                      ? 'Individual'
                      : participant_type == 2
                      ? 'Team member'
                      : '-'}
                  </td>
                  <td>
                    {participant_type == 2 && (
                      <Link className={Styles.userLink}>
                        {team_file_id ? (
                          <img
                            src={process.env.REACT_APP_RESOURCES_URL + team_file_id}
                            className={Styles.avatar}
                          />
                        ) : (
                          <img src={UserImage} className={Styles.avatar} />
                        )}
                        {team_name}
                      </Link>
                    )}
                  </td>
                </tr>
              )
            )}
          {loading &&
            SkeletonArr.map((idx) => (
              <tr key={idx}>
                <td style={{ width: '300px' }}>
                  <Skeleton count={1} />
                </td>
                <td>
                  <Skeleton count={1} />
                </td>
                <td>
                  <Skeleton count={1} />
                </td>
                <td>
                  <Skeleton count={1} />
                </td>
                <td>
                  <Skeleton count={1} />
                </td>
                <td>
                  <Skeleton count={1} />
                </td>
              </tr>
            ))}
        </TableBody>
      </Table>
      {listings && listings.length > 0 && (
        <Pagination
          pageSize={_metadata?.pageSize}
          totalRecord={_metadata?.totalResults}
          currentPage={currentPage}
          styles={{ marginTop: '40px' }}
          onPaginationChange={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
      <div style={{ marginTop: '40px', marginBottom: '40px' }}>
        {!loading && listings.length === 0 && <Empty />}
      </div>
    </div>
  );
};

export default Participant;
