import SecondryNav from '../../components/secondyNav';
const Index = ({ children }) => {
  return (
    <div className="secondy__wrapper">
      <SecondryNav />
      {children}
    </div>
  );
};

export default Index;
