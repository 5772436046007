import React from 'react';
import Styles from './button.module.scss';
import { Button as AntdButton } from 'antd';
import { NavLink } from 'react-router-dom';

const Button = ({ type, onClick, children, className = null, as = "button", disabled = false, size = 'md', submitType, to }) => {
    return (
        as == "link" ? <a className={`${Styles.anchor} ${className}`} onClick={onClick}>{children}</a>
            : as == "route" ? <NavLink className={`${Styles.button} ${Styles[type]} ${Styles[size]} ${className}`} to={to}>{children}</NavLink> :
                <AntdButton className={`${Styles.button} ${Styles[type]} ${Styles[size]} ${className}`} onClick={onClick} disabled={disabled} htmlType={submitType}>
                    {children}
                </AntdButton>
    )
}

export default Button
