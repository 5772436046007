import { useMediaQuery } from 'react-responsive';

export const useIsDesktop = () => {
  return useMediaQuery({ query: '(max-width: 1024px)' });
};

export const useIsIpadPro = () => {
  return useMediaQuery({ query: '(max-width: 1200px)' });
};

export const useIsTablet = () => {
  return useMediaQuery({ query: '(max-width: 991px)' });
};

export const useIsMobile = () => {
  return useMediaQuery({ query: '(max-device-width: 700px)' });
};

export const useIsLandscape = () => {
  return useMediaQuery({ query: '(max-device-height: 450px)' });
};
