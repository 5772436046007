import { lookUpEndpoint } from '../../utils/endPoints';
import { axiosInstance } from '../../configs/axois';

export const getLookupListings = async (lookUpName) => {
    try {
        const { data, status } = await axiosInstance.get(lookUpEndpoint(lookUpName));
        if (status !== 200) {
            throw `Error Fetching Challenges ${status}`;
        }
        let { listings, statusCode, _metadata } = data;
        return { listings, statusCode, _metadata };

    } catch (err) {
        throw 'Error Fetching Challenges';
    }
}