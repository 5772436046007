import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import ChallengePreview from "../../../../pages/challenge/ChallengePreview";
import Styles from "./previewChallenge.module.scss";
import BackArrow from "../../../../asset/icons/backArrow.svg";
import Icon from "../../../../components/Icons";
import Back from "../../../../components/Back";
import { getChallengeById, resetChallengeForm, resetCloseChallengeAction } from "../../../../redux-store/actions/ChallengeAction";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

const PreviewChallenge = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // dispatch(resetChallengeForm());
      dispatch(resetCloseChallengeAction())
    }
  }, [])
  return (
    <div className={Styles.challengePreviewContainer}>
      <Back link={"/admin/challenges"} />
      <ChallengePreview isAdmin={true} />
    </div>
  );
};

export default PreviewChallenge;
