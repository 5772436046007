import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LoginWithLinkedIn, SignUpWithLinkedIn } from "../../services/auth/api";
import { useHistory } from "react-router-dom";
const LinkedIn = ({ }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let queryParams = new URLSearchParams(useLocation().search);

  useEffect(() => {
    let linkedInCode = history.location.state.code;
    let {
      has_accepted_terms_of_use,
      has_accepted_no_cooling_off_period,
      has_accepted_share_data_3rd_parties,
    } = JSON.parse(history.location.state.state);
    SignUpWithLinkedIn(dispatch, {
      data: {
        code: linkedInCode,
        has_accepted_terms_of_use,
        has_accepted_no_cooling_off_period,
        has_accepted_share_data_3rd_parties
      },
    });

    return () => { };
  }, []);

  return <div>Please Wait .. </div>;
};

export default LinkedIn;
