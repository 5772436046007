import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './challengeTabs.module.scss';
import Table from '../../admin/Components/Table';
import TableBody from '../../admin/Components/Table/tableBody';
import TableHead from '../../admin/Components/Table/tableHead';
import upload from '../../asset/icons/upload.svg';
import fileDownload from '../../asset/icons/fileDownload.svg';
import clock from '../../asset/icons/clockFilled.svg';
import barGraph from '../../asset/icons/barGraph.svg';
import UserImage from '../../asset/images/user.svg';
import trophy from '../../asset/icons/trophy.svg';
import Edit from '../../asset/icons/editBlue.svg';
import Icon from '../Icons';
import FileIcon from '../../asset/icons/file.svg';
import Notify from '../../theme/notifications';
import FileListing from '../../asset/icons/fileListing.svg';
import Refresh from '../../asset/images/team/refresh.svg';
import {
  getChallengeSubmissions,
  resetChallengeSubmission,
  submitChallengeFileAction,
  updateChallengeSubmissions
} from '../../redux-store/actions/ChallengeAction';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { notify } from '../../theme/antNotify';
import { PREDICTION, SOLUTION } from '../../utils/constants';
import ProgressBar from '../ProgressBar';
import {
  createProtectedFile,
  getProtectedFileAction,
  resetProtectedFile
} from '../../redux-store/actions/protectedFileAction';
import { Empty, Input, Spin } from 'antd';
import { buildParams, PaginationModel, textTrim } from '../../utils';
import Pagination from '../Pagination';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SearchBox from '../../admin/Components/SearchBox';
import SortHeader from '../../admin/Components/Table/SortHeader';
import Button from '../Button';
import { updateChallengeScore } from '../../redux-store/services/Challenges';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../../configs/axois';
import Progress from '../ProgressBar';
import { setUploadStatus } from '../../redux-store/actions/protectedFileUploadingAction';

const { v4: uuidv4 } = require('uuid');
const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#C4C5D2' }} spin />;

const antIconLoading = <LoadingOutlined style={{ fontSize: 30, color: '#5F76FF' }} spin />;

const PAGE_SIZE = 20;
const Submission = ({
  challengeId,
  isLoggedIn,
  isAdmin,
  competitionStatus,
  isScoringManual,
  submissionLoading
}) => {
  let percentageValue = 1;
  const { t } = useTranslation();
  const SkeletonArr = [1, 2, 3];
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFile, setSelectedFile] = useState();
  const [percentage, setPercentage] = useState(25);
  const [selectedSolutionFile, setSelectedSolutionFile] = useState();
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [userName, setUserName] = useState(null);

  const [scoreEditMode, setScoreEditMode] = useState(false);
  const [scoringEditId, setScoringEditId] = useState(-1);
  const [updatedScore, setUpdatedScore] = useState();

  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const [dragOver, setDragOver] = useState(false);
  const onDragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    if (e) {
      setDragOver(true);
    }
  };

  const dragLeave = (e) => {
    e.preventDefault();
    if (e) {
      setDragOver(false);
    }
  };

  const submissionFilters = {};
  submissionFilters.pagination = new PaginationModel();
  submissionFilters.pagination.pageSize = PAGE_SIZE;

  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userProfile);

  const { submissionId, loading: challengeSubmission } = useSelector(
    (state) => state.challengeSubmission
  );

  const {
    loading: isProtectedFileUploading,
    progress,
    protectedFileId
  } = useSelector((state) => state.protectedFileUploadStatus);

  const { listings, loading, _metadata } = useSelector(
    (state) => state.challengeSubmissionHistories
  );

  const {
    challengeSolutionFile,
    loading: fileUploadingStatus,
    actionType
  } = useSelector((state) => state.protectedFiles);

  const onUploadFile = (event) => {
    event.preventDefault();

    // const fileData = new FormData();
    // fileData.append('upfile', files[0]);
    // fileData.append('file_type', PREDICTION);
    // let payload = {
    //   upFile: fileData,
    //   challengeId: challengeId,
    //   fileType: PREDICTION
    // };
    // setSelectedFile(files[0]);
    // dispatch(createProtectedFile(payload));
    // ========================================================================

    console.log('hello');
    try {
      event.preventDefault();
      const files = event.target.files || event.dataTransfer.files;
      let fileExtension = files[0].name.match(/\.(.+)$/)[1];
      const uuid = uuidv4();

      if (validateFile(fileExtension)) {
        const chunkSize = 4 * 1024 * 1024; // 4MB chunk size
        const totalChunks = Math.ceil(files[0].size / chunkSize);
        let currentChunk = 0;

        const uploadChunk = (start, end) => {
          const formData = new FormData();
          console.log('upfile', files[0].slice(start, end));
          formData.append('upfile', files[0].slice(start, end));
          formData.append('file_type', PREDICTION);

          axiosInstance
            .post(`challenge/${challengeId}/resources/protectedfiles`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'X-Chunk-Number': currentChunk,
                'X-Total-Chunks': totalChunks,
                'X-file-uuid': uuid
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                  ((currentChunk * chunkSize + progressEvent.loaded) * 100) / files[0].size
                );
                console.log('first', progress);
                // setUploadProgress(progress);
              }
            })
            .then((response) => {
              // Chunk uploaded successfully, proceed to next chunk
              currentChunk++;
              if (currentChunk < totalChunks) {
                const start = currentChunk * chunkSize;
                const end = start + chunkSize;
                uploadChunk(start, end);
              } else {
                // All chunks uploaded

                console.log('File upload complete!');
              }
            })
            .catch((error) => {
              console.error('Error uploading chunk:', error);
            });

          // Start uploading the first chunk

          // const dataFileId = await saveProtectedDataSetFile(dispatch, payload);
        };
        // Start uploading the first chunk
        debugger;
        const start = currentChunk * chunkSize;
        const end = start + chunkSize;
        uploadChunk(start, end);
        // setUploadProgress(0);
      }
    } catch (err) {}
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  const validateFile = (fileExtension) => {
    switch (fileExtension) {
      case 'zip':
        return true;
      default:
        notify('error', 'Invalid File', 'File with extension (.zip) is allowed');
    }
  };

  useEffect(() => {
    if (challengeSolutionFile && !fileUploadingStatus && actionType == 'SAVE') {
      dispatch(
        submitChallengeFileAction({
          challengeId,
          protected_file_id: challengeSolutionFile.protected_file_id
        })
      );
    } else if (challengeSolutionFile && !fileUploadingStatus && actionType == 'GET') {
      const url = window.URL.createObjectURL(new Blob([challengeSolutionFile]));
      const link = document.createElement('a');
      link.href = url;
      // const fileName = userName + '.zip' || "predicted-solution.zip";
      const fileName = challengeSolutionFile.fileName.replace('"', '').replace('"', '');
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }, [challengeSolutionFile]);

  useEffect(() => {
    if (protectedFileId) {
      dispatch(
        submitChallengeFileAction({
          challengeId,
          protected_file_id: protectedFileId
        })
      );
    }
  }, [protectedFileId]);

  useEffect(() => {
    if (submissionId) {
      getSubmission(submissionFilters);
      setSelectedFile(null);
      dispatch(resetChallengeSubmission());
      dispatch(resetProtectedFile());
      dispatch(
        setUploadStatus({
          loading: false,
          progress: 0,
          protectedFileId: null
        })
      );
    }
  }, [submissionId]);

  useEffect(() => {
    getSubmission(submissionFilters);

    setIsComponentMounted(true);
    return () => {
      dispatch(resetProtectedFile());
    };
  }, []);

  useEffect(() => {
    if (isComponentMounted) {
      submissionFilters.pagination.pageNum = currentPage;
      getSubmission(submissionFilters);
    }
    setIsComponentMounted(true);
  }, [currentPage]);

  const downloadFile = (protectedFileId) => {
    dispatch(getProtectedFileAction({ protectedFileId, challengeId }));
  };

  const getSubmission = (filters) => {
    let params = buildParams(filters);
    params.challengeId = challengeId;
    // if (!isAdmin) params.user_id = user.user_id;
    dispatch(getChallengeSubmissions(params));
  };

  const sortColumn = () => {
    if (isComponentMounted) {
      submissionFilters.sortBy = sortOrder;
      submissionFilters.orderField = sortBy;
      getSubmission(submissionFilters);
    }
  };

  const editScore = async (submission_id, score) => {
    try {
      if (scoreEditMode) {
        let payload = {
          submission_id,
          score: parseFloat(updatedScore || score || 0)
        };
        const response = await updateChallengeScore(payload);
        dispatch(updateChallengeSubmissions(response));
      }
      setUpdatedScore(score);
      setScoreEditMode(!scoreEditMode);
      setScoringEditId(submission_id);
    } catch (err) {}
  };

  return (
    <div className={isAdmin ? Styles.submissionAdminContainer : Styles.submissionContainer}>
      {!isAdmin && (
        <div className={Styles.submissionDropbox}>
          {competitionStatus == 3 && (
            <Notify
              msg={t('odp-pu.challenge.submission-alert.under-evaluation')}
              className="info"
              twoToneColor="#4ABEFF"
            />
          )}
          {competitionStatus == 4 && (
            <Notify
              msg={t('odp-pu.challenge.submission-alert.closed')}
              className="info"
              twoToneColor="#4ABEFF"
            />
          )}
          {competitionStatus == 1 && (
            <Notify
              msg={t('odp-pu.challenge.submission-alert.planned')}
              className="info"
              twoToneColor="#4ABEFF"
            />
          )}
          {
            <div
              className={Styles.filePicker}
              onDragOver={onDragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={onUploadFile}>
              {!isProtectedFileUploading && (
                <>
                  <div className={Styles.uploadBox}>
                    <img src={upload} />
                  </div>
                  <p className={Styles.browseFile}>
                    <div className={`file-selector text-center ${Styles.btnSelect}`}>
                      <input
                        type="file"
                        className="custom-file-input d-none"
                        id="inputGroupFile04"
                        onChange={onUploadFile}
                        onClick={(e) => onInputClick(e)}
                        disabled={competitionStatus !== 2}
                      />
                      <label
                        htmlFor="inputGroupFile04"
                        style={{ cursor: competitionStatus !== 2 ? 'not-allowed' : 'pointer' }}>
                        <i className="fa fa-cloud-upload"></i> Browse
                      </label>
                    </div>{' '}
                    your solution file or {!dragOver && <span>&nbsp;drag and </span>}
                    <span>&nbsp;drop it here</span>
                  </p>
                  <p className={Styles.fileSpecs}>Only .zip file with max size of 200 MB</p>
                </>
              )}
              {isProtectedFileUploading && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spin indicator={antIconLoading} />
                  <p className="mb-0 ms-3">Please wait, while we're uploading file</p>
                </div>
              )}
            </div>
          }

          {progress > 0 && (
            <div style={{ width: '500px', marginTop: '25px' }}>
              <Progress percentage={progress} />
            </div>
          )}
        </div>
      )}

      <div className={Styles.submissionHistory}>
        {!isAdmin && (
          <div className={Styles.submissionHistoryTitle}>
            <h5>{t('odp-pu.challenge.submission-history.label')}</h5>
            <Icon
              src={Refresh}
              onClick={() => {
                getSubmission(submissionFilters);
              }}
            />
          </div>
        )}
        {isAdmin && (
          <SearchBox
            placeHolder={'Filter by name...'}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        )}
        <Table>
          <TableHead>
            {isAdmin && (
              <SortHeader
                childClassName={`${Styles.iconHead}`}
                sortField="ui_display_name"
                sortBy={sortBy}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                onSortColumn={sortColumn}>
                <Icon src={FileListing} />
                <span>Player</span>
              </SortHeader>
            )}
            {!isAdmin && (
              <th>
                <span className={Styles.iconHead}>
                  <Icon src={FileListing} />
                  <span>File</span>
                </span>
              </th>
            )}
            <SortHeader
              childClassName={`${Styles.iconHead}`}
              sortField="submission_date"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}>
              <Icon src={clock} />
              <span>{isAdmin ? 'Date' : 'Submission Date'}</span>
            </SortHeader>
            {isAdmin && (
              <SortHeader
                childClassName={`${Styles.iconHead}`}
                sortField="submission_date"
                sortBy={sortBy}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                onSortColumn={sortColumn}>
                <Icon src={clock} />
                <span>Hours</span>
              </SortHeader>
            )}
            <SortHeader
              className={!isAdmin || competitionStatus != 3 ? 'text-center' : ''}
              childClassName={`${Styles.iconHead} ${
                !isAdmin || competitionStatus != 3 ? 'justify-content-center' : ''
              }`}
              sortField="score"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
              colSpan={isAdmin && competitionStatus == 3 ? 2 : 1}>
              <Icon src={barGraph} />
              <span>Score</span>
            </SortHeader>
            {!isAdmin && (
              <SortHeader
                className="text-center"
                childClassName={`${Styles.iconHead} justify-content-center`}
                sortField="score_rank"
                sortBy={sortBy}
                setSortBy={setSortBy}
                setSortOrder={setSortOrder}
                onSortColumn={sortColumn}>
                <Icon src={trophy} />
                <span>Rank</span>
              </SortHeader>
            )}
            <th className="text-center">Status</th>
            <th>{'Failed Reason'}</th>
            {isAdmin && <th>{'Action'}</th>}
          </TableHead>
          <TableBody>
            {!loading &&
              listings.length > 0 &&
              listings.map(
                (
                  {
                    avatar_url,
                    display_name,
                    team_name,
                    ui_display_name,
                    team_id,
                    team_file_id,
                    evaluation_status,
                    evaluation_status_desc,
                    failed_reason,
                    participant_type,
                    score,
                    score_rank,
                    submission_date,
                    solution_file_id,
                    submission_id
                  },
                  idx
                ) => (
                  <tr key={idx}>
                    {isAdmin && (
                      <td>
                        <p className={Styles.userLink}>
                          {avatar_url || team_file_id ? (
                            <img
                              src={
                                process.env.REACT_APP_RESOURCES_URL + (avatar_url || team_file_id)
                              }
                              className={Styles.avatar}
                            />
                          ) : (
                            <img src={UserImage} className={Styles.avatar} />
                          )}
                          {ui_display_name || '-'}
                          {team_id && (
                            <div className={Styles.teamLabel}>
                              <label>Team</label>
                            </div>
                          )}
                        </p>
                      </td>
                    )}
                    {!isAdmin && (
                      <td>
                        <span
                          className={Styles.fileDownload}
                          onClick={() => {
                            setSelectedSolutionFile(solution_file_id);
                            downloadFile(solution_file_id);
                          }}>
                          {actionType == 'GET' &&
                          fileUploadingStatus &&
                          selectedSolutionFile == solution_file_id ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <Icon src={fileDownload} />
                          )}
                        </span>
                      </td>
                    )}
                    <td>{submission_date ? moment(submission_date).format('DD/MM/YYYY') : '-'}</td>
                    {isAdmin && (
                      <td>{submission_date ? moment(submission_date).format('hh:mm a') : '-'}</td>
                    )}

                    {isAdmin && competitionStatus == 3 && (
                      <td className={'text-center'}>
                        {scoreEditMode && submission_id == scoringEditId ? (
                          <Input
                            value={updatedScore}
                            style={{ width: '50%' }}
                            onChange={(e) => setUpdatedScore(e.target.value)}
                          />
                        ) : (
                          score || 0
                        )}
                      </td>
                    )}
                    {isAdmin && competitionStatus != 3 && (
                      <td className="text-center">{score || 0}</td>
                    )}
                    {/* {(evaluation_status == 2 || evaluation_status == 3 || evaluation_status == 4) && isAdmin && <td className={"text-center"}>{isScoringManual ? (score || 'Under Evaluation') : (score || 0)}</td>} */}

                    {isAdmin && competitionStatus == 3 && (
                      <td>
                        <Button
                          as="link"
                          onClick={() => {
                            editScore(submission_id, score);
                          }}>
                          {!scoreEditMode && <Icon src={Edit} />}{' '}
                          {scoreEditMode && submission_id == scoringEditId ? 'Save' : 'Edit'}
                        </Button>
                      </td>
                    )}

                    {evaluation_status == 1 && !isScoringManual && (
                      <td
                        className="text-center"
                        onClick={() => {
                          getSubmission(submissionFilters);
                        }}>
                        <Icon src={Refresh} style={{ width: '20px' }} />
                      </td>
                    )}

                    {isScoringManual && !isAdmin && (
                      <td className="text-center">{score || 'Under Evaluation'}</td>
                    )}

                    {!isScoringManual &&
                      !isAdmin &&
                      (evaluation_status == 2 ||
                        evaluation_status == 3 ||
                        evaluation_status == 4) && <td className="text-center">{score || 0}</td>}

                    {!isAdmin && (
                      <td className="text-center">
                        {(evaluation_status == 2 && score_rank) || '-'}
                      </td>
                    )}

                    <td className="text-center">
                      <span
                        className={`badge bg-${
                          evaluation_status == 1
                            ? 'info'
                            : evaluation_status == 2
                            ? 'success'
                            : evaluation_status == 3
                            ? 'warning'
                            : 'danger'
                        }`}>
                        {evaluation_status_desc}
                      </span>
                    </td>

                    <td className={failed_reason ? Styles.failedReasons : ''}>
                      <span className={failed_reason ? Styles.failedLabel : ''}>
                        {failed_reason || '-'}
                      </span>
                    </td>

                    {isAdmin && (
                      <td className={failed_reason ? Styles.failedReasons : ''}>
                        <span
                          className={Styles.fileDownload}
                          onClick={() => {
                            setSelectedSolutionFile(solution_file_id);
                            setUserName(ui_display_name);
                            downloadFile(solution_file_id);
                          }}>
                          {actionType == 'GET' &&
                          fileUploadingStatus &&
                          selectedSolutionFile == solution_file_id ? (
                            <Spin indicator={antIcon} />
                          ) : (
                            <div className={Styles.fileDownload}>
                              <Icon src={fileDownload} />
                              <span>Download</span>
                            </div>
                          )}
                        </span>
                      </td>
                    )}
                  </tr>
                )
              )}
            {loading &&
              SkeletonArr.map((index) => (
                <tr key={index}>
                  <td style={{ width: '300px' }}>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          {!loading && listings.length === 0 && <Empty />}
        </div>
        {listings && listings.length > 0 && (
          <Pagination
            pageSize={_metadata?.pageSize}
            totalRecord={_metadata?.totalResults}
            currentPage={currentPage}
            styles={{ marginTop: '40px' }}
            onPaginationChange={(page) => {
              setCurrentPage(page);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Submission;
