import { useSelector } from "react-redux";
import SkillContainer from "./skillContainer";
import Notify from "../../../theme/notifications";
import "./skillAssesment.scss";

import { useTranslation } from "react-i18next";

const SkillAssesment = ({ setSkillSet, skillSet, isCompanyAdmin }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userProfile);
  return (
    <div className="skillAssesment__wrapper">
      <div className="left__skill">
        <h4>{t("odp-pu.user.profile.skills-assessment")}</h4>
      </div>
      <div className="rigt__skill">
        {skillSet?.length > 0 ? (
          <SkillContainer setSkillSet={setSkillSet} skillSet={skillSet} />
        ) : (
          <Notify msg={t("odp-pu.user.profile.add-skills")} className="info" />
        )}
      </div>
    </div>
  );
};

export default SkillAssesment;
