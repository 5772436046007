import React, { useEffect, useState } from 'react';
import SearchBox from '../../../admin/Components/SearchBox';
import Styles from './users.module.scss';
import Table from '../../Components/Table';
import TableHead from '../../Components/Table/tableHead';
import TableBody from '../../Components/Table/tableBody';
import { Badge, Select } from 'antd';
import { getUsersListings } from '../../../redux-store/actions/ChallengeAction';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import UserImage from '../../../asset/images/user.svg';
import Download from '../../../asset/icons/downloadbtn.svg';
import downArrow from '../../../asset/icons/downArrow.svg';
import times from '../../../asset/icons/times.svg';
import Icon from '../../../components/Icons';
import Pagination from '../../../components/Pagination';
import { buildParams, PaginationModel, toTitleCase } from '../../../utils';
import { Empty } from 'antd';
import Button from '../../../components/Button';
import { downloadData, removeDownloadData } from '../../../redux-store/actions/userAction';
import moment from 'moment';
import FileSaver from 'file-saver';
import { Link } from 'react-router-dom';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DATE_FORMAT = 'DD/MM/YYYY';
const { Option } = Select;
let filters = {};
const Users = () => {
  const userFilters = {};
  userFilters.pagination = new PaginationModel();
  const [currentPage, setCurrentPage] = useState(1);

  const onChangeFilter = (role) => {
    filters.user_type_name = role;
    filters.is_my_company = true;
    dispatch(getUsersListings(filters));
  };
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const SkeletonArr = [1, 2, 3, 4, 5];

  const { listings, loading, _metadata } = useSelector((state) => state.usersListings);

  const {
    fileContent,
    fileName,
    fileMimeType,
    loading: fileLoading
  } = useSelector((state) => state.userDownloadedData);

  useEffect(() => {
    filters = buildParams(userFilters);
    filters.pageSize = 10;
    filters.is_my_company = true;
    dispatch(getUsersListings(filters));
  }, []);

  useEffect(() => {
    if (fileContent && fileContent.length > 0) {
      let ab = new ArrayBuffer(fileContent.length);
      var view = new Uint8Array(ab);
      for (var i = 0; i < fileContent.length; i++) {
        view[i] = fileContent[i];
      }
      var file = new Blob([ab], { type: fileMimeType });
      FileSaver.saveAs(file, fileName);

      return () => {
        dispatch(removeDownloadData());
      };
    }
  }, [fileContent]);

  const clearFilter = () => {
    setSearchText(null);
    filters = buildParams(userFilters);
    filters.pageSize = 10;
    filters.is_my_company = true;
    dispatch(getUsersListings(filters));
  };

  const getFilteredList = () => {
    filters.search_key = 'display_name';
    filters.search_value = searchText;
    filters.is_my_company = true;
    dispatch(getUsersListings(filters));
  };

  useEffect(() => {
    if (currentPage) {
      console.log('filters', filters);
      if (filters.user_type_name) userFilters.user_type_name = filters.user_type_name;
      filters = buildParams(userFilters);
      filters.pageSize = 10;
      filters.is_my_company = true;
      filters.pageNum = currentPage;
      dispatch(getUsersListings(filters));
    }
  }, [currentPage]);

  const downloadUserData = () => {
    dispatch(downloadData());
  };
  const { t } = useTranslation();
  return (
    <div className={Styles.userContainer}>
      <div className="text-center mb-4">
        <h2>{t('odp-ad.user.manage.admin-users-dashboard')}</h2>
      </div>
      <Button type="secondary" onClick={downloadUserData}>
        <img src={Download} alt="" />{' '}
        <span style={{ marginLeft: '3px' }}>{t('odp-ad.user.manage.download-user-data')}</span>
      </Button>
      <div className={`row ${Styles.filterContainer}`}>
        <div className="col-sm-9">
          <div className={Styles.searchBox}>
            <SearchBox
              prefix={<SearchOutlined />}
              suffix={searchText && <CloseCircleOutlined onClick={clearFilter} />}
              placeHolder={t('odp-ad.user.manage.filter-by-name')}
              onEnter={getFilteredList}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>

        <div className={`col-sm-3`}>
          <span className="filterLabels">{t('odp-ad.user.manage.filter-by')}</span>
          <Select
            placeholder={t('odp-ad.user.manage.select-role')}
            allowClear
            onChange={onChangeFilter}
            style={{ width: '100%' }}
            suffixIcon={<Icon src={downArrow} />}>
            <Option value="player">{t('odp-ad.user.manage.player')}</Option>
            <Option value="admin">{t('odp-ad.user.manage.admin')}</Option>
            <Option value="sponsor">Sponsor</Option>
          </Select>
        </div>
      </div>
      <div className={Styles.tableContainer}>
        <Table>
          <TableHead>
            <th className="text-center">{t('odp-ad.user.manage.rank')}</th>
            <th>{t('odp-ad.user.manage.name')}</th>
            <th className="text-center">{t('odp-ad.user.manage.creation-date')}</th>
            <th className="text-center">{t('odp-ad.user.manage.last-update')}</th>
            <th className="text-center">{t('odp-ad.user.manage.role')}</th>
          </TableHead>
          <TableBody>
            {!loading &&
              listings.length > 0 &&
              listings.map(({ user_id, userProfile, user_stats }) => (
                <tr key={user_id}>
                  <td className="text-center">{user_stats?.global_rank || '0'}</td>
                  <td>
                    <Link to={`/admin/user/${user_id}`}>
                      {
                        <img
                          src={
                            userProfile?.avatar_url
                              ? process.env.REACT_APP_RESOURCES_URL + userProfile?.avatar_url
                              : UserImage
                          }
                          className={Styles.avatar}
                        />
                      }
                      {userProfile?.display_name || 'Anonymous'}
                    </Link>
                  </td>

                  <td className="text-center">
                    {moment(userProfile.create_date).format(DATE_FORMAT)}
                  </td>
                  <td className="text-center">
                    {moment(userProfile.mod_date).format(DATE_FORMAT)}
                  </td>
                  <td className="text-center">
                    {userProfile.user_type_names.map((role) => (
                      <Badge
                        count={role}
                        className={Styles.badge}
                        key={role}
                        style={{
                          backgroundColor:
                            role == 'Admin' ? '#5f76ff' : role == 'Sponsor' ? '#13c2c2' : '#000'
                        }}
                      />
                    ))}
                  </td>
                </tr>
              ))}
            {loading &&
              SkeletonArr.map((obj, index) => (
                <tr key={index}>
                  <td style={{ width: '300px' }}>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
      </div>
      <Pagination
        pageSize={_metadata?.pageSize}
        totalRecord={_metadata?.totalResults}
        currentPage={currentPage}
        styles={{ marginTop: '40px' }}
        onPaginationChange={(page) => {
          setCurrentPage(page);
        }}
      />
      <div style={{ marginTop: '40px', marginBottom: '40px' }}>
        {!loading && listings.length === 0 && <Empty />}
      </div>
    </div>
  );
};

export default Users;
