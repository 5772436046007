import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';
import { ChallengeProperty } from './ChallengeProperty'
import { PrizeSet } from './PrizeSet';

export class ChallengeObj {

    competition_id = null;
    competition_name = null;
    competition_prop_id = null;
    competition_description = null;
    competition_type_id = null;
    prize_set_id = null;
    evaluation_function_id = null;
    evaluation_function_values = {};
    is_published = false;
    thumbnail_image_id = null;
    cover_image_id = null;
    company_id = null;
    challengeproperty = {};
    prizeSet = {};

    constructor() {
        this.competition_id = null;
        this.competition_name = null;
        this.competition_prop_id = null;
        this.competition_description = null;
        this.competition_type_id = null;
        this.prize_set_id = null;
        this.evaluation_function_id = null;
        this.evaluation_function_values = {};
        this.is_published = false;
        this.thumbnail_image_id = null;
        this.cover_image_id = null;
        this.company_id = null;
        this.challengeproperty = new ChallengeProperty();
        this.prizeSet = new PrizeSet();
    }

    deserialize(model) {
        this.competition_id = StringConverter(model.competition_id);
        this.competition_name = StringConverter(model.competition_name);
        this.competition_prop_id = StringConverter(model.competition_prop_id);
        this.competition_description = StringConverter(model.competition_description);
        this.competition_type_id = StringConverter(model.competition_type_id);
        this.prize_set_id = StringConverter(model.prize_set_id);
        this.evaluation_function_id = StringConverter(model.evaluation_function_id);

        if (model.evaluation_function_values && Object.keys(model.evaluation_function_values).length > 0)
            this.evaluation_function_values = model.evaluation_function_values;
        else {
            this.evaluation_function_values = {}

        }
        this.is_published = BooleanConverter(model.is_published);
        this.thumbnail_image_id = StringConverter(model.thumbnail_image_id);
        this.cover_image_id = StringConverter(model.cover_image_id);
        this.company_id = StringConverter(model.company_id);
        if (model.prizeSet) {
            this.prizeSet = new PrizeSet().deserialize(model.prizeSet);
        }
        if (model.challengeproperty) {
            this.challengeproperty = new ChallengeProperty().deserialize(model.challengeproperty);
        }
        return this;
    }
}