import { useSelector, useDispatch } from 'react-redux';
import { updateUserSkillsAssesment } from '../../../store/profile';
import Skill from './skill';

const SkillContainer = ({ setSkillSet, skillSet }) => {

  const updateSkillLevel = (updateSkill) => {
    let updateSkillScore = skillSet.map((skill) => {

      if (skill.skill_set_id === updateSkill.skill_set_id) {
        return {
          ...skill,
          skill_level: updateSkill.level,
        };
      } else {
        return skill;
      }
    });

    setSkillSet(updateSkillScore);
  };
  return (
    <>
      {skillSet.map((skill) => {
        return (
          <Skill
            {...skill}
            updateSkillLevel={updateSkillLevel}
            key={skill.skill_set_id}
          />
        );
      })}
    </>
  );
};

export default SkillContainer;
