import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { updateAboutInfo } from '../../../store/profile/';
import { updateIsOpenToWork } from '../../../store/profile/';
import LinkedInIcon from '../../../asset/icons/linkedIn.svg';
import './userAbout.scss';
import GithubIcon from '../../../asset/icons/github.svg';
import LinkIcon from '../../../asset/icons/link.svg';
import TwitterIcon from '../../../asset/icons/twitter.svg';
import Icon from '../../Icons';

import { GithubOutlined, LinkOutlined, TwitterOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Input, Switch } from 'formik-antd';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;

const UserAbout = ({ isCompanyAdmin, userValue, isAdmin }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.userProfile);
  const [is_open_to_work, setis_open_to_work] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (user) {
      let { userProfile } = user;
      setis_open_to_work(userProfile.is_open_to_work);
    }
  }, [user]);

  let toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  const routeToSocialURL = (e, url) => {
    e.preventDefault()
    if (url)
      window.open(url);
  }
  return (
    <div className="about__wrapper">
      <div className="left__skill">
        <h4>{t('odp-pu.menu.footer.about')}</h4>
      </div>
      <div className="rigt__skill">
        <div className="about">
          <div className="input__wrapper" style={{ marginTop: '-15px' }}>
            <span className="inputLabels">{t('odp-pu.menu.footer.about')}</span>
            <TextArea
              placeholder={`${t('odp-pu.menu.footer.about')} (200 Max)`}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={200}
              name="userProfile.about"
              disabled={isCompanyAdmin}
            />
          </div>
        </div>

        <div className="upper__section row">
          <div className="col-12 col-md-6">
            <span className="inputLabels">URL LinkedIn</span>
            <Input
              label="URL LinkedIn"
              type="text"
              name="userProfile.linkedin_url"
              suffix={(isCompanyAdmin || isAdmin) && <button className='social-link--cta' onClick={(e) => { routeToSocialURL(e, userValue?.userProfile?.linkedin_url) }}><Icon src={LinkedInIcon} /></button>}
              placeholder="LinkedIn URL"
              className="inputFields"
              readOnly={isCompanyAdmin}
	      onClick={(e) => {if (isCompanyAdmin) {routeToSocialURL(e, userValue?.userProfile?.linkedin_url);}}}
            />
            <span className="error-text"></span>
          </div>
          <div className="col-12 col-md-6">
            <span className="inputLabels">URL Github</span>
            <Input
              label="URL GitHub"
              type="text"
              name="userProfile.github_url"
              suffix={(isCompanyAdmin || isAdmin) &&
                <button className='social-link--cta' onClick={(e) => { routeToSocialURL(e, userValue?.userProfile?.github_url) }}><Icon src={GithubIcon} /></button>
              }
              placeholder="GitHub URL"
              className="inputFields"
              readOnly={isCompanyAdmin}
              onClick={(e) => {if (isCompanyAdmin) {routeToSocialURL(e, userValue?.userProfile?.github_url);}}}	  
            />
            <span className="error-text"></span>
          </div>
        </div>
        <div className="lower__section row">
          <div className="col-12 col-md-6">
            <span className="inputLabels">URL Twitter</span>
            <Input
              label="URL Twitter"
              type="text"
              name="userProfile.twitter_url"
              suffix={(isCompanyAdmin || isAdmin) && <button className='social-link--cta' onClick={(e) => { routeToSocialURL(e, userValue?.userProfile?.twitter_url) }}><Icon src={TwitterIcon} /></button>}
              placeholder="Twitter URL"
              className="inputFields"
              readOnly={isCompanyAdmin}
              onClick={(e) => {if (isCompanyAdmin) {routeToSocialURL(e, userValue?.userProfile?.twitter_url);}}}
            />
            <span className="error-text"></span>
          </div>

          <div className="col-12 col-md-6">
            <span className="inputLabels">URL Link</span>
            <Input
              label="URL Link"
              type="text"
              name="userProfile.link_url"
              suffix={(isCompanyAdmin || isAdmin) && <button className='social-link--cta' onClick={(e) => { routeToSocialURL(e, userValue?.userProfile?.link_url) }}><Icon src={LinkIcon} /></button>}
              placeholder="Link URL"
              className="inputFields"
              readOnly={isCompanyAdmin}
              onClick={(e) => {if (isCompanyAdmin) {routeToSocialURL(e, userValue?.userProfile?.link_url);}}}	  
            />
            <span className="error-text"></span>
          </div>
        </div>
        <div className="open-to-work">
          {is_open_to_work ? (
            <span className={toggle ? 'work' : 'activeWork'}>
              {t('odp-pu.user.profile.open-to-work')}
            </span>
          ) : (
            <span className={toggle ? 'activeWork' : 'work'}>
              {t('odp-pu.user.profile.open-to-work')}
            </span>
          )}
          <div className="switch-wrapper">
            <Switch onChange={toggler} name="userProfile.is_open_to_work" disabled={isCompanyAdmin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAbout;
