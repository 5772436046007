import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Download from '../../asset/icons/downloadbtn.svg';
import privacyPolicyPdf from './odp_privacy_policy.pdf';

import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy__policy__container">
      <h5 className="bold__text">
        INFORMATIVA PRIVACY EX ARTT. 13 E 14 DEL REGOLAMENTO UE 2016/679 - PIATTAFORMA “OPEN DATA
        PLAYGROUND”
      </h5>
      <Table bordered responsive className="pp__table">
        <tbody>
          <tr>
            <td>
              <p className="bold__text">1. Definizioni</p>
              <p>
                Challenge, Condizioni Generali, Dati Personali, Form, GDPR, Informativa,
                Interessati, OST, Partecipanti, Partner, Piattaforma, Profilo, Profilo Linkedin,
                Premi, Regolamento, Registrazione, Servizi, Sponsor, Team, Titolare, Trattamento,
                Utente.
              </p>
            </td>
            <td>
              <ol>
                <li>
                  <span className="bold__text">Challenge:</span> processo competitivo online/onsite
                  organizzato sulla Piattaforma su indicazioni degli Sponsor volto ad individuare -
                  secondo i criteri indicati nel Regolamento - profili aventi determinate
                  caratteristiche (i.e. competenze in ambito e-skill) tra i Partecipanti.
                </li>
                <li>
                  <span className="bold__text">Condizioni Generali:</span> condizioni generali che
                  regolano il rapporto tra OST e l’Utente in merito all’utilizzo della Piattaforma
                  da parte dell’Utente e alla prestazione dei Servizi offerti da OST mediante la
                  Piattaforma stessa.
                </li>
                <li>
                  <span className="bold__text">Dati Personali:</span> qualunque informazione
                  relativa ad una persona fisica, identificata o identificabile, direttamente e
                  indirettamente, mediante riferimento al nome, ad un numero di identificazione, ai
                  dati di ubicazione, a un identificativo online o a elementi caratteristici della
                  sua identità fisica, fisiologica, genetica, psichica, economica, culturale o
                  sociale.
                </li>
                <li>
                  <span className="bold__text">Form:</span> il form presente sulla Piattaforma
                  mediante cui gli Utenti effettuano la Registrazione sulla Piattaforma.
                </li>
                <li>
                  <span className="bold__text">GDPR:</span> il regolamento UE 2016/679 del 27 aprile
                  2016.
                </li>
                <li>
                  <span className="bold__text">Informativa:</span> il presente documento relativo ai
                  trattamenti dei Dati Personali svolti da OST.
                </li>
                <li>
                  <span className="bold__text">Interessati:</span> le persone fisiche a cui si
                  riferiscono i Dati Personali, ai sensi dell’art. 4 del GDPR (e.g. gli Utenti).
                </li>
                <li>
                  <span className="bold__text">Newsletter:</span> le comunicazioni inviate da OST
                  aventi ad oggetto materiale informativo e pubblicitario (e.g. newsletter), nonché
                  la segnalazione di e l’invito a eventi e iniziative promozionali, relativi alla
                  Società, alla Piattaforma e/o agli Sponsor.
                </li>
                <li>
                  <span className="bold__text">OST:</span> Open Search Tech S.r.l., con sede legale
                  in Via G. Nicotera, 7, 00195, Roma, C.F., P.IVA e Registro delle Imprese di Roma
                  n. 15951221009.
                </li>
                <li>
                  <span className="bold__text">Partecipanti:</span> gli Utenti che si iscrivono e
                  partecipano a una determinata Challenge.
                </li>
                <li>
                  <span className="bold__text">Partner:</span> soggetti terzi, partner della
                  Società, di cui quest’ultima si avvale – ad esempio – per prestare i Servizi e/o
                  erogare i Premi.
                </li>
                <li>
                  <span className="bold__text">Piattaforma:</span> la piattaforma online “Open Data
                  Playground”.
                </li>
                <li>
                  <span className="bold__text">Profilo:</span> il profilo creato dall’Utente sulla
                  Piattaforma per la Registrazione.
                </li>
                <li>
                  <span className="bold__text">Profilo Linkedin:</span> il profilo Linkedin
                  dell’Utente.
                </li>
                <li>
                  <span className="bold__text">Premi:</span> premi di entità e natura diverse,
                  assegnati dallo Sponsor al Partecipante vincitore della Challenge, anche tramite
                  la Piattaforma e/o i Partner (e.g. possibilità di accedere a un processo di
                  recruitment organizzato dallo Sponsor ed eventuali partner in autonomia, somme di
                  denaro, gift card, offerte per l’acquisto di determinati beni o servizi,
                  riconoscimenti pubblici, ecc.).
                </li>
                <li>
                  <span className="bold__text">Regolamento:</span> il regolamento relativo a una
                  Challenge, predisposto dallo Sponsor che organizza tale Challenge e che disciplina
                  la partecipazione dei Partecipanti alla Challenge stessa.
                </li>
                <li>
                  <span className="bold__text">Registrazione:</span> creazione di un Profilo tramite
                  compilazione di un Form e/o tramite collegamento al Profilo Linkedin.
                </li>
                <li>
                  <span className="bold__text">Servizi:</span> i servizi offerti da OST all’Utente
                  tramite la Piattaforma, così come definiti nelle Condizioni Generali.
                </li>
                <li>
                  <span className="bold__text">Sponsor:</span> le persone giuridiche (e.g. società
                  di capitali, enti universitari, centri di ricerca e istituti di formazione
                  professionale) che organizzano le Challenge.
                </li>
                <li>
                  <span className="bold__text">Team:</span> i team di Partecipanti creato al fine di
                  partecipare, insieme agli altri Partecipanti facenti parte dello stesso Team, alle
                  Challenge esclusivamente riservate ai team.
                </li>
                <li>
                  <span className="bold__text">Titolare:</span> la persona fisica o giuridica,
                  l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad
                  altri, determina le finalità e i mezzi del trattamento di Dati Personali ai sensi
                  dell’art. 4 del GDPR.
                </li>
                <li>
                  <span className="bold__text">Trattamento:</span> qualsiasi operazione o insieme di
                  operazioni aventi ad oggetto i Dati Personali, quali, a titolo esemplificativo, la
                  raccolta, l’organizzazione, la strutturazione, la conservazione, la modifica,
                  l’estrazione, la consultazione, l’uso, la comunicazione, l’interconnessione, la
                  limitazione, la cancellazione e la distruzione.
                </li>
                <li>
                  <span className="bold__text">Utente:</span> gli utenti della Piattaforma.
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">2. Titolare del Trattamento</p>
              <p className="text-center">
                Open Search Tech S.r.l.{' '}
                <a href="mailto: privacy@opendataplayground.com" rel="noreferrer" target="_blank">
                  [privacy@opendataplayground.com]
                </a>
              </p>
            </td>
            <td>
              Il Titolare dei Trattamenti di cui al punto 3 della presente Informativa è{' '}
              <span className="bold__text">OST</span>. Qualsiasi richiesta degli Interessati
              relativa ai Trattamenti eseguiti da OST avente ad oggetto i propri Dati Personali (ivi
              incluso l’esercizio dei diritti di cui ai successivi punti 8 e 9), andrà rivolta a
              OST, a mezzo posta presso la sede legale, ovvero a mezzo e-mail all’indirizzo e-mail:{' '}
              <a href="mailto: privacy@opendataplayground.com" rel="noreferrer" target="_blank">
                privacy@opendataplayground.com
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">3. Finalità e base giuridica del Trattamento</p>
              <ul>
                <li>
                  consentire agli Utenti di creare il Profilo e usufruire dei Servizi:
                  sottoscrizione delle Condizioni Generali;
                </li>
                <li>
                  inviare comunicazioni di marketing e inviti alle Challenge agli Utenti: legittimo
                  interesse;
                </li>
                <li>gestire richieste di contatto: legittimo interesse;</li>
                <li>miglioramento dei Servizi e della Piattaforma: legittimo interesse.</li>
              </ul>
            </td>
            <td>
              <p>
                OST raccoglie e utilizza i Dati Personali degli Utenti per il conseguimento delle
                seguenti finalità:
              </p>
              <ul>
                <li>
                  <span className="bold__text">
                    Consentire agli Utenti di creare il Profilo e usufruire dei Servizi:
                  </span>{' '}
                  il Trattamento dei Dati Personali per la finalità di cui alla lettera a) è{' '}
                  <span className="bold__text">necessario</span> per la creazione del Profilo e per
                  la conseguente fruizione dei Servizi da parte dell’Utente, e l’eventuale rifiuto
                  impedisce agli Utenti di fruire dei Servizi previsti dalle Condizioni Generali.
                  Tale Trattamento si fonda sulla{' '}
                  <span className="bold__text">sottoscrizione delle Condizioni Generali</span> da
                  parte degli Utenti.
                </li>
                <li>
                  <span className="bold__text">
                    Inviare comunicazioni di marketing (e.g. newsletter, comunicazioni promozionali)
                    e inviti alle Challenge agli Utenti:
                  </span>{' '}
                  il Trattamento dei Dati Personali per la finalità di cui alla lettera b) è
                  facoltativo e l’eventuale rifiuto da parte dell’Utente impedisce a quest’ultimo di
                  ricevere comunicazioni di marketing relative a OST, alla Piattaforma e/o ai
                  Servizi, o gli inviti alle Challenge. Tale Trattamento si fonda sul{' '}
                  <span className="bold__text">legittimo interesse</span> di OST, costituito dal
                  beneficio che OST può ottenere dall’invio di comunicazioni, aventi ad oggetto OST,
                  la Piattaforma e/o i Servizi e/o inviti a Challenge che potrebbero interessare gli
                  Utenti, che OST ritiene essere prevalente rispetto al diritto alla riservatezza
                  degli Interessati. L’opposizione può essere esercitata in ogni tempo, seguendo le
                  indicazioni di cui al punto 9 della presente Informativa.
                </li>
                <li>
                  <span className="bold__text">
                    Gestire le richieste di contatto pervenute sulla Piattaforma attraverso la
                    compilazione del form di contatto da parte degli Utenti e/o tramite e-mail:
                  </span>{' '}
                  il Trattamento dei Dati Personali per la finalità di cui alla lettera c) è{' '}
                  <span className="bold__text">necessario</span> per la gestione delle richieste di
                  contatto inviate dall’Utente. Tale Trattamento si fonda sul{' '}
                  <span className="bold__text">legittimo interesse</span> di OST di gestire le
                  richieste di contatto degli Utenti, che OST ritiene essere prevalente rispetto al
                  diritto alla riservatezza degli Interessati. L’opposizione al presente Trattamento
                  (qualora ne sussistano i requisiti) può essere esercitata dall’Utente in ogni
                  tempo seguendo le indicazioni di cui al punto 8 lett. f) della presente
                  Informativa.
                </li>
                <li>
                  <span className="bold__text">
                    Eseguire analisi statistiche, ricerche di mercato, migliorare l’erogazione dei
                    Servizi e l’uso della Piattaforma
                  </span>
                  . Laddove possibile, per conseguire la presente finalità utilizziamo Dati
                  Personali anonimi (Dati Personali che non identificano o rendono identificabile
                  gli Utenti): il Trattamento dei Dati Personali di cui alla lettera d) è{' '}
                  <span className="bold__text">facoltativo</span> e l’eventuale rifiuto da parte
                  dell’Utente impedisce a quest’ultimo di fruire di Servizi più affini alle proprie
                  preferenze, senza alcun pregiudizio per la ricezione dei Servizi. Tale Trattamento
                  si fonda sul <span className="bold__text">legittimo interesse</span> di OST
                  costituito dal beneficio che OST può ottenere dall’offerta di Servizi più affini
                  alle preferenze manifestate dai propri Utenti durante la navigazione sulla
                  Piattaforma e la fruizione dei Servizi, che OST ritiene essere prevalente rispetto
                  al diritto alla riservatezza degli Utenti. L’opposizione al presente Trattamento
                  (qualora non eseguito con dati anonimi) può essere esercitata dall’Utente in ogni
                  tempo seguendo le indicazioni di cui al punto 8 lett. f) della presente
                  Informativa.
                </li>
                <li>
                  Previo facoltativo consenso,{' '}
                  <span className="bold__text">comunicare i Dati Personali ai Partner</span>, al
                  fine di consentire a tali Partner di inviare agli Utenti comunicazioni
                  promozionali aventi ad oggetto i propri prodotti e servizi: il Trattamento dei
                  Dati Personali di cui alla lettera e) è{' '}
                  <span className="bold__text">facoltativo</span> e l’eventuale rifiuto da parte
                  dell’Utente impedisce a OST di comunicare i Dati Personali ai Partner, e agli
                  Utenti di ricevere le comunicazioni promozionali aventi ad oggetto i prodotti e
                  servizi dei Partner. Tale Trattamento si fonda sul{' '}
                  <span className="bold__text">consenso</span> libero, specifico, informato ed
                  inequivocabile dell’Utente, espresso mediante dichiarazione o azione positiva
                  (e.g. flag) sulla Piattaforma. La revoca del consenso può essere esercitata
                  dall’Utente in ogni tempo, seguendo le indicazioni di cui al punto 8 lett. a)
                  della presente Informativa.
                </li>
                <li>
                  Previo facoltativo consenso, inviare le Newsletter all’Utente: il Trattamento dei
                  Dati Personali di cui alla lettera f) è{' '}
                  <span className="bold__text">facoltativo</span> e l’eventuale rifiuto da parte
                  dell’Utente impedisce a quest’ultimo di ricevere le Newsletter. Tale Trattamento
                  si fonda sul <span className="bold__text">consenso</span> libero, specifico,
                  informato ed inequivocabile dell’Utente, espresso mediante dichiarazione o azione
                  positiva (e.g. completamento del relativo form) sulla Piattaforma. La revoca del
                  consenso può essere esercitata in ogni tempo, seguendo le indicazioni di cui al
                  punto 8 lett. a) della presente Informativa.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">4. Modalità di raccolta dei dati</p>
              <ul>
                <li>Dati Personali inseriti nella Piattaforma dagli Utenti;</li>
                <li>Dati Personali espressamente comunicati a OST dagli Utenti;</li>
                <li>Dati Personali comunicati dagli Sponsor.</li>
              </ul>
            </td>
            <td>
              <p>OST raccoglie i Dati Personali con le seguenti modalità:</p>
              <ul>
                <li>
                  Dati Personali inseriti nella Piattaforma dagli Utenti: sono i Dati Personali
                  forniti, a titolo esemplificativo e non esaustivo, per registrarsi alla
                  Piattaforma e usufruire dei Servizi;
                </li>
                <li>
                  Dati Personali espressamente comunicati a OST dagli Utenti: sono i Dati Personali
                  forniti, a titolo esemplificativo, al servizio clienti di OST (in caso di
                  richiesta di contatto) ovvero raccolti in occasione di Challenge e/o iniziative
                  organizzati da OST;
                </li>
                <li>
                  Dati Personali degli Utenti comunicati dagli Sponsor: sono i Dati Personali
                  comunicati ad OST direttamente dagli Sponsor (e.g. società di recruiting) che
                  hanno organizzato una Challenge, in caso di Challenge destinate a Utenti/Team
                  privati selezionati dagli Sponsor stessi.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">5. Eventuali categorie di destinatari dei Dati Personali</p>
              <p>
                Dipendenti, collaboratori, Sponsor, Partner, società dello stesso gruppo societario
                di OST, fornitori di servizi IT, terzi necessari per consentire la prestazione dei
                Servizi, consulenti terzi, terzi tecnici e commerciali per la gestione della
                Piattaforma, servizi automatici di comunicazioni di marketing.
              </p>
            </td>
            <td>
              <p>
                I Dati Personali sono trattati da OST e/o da soggetti terzi, selezionati per
                affidabilità e competenza, ed a cui possono essere comunicati in quanto ciò sia
                necessario od opportuno. In particolare, OST informa gli Utenti che i Dati Personali
                possono essere trattati da, e/o comunicati a:
              </p>
              <ul>
                <li>dipendenti e/o collaboratori di OST;</li>
                <li>Sponsor e Partner;</li>
                <li>
                  società facenti parte del medesimo “gruppo societario” di cui la Società è parte;
                </li>
                <li>
                  soggetti terzi fornitori di servizi necessari a garantire il funzionamento della
                  Piattaforma (a titolo esemplificativo, società che si occupa dei servizi di
                  hosting);
                </li>
                <li>
                  soggetti terzi fornitori di servizi necessari a garantire la prestazione dei
                  Servizi (a titolo esemplificativo, gestori dei pagamenti);
                </li>
                <li>
                  soggetti terzi che forniscono servizi di consulenza e assistenza (e.g. consulente
                  fiscale);
                </li>
                <li>
                  soggetti terzi che forniscono servizi di analisi dei dati di navigazione sulla
                  Piattaforma e/o servizi automatici di invio di newsletter e/o qualsiasi altro tipo
                  di comunicazione di marketing.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">
                6. Periodo di conservazione dei Dati Personali (o criteri per la determinazione)
              </p>
              <ul>
                <li>
                  Dati Personali per la fruizione dei Servizi: durata delle Condizioni Generali e
                  per i successivi 26 mesi successivi all’ultima interazione dell’Utente con la
                  Piattaforma;
                </li>
                <li>
                  Dati Personali per le comunicazioni di marketing diretto e di invito a Challenge e
                  per l’invio di Newsletter agli Utenti: 24 mesi successivi all’ultima interazione
                  dell’Utente con la Piattaforma;
                </li>
                <li>Dati Personali del form “contatti”: 3 mesi dalla ricezione della richiesta;</li>
                <li>
                  Dati Personali per la comunicazione ai Partner: 24 mesi dalla prestazione del
                  consenso;
                </li>
                <li>
                  Dati Personali per obblighi legali o tutela giudiziaria: 10 anni dalla raccolta o
                  fino al termine dell’accertamento o procedimento giudiziario.
                </li>
              </ul>
            </td>
            <td>
              <p>
                I Dati Personali sono conservati da OST per il periodo di tempo strettamente
                necessario al conseguimento delle finalità per le quali sono stati raccolti, come
                indicato al punto 3 che precede. In particolare, salvo esercizio del diritto di
                revoca del consenso di cui al successivo punto 8 lett. a) o del diritto di
                opposizione di cui al punto 8 lett. f) e di cui al punto 9, OST conserva i Dati
                Personali degli Utenti per i seguenti termini:
              </p>
              <ul>
                <li>
                  Dati Personali per la creazione del Profilo e fruizione dei Servizi: per la
                  finalità di cui al punto 3 lettera a) per il periodo di durata delle Condizioni
                  Generali e per un ulteriore periodo di <span className="bold__text">26 mesi</span>{' '}
                  dopo l’ultima interazione dell’Utente con la Piattaforma, fermo restando quanto
                  previsto al punto 6, lettera e);
                </li>
                <li>
                  Dati Personali per le comunicazioni di marketing e di invito a Challenge agli
                  Utenti nonché per le Newsletter: per le finalità di cui al punto 3 lettere b) e
                  f), per un periodo di <span className="bold__text">24 mesi </span>
                  successivi all’ultima interazione da parte dell’Utente con la Piattaforma, idoneo
                  a dimostrare un interesse per i Servizi e/o le Challenge;
                </li>
                <li>
                  Dati Personali per la gestione delle richieste di contatto: per la finalità di cui
                  al punto 3 lettera c), per un periodo di{' '}
                  <span className="bold__text">3 mesi</span> dalla ricezione della richiesta di
                  contatto inviata dall’Utente mediante compilazione dell’apposito form sulla
                  Piattaforma. In deroga a tale termine, in caso di invio a mezzo del form
                  “contatti” di un reclamo o di una lamentela, i Dati Personali dell’Utente verranno
                  conservati per il periodo di cui alla successiva lettera e);
                </li>
                <li>
                  Dati Personali per la comunicazione degli stessi ai Partner: per la finalità di
                  cui al punto 3 lettera e), per un periodo di{' '}
                  <span className="bold__text">24 mesi</span> successivi alla prestazione del
                  consenso facoltativo alla comunicazione da parte dell’Utente, espresso mediante
                  flag sulla Piattaforma;
                </li>
                <li>
                  in ogni caso, OST è autorizzata a conservare, in tutto o in parte, i Dati
                  Personali per il periodo massimo di <span className="bold__text">10 anni</span>{' '}
                  dalla raccolta, limitatamente alle informazioni necessarie per adempiere ad
                  obblighi legali e consentire a OST stessa l’eventuale accertamento, esercizio e
                  difesa di un proprio diritto in sede giudiziaria, ovvero per un periodo maggiore
                  qualora l’eventuale accertamento o procedimento giudiziario avesse una durata
                  superiore a 10 anni.
                </li>
              </ul>
              <p>
                Decorsi tali termini, OST provvederà alla cancellazione automatica dei Dati
                Personali raccolti, ovvero alla loro trasformazione in forma anonima in maniera
                irreversibile.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">7. Trasferimento Dati Personali extra SEE</p>
              <p>Sì.</p>
            </td>
            <td>
              <p>
                I Dati Personali raccolti da OST per le finalità di cui al punto 3 della presente
                Informativa, sono trasmessi da OST, ai sensi degli artt. 44 e ss. del GDPR, in virtù
                di garanzie adeguate ad assicurare la tutela di Dati Personali, a:
              </p>
              <ul>
                <li>
                  Open Search Network Ltd., con sede legale in 32 Kilmaine Rd, London SW6 7JX, Regno
                  Unito, in virtù della decisione di adeguatezza della Commissione Europea C-(2021)
                  4800 del 28/06/2021;
                </li>
                <li>
                  Bulhorn Inc., con sede legale in 100 Summer Street, Boston (MA), in attesa della
                  decisione di adeguatezza della Commissione Europea (nel rispetto dei principi del
                  Trans-Atlantic Data Privacy Framework), in virtù di garanzie adeguate previste dal
                  Regolamento.
                </li>
              </ul>
              <p>
                Maggiori informazioni in merito alle garanzie adottate possono essere richieste a
                OST.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">8. Diritti dell’Utente</p>
              <p>
                Revoca del consenso, accesso ai Dati Personali, rettifica o integrazione dei Dati
                Personali, cancellazione dei Dati Personali, limitazione del Trattamento,
                opposizione al Trattamento, portabilità dei Dati Personali, data breach, reclamo
                alle autorità competenti
              </p>
            </td>
            <td>
              <p>
                Ai sensi e per gli effetti del GDPR, per il periodo di cui al punto 6 della presente
                Informativa, ciascun Utente ha diritto di:
              </p>
              <ul>
                <li>
                  con riferimento ai Trattamenti di cui al punto 3 lett. e) e f), revocare il
                  consenso in qualsiasi momento senza pregiudicare la liceità del Trattamento,
                  mediante invio di un’e-mail a OST (cfr. art. 7 del GDPR);
                </li>
                <li>
                  chiedere a OST l’accesso ai Dati Personali e alle informazioni del relativo
                  Trattamento ed eventuale copia in formato elettronico, salvo specifica diversa
                  richiesta dell’Utente (cfr. art. 15 del GDPR);
                </li>
                <li>
                  chiedere la rettifica e/o l’integrazione dei Dati Personali, senza ingiustificato
                  ritardo (cfr. art. 16 del GDPR);
                </li>
                <li>
                  per specifici motivi (ad esempio, trattamento illecito, revoca del consenso,
                  insussistenza della finalità del Trattamento), chiedere la cancellazione dei Dati
                  Personali, senza ingiustificato ritardo (cfr. art. 17 del GDPR);
                </li>
                <li>
                  al ricorrere di specifiche ipotesi (ad esempio, inesattezza dei Dati Personali,
                  illiceità del Trattamento, esercizio di un diritto in sede giudiziaria), chiedere
                  la limitazione del Trattamento (art. 18 del GDPR);
                </li>
                <li>
                  con riferimento ai Trattamenti di cui al punto 3 lettere c) e d) opporsi in
                  qualsiasi momento al Trattamento dei Dati Personali (qualora non utilizzati in
                  forma anonima), mediante l’invio di un’e-mail a OST (cfr. art. 21 del GDPR);
                </li>
                <li>
                  in caso di Trattamento automatizzato, ricevere i Dati Personali in formato
                  leggibile, ai fini della relativa comunicazione a un soggetto terzo, ovvero,
                  laddove tecnicamente fattibile, chiedere la trasmissione dei Dati Personali da
                  parte di OST direttamente a tale soggetto terzo (cd. diritto alla portabilità dei
                  Dati Personali - cfr. art. 20 del GDPR);
                </li>
                <li>
                  essere informato da OST senza ingiustificato ritardo di eventuali violazioni o
                  accessi non autorizzati da parte di terzi ai propri sistemi contenenti i Dati
                  Personali (c.d. data breach - cfr. art. 34 del GDPR);
                </li>
                <li>
                  proporre reclamo all’autorità di controllo del Paese UE in cui risiede, lavora
                  ovvero in cui ritiene si sia verificata la violazione dei propri diritti (cfr.
                  art. 77 del GDPR).
                </li>
              </ul>
              <p>
                Per ulteriori informazioni in merito ai termini e alle condizioni per l’esercizio
                dei diritti riconosciuti all’Utente, quest’ultimo può consultare il testo del GDPR
                pubblicato al seguente link, ovvero contattare OST nelle forme previste dal punto 2
                della presente Informativa.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p className="bold__text">
                9. Opposizione al Trattamento relativo alle comunicazioni di marketing e invito ad
                Challenge di OST agli Utenti
              </p>
            </td>
            <td>
              <p>
                OST evidenzia che ciascun Utente ha diritto di opporsi al Trattamento dei Dati
                Personali di cui al punto 3 lettera b), in ogni tempo e senza alcuna motivazione,
                mediante invio di un’e-mail ad OST ovvero esercizio dell’opt-out nelle comunicazioni
                di marketing di OST. In caso di esercizio di tale diritto, OST interromperà
                l’utilizzo dei Dati Personali dell’Utente per le finalità di marketing relative
                Servizi simili a quelli in precedenza fruiti dall’Utente stesso.
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="pp__button">
        <Link
          className="secondy pp__pdf__download__button"
          to={privacyPolicyPdf}
          target="_blank"
          download>
          <img src={Download} alt="download Privacy Policy" />
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
