import React from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

class GAListener extends React.Component {
  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
    this.sendPageView(this.props.history.location);
    this.props.history.listen(this.sendPageView);
  }

  sendPageView = (location) => {
    ReactGA.set({
      page: location.pathname + this.props.parameters
    });
    ReactGA.pageview(location.pathname + this.props.parameters);
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(GAListener);
