import Decide from '../../asset/images/general/decide.svg';
import { Spin } from 'antd';
import { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { verficationToken } from '../../services/auth/api';
const Index = () => {
  // const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  let query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    let hash = query.get('hash');

    verficationToken(dispatch, {
      data: {
        hash,
      },
    });
  }, []);

  return (
    <div className="development__wrapper">
      <Spin />
      <h1>Verification</h1>
      <h3>Verification is underprocess</h3>
      <p className="pg-regular">
        Please wait for a moment, we are working on your request.
      </p>

      <img src={Decide} alt={Decide} />
    </div>
  );
};

export default Index;
