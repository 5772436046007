import { StringConverter, NumberConverter } from '../../utils';
import { PrizePoints } from './PrizePoints';
import { PrizeRanking } from './PrizeRanking';

export class Prizes {
    prize_id = null;
    prize_set_id = null;
    prize_type = 1;
    prize_ranking = [];
    prize_points = [];

    constructor() {
        this.prize_id = null;
        this.prize_set_id = null;
        this.prize_type = 1;
        this.prize_ranking = [];
        this.prize_points = [];
    }

    deserialize(model) {
        this.prize_id = StringConverter(model.prize_id);
        this.prize_set_id = StringConverter(model.prize_set_id);
        this.prize_type = NumberConverter(model.prize_type || 1);

        if (model.prize_ranking && model.prize_ranking.length > 0) {
            for (let index = 0; index < model.prize_ranking.length; index++) {
                if ('value' in model.prize_ranking[index]) {
                    model.prize_ranking[index].position = index + 1;
                    this.prize_ranking.push(new PrizeRanking().deserialize(model.prize_ranking[index]));
                }
            }
        }

        if (model.prize_points && model.prize_points.length > 0) {
            for (let index = 0; index < model.prize_points.length; index++) {
                this.prize_points.push(new PrizePoints().deserialize(model.prize_points[index]));
            }
        }

        return this;

    }

}