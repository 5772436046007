import * as yup from "yup";

export const schema = yup.object().shape({
  firstName: yup.string().required("First Name is Required ").min(1).max(10),
  email: yup
    .string()
    .email("should be match email")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});

export const loginSchema = yup.object().shape({
  username: yup.string().required("odp-pu.login.error-message.email-is-required").nullable(),
  password: yup.string().required("odp-pu.login.error-message.password-is-required").nullable(),
});

export const contactFormSchema = yup.object().shape({
  email: yup.string().email('Invalid Email').required(" ").nullable(),
  name: yup.string().required(" ").nullable(),
  body: yup.string().required(" ").nullable()
});

export const signUpValidatorSchema = yup.object().shape({
  email: yup.string().required("odp-pu.login.error-message.email-is-required").nullable(),
  password: yup.string().required("odp-pu.login.error-message.password-is-required").nullable().min(8, 'Length must be greater than 8 characters.')
    .matches(/(?=.*?[A-Z])/, 'Password must contain uppercase letters')
    .matches(/(?=.*[0-9])/, 'Password must contain numbers'),
  confirmPassword: yup.string().required("odp-pu.sign-up.error-message.confirm-password-is-required").nullable().oneOf([yup.ref('password'), null], 'Passwords do not match'),
  //allow_newsletter: yup.boolean().required('Newsletter is Required').isTrue(),
  // termsConditions: yup.boolean().required('Terms & Condition is Required').isTrue(),
});

export const forgotPasswordSchema = yup.object().shape({
  verification_email: yup
    .string()
    .required("Email is Required")
    .email("Please enter valid Email")
});

export const changePasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .required("Password is Required")
    .matches(/^(?=.*\d).+$/, "number")
    .matches(/[A-Z]+/, "upperCase")
    .min(8, "length"),
});

export const challengeSchema = yup.object().shape({
  competition_name: yup.string().required("Name is Required").nullable(),
  competition_description: yup.string().required("Description is Required").nullable(),
  competition_type_id: yup.string().required("Name is Required").nullable(),
  company_id: yup.string().required('Company is required').nullable(),
  challengeproperty: yup.object().shape({
    start_date: yup.string().required("start_date").nullable(),
    end_date: yup.string().required("end_date").nullable(),
    start_time: yup.string().required("start_time").nullable(),
    end_time: yup.string().required("end_time").nullable(),
    competition_details: yup.string().nullable(),
    competition_rules_description: yup.string().nullable(),
    competition_prize_description: yup.string().nullable(),
    data_set_file_id: yup.string().nullable(),
    solution_file_id: yup.string().nullable(),
    competition_location_type: yup.string().nullable(),
    winning_prize: yup.string().required().nullable(),
    competition_location_url: yup.string().when('competition_location_type', { is: "1", then: yup.string().required("competition_location_url") }).nullable(),
    participation_type: yup.string().required('participation_type').nullable(),
    privacy_mode: yup.number().nullable(),
    private_domain: yup.string().when('privacy_mode', { is: 2, then: yup.string().required("private_domain") }).nullable(),
    competition_category_id: yup.string().required("competition_category_id").nullable(),
    limit_participation_for_days: yup.string().when('is_limited_participation', { is: true, then: yup.string().required("limit_participation_for_days") }).nullable(),

  }),
});

export const sponsorSchema = yup.object().shape({
  company_name: yup.string().required("Name is Required").nullable(),
  logo_file_id: yup.string().nullable(),
  banner_file_id: yup.string().nullable(),
  company_website: yup.string().matches(
    /((https!):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    'Enter correct url!'
  ).required("Company Website is Required").nullable(),
  privacy_policy_url: yup.string().matches(
    /((https!):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    'Enter correct url!'
  ).nullable(),
})
