import { put, select, takeEvery, takeLatest, call } from "redux-saga/effects";
import { GET_USER, SAVE_USER } from "../actions/userAction";
import { GET_DOWNLOAD_DATA } from "../actions/userAction";
import { axiosInstance } from "../../configs/axois";
import { failure, request, success } from "../../utils/fetch";
import { FETCH_TEAM_BY_ID } from "../actions/teamAction";
import { notify } from "../../theme/antNotify";

function* fetchTeamById({ teamId }) {
  try {
    yield put({ type: request(FETCH_TEAM_BY_ID) });
    const { data } = yield call(axiosInstance.get, `team/${teamId}`);
    yield put({ type: success(FETCH_TEAM_BY_ID), response: data });
  } catch (error) {
    yield put({ type: failure(FETCH_TEAM_BY_ID), error });
  }
}

function* fetchUserById({ userId }) {
  try {
    yield put({ type: request(GET_USER) });
    const { data } = yield call(axiosInstance.get, `user/${userId}`, { params: { is_my_company: true } });
    yield put({ type: success(GET_USER), response: data });
  } catch (error) {
    yield put({ type: failure(GET_USER), error });
  }
}

function* fetchDownloadData() {
  try {
    yield put({ type: request(GET_DOWNLOAD_DATA) });
    const { data } = yield call(axiosInstance.get, `user/data/download`);
    yield put({ type: success(GET_DOWNLOAD_DATA), response: data });
  } catch (error) {
    yield put({ type: failure(GET_DOWNLOAD_DATA), error });
  }
}

function* saveUserSaga({ payload }) {
  try {
    yield put({ type: request(SAVE_USER) });
    const { data } = yield call(axiosInstance.patch, `user`, payload);
    yield put({ type: success(SAVE_USER), response: data });
    notify('success', 'Profile successfully Updated')
  } catch (error) {
    yield put({ type: failure(SAVE_USER), error });
  }
}
export default [
  takeLatest(GET_USER, fetchUserById),
  takeLatest(GET_DOWNLOAD_DATA, fetchDownloadData),
  takeLatest(FETCH_TEAM_BY_ID, fetchTeamById),
  takeLatest(SAVE_USER, saveUserSaga),
];
