import React from "react";
import quoteSvg from "../../asset/icons/static-pages-svgs/quote.svg";

import "./quotesSection.scss";

const QuotesSection = ({ text, title, quote, author }) => {
  return (
    <div className="quotes__wrapper">
      <h1 className="quotes__heading">{title}</h1>
      <div className="quote__section">
        <img src={quoteSvg} className="quote__icon" alt="quoteSvg" />
        <p className="quote__paragraph">{quote}</p>
        <p className="quote__author">{author}</p>
      </div>
      <div className="quotes__description">
        <p>{text.quotesDescription1}</p>
        <p>{text.quotesDescription2}</p>
        <p>{text.quotesDescription3}</p>
        <p>{text.quotesDescription4}</p>
      </div>
    </div>
  );
};

export default QuotesSection;
