import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './forgotPassword.module.scss';
import { AButton } from '../../theme/button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from '../../helpers/schemas';
import { Input } from 'antd';
import ErrorMessage from '../../components/ErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordAction } from '../../redux-store/actions/authenticationAction';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { forgotPasswordLoad } = useSelector((state) => state.authentication);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(forgotPasswordSchema)
  });

  const confirmForgotPassword = (requestObject) => {
    console.log('asdas', requestObject);
    dispatch(forgotPasswordAction(requestObject));
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.forgotPasswordForm}>
        <div className={Styles.header}>
          <h1>Reset Password</h1>
          <p className="pg-regular">
            Enter the email associated with your account and we'll send an email with instructions
            to reset your passwords
          </p>
        </div>

        <div className={Styles.body}>
          <div className={Styles.formGroup}>
            <Controller
              name="verification_email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="Email address"
                  className={errors.verification_email?.type?.min ? 'inputError' : ''}
                />
              )}
            />
            {errors.verification_email && (
              <ErrorMessage errorMessage={errors.verification_email.message} />
            )}
            <div className={Styles.buttonBlock}>
              <AButton
                onClick={handleSubmit(confirmForgotPassword)}
                label="Reset Password"
                type="regular"
                loading={forgotPasswordLoad}
                // disabled={loading === "pending" ? true : false}
              ></AButton>
            </div>
          </div>
        </div>
        <div className={Styles.footer}>
          <span className="pg-regular gray">Already have login and password ?</span>
          <Link className={Styles.signInLink} to="login">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
