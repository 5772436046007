import { Routes } from '../helpers/links';
import { Switch } from 'react-router-dom';
import ProtectedRoutes from './protectedRoutes';
const RuterView = () => {
  const AllRoutes = Routes.map((route) => {
    return (
      <ProtectedRoutes
        component={route.component}
        path={route.path}
        exact={route.exact}
        key={route.path}
        auth={route.auth}
      />
    );
  });
  return (
    <div className="router__view">
      <Switch>{AllRoutes}</Switch>
    </div>
  );
};
export default RuterView;
