export const StringConverter = (value) => {
    if (value === null || value === undefined || typeof value === "string")
        return value;

    return value.toString();
}

export const BooleanConverter = (value) => {
    if (value === null || value === undefined || typeof value === "boolean")
        return value;

    return value.toString().toLowerCase() == "false" ? false : true;
}

export const NumberConverter = (value) => {
    if (value === null || value === undefined || typeof value === "number")
        return value;

    return Number(value.toString());
}