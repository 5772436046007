import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';

export class UserWorkExperience {
    user_experience_detail_id = null;
    job_title = null;
    company_name = null;
    job_description = null;
    is_current_job = false;
    start_date = null;
    end_date = null;
    constructor() {
        this.user_experience_detail_id = null;
        this.job_title = null;
        this.company_name = null;
        this.job_description = null;
        this.is_current_job = false;
        this.start_date = null;
        this.end_date = null;
    }

    deserialize(model) {
        this.user_experience_detail_id = StringConverter(model.user_experience_detail_id || null);
        this.job_title = StringConverter(model.job_title);
        this.company_name = StringConverter(model.company_name);
        this.job_description = StringConverter(model.job_description);
        this.is_current_job = BooleanConverter(model.is_current_job);
        this.start_date = new Date(StringConverter(model.start_date)).toISOString();
        this.end_date = new Date(StringConverter(model.end_date)).toISOString();
        return this;
    }

}