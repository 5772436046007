import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import Bell from "../../asset/images/header/bell.svg";
import { useState } from "react";
import { CustomModal } from "../CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../redux-store/actions/notificationActions";
import { respondInvite } from "../../services/team/api";
import { Badge, Spin } from "antd";
import OutsideAlerter from "../OutsideClick";

import { getUserID, getToken } from "../../services/token";
import { useEffect } from "react";
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000000' }} spin />;

const Notification = () => {
  const [isNotifyActive, setisNotifyActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.Notification.notifications
  );
  const notificationStatus = useSelector(
    (state) => state.Notification.loading
  );

  const modalText = `You are about to join this team: test your skills and take part in the challenges!`;
  const infoText = `As team member you will be able to submit solutions for your team.
  After the start of challenge it will no longer be possible to leave the team.`;

  const onNotificationIconClick = () => {
    setisNotifyActive(!isNotifyActive);
    dispatch(getNotification(null));
  };

  const notificationClick = (notification) => {
    setisNotifyActive(!isNotifyActive);
    if (notification.notification_type === 1) {
      setVisible(true);
    }
    setSelectedNotification(notification);
  };

  const leftBtnAction = () => {
    const payload = {
      team_id: selectedNotification.request.team_id,
      invitee_email: selectedNotification.request.invitee_email,
      invitation_status: 3,
      notification_id: selectedNotification.notification_id,
    };
    respondInvite(payload, selectedNotification.request.team_invitation_id);
    setVisible(false);
  };
  const rightBtnAction = () => {
    const payload = {
      team_id: selectedNotification.request.team_id,
      invitee_email: selectedNotification.request.invitee_email,
      invitation_status: 2,
      notification_id: selectedNotification.notification_id,
    };
    respondInvite(payload, selectedNotification.request.team_invitation_id);
    setVisible(false);
  };

  useEffect(() => {
    if (getUserID() != null)
      dispatch(getNotification(null));
  }, [])

  return (
    <div className="notify__menu">
      <div className="notfiyicon" onClick={onNotificationIconClick}>
        <Badge count={notifications?.length} showZero>
          <img src={Bell} alt={Bell} className="icon" />
        </Badge>
        {/* <span className="isNotification"></span> */}
      </div>
      <OutsideAlerter onClick={() => { setisNotifyActive(false); }}>
        <div
          className={
            isNotifyActive ? "notify__dropdown ative" : "notify__dropdown"
          }
        >
          {notificationStatus != 'pending' ? <>
            {notifications && notifications.length > 0 ? (
              notifications?.map((noti) => (
                <ul key={noti.team_name}>
                  <li>
                    <span
                      // to={`/profile/${getUserID()}/profile`}
                      onClick={() => notificationClick(noti)}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <span className={`${noti.is_seen ? "" : "noneread"}`}></span>
                      <p className="msg">
                        {noti.notification_type === 1
                          ? `Join the ${noti.team_name} team!, You have an invitation to join the ${noti.team_name}`
                          : ""}{" "}
                      </p>
                      <ArrowRightOutlined className="arrow" />
                    </span>
                  </li>
                </ul>
              ))
            ) : (
              <h5 style={{ textAlign: "center", marginTop: 50 }}>
                No notifications yet
              </h5>
            )}
          </> : <h5 style={{ textAlign: "center", marginTop: 50 }}>
            <Spin indicator={antIcon} />
          </h5>}
        </div>
      </OutsideAlerter>
      <CustomModal
        visible={visible}
        setVisible={setVisible}
        setConfirmLoading={setConfirmLoading}
        loading={confirmLoading}
        modalText={modalText}
        leftButtonLabel={"Decline invitation"}
        leftButtonAction={leftBtnAction}
        rightButtonLabel={"Join the team"}
        rightButtonAction={rightBtnAction}
        title={`Join the ${selectedNotification.team_name}`}
        infoText={infoText}
      />
    </div>
  );
};

export default Notification;
