import { axiosInstance } from '../../configs/axois';

export const submitQueryForm = async (payload) => {
    try {
        const { data } = await axiosInstance.post('email/contactUs', payload);
        return data;
    } catch (err) {
        throw err;
    }
}
