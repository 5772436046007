import { notify } from '../theme/antNotify';

export const ImageUploader = (file) => {
  if (
    file.type === 'image/png' ||
    file.type === 'image/gif' ||
    file.type === 'image/jpeg' ||
    file.type === 'image/webp'
  ) {
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = (e) => {
      return reader.result;
    };
  } else {
    notify('error', 'Picture Upload Error Only picture can upload here');
  }
};

export const bufferToString = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
