import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Styles from "./challengeTabs.module.scss";
import SearchBox from "../../admin/Components/SearchBox";
import Table from "../../admin/Components/Table";
import TableBody from "../../admin/Components/Table/tableBody";
import TableHead from "../../admin/Components/Table/tableHead";
import Pagination from "../Pagination";
import {
  closeChallengeAction,
  getLeaderboardbyChallengeId,
  getLeaderboardListings,
  resetCloseChallengeAction,
} from "../../redux-store/actions/ChallengeAction";
import { styles } from "dom7";
import { Empty, Modal, Spin, Menu, Dropdown } from "antd";
import TableFooter from "../../admin/Components/Table/tableFooter";
import { buildParams, PaginationModel } from "../../utils";
import Icon from "../Icons";
import Button from "../Button";
import user from "../../asset/icons/user.svg";
import points from "../../asset/icons/points.svg";
import dots from "../../asset/icons/threeDots.svg";
import exclamationCircle from "../../asset/icons/exclamation-circle.svg";
import UserImage from "../../asset/images/user.svg";
import trophy from "../../asset/icons/trophy.svg";
import xCircle from "../../asset/icons/x-circle.svg";
import tickCircleDark from "../../asset/icons/tick-circle-dark.svg";
import timer from "../../asset/icons/timer.svg";
import entries from "../../asset/icons/entries.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  closeChallengeService,
  updateParticipantStatus,
} from "../../redux-store/services/Challenges";
import { notify } from "../../theme/antNotify";
import { downloadLeaderBoardFile } from "../../redux-store/services/File";
import FileSaver from "file-saver";
import SortHeader from "../../admin/Components/Table/SortHeader";
import { useTranslation } from "react-i18next";

const antIcon = <LoadingOutlined style={{ fontSize: 22 }} spin />;

const Leaderboard = ({ challengeId, isLoggedIn, isAdmin, challengeStatus }) => {
  const [currentRecord, setCurrentRecord] = useState({});
  const leaderBoardFilters = {};
  leaderBoardFilters.pagination = new PaginationModel();
  const [challengeCloseModal, setChallengeCloseModal] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  leaderBoardFilters.challengeId = challengeId;
  const [currentPage, setCurrentPage] = useState(1);
  const [isChallengeClosed, setIsChallengeClosed] = useState(false);
  const dispatch = useDispatch();
  const SkeletonArr = [1, 2, 3, 4, 5];
  const [searchText, setSearchText] = useState("");
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const { listings, loading, _metadata } = useSelector(
    (state) => state.leaderBoard
  );

  const { challengeId: closeChallengeId, loading: closeChallengeLoading } =
    useSelector((state) => state.closedChallenge);

  useEffect(() => {
    getLeaderBoardListings(leaderBoardFilters);
  }, []);

  useEffect(() => {
    if (closeChallengeId != null) {
      setIsChallengeClosed(true);
      setChallengeCloseModal(false);
    }
  }, [closeChallengeId]);

  useEffect(() => {
    if (isComponentMounted) {
      leaderBoardFilters.pagination.pageNum = currentPage;
      getLeaderBoardListings(leaderBoardFilters);
    }
    setIsComponentMounted(true);
  }, [currentPage]);

  const closeChallenge = () => {
    const payload = {
      challengeId,
      action: 1,
    };
    dispatch(closeChallengeAction(payload));
  };

  const handleCancel = () => {
    setChallengeCloseModal(false);
  };

  const searchLeaderBoard = () => {
    leaderBoardFilters.searchText = searchText;
    leaderBoardFilters.searchBy = "display_name";
    getLeaderBoardListings(leaderBoardFilters);
  };

  const getLeaderBoardListings = (filters) => {
    let params = buildParams(filters);
    dispatch(getLeaderboardbyChallengeId(params));
  };

  const downloadLeaderBoard = async () => {
    try {
      setDownloadLoading(true);
      const { content, fileName, mimeType } = await downloadLeaderBoardFile(
        challengeId
      );

      if (content && content.length > 0) {
        let ab = new ArrayBuffer(content.length);
        var view = new Uint8Array(ab);
        for (var i = 0; i < content.length; i++) {
          view[i] = content[i];
        }
        var file = new Blob([ab], { type: mimeType });
        FileSaver.saveAs(file, fileName);
      }

      setDownloadLoading(false);
    } catch (e) {
      setDownloadLoading(false);
    }
  };

  const sortColumn = () => {
    if (isComponentMounted) {
      leaderBoardFilters.sortBy = sortOrder;
      leaderBoardFilters.orderField = sortBy;
      getLeaderBoardListings(leaderBoardFilters);
    }
  };

  const markSubmissionStatus = async () => {
    // alert(currentRecord.team_id)
    let { participant_id, participant_status } = currentRecord;
    let payload = {
      challengeId,
      participant_id,
      participant_status: participant_status == 1 ? "BLOCKED" : "ACTIVE",
    };
    const response = await updateParticipantStatus(payload);
    getLeaderBoardListings(leaderBoardFilters);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        {currentRecord.participant_status == 2 && (
          <a onClick={markSubmissionStatus}>
            <Icon src={tickCircleDark} /> <span>Valid submission</span>
          </a>
        )}
        {currentRecord.participant_status == 1 && (
          <a onClick={markSubmissionStatus}>
            <Icon src={xCircle} /> <span>Invalid submission</span>
          </a>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={Styles.leaderBoardContainer}>
        {isAdmin && (
          <div className={Styles.btnDownloadParticipants}>
            <Button
              onClick={downloadLeaderBoard}
              type="secondary"
              disabled={downloadLoading || listings.length == 0}
            >
              <span>Download Leaderboard</span>
            </Button>
          </div>
        )}
        <SearchBox
          placeHolder={"Filter by name..."}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (!e.target.value) getLeaderBoardListings(leaderBoardFilters);
          }}
          onEnter={searchLeaderBoard}
        />
        <Table>
          <TableHead>
            <SortHeader
              className="text-center"
              childClassName={`${Styles.iconHead} justify-content-center`}
              sortField="score_rank"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={trophy} />
              <span>Rank</span>
            </SortHeader>

            <SortHeader
              childClassName={`${Styles.iconHead}`}
              sortField="ui_display_name"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={user} />
              <span>Name</span>
            </SortHeader>

            <SortHeader
              childClassName={`${Styles.iconHead}`}
              sortField="last_submission_date"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={timer} />
              <span>Last Submission</span>
            </SortHeader>

            <SortHeader
              className="text-center"
              childClassName={`${Styles.iconHead} justify-content-center`}
              sortField="submission_count"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={entries} />
              <span>Entries</span>
            </SortHeader>

            <SortHeader
              className="text-center"
              childClassName={`${Styles.iconHead} justify-content-center`}
              sortField="top_score"
              sortBy={sortBy}
              setSortBy={setSortBy}
              setSortOrder={setSortOrder}
              onSortColumn={sortColumn}
            >
              <Icon src={points} />
              <span>Highest Score</span>
            </SortHeader>

            <th></th>

            {isAdmin && (
              <th className="text-center">
                <span className={`${Styles.iconHead} justify-content-center`}>
                  <span>Action</span>
                </span>
              </th>
            )}
          </TableHead>
          <TableBody>
            {!loading &&
              listings.length > 0 &&
              listings.map(
                (
                  {
                    record_count,
                    participant_id,
                    display_name,
                    team_name,
                    avatar_url,
                    team_file_id,
                    top_score,
                    last_submission_date,
                    submission_count,
                    score_rank,
                    user_id,
                    team_id,
                    participant_type,
                    participant_status,
                  },
                  idx
                ) => (
                  <tr key={idx}>
                    <td className="text-center">{score_rank}</td>
                    <td>
                      <Link
                        className={Styles.userLink}
                        to={
                          user_id
                            ? `/user/profile/${user_id}`
                            : `/team/profile/${team_id}`
                        }
                      >
                        {avatar_url || team_file_id ? (
                          <img
                            src={
                              process.env.REACT_APP_RESOURCES_URL +
                              (avatar_url || team_file_id)
                            }
                            className={Styles.avatar}
                          />
                        ) : (
                          <img src={UserImage} className={Styles.avatar} />
                        )}
                        {display_name || team_name || "-"}
                        {team_id && (
                          <div className={Styles.teamLabel}>
                            <label>Team</label>
                          </div>
                        )}
                      </Link>
                    </td>
                    <td>
                      {last_submission_date
                        ? moment(last_submission_date).format("DD/MM/YYYY")
                        : "-"}
                    </td>
                    <td className="text-center">{submission_count || "0"}</td>
                    <td className="text-center">
                      {top_score ? top_score.toFixed(3) : 0}
                    </td>
                    {isAdmin && participant_status == 2 ? (
                      <td className={Styles.invalidSubmission}>
                        <Icon src={exclamationCircle} /> requirements not met
                      </td>
                    ) : (
                      <td></td>
                    )}
                    {isAdmin && (
                      <td
                        className="text-center"
                        onClick={() => {
                          setCurrentRecord({
                            participant_id,
                            participant_status,
                          });
                        }}
                      >
                        <Dropdown overlay={menu} trigger={["click"]}>
                          <Icon src={dots} />
                        </Dropdown>
                      </td>
                    )}
                  </tr>
                )
              )}
            {loading &&
              SkeletonArr.map(() => (
                <tr>
                  <td style={{ width: "300px" }}>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  <td>
                    <Skeleton count={1} />
                  </td>
                  {isAdmin && (
                    <td>
                      <Skeleton count={1} />
                    </td>
                  )}
                  {isAdmin && (
                    <td>
                      <Skeleton count={1} />
                    </td>
                  )}
                </tr>
              ))}
          </TableBody>
        </Table>
        {listings && listings.length > 0 && (
          <Pagination
            pageSize={_metadata?.pageSize}
            totalRecord={_metadata?.totalResults}
            currentPage={currentPage}
            styles={{ marginTop: "40px" }}
            onPaginationChange={(page) => {
              setCurrentPage(page);
            }}
          />
        )}
        <div style={{ marginTop: "40px", marginBottom: "40px" }}>
          {!loading && listings && listings.length === 0 && <Empty />}
        </div>
        {isAdmin && !loading && !isChallengeClosed && challengeStatus == 3 && (
          <div className={Styles.btnCloseChallenge}>
            <Button
              onClick={() => {
                setChallengeCloseModal(true);
              }}
            >
              <span>Close the challenge and confirm points</span>
            </Button>
          </div>
        )}
      </div>
      <Modal
        title={t("odp-ad.challenge.competition-rules.competition-rules")}
        visible={challengeCloseModal}
        wrapClassName={Styles.modal}
        onOk={closeChallenge}
        onCancel={handleCancel}
        centered
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("odp-ad.challenge.leaderboard-participants-submission.cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            className={Styles.registrationBtn}
            onClick={closeChallenge}
          >
            {closeChallengeLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              "Close the challenge and confirm points"
            )}
          </Button>,
        ]}
      >
        <p>
          When you declare the challenge closed, the points are distributed to
          the participants.
        </p>
      </Modal>
    </>
  );
};

export default Leaderboard;
