import update from "immutability-helper";
import {
  CLOSE_CHALLENGE,
  CREATE_CHALLENGE,
  DOWNLOAD_PARTICIPANT,
  EDIT_CHALLENGE,
  FETCH_ACTIVE_CHALLENGES,
  FETCH_CHALLENGE,
  FETCH_CHALLENGES,
  FETCH_CHALLENGE_SUBMISSIONS,
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_LISTINGS,
  FETCH_PARTICIPANTS,
  FETCH_USERS_LISTINGS,
  FETCH_USER_CHALLENGE,
  JOIN_CHALLENGE,
  LIVE_CHALLENGES_SUCCESS,
  PUBLISH_CHALLENGE,
  RESET_CHALLENGE_FORM,
  RESET_CHALLENGE_ID,
  RESET_CHALLENGE_SUBMISSION,
  RESET_CLOSE_CHALLENGE,
  RESET_ERROR_TAB,
  RESET_JOIN_CHALLENGE,
  RESET_PUBLISH,
  SELECT_MENU,
  RESET_SPECIFIC_ERROR_TAB,
  SET_ERROR_TAB,
  SUBJECT_LOOKUP_SUCCESS,
  SUBMIT_CHALLENGE_SOLUTION,
  TYPE_LOOKUP_SUCCESS,
  UN_PUBLISH_CHALLENGE,
  UPCOMING_CHALLENGES_SUCCESS,
  UPDATE_CHALLENGE,
  UPDATE_CHALLENGE_SUBMISSIONS,
  UPDATE_PUBLISH_STATUS,
} from "../actions/ChallengeAction";
import { failure, request, success } from "../../utils/fetch";
import Users from "../../admin/pages/Users";

const initialState = {
  statusCode: 0,
  error: {},
  listings: [],
  _metadata: {
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    pageSize: 5,
  },
  pageDetails: {
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    pageSize: 0,
  },
  liveChallenges: [],
  upComingChallenges: [],
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  typeLookup: [],
  subjectLookup: [],
};

const challengePayload = {
  publishStatus: false
};

const initialPublishState = {
  challengeId: null,
  publish: false,
};

const leaderboard = {
  statusCode: 0,
  listings: [],
  _metadata: {
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    pageSize: 10,
  },
  loading: false,
};

const challengeCreateState = {
  loading: false,
  challenge_id: null,
  statusCode: 0,
};

const challengeSubmitState = {
  loading: false,
  statusCode: 0,
  submissionId: null,
};

const closeChallengeState = {
  loading: false,
  statusCode: 0,
  challengeId: null,
};

const menuState = {
  ready: false,
  menu: "basic",
};

const challengeTab = {
  basic: false,
  design: false,
  content: false,
  submission: false
}

const yourChallenges = {
  loading1: false,
  error: {},
  activeChallenges: [],
  _metaDataActive: {},
};

const fileState = {
  statusCode: 0,
  error: null,
  fileContent: [],
  fileName: null,
  fileMimeType: null,
};

export function ChallengeMenu(state = menuState, action) {
  switch (action.type) {
    case SELECT_MENU:
      return update(state, { menu: { $set: action.menu } });
    default:
      return state;
  }
}

export function updateErrorTab(state = challengeTab, action) {

  switch (action.type) {
    case SET_ERROR_TAB:
      return update(state, {
        design: { $set: action.tab == 'design' || state.design },
        content: { $set: action.tab == 'content' || state.content },
        submission: { $set: action.tab == 'submission' || state.submission },
        basic: { $set: action.tab == 'basic' || state.basic },
      });
    case RESET_ERROR_TAB:
      return update(state, {
        design: { $set: false },
        content: { $set: false },
        submission: { $set: false },
        basic: { $set: false },
      });

    case RESET_SPECIFIC_ERROR_TAB:
      return update(state, {
        design: { $set: action.tab == 'design' ? false : state.design },
        content: { $set: action.tab == 'content' ? false : state.content },
        submission: { $set: action.tab == 'submission' ? false : state.submission },
        basic: { $set: action.tab == 'basic' ? false : state.basic },
      });
    default:
      return state;
  }
}



export function UpdateChallengeReducer(state = challengePayload, action) {
  switch (action.type) {
    case RESET_CHALLENGE_FORM:
      return update(state, {
        requestObject: { $set: null },
        loading: { $set: false },
        error: { $set: null },
      });
    case UPDATE_CHALLENGE:
      return update(state, {
        requestObject: { $set: action.request },
        loading: { $set: false },
        error: { $set: null },
      });
    case UPDATE_PUBLISH_STATUS:
      return update(state, {
        publishStatus: { $set: action.status },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(PUBLISH_CHALLENGE):
      return update(state, {
        statusCode: { $set: 0 },
        publishStatus: { $set: true },
        // requestObject: { $set: { ...state.requestObject, is_published: true } },
        loading: { $set: false },
      });
    case failure(PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case request(UN_PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(UN_PUBLISH_CHALLENGE):
      return update(state, {
        statusCode: { $set: 0 },
        publishStatus: { $set: false },
        // requestObject: { $set: { ...state.requestObject, is_published: false } },
        loading: { $set: false },
      });
    case failure(UN_PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case request(FETCH_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_CHALLENGE):
      return update(state, {
        statusCode: { $set: 0 },
        requestObject: { $set: action.response },
        loading: { $set: false },
      });
    case failure(FETCH_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function createChallengeReducer(state = challengeCreateState, action) {
  switch (action.type) {
    case RESET_CHALLENGE_ID:
      return update(state, {
        loading: { $set: false },
        challenge_id: { $set: null },
        error: { $set: null },
      });
    case CREATE_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        challenge_id: { $set: null },
        error: { $set: null },
      });
    case request(CREATE_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        challenge_id: { $set: null },
        error: { $set: null },
      });
    case success(CREATE_CHALLENGE):
      let { competition_id } = action.response;
      return update(state, {
        statusCode: { $set: 0 },
        challenge_id: { $set: competition_id },
        loading: { $set: false },
      });
    case failure(CREATE_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        challenge_id: { $set: null },
        error: { $set: action.error },
      });
    case EDIT_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case request(EDIT_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(EDIT_CHALLENGE):
      return update(state, {
        statusCode: { $set: 0 },
        loading: { $set: false },
      });
    case failure(EDIT_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function ChallengeReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHALLENGES:
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        loading3: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_CHALLENGES):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: true },
        loading3: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_CHALLENGES):
      const { listings, statusCode, _metadata } = action.response;
      const {
        totalPages,
        totalResults,
        currentPage,
        pageSize } = _metadata;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        pageDetails: {
          $set: {
            totalPages: totalPages,
            totalResults: totalResults,
            currentPage: currentPage == 1 ? currentPage : ((currentPage * pageSize) - pageSize) + 1,
            pageSize: currentPage * pageSize,
          }
        },
        loading: { $set: false },
        loading3: { $set: false },
      });
    case failure(FETCH_CHALLENGES):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        loading3: { $set: false },
        error: { $set: action.error },
      });
    case LIVE_CHALLENGES_SUCCESS:
      return {
        ...state,
        liveChallenges: action.challenges.data.listings,
      };
    case UPCOMING_CHALLENGES_SUCCESS:
      return {
        ...state,
        upComingChallenges: action.challenges.data.listings,
      };
    case TYPE_LOOKUP_SUCCESS:
      return {
        ...state,
        typeLookup: action.types.data.listings,
      };
    case SUBJECT_LOOKUP_SUCCESS:
      return {
        ...state,
        subjectLookup: action.subjects.data.listings,
      };
    default:
      return state;
  }
}

export function LeaderBoardReducers(state = leaderboard, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD:
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_LEADERBOARD):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_LEADERBOARD):
      const { listings, statusCode, _metadata } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        loading: { $set: false },
      });
    case failure(FETCH_LEADERBOARD):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function LeaderBoardListingsReducers(state = leaderboard, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD_LISTINGS:
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_LEADERBOARD_LISTINGS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_LEADERBOARD_LISTINGS):
      const { listings, statusCode, _metadata } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        loading: { $set: false },
      });
    case failure(FETCH_LEADERBOARD_LISTINGS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function UsersListingsReducers(state = leaderboard, action) {
  switch (action.type) {
    case FETCH_USERS_LISTINGS:
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_USERS_LISTINGS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_USERS_LISTINGS):
      const { listings, statusCode, _metadata } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        loading: { $set: false },
      });
    case failure(FETCH_USERS_LISTINGS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function JoinChallengeReducer(state = challengeCreateState, action) {
  switch (action.type) {
    case RESET_JOIN_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
        statusCode: { $set: 0 },
      });
    case JOIN_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case request(JOIN_CHALLENGE):
      return update(state, {
        statusCode: { $set: 0 },
        loading: { $set: true },
        error: { $set: null },
      });
    case success(JOIN_CHALLENGE):
      return update(state, {
        statusCode: { $set: 1 },
        loading: { $set: false },
        error: { $set: null },
      });
    case failure(JOIN_CHALLENGE):
      return update(state, {
        statusCode: { $set: 2 },
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function challengeSubmissionHistories(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CHALLENGE_SUBMISSIONS:
      const submission = action.payload;
      state.listings.forEach((res) => {
        if (res.submission_id == submission.submission_id) {
          res.score = submission.score;
          res.evaluation_status = 2
          res.evaluation_status_desc = 'Complete'
        }
      })
      return update(state, {
        listings: { $set: [...state.listings] },
        loading: { $set: false },
        error: { $set: null },
      });
    case FETCH_CHALLENGE_SUBMISSIONS:
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_CHALLENGE_SUBMISSIONS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_CHALLENGE_SUBMISSIONS):
      const { listings, statusCode, _metadata } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        loading: { $set: false },
      });
    case failure(FETCH_CHALLENGE_SUBMISSIONS):
      return update(state, {
        listings: { $set: [] },
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function challengeSubmission(state = challengeSubmitState, action) {
  switch (action.type) {
    case SUBMIT_CHALLENGE_SOLUTION:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case RESET_CHALLENGE_SUBMISSION:
      return update(state, {
        loading: { $set: false },
        submissionId: { $set: null },
        error: { $set: null },
      });
    case request(SUBMIT_CHALLENGE_SOLUTION):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(SUBMIT_CHALLENGE_SOLUTION):
      const { submission_id, statusCode } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        submissionId: { $set: submission_id },
        loading: { $set: false },
      });
    case failure(SUBMIT_CHALLENGE_SOLUTION):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function publishChallenge(state = initialPublishState, action) {
  switch (action.type) {
    case UN_PUBLISH_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case request(UN_PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(UN_PUBLISH_CHALLENGE):
      // const { submission_id, statusCode } = action.response;
      return update(state, {
        challengeId: { $set: true },
        loading: { $set: false },
      });
    case failure(UN_PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case PUBLISH_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case RESET_PUBLISH:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case request(PUBLISH_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(PUBLISH_CHALLENGE):
      // const { submission_id, statusCode } = action.response;
      return update(state, {
        challengeId: { $set: true },
        loading: { $set: false },
      });
    case failure(PUBLISH_CHALLENGE):

      return update(state, {
        loading: { $set: false },
        error: { $set: { type: 'ERROR_PUBLISH_CHALLENGE', errorObj: action?.error } },
      });
    default:
      return state;
  }
}

export function userChallengeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    case request(FETCH_USER_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(FETCH_USER_CHALLENGE):
      const { listings, statusCode, _metadata } = action.response;
      return {
        ...state,
        statusCode: statusCode,
        listings: listings,
        loading: false,
        _metadata: _metadata,
      };
    case failure(FETCH_USER_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export const yourChallengesReducer = (state = yourChallenges, action) => {
  switch (action.type) {
    case request(FETCH_ACTIVE_CHALLENGES):
      return {
        ...state,
        loading1: true,
        error: null,
      };
    case success(FETCH_ACTIVE_CHALLENGES):
      const { listings, _metadata } = action.response;
      return {
        ...state,
        activeChallenges: listings,
        _metaDataActive: _metadata,
        loading1: false,
      };

    case failure(FETCH_ACTIVE_CHALLENGES):
      return { ...state, loading1: false, error: action.error };

    default:
      return yourChallenges;
  }
};

export function closeChallengeSaga(state = closeChallengeState, action) {
  switch (action.type) {
    case RESET_CLOSE_CHALLENGE:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
        challengeId: { $set: null },
      });
    case request(CLOSE_CHALLENGE):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(CLOSE_CHALLENGE):
      const { competition_id, statusCode } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        challengeId: { $set: competition_id },
        loading: { $set: false },
      });
    case failure(CLOSE_CHALLENGE):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function participantsListingsSaga(state = initialState, action) {
  switch (action.type) {
    // case RESET_CLOSE_CHALLENGE:
    //   return update(state, {
    //     loading: { $set: false },
    //     error: { $set: null },
    //     challengeId: { $set: null },
    //   });
    case request(FETCH_PARTICIPANTS):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
        listings: { $set: [] },
      });
    case success(FETCH_PARTICIPANTS):
      const { listings, statusCode, _metadata } = action.response;
      return update(state, {
        statusCode: { $set: statusCode },
        listings: { $set: listings },
        _metadata: { $set: _metadata },
        loading: { $set: false },
      });
    case failure(FETCH_PARTICIPANTS):
      return update(state, {
        loading: { $set: false },
        listings: { $set: [] },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}

export function downloadParticipantReducer(state = fileState, action) {
  switch (action.type) {
    case DOWNLOAD_PARTICIPANT:
      return update(state, {
        loading: { $set: false },
        error: { $set: null },
      });
    // case REMOVE_DOWNLOAD_DATA:
    //   return update(state, {
    //     loading: { $set: false },
    //     fileContent: { $set: [] },
    //     error: { $set: null },
    //   });
    case request(DOWNLOAD_PARTICIPANT):
      return update(state, {
        loading: { $set: true },
        error: { $set: null },
      });
    case success(DOWNLOAD_PARTICIPANT):
      const { fileName, mimeType, content } = action.response;
      return update(state, {
        statusCode: { $set: 0 },
        fileContent: { $set: content },
        fileName: { $set: fileName },
        fileMimeType: { $set: mimeType },
        loading: { $set: false },
      });
    case failure(DOWNLOAD_PARTICIPANT):
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    default:
      return state;
  }
}