import React, { useEffect, useState } from "react";
import Styles from "./dashboard.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard from "../ProfileCard";
import { getUserById } from "../../redux-store/actions/userAction";
import { useParams, useHistory } from "react-router";
import UserStats from "../UserStats";
import ChallengeCard from "../ChallengeCard";
import SkeletonLoading from "react-loading-skeleton";
import Pagination from "../Pagination";
import Button from "../Button";
import { getChallengeByUserId } from "../../redux-store/actions/ChallengeAction";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { buildParams, PaginationModel } from "../../utils";

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user_id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const challengeFilters = {};
  challengeFilters.pagination = new PaginationModel();
  challengeFilters.pagination.pageSize = 10;

  const { user, loading: userLoading } = useSelector(
    (state) => state.publicProfile
  );

  const { listings, loading, _metadata } = useSelector(
    (state) => state.userChallenge
  );

  useEffect(() => {
    dispatch(getUserById(user_id));
    challengeFilters.challengeStatus = [2, 3];
    getChallenges(challengeFilters);
  }, []);

  const getChallenges = (filters) => {
    let params = buildParams(filters);
    dispatch(getChallengeByUserId({ userId: user_id, filters: params }));
  };

  const routeToChallenges = () => {
    history.push(`/profile/${user_id}/challenge`);
  };

  return (
    <div className={Styles.dashboardContainer}>
      <h1 className="text-center">
        {t("odp-pu.label.dashboard.your-dashboard")}
      </h1>

      <div className={Styles.profileCardContainer}>
        <h4>Your details</h4>
        <ProfileCard
          user={user}
          userLoading={userLoading}
          isDashboardView={true}
        />
      </div>

      <div className={Styles.globalScore}>
        <h4>{t("odp-pu.label.dashboard.your-global-score")}</h4>
        <UserStats user={user} userLoading={userLoading} />
      </div>

      <div className={Styles.challenges}>
        <h4 className="text-center">
          {t("odp-pu.label.dashboard.your-active-challenges")}
        </h4>
        {!loading && (
          <>
            <div className="mt-5 mb-5">
              {listings.length > 0 ? (
                listings.map((challenge) => (
                  <div className="mt-4">
                    <ChallengeCard
                      {...challenge}
                      previewChallenge={`/challenge/${challenge.competition_id}`}
                    />
                  </div>
                ))
              ) : (
                <div className="mb-5">
                  <Empty />
                </div>
              )}
            </div>

            <div className="text-center mb-3">
              <Button type="secondary" onClick={routeToChallenges}>
                See your challenges
              </Button>
            </div>
          </>
        )}
        {loading && (
          <div className="mt-5 mb-5">
            <SkeletonLoading
              style={{ height: "250px", width: "100%", borderRadius: "10px" }}
            />
            <SkeletonLoading
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
