import React from "react";
import { Input } from "antd";
import Styles from "./searchBox.module.scss";
import Icon from "../../../components/Icons";
import Search from "../../../asset/icons/search.svg";

const SearchBox = ({ placeHolder, onEnter, onChange, value , suffix }) => {
  return (
    <div className={Styles.searchBox}>
      <Input
        placeholder={placeHolder}
        onPressEnter={onEnter}
        prefix={<Icon src={Search} />}
        value={value}
        onChange={onChange}
        bordered
        suffix={suffix}
      />
    </div>
  );
};

export default SearchBox;
