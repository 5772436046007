import React, { useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Route } from "react-router-dom";
import AdminApp from "./Components/App";
import { routes } from "./routes";

const AdminPanel = () => {
  const nodeRef = useRef(null);
  return (
    <AdminApp>
      <TransitionGroup>
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
            {({ match, history }) => (
              <CSSTransition
                in={match != null}
                timeout={200}
                classNames={"fade"}
                unmountOnExit
              >
                <div ref={nodeRef}>
                  <Component params={match?.params} history={history} />
                </div>
              </CSSTransition>
            )}
          </Route>
        ))}
      </TransitionGroup>
    </AdminApp>
  );
};

export default AdminPanel;
