import { useState } from 'react';
import Styles from './accountSettings.module.scss';
import { Input } from 'antd';
import { AButton } from '../../theme/button';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { changeEmail, changePassword } from '../../redux-store/actions/accountSettingsAction';
import { emailValidate } from '../../helpers/regex';
import { notify } from '../../theme/antNotify';
import { UserSettings } from './UserSettings';
import { useTranslation } from 'react-i18next';

export const AccountSettings = () => {
  const [email, setEmail] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();

  const numberRegex = /(?=.*[0-9])/;
  const upperCaseRegex = /(?=.*?[A-Z])/;
  const eightCharacters = /(?=.{8,})/;

  const onChangePassword = () => {
    if (!oldPassword) {
      notify('error', 'Password', 'Old password cannot be empty');
      return;
    }
    if (!newPassword) {
      notify('error', 'Password', 'New password cannot be empty');

      return;
    }
    if (!confirmPassword) {
      notify('error', 'Password', 'Confirm password cannot be empty');
      return;
    }
    if (!newPassword.match(numberRegex)) {
      notify('error', 'Password', 'Password must contain number');
      return;
    }
    if (!newPassword.match(upperCaseRegex)) {
      notify('error', 'Password', 'Password must contain uppercase letters');
      return;
    }
    if (!newPassword.match(eightCharacters)) {
      notify('error', 'Password', 'Password must be greater than 8 characters');
      return;
    }
    if (newPassword !== confirmPassword) {
      notify('error', 'Password', 'New password must match Confirm password');
      return;
    }
    const payload = {
      user_id: params.user_id,
      current_password: oldPassword,
      new_password: newPassword
    };
    dispatch(changePassword(payload));
  };

  const onChangeEmail = () => {
    if (!email) {
      notify('error', 'Email', 'Email cannot be empty');
      return;
    }
    if (!emailValidate(email)) {
      notify('error', 'Email', 'Email entered is not valid');
      return;
    }
    const payload = { user_id: params.user_id, new_email: email };
    dispatch(changeEmail(payload));
  };

  return (
    <div className="text-center">
      <h1 className="mb-5">Account & Settings</h1>
      {/* hiding user settings for now */}
      {/* <UserSettings user_id={params.user_id} /> */}
      <br></br>
      <div className={Styles.container}>
        <div className={Styles.forgotPasswordForm}>
          <div className={Styles.header}>
            <h2>Change Email</h2>
          </div>

          <div className={Styles.body}>
            <div className={Styles.formGroup}>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Nuova email"
              />

              <div className={Styles.buttonBlock}>
                <AButton
                  onClick={onChangeEmail}
                  label={t('odp-pu.label.profile.save')}
                  type="regular"
                  disabled={!email}></AButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className={Styles.container}>
        <div className={Styles.forgotPasswordForm}>
          <div className={Styles.header}>
            <h2>Change password</h2>
          </div>

          <div className={Styles.changePasswordBody}>
            <div className={Styles.formGroup}>
              <Input.Password
                type="password"
                name="oldPassword"
                placeholder="Old password"
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <Input.Password
                type="password"
                name="newPassword"
                placeholder="New password"
                onChange={(e) => setNewPassword(e.target.value)}
                style={{ marginTop: 20, marginBottom: 10 }}
              />
              <ul>
                <li>Password must contain numbers</li>
                <li>Password must contain uppercase letters</li>
                <li>Length must be greater than 8 characters</li>
              </ul>
              <Input.Password
                type="password"
                name="confirmNewPassword"
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ marginTop: 20, marginBottom: 20 }}
              />
              <div className={Styles.buttonBlock}>
                <AButton
                  onClick={onChangePassword}
                  label={t('odp-pu.label.profile.save')}
                  type="regular"
                  disabled={!confirmPassword}></AButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
    </div>
  );
};
