import React, { useEffect } from "react";
import Styles from "./teamProfile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import CompleteIcon from "../../asset/images/team/completeIcon.svg";
import FlagIcon from "../../asset/images/team/flagIcon.svg";
import { getTeamById } from "../../redux-store/actions/teamAction";
import image from "../../asset/images/profile/profilePlaceHolder.svg";
import { Collapse } from "antd";
import TeamMembers from "../../components/Team/teams/teamMembers";
import TeamChallenges from "../../components/Team/teams/teamChallanges";
import { getTeamChallanges } from "../../services/team/api";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
const TeamProfile = () => {
  const { team_id } = useParams();
  const { team } = useSelector((state) => state.teamProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeamById(team_id));
  }, []);
  const { t } = useTranslation();
  return (
    <div className={Styles.teamProfileContainer}>
      <div className={Styles.teamCard}>
        <>
          <div className={`col-5 ${Styles.teamPersonalInfoContainer}`}>
            <div className={`${Styles.teamPersonalInfo}`}>
              <img
                src={
                  team?.file_id
                    ? `${process.env.REACT_APP_RESOURCES_URL}${team?.file_id}`
                    : image
                }
                className={Styles.userAvatar}
              />
              <div className={Styles.teamBasicInfo}>
                <p className={Styles.teamName}>{team?.team_name}</p>
                <p>
                  <img src={FlagIcon} alt={FlagIcon} />
                  <span>{team?.country_name}</span>
                </p>
                <p>
                  <img src={CompleteIcon} alt={CompleteIcon} />
                  Challenges:
                  <span>
                    {" "}
                    {team?.competition_stats?.competition_count || 0}
                  </span>
                </p>
              </div>
            </div>
            <p className="mt-2">{team?.description}</p>
          </div>
          <div className={`col-7 ${Styles.teamSkillContainer}`}>
            <h5>{t("odp-pu.label.profile.skills")}</h5>
            {team?.team_skills && team?.team_skills?.length > 0 ? (
              <div className={Styles.teamSkills}>
                {team?.team_skills?.map((skillLabel) => (
                  <label className={Styles.skill} key={skillLabel}>
                    {skillLabel}
                  </label>
                ))}
              </div>
            ) : (
              "No Skills"
            )}
          </div>
        </>
      </div>
      {/* <div className={Styles.accordionContainer}>
                <Collapse ghost expandIconPosition="right" defaultActiveKey={["0"]} className={Styles.collapse}>
                    <Panel
                        className={Styles.panel}
                        header={`Team members (${team?.team_users?.length || "0"
                            })`}
                    >
                        {team?.team_users &&
                            team?.team_users.map((member) => (
                                <TeamCard {...member} key={member.avatar_url} />
                            ))}
                    </Panel>
                </Collapse>
            </div> */}
      <div className={Styles.accordionContainer}>
        <TeamMembers team_users={team?.team_users} />
      </div>

      <div className={Styles.accordionContainer}>
        <TeamChallenges team_id={team_id} />
      </div>
    </div>
  );
};

export default TeamProfile;
