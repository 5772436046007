import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';
import { resources } from './utils/localeResources';
i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(Cache)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
        keySeparator: '.',
        detection: {
            // order and from where user language should be detected
            order: ['htmlTag'],
            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        cache: ['localStorage']
    });


export default i18n;
