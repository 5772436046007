import { Modal, Button } from "antd";
import Notify from "../../theme/notifications";

import "./customModal.scss";

export const CustomModal = ({
  confirmLoading,
  modalText,
  visible,
  setVisible,
  setConfirmLoading,
  rightButtonLabel,
  rightButtonAction,
  leftButtonLabel,
  leftButtonAction,
  title,
  infoText,
}) => {
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal with async logic
      </Button> */}
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="customModal"
        footer={[
          <Button key="back" onClick={leftButtonAction}>
            {leftButtonLabel}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={rightButtonAction}
          >
            {rightButtonLabel}
          </Button>,
        ]}
      >
        <Notify msg={infoText} className="info" />
        <p className="mt-2">{modalText}</p>
      </Modal>
    </>
  );
};
