import './teamCard.scss';
import 'swiper/swiper.scss';
import { Link } from 'react-router-dom';
import { Progress } from 'antd';
import BreifcaseIcon from '../../../../asset/images/team/card/breifcase.svg';
import LogoutIcon from '../../../../asset/images/team/card/logout.svg';
import TeamIcon from '../../../../asset/images/team/card/team.svg';
import TrophIicon from '../../../../asset/images/team/card/trophy.svg';
import UserIcon from '../../../../asset/images/team/card/user.svg';
import TeamImg from '../../../../asset/images/team.jpg';
import ChallangeImg from '../../../../asset/images/team/card/challange.jpg';
import Compnaylogo from '../../../../asset/images/team/card/compnaylogo.jpg';
import moment from 'moment';
import { textTrim } from '../../../../utils';
import { useIsMobile } from '../../../../hooks/customHooks';
import { useTranslation } from 'react-i18next';
const dateFormat = 'YYYY-MM-DD';

const Card = ({
  competition_id,
  competition_description,
  competition_type_name,
  thumbnail_image_id,
  competition_status,
  challengeproperty,
  score,
  company
}) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  return (
    <div className="challange__card">
      <Link to={`/challenge/${competition_id}`} className="challange__content">
        <div className="challange__header">
          {competition_status === 1 && <span className="tag planned">Planned</span>}
          {competition_status === 2 && <span className="tag live">Live</span>}
          {competition_status === 3 && <span className="tag under">Under evaluation</span>}
          {competition_status === 4 && <span className="tag finished">Closed</span>}

          <div className="challange__imgBox">
            <img
              src={process.env.REACT_APP_RESOURCES_URL + thumbnail_image_id}
              alt={ChallangeImg}
            />
          </div>

          <div className="company__logo">
            <img
              src={process.env.REACT_APP_RESOURCES_URL + company?.logo_file_id}
              alt={Compnaylogo}
            />
          </div>
        </div>

        <div className="challange__body">
          <div className="upper__body">
            <ul className="left">
              <li className="accent_blue">
                <img src={TrophIicon} alt={TrophIicon} /> {challengeproperty?.winning_prize}
              </li>
              <li className="accent_blue">{challengeproperty?.competition_category_name}</li>
              <li className="left_down">
                <span className="Senior">
                  <img src={UserIcon} alt={UserIcon} />
                  {challengeproperty?.participation_type === 1 &&
                    "Individual"}
                  {challengeproperty?.participation_type === 2 && "Teams"}
                  {challengeproperty?.participation_type === 3 &&
                    `${t(
                      "odp-ad.challenge.competition-rules.teams-individual"
                    )}`}
                </span>
                <span className="Recruiting">
                  <img src={BreifcaseIcon} alt={BreifcaseIcon} />
                  {competition_type_name}
                </span>
              </li>
            </ul>
            <ul className="middle">
              <li className="gray_xt">
                <img src={TeamIcon} alt={TeamIcon} />{' '}
                {challengeproperty?.participation_type === 1 && 'Individual'}
                {challengeproperty?.participation_type === 2 && 'Teams'}
                {challengeproperty?.participation_type === 3 &&
                  `${t('odp-ad.challenge.competition-rules.teams-individual')}`}
              </li>
            </ul>

            {score !== null ? (
              <div className="right">
                <div className="right_body">
                  <ul className="right__left">
                    <li className="accent_blue">{score?.team_name}</li>
                    <li className="gray_text">Rank Challenge:</li>
                  </ul>
                  <ul className="right__right">
                    <li className="img__box">
                      <img
                        src={
                          score !== null && score.team_id
                            ? process.env.REACT_APP_RESOURCES_URL + score.team_file_id
                            : score !== null &&
                            process.env.REACT_APP_RESOURCES_URL + score.avatar_url
                        }
                        alt={TeamImg}
                      />
                    </li>
                    <li className="rang accent_blue">{score?.score_rank}</li>
                  </ul>
                </div>

                <ul>
                  <Link className="accent_blue gray_text leave" to="/">
                    <img src={LogoutIcon} alt={LogoutIcon} /> Leave the challenge
                  </Link>
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="middle__body">
            <p className="despction">
              {/* Control Panel of Spaceship Earth: Misure sul benessere e impatti
              sul pianeta lorem ipsum dolor sit amet etcetera */}
              {competition_description && textTrim(competition_description, isMobile ? 30 : 150)}
            </p>
          </div>
          <div className="lower__body">
            <p className="time">
              {moment(challengeproperty.start_date, dateFormat).format('ll')} &nbsp; - &nbsp;
              {moment(challengeproperty.end_date, dateFormat).format('ll')}
            </p>
            <div className="lower__body__footer">
              <div className="body__bar">
                <Progress percent={challengeproperty?.competition_progress} />
              </div>
              <Link to={`/challenge/${competition_id}`} className="accent_blue ">
                Scopri di più
              </Link>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
