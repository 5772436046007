import React, { useEffect, useState } from "react";
import UserStats from "../UserStats";
import Styles from "./challenges.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../redux-store/actions/userAction";
import { useParams } from "react-router";
import ChallengeCard from "../ChallengeCard";
import SkeletonLoading from "react-loading-skeleton";
import {
  getChallengeByUserId,
  getUserActiveChallenges,
} from "../../redux-store/actions/ChallengeAction";
import { Button, Empty, Select } from "antd";
import ChallengeCardSmall from "../ChallengeCard/CallengeCardSmall/ChallengeCardSmall";
import { buildParams, PaginationModel } from "../../utils";
import ListingPagination from "../../components/Pagination";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const YourChallenges = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const challengeFilters = {};
  const activeChallengeFilters = {};

  const [sortBy, setSortBy] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageActive, setCurrentPageActive] = useState(1);

  challengeFilters.pagination = new PaginationModel();
  activeChallengeFilters.pagination = new PaginationModel();
  challengeFilters.pagination.pageSize = 5;
  activeChallengeFilters.pagination.pageSize = 5;

  const { user, loading: userLoading } = useSelector(
    (state) => state.publicProfile
  );
  const { listings, loading, _metadata } = useSelector(
    (state) => state.userChallenge
  );
  const { loading1, activeChallenges, _metaDataActive } = useSelector(
    (state) => state.yourChallenges
  );

  useEffect(() => {
    activeChallengeFilters.challengeStatus = [2, 3]
    let filters = buildParams(activeChallengeFilters);
    let allFilters = buildParams(challengeFilters);

    dispatch(getUserById(user_id));
    dispatch(getChallengeByUserId({ userId: user_id, filters: allFilters }));
    dispatch(
      getUserActiveChallenges({
        userId: user_id,
        filters,
      })
    );
  }, []);

  useEffect(() => {
    let filters = buildParams(activeChallengeFilters);
    filters.pageNum = currentPageActive;
    dispatch(getUserActiveChallenges({ userId: user_id, filters }));
  }, [currentPageActive]);

  useEffect(() => {
    challengeFilters.sortBy = 'desc';
    challengeFilters.orderField = sortBy;
    let filters = buildParams(challengeFilters);
    filters.pageNum = currentPage;
    dispatch(getChallengeByUserId({ userId: user_id, filters }));
  }, [currentPage, sortBy]);

  const sortByOptions = [
    { id: 0, value: "", option: "Please select sort filter" },
    { id: 1, value: "create_date", option: "Date (most recent)" },
    { id: 2, value: "competition_prize_description", option: "Prize amount" },
  ];

  return (
    <div className={Styles.challengesContainer}>
      <h2>{t('odp-pu.label.dashboard.your-challenges')}</h2>
      <h4 style={{ marginTop: 40 }}>{t('odp-pu.label.dashboard.your-global-score')}</h4>

      <UserStats user={user} userLoading={userLoading} />
      <div className={Styles.challenges}>
        <h4 className="my-5 text-center">{t('odp-pu.label.dashboard.your-active-challenges')}</h4>
        <span>{t('odp-pu.label.dashboard.total-active-challenges')}: {_metaDataActive && _metaDataActive.totalResults}</span>
        {!loading1 && (
          <div className="mt-5 mb-5">
            {activeChallenges.length > 0 ? (
              activeChallenges.map((challenge) => (
                <div className="mt-4">
                  <ChallengeCard
                    {...challenge}
                    previewChallenge={`/challenge/${challenge.competition_id}`}
                  />
                </div>
              ))
            ) : (
              <div className="mb-5">
                <Empty />
              </div>
            )}
          </div>
        )}
        {loading1 && (
          <div className="mt-5 mb-5">
            <SkeletonLoading
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
      </div>
      <ListingPagination
        pageSize={_metaDataActive?.pageSize}
        totalRecord={_metaDataActive?.totalResults}
        currentPage={currentPageActive}
        styles={{ marginTop: "40px", marginBottom: "40px", width: "100%" }}
        onPaginationChange={(page) => {
          setCurrentPageActive(page);
        }}
      />
      <div className={Styles.allChallenges}>
        <h4 className="text-center">{t('odp-pu.label.dashboard.all-your-challnges')}</h4>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span>{t('odp-ad.user.total-challenges.total-challenges')}: {_metadata.totalResults}</span>
          </div>
          <div style={{ flexDirection: "column" }}>
            <div>Sort by</div>
            <Select
              style={{
                width: 100 + "%",
                borderRadius: 5 + "px",
              }}
              value={sortBy}
              onChange={(value) => setSortBy(value)}
            >
              {sortByOptions.map((item) => (
                <Option value={item.value}> {item.option} </Option>
              ))}
            </Select>
          </div>
        </div>
        {!loading && (
          <div className="mt-5 mb-5">
            {listings.length > 0 ? (
              listings.map((challenge) => (
                <div className="mt-4">
                  <ChallengeCard
                    {...challenge}
                    previewChallenge={`/challenge/${challenge.competition_id}`}
                  />
                </div>
              ))
            ) : (
              <div className="mb-5">
                <Empty />
              </div>
            )}
          </div>
        )}
        {loading && (
          <div className="mt-5 mb-5">
            <SkeletonLoading
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                borderRadius: "10px",
              }}
            />
            <SkeletonLoading
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
      </div>
      <ListingPagination
        pageSize={_metadata?.pageSize}
        totalRecord={_metadata?.totalResults}
        currentPage={currentPage}
        styles={{ marginTop: "40px", marginBottom: "40px", width: "100%" }}
        onPaginationChange={(page) => {
          setCurrentPage(page);
        }}
      />
    </div>
  );
};

export default YourChallenges;
