export const FETCH_CHALLENGES = "FETCH_CHALLENGES";
export const FETCH_CHALLENGE = "FETCH_CHALLENGE";
export const UPDATE_CHALLENGE = "UPDATE_CHALLENGE";
export const EDIT_CHALLENGE = "EDIT_CHALLENGE";
export const CREATE_CHALLENGE = "CREATE_CHALLENGE";
export const FETCH_LEADERBOARD = "FETCH_LEADERBOARD";
export const SELECT_MENU = "SELECT_MENU";
export const JOIN_CHALLENGE = "JOIN_CHALLENGE";
export const FETCH_CHALLENGE_SUBMISSIONS = "FETCH_CHALLENGE_SUBMISSIONS";
export const UPDATE_CHALLENGE_SUBMISSIONS = "UPDATE_CHALLENGE_SUBMISSIONS";
export const SUBMIT_CHALLENGE_SOLUTION = "SUBMIT_CHALLENGE_SOLUTION";
export const RESET_CHALLENGE_SUBMISSION = "RESET_CHALLENGE_SUBMISSION";
export const FETCH_LEADERBOARD_LISTINGS = "FETCH_LEADERBOARD_LISTING";
export const FETCH_USERS_LISTINGS = "FETCH_USERS_LISTINGS";
export const FETCH_USER_CHALLENGE = "FETCH_USER_CHALLENGE";
export const LIVE_CHALLENGES = "LIVE_CHALLENGES";
export const UPCOMING_CHALLENGES = "UPCOMING_CHALLENGES";
export const LIVE_CHALLENGES_SUCCESS = "LIVE_CHALLENGES_SUCCESS";
export const UPCOMING_CHALLENGES_SUCCESS = "UPCOMING_CHALLENGES_SUCCESS";
export const TYPE_LOOKUP = "TYPE_LOOKUP";
export const SUBJECT_LOOKUP = "SUBJECT_LOOKUP";
export const TYPE_LOOKUP_SUCCESS = "TYPE_LOOKUP_SUCCESS";
export const SUBJECT_LOOKUP_SUCCESS = "SUBJECT_LOOKUP_SUCCESS";
export const FETCH_ACTIVE_CHALLENGES = "FETCH_ACTIVE_CHALLENGES";
export const RESET_CHALLENGE_ID = "RESET_CHALLENGE_ID";
export const RESET_CHALLENGE_FORM = "RESET_CHALLENGE_FORM";
export const PUBLISH_CHALLENGE = "PUBLISH_CHALLENGE";
export const UN_PUBLISH_CHALLENGE = "UN_PUBLISH_CHALLENGE";
export const CLOSE_CHALLENGE = "CLOSE_CHALLENGE";
export const RESET_CLOSE_CHALLENGE = "RESET_CLOSE_CHALLENGE";
export const FETCH_PARTICIPANTS = "FETCH_PARTICIPANTS";
export const RESET_JOIN_CHALLENGE = "RESET_JOIN_CHALLENGE";
export const DOWNLOAD_PARTICIPANT = "DOWNLOAD_PARTICIPANT";
export const UPDATE_PUBLISH_STATUS = "UPDATE_PUBLISH_STATUS";
export const SET_ERROR_TAB = "SET_ERROR_TAB";
export const RESET_SPECIFIC_ERROR_TAB = "RESET_SPECIFIC_ERROR_TAB";
export const RESET_ERROR_TAB = "RESET_ERROR_TAB";
export const RESET_PUBLISH = "RESET_PUBLISH";

export const getChallengeListings = (request) => ({
  type: FETCH_CHALLENGES,
  request,
});

export const getLiveChallenges = () => ({
  type: LIVE_CHALLENGES,
});

export const publishChallenge = (requestObject) => ({
  type: PUBLISH_CHALLENGE,
  requestObject,
});

export const updateChallengeStatus = (status) => ({
  type: UPDATE_PUBLISH_STATUS,
  status,
});

export const unPublishChallenge = (challengeId) => ({
  type: UN_PUBLISH_CHALLENGE,
  challengeId,
});

export const resetChallengeId = () => ({
  type: RESET_CHALLENGE_ID,
});

export const resetChallengeForm = () => ({
  type: RESET_CHALLENGE_FORM,
});

export const getUpcomingChallenges = () => ({
  type: UPCOMING_CHALLENGES,
});

export const getTypeLookup = () => ({
  type: TYPE_LOOKUP,
});

export const getSubjectLookup = () => ({
  type: SUBJECT_LOOKUP,
});

export const getChallengeById = (challengeId) => ({
  type: FETCH_CHALLENGE,
  challengeId,
});

export const getLeaderboardbyChallengeId = (filters) => ({
  type: FETCH_LEADERBOARD,
  filters,
});

export const selectMenuAction = (menu) => ({
  type: SELECT_MENU,
  menu,
});

export const updateChallenge = (request) => ({
  type: UPDATE_CHALLENGE,
  request,
});

export const createChallenge = (request) => ({
  type: CREATE_CHALLENGE,
  request,
});

export const editChallenge = (request) => ({
  type: EDIT_CHALLENGE,
  request,
});

export const joinChallengeAction = (request) => ({
  type: JOIN_CHALLENGE,
  request,
});

export const resetJoinChallengeAction = () => ({
  type: RESET_JOIN_CHALLENGE
});

export const submitChallengeFileAction = (request) => ({
  type: SUBMIT_CHALLENGE_SOLUTION,
  request,
});

export const getChallengeSubmissions = (params) => ({
  type: FETCH_CHALLENGE_SUBMISSIONS,
  params,
});

export const updateChallengeSubmissions = (payload) => ({
  type: UPDATE_CHALLENGE_SUBMISSIONS,
  payload,
});

export const resetChallengeSubmission = () => ({
  type: RESET_CHALLENGE_SUBMISSION,
});

export const getLeaderboardListings = (params) => ({
  type: FETCH_LEADERBOARD_LISTINGS,
  params,
});
export const getUsersListings = (params) => ({
  type: FETCH_USERS_LISTINGS,
  params,
});
export const getChallengeByUserId = (payload) => ({
  type: FETCH_USER_CHALLENGE,
  payload,
});

export const getUserActiveChallenges = (payload) => ({
  type: FETCH_ACTIVE_CHALLENGES,
  payload,
});

export const closeChallengeAction = (payload) => ({
  type: CLOSE_CHALLENGE,
  payload,
});

export const resetCloseChallengeAction = () => ({
  type: RESET_CLOSE_CHALLENGE
});

export const getParticipantsAction = (params) => ({
  type: FETCH_PARTICIPANTS,
  params
});

export const downloadParticipantAction = (challengeId) => ({
  type: DOWNLOAD_PARTICIPANT,
  challengeId
});

export const setErrorTab = (tab) => ({
  type: SET_ERROR_TAB,
  tab
})

export const resetErrorTab = () => ({
  type: RESET_ERROR_TAB,
})

export const resetSpecificErrorTab = (tab) => ({
  type: RESET_SPECIFIC_ERROR_TAB,
  tab
})

export const resetPublish = () => ({
  type: RESET_PUBLISH,
})              