import React from 'react';
import { useSelector } from 'react-redux';
import ImageUploader from '../../../theme/imageUploader';
import { Input, Select, Switch } from 'formik-antd';
import Styles from './personalUserInfo.module.scss';
import Home from '../../../asset/icons/home.svg';
import linkedInBlueSolid from '../../../asset/icons/linkedInBlueSolid.svg';
import Flag from '../../../asset/icons/flag.svg';
import TickCircle from '../../../asset/icons/tick-circle.svg';
import Linkedin from '../../../asset/icons/linkedInwhite.svg';
import Icon from '../../Icons';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import { getLinkedInUrl } from '../../../utils';

const { Option } = Select;
const PersonalUserInfo = ({ setUserImage, isAdmin, userImageId, isCompanyAdmin }) => {
  const { countries } = useSelector((state) => state.countries);
  const loading = useSelector((state) => state.countries.loading);
  const { user } = useSelector((state) => state.publicProfile);
  const { t } = useTranslation();

  const connectLinkedin = () => {
    window.location.href = getLinkedInUrl('login_linkedIn');
  };

  return (
    <div className={Styles.profileInfoWrapper}>
      <ImageUploader setUserImage={setUserImage} userImageId={userImageId} />
      <div className={Styles.profileContent}>
        {isAdmin && (
          <div className={`switch-wrapper mb-5 ${Styles.switchContainer}`}>
            <Switch name="is_admin" className={`mr-2 ${Styles.switch}`} disabled={isCompanyAdmin} />
            <span className="show">{t('odp-ad.user.detail-page.is-admin')}</span>
          </div>
        )}
        <div className={Styles.header}>
          <h4>{t('odp-pu.user.profile.public-visible-data')}</h4>
          <span className={Styles.required}>{t('odp-pu.for-companies.-required')}</span>
        </div>
        <div className={Styles.body}>
          <div className={`row ${Styles.inputForm}`}>
            <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
              <span className="inputLabels">{t('odp-pu.label.profile.-username')}</span>
              <Input
                placeholder="Username"
                name="userProfile.display_name"
                className={Styles.input}
                disabled={isCompanyAdmin}
              />
              <span className="error-text"></span>
            </div>
            <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
              <span className="inputLabels">{t('odp-pu.user.profile.occupation')}</span>
              <Input
                placeholder={`${t('odp-pu.user.profile.write-your-current-occupation')}`}
                name="userProfile.occupation"
                className={Styles.input}
                disabled={isCompanyAdmin}
              />
              <span className="error-text"></span>
            </div>
          </div>
          <div className={`row ${Styles.inputForm} mt-3`}>
            <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
              <span className="inputLabels">{t('odp-pu.user.profile.organization')}</span>
              <Input
                placeholder={`${t('odp-pu.user.profile.write-the-name-of-your-organization-or-c')}`}
                name="userProfile.organization"
                className={Styles.input}
                disabled={isCompanyAdmin}
              />
              <span className="error-text"></span>
            </div>
            <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
              <span className="inputLabels">{t('odp-pu.user.profile.city')}</span>
              <Input
                placeholder={`${t('odp-pu.user.profile.enter-your-city')}`}
                name="userProfile.city"
                suffix={<Icon src={Home} />}
                className={Styles.input}
                disabled={isCompanyAdmin}
              />
              <span className="error-text"></span>
            </div>
          </div>
          <div className={`row ${Styles.inputForm} mt-3`}>
            <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
              <span className="inputLabels">{t('odp-pu.user.profile.country')}</span>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Choose your Country"
                suffixIcon={<Icon src={Flag} />}
                style={{
                  width: 100 + '%',

                  borderRadius: 5 + 'px'
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={isCompanyAdmin}
                name="userProfile.country_id">
                {loading === 'sucsuss' &&
                  countries?.listings?.map((country) => {
                    return (
                      <Option value={country.country_id} key={country.country_id}>
                        {country.country_name}
                      </Option>
                    );
                  })}
              </Select>
              <span className="error-text"></span>
            </div>
            {user && !isAdmin && (
              <div className={`col-12 col-md-6 ${Styles.inputContainer}`}>
                <span className="inputLabels">{t('odp-pu.label.profile.-linkedin-profile')}</span>
                {user?.is_linkedin_verified ? (
                  <div className={`row ${Styles.verifiedLinkedin}`}>
                    <div className="col-6">
                      <Icon src={TickCircle} />
                      <p>{t('odp-pu.label.profile.connected-with-linkedin')}</p>
                    </div>
                    <div className="col-6">
                      <Icon src={linkedInBlueSolid} />
                      <Button as="link" type="primary" onClick={connectLinkedin}>
                        {t('odp-pu.user.profile.update')}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className={`${Styles.linkedInVerification}`} onClick={connectLinkedin}>
                    <Icon src={Linkedin} />
                    <p>Connect your Linkedin</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalUserInfo;
