export const FETCH_SPONSORS = "FETCH_SPONSORS";
export const FETCH_SPONSOR = "FETCH_SPONSOR";

export const getSponsorListings = (request) => ({
    type: FETCH_SPONSORS,
    request,
});

export const getSponsorById = (sponsorId) => ({
    type: FETCH_SPONSOR,
    sponsorId,
});
