import update from "immutability-helper";
import { failure, request, success } from "../../utils/fetch";
import { FETCH_SPONSORS } from "../actions/sponsorAction";

const initialState = {
    statusCode: 0,
    error: {},
    listings: [],
    _metadata: {
        totalPages: 0,
        totalResults: 0,
        currentPage: 0,
        pageSize: 10,
    },
}

export function SponsorReducers(state = initialState, action) {
    switch (action.type) {
        case FETCH_SPONSORS:
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: null },
            });
        case request(FETCH_SPONSORS):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: true },
                error: { $set: null },
            });
        case success(FETCH_SPONSORS):
            const { listings, statusCode, _metadata } = action.response;
            return update(state, {
                statusCode: { $set: statusCode },
                listings: { $set: listings },
                _metadata: { $set: _metadata },
                loading: { $set: false },
            });
        case failure(FETCH_SPONSORS):
            return update(state, {
                listings: { $set: [] },
                loading: { $set: false },
                error: { $set: action.error },
            });
        default:
            return state;
    }
}