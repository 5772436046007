import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChallengeCard from '../../components/ChallengeCard';
import ChallengeCardSmall from '../../components/ChallengeCard/CallengeCardSmall/ChallengeCardSmall';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useIsDesktop } from '../../hooks/customHooks';
import { getChallengeListings } from '../../redux-store/actions/ChallengeAction';
import Styles from './landingPageChallenges.module.scss';
import { useTranslation } from 'react-i18next';
import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';
import { PaginationModel, buildParams } from '../../utils';
import LeftArrow from '../../asset/icons/prevslide.svg';
import RightArrow from '../../asset/icons/nextslide.svg';
import Button from '../Button';
import { useHistory } from 'react-router';
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([EffectFade, Navigation, Pagination]);
export const LandingPageChallenges = ({ btnLabel, prevRefNum, nextRefNum }) => {
  const challengeFilters = {};
  const isMobile = useIsDesktop();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { listings: challengeListings } = useSelector((state) => state.challenge);

  challengeFilters.pagination = new PaginationModel();

  useEffect(() => {
    challengeFilters.challengeStatus = [1, 2];
    let filters = buildParams(challengeFilters);
    getChallenges(filters);
  }, []);

  const getChallenges = (filters) => {
    filters.is_published = true;
    filters.order_field = 'start_date';
    filters.order_direction = 'asc';
    dispatch(getChallengeListings(filters));
  };

  return (
    <>
      {challengeListings && challengeListings.length > 0 && (
        <>
          <div className={Styles.wrapperone}>
            <div className={Styles.challengeContainer}>
              <h1 className={Styles.main__heading}>{t('odp-pu.homepage.join-the-challenge')}</h1>
              <div className={'row justify-content-center ' + Styles.main__content}>
                <p className="col-md-7 text-center">
                  {t('odp-pu.homepage.check-out-competitions-dedicated-to-data')}
                </p>
              </div>
              <div
                className={`${!isMobile ? 'd-flex align-items-center' : ''} ${
                  challengeListings.length > 3 ? 'justify-content-center' : 'justify-content-around'
                } ${Styles.challengeCards}`}>
                {challengeListings &&
                  challengeListings.length <= 3 &&
                  challengeListings.map((challenge, idx) => (
                    <div className={Styles.challengeCard} key={idx}>
                      <SwiperSlide>
                        <ChallengeCardSmall
                          {...challenge}
                          previewChallenge={`/challenge/${challenge.competition_id}`}
                        />
                      </SwiperSlide>
                    </div>
                  ))}

                {challengeListings && challengeListings.length > 3 && (
                  <>
                    <Swiper
                      mousewheel={{ sensitivity: 2 }}
                      slidesPerView={3}
                      freeMode={false}
                      navigation={!isMobile}
                      pagination={{
                        clickable: true,
                        // dynamicBullets: true,
                        el: '.custom-pagination',
                        bulletActiveClass: Styles.customPaginationBulletActive,
                        renderBullet: (index, className) => {
                          return `<span class="${className} ${Styles.customPaginationBullet}"></span>`;
                        }
                      }}
                      className="mySwiper"
                      breakpoints={{
                        320: { slidesPerView: 1, centeredSlides: true, slidesPerGroup: 1 },
                        768: {
                          slidesPerView: 2,
                          slidesPerGroup: 2
                        },
                        1024: {
                          slidesPerView: 3,
                          slidesPerGroup: 3
                        }
                      }}>
                      <div className="d-flex justify-content-between">
                        {challengeListings &&
                          challengeListings.length > 0 &&
                          challengeListings.map((challenge, idx) => (
                            <div className={Styles.challengeCard} key={challenge.competition_id}>
                              <SwiperSlide>
                                <ChallengeCardSmall
                                  {...challenge}
                                  previewChallenge={`/challenge/${challenge.competition_id}`}
                                />
                              </SwiperSlide>
                            </div>
                          ))}
                      </div>
                    </Swiper>
                  </>
                )}
              </div>
              <div className={`col-12 ${Styles.customPaginationContainer}`}>
                <div
                  className={`custom-pagination ${Styles.customPagination}`}
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                    marginRight: '10px'
                  }}></div>
              </div>

              <div className="d-flex justify-content-center col-md-12 text-center mt-5">
                <Button type="secondary" onClick={() => history.push('/challenges')}>
                  {btnLabel}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LandingPageChallenges;
