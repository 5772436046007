import { challengeLeaderBoardEndpoint, downloadParticipantsEndpoint, getProtectedFileEndpoint, saveProtectedFileEndpoint } from '../../utils/endPoints';
import { axiosInstance } from '../../configs/axois';

export const saveProtectedFile = async ({ request }) => {
    try {
        const { data, status } = await axiosInstance.post(saveProtectedFileEndpoint(request.challengeId), request.upFile, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (status !== 200) {
            throw `Error Fetching Challenge ${status}`;
        }
        return data;

    } catch (err) {
        throw 'Error Fetching Challenges';
    }
}

export const getProtectedFile = async ({ challengeId, protectedFileId, is_url }) => {
    try {
        const { data, headers } = await axiosInstance.get(getProtectedFileEndpoint(challengeId, protectedFileId), { responseType: !is_url ? 'blob' : 'json' });
        let fileName = headers['content-disposition'];

        if (fileName)
            data.fileName = fileName.split('=')[1];


        return data;
    } catch (err) {
        throw 'Error Fetching Protected File';
    }
}

export const downloadFile = async (fileId) => {
    try {
        const { data } = await axiosInstance.get(`file/${fileId}`);

        return data;
    } catch (err) {
        throw 'Error Fetching Protected File';
    }
}

export const downloadParticipantsFile = async (challengeId) => {
    try {
        let params = {
            challenge_id: challengeId
        }
        const { data, headers } = await axiosInstance.get(downloadParticipantsEndpoint, { params: params, responseType: 'blob' });
        let fileName = headers['content-disposition'] ? headers['content-disposition'].split('=')[1] : null;
        const fileExtension = fileName.split('.').pop();
        return { data, fileName, fileExtension };
    } catch (err) {
        throw 'Error Fetching Protected File';
    }
}

export const downloadLeaderBoardFile = async (challengeId) => {
    try {
        let params = {
            is_export: true
        }
        const { data } = await axiosInstance.get(challengeLeaderBoardEndpoint(challengeId), { params: params });
        return data;
    } catch (err) {
        throw 'Error Fetching Protected File';
    }
}
