import { createSlice } from '@reduxjs/toolkit';
import { loading } from '../../helpers/loading';

const initialState = {
  loading: loading.INIT,
  error: null,
  countries: null,
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    /*get and set countries countrie varify reducers*/
    getCountries: (state) => {
      state.loading = loading.PENDING;
    },
    setCountries: (state, action) => {
      state.loading = loading.SUCSUSS;
      state.countries = action.payload;
    },
    errorCountries: (state) => {
      state.loading = loading.PENDING;
    },
  },
});

export const { getCountries, setCountries, errorCountries } =
  countriesSlice.actions;

export default countriesSlice.reducer;
