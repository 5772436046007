import React from 'react';
import Styles from './userStats.module.scss';
import trophyBlueFilled from '../../asset/icons/trophyBlueFilled.svg';
import teams from '../../asset/icons/teams.svg';
import fileCheck from '../../asset/icons/fileCheck.svg';
import fileListFilled from '../../asset/icons/fileListFilled.svg';
import graph from '../../asset/icons/graph.svg';

import { useIsMobile } from '../../hooks/customHooks';
import { Empty, Skeleton } from 'antd';
const UserStats = ({ user, userLoading }) => {
  const isMobile = useIsMobile();
  return (
    <div className={Styles.userStatsContainer}>
      <div className={Styles.userStats}>
        {!userLoading && (
          <>
            <div className={Styles.stat}>
              <img src={trophyBlueFilled} />
              <p>Global Ranking ({user?.user_stats?.total_users})</p>
              <p>#{user?.user_stats?.global_rank || '-'}</p>
            </div>
            <div className={Styles.stat}>
              <img src={fileCheck} />
              <p>Challenges</p>
              <p>{user?.user_stats?.total_competitions || '-'}</p>
            </div>
            <div className={Styles.stat}>
              <img src={graph} />
              <p>Points</p>
              <p>{user?.user_stats?.total_points || '-'}</p>
            </div>
            {!isMobile && (
              <>
                <div className={Styles.stat}>
                  <img src={fileListFilled} />
                  <p>Entries</p>
                  <p>{user?.user_stats?.total_submissions || '-'}</p>
                </div>
                <div className={Styles.stat}>
                  <img src={teams} />
                  <p>Teams</p>
                  <p>{user?.user_stats?.team_count || '-'}</p>
                </div>
              </>
            )}
          </>
        )}
        {userLoading && <Skeleton paragraph={{ rows: 2 }} />}
      </div>

      {isMobile && (
        <div className={`mt-4 ${Styles.userStats}`}>
          <div className={Styles.stat}>
            <img src={fileListFilled} />
            <p>Entries</p>
            <p>{user?.user_stats?.total_submissions || '-'}</p>
          </div>
          <div className={`${Styles.stat}`}>
            <img src={teams} />
            <p>Teams</p>
            <p>{user?.user_stats?.user_count || '-'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserStats;
