import { StringConverter, BooleanConverter, NumberConverter } from '../../utils';

export class UserProfile {
    user_profile_id = null;
    first_name = null;
    middle_name = null;
    last_name = null;
    display_name = null;
    avatar_url = null;
    github_url = null;
    linkedin_url = null;
    twitter_url = null;
    link_url = null;
    gender = null;
    phone = null;
    email = null;
    mobile = null;
    date_of_birth = null;
    bio = null;
    occupation = null;
    organization = null;
    city = null;
    country_id = null;
    manage_visibility = false;
    is_open_to_work = false;
    about = null;

    constructor() {
        this.user_profile_id = null;
        this.first_name = null;
        this.middle_name = null;
        this.last_name = null;
        this.display_name = null;
        this.avatar_url = null;
        this.github_url = null;
        this.linkedin_url = null;
        this.twitter_url = null;
        this.link_url = null;
        this.gender = null;
        this.phone = null;
        this.email = null;
        this.mobile = null;
        this.date_of_birth = null;
        this.bio = null;
        this.occupation = null;
        this.organization = null;
        this.city = null;
        this.country_id = null;
        this.manage_visibility = false;
        this.is_open_to_work = false;
        this.about = null;
    }

    deserialize(model) {
        this.user_profile_id = StringConverter(model.user_profile_id);
        this.first_name = StringConverter(model.first_name);
        this.middle_name = StringConverter(model.middle_name);
        this.last_name = StringConverter(model.last_name);
        this.display_name = StringConverter(model.display_name);
        this.avatar_url = StringConverter(model.avatar_url);
        this.github_url = StringConverter(model.github_url);
        this.linkedin_url = StringConverter(model.linkedin_url);
        this.twitter_url = StringConverter(model.twitter_url);
        this.link_url = StringConverter(model.link_url);
        this.organization = StringConverter(model.organization);
        this.gender = StringConverter(model.gender);
        this.phone = StringConverter(model.phone);
        this.email = StringConverter(model.email);
        this.mobile = StringConverter(model.mobile);
        this.date_of_birth = new Date(StringConverter(model.date_of_birth)).toISOString();
        this.bio = StringConverter(model.bio);
        this.occupation = StringConverter(model.occupation);
        this.city = StringConverter(model.city);
        this.country_id = StringConverter(model.country_id);
        this.manage_visibility = BooleanConverter(model.manage_visibility || false);
        this.is_open_to_work = BooleanConverter(model.is_open_to_work);
        this.about = StringConverter(model.about);
        return this;
    }
}