import { AButton, SButtonWithIcon } from '../../../../theme/button';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import TeamUploader from '../../../../theme/teamUploader';
import { Link } from 'react-router-dom';
import {
  createTeamApi,
  editTeamApi,
  getTeamById,
  getTeamMembers
} from '../../../../services/team/api';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import './createe.scss';
import { useParams } from 'react-router-dom';
import EditIcon from '../../../../asset/images/team/editIcon.svg';
import FlagIcon from '../../../../asset/images/team/flagIcon.svg';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

const CreateTeam = ({ data }) => {
  const { t } = useTranslation();
  const [about, setabout] = useState(null);
  const [teamname, setteamname] = useState('');
  const [country, setcountry] = useState(null);
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.countries);
  const loading = useSelector((state) => state.countries.loading);
  const { user_id } = useParams();
  const TeamLoading = useSelector((state) => state.Team.loading);
  const fileId = useSelector((state) => state.userProfile.team_avatar_url);
  const [imageId, setImageId] = useState('');
  const [editMode, setEditMode] = useState(false);
  const teamData = useSelector((state) => state.Team.teamData);

  const selectCountry = (value) => {
    setcountry(value);
  };

  useEffect(async () => {
    if (data && data.team_id) {
      await getTeamById(dispatch, data.team_id);

      setEditMode(true);

      let payload = [
        {
          invitee_user_id: data.params.user_id,
          team_id: data.params.teamId
        }
      ];
      getTeamMembers(dispatch, payload);
    }
  }, [data.team_id]);

  useEffect(() => {
    if (teamData) {
      setImageId(teamData.file_id);
      setteamname(teamData.team_name);
      selectCountry(teamData.country_id);
      setabout(teamData.description);
    }
  }, [teamData]);

  const tocreateTeam = (data) => {
    if (data.team_id) {
      editTeamApi(
        dispatch,
        {
          team_name: teamname,
          country_id: country,
          file_id: fileId || imageId,
          description: about
        },
        { user_id },
        data.team_id
      );
      setEditMode(true);
    } else {
      createTeamApi(
        dispatch,
        {
          team_name: teamname,
          country_id: country,
          file_id: fileId,
          description: about
        },
        { user_id }
      );
    }
  };
  const toCancel = (e) => {
    e.preventDefault();
    setEditMode(true);
  };
  return (
    <div className="create__team__form">
      <div className="form-conent">
        <div className="form-header">
          <p className="heading">{t('odp-pu.label.team.team-profile')}</p>
          <div className="team_upload__wrapper">
            {editMode ? (
              <div className="img__wrapper mt-4">
                <img
                  src={process.env.REACT_APP_RESOURCES_URL + teamData.file_id}
                  className="avatar one"
                />
              </div>
            ) : (
              <TeamUploader imageId={imageId} />
            )}
          </div>

          {!editMode && <p className="sub-heading pt-1">1:1 min 150x150 max 300x300</p>}
        </div>
        <div className="form-body">
          <p className="body-heading mt-3">{t('odp-pu.user.profile.public-visible-data')}</p>
          {editMode ? (
            <div>
              <h5>{teamData.team_name}</h5>
              <div className="d-flex mt-1">
                <img src={FlagIcon} />
                <h6 style={{ marginLeft: 10 }}>{teamData.country_name}</h6>
              </div>
              <p className="mt-4 paragraphDescription">{teamData.description}</p>
            </div>
          ) : (
            <>
              <div className="input__wrapper">
                <span className="inputLabels">*Team name</span>
                <Input
                  placeholder="Team name"
                  value={teamname}
                  onChange={(e) => setteamname(e.target.value)}
                />
              </div>
              <div className="select__box">
                <span className="inputLabels">Choose your Country</span>
                <div className="input__wrapper text-area">
                  <Select
                    value={country}
                    showSearch
                    optionFilterProp="children"
                    placeholder="Choose your Country"
                    style={{
                      width: 100 + '%',

                      borderRadius: 5 + 'px'
                    }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={selectCountry}>
                    {loading === 'sucsuss' &&
                      countries.listings.map((country) => {
                        return (
                          <Option value={country.country_id} key={country.country_id}>
                            {country.country_name}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>

              <div className="input__wrapper text-area">
                <span className="inputLabels">{t('odp-pu.menu.footer.about')} (200 Max)</span>
                <TextArea
                  placeholder={`${t('odp-pu.menu.footer.about')} (200 Max)`}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={200}
                  onChange={(e) => {
                    setabout(e.target.value);
                  }}
                  value={about}
                />
              </div>
            </>
          )}
        </div>
        {editMode ? (
          // <AButton label="Edit" onClick={() => setEditMode(false)} />

          <div className="icon_button">
            <SButtonWithIcon
              onClick={() => setEditMode(false)}
              label={t('odp-ad.user.edit.edit')}
              icon={EditIcon}
            />
          </div>
        ) : (
          <div className="form-footer">
            <Link onClick={(e) => toCancel(e)}>Cancel</Link>
            <AButton
              label={t('odp-pu.label.profile.save')}
              onClick={() => tocreateTeam(data)}
              disabled={TeamLoading === 'pending' ? true : false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateTeam;
