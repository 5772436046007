import { put, takeLatest, call } from "redux-saga/effects";
import { FETCH_SPONSORS } from "../actions/sponsorAction";
import { axiosInstance } from "../../configs/axois";
import { sponsorEndpoint } from "../../utils/endPoints";
import { failure, request, success } from "../../utils/fetch";
import { logout } from "../../store/profile";
import { clearStorage } from "../../utils/storage";
import { clearStoreOnLogout } from "../actions/userAction";
import history from "../../utils/history";
import subscribeNotifications from "../../utils/subscribeNotifications";

function* fetchSponsors(params) {
    try {
        let queryParams = {};
        let permissions;
        if ('permission' in params.request)
            permissions = params.request.permission;
        // delete params.request.permission;
        let { is_company_admin, is_admin, dispatch } = permissions || {}
        if (params.request) {
            queryParams = Object.assign({}, params.request);
        }

        delete queryParams.permission;
        yield put({ type: request(FETCH_SPONSORS) });
        const { data } = yield call(axiosInstance.get, sponsorEndpoint, {
            params: queryParams,
        });

        yield put({ type: success(FETCH_SPONSORS), response: data });

        if (data && data.listings.length === 0
            && is_admin == false && is_company_admin == true) {
            let currentLanguage = localStorage.getItem('currentLanguage');
            clearStorage();
            dispatch(logout());
            dispatch(clearStoreOnLogout());
            localStorage.setItem('currentLanguage', currentLanguage);
            history.push("/login");

            const notification = new subscribeNotifications();
            notification.clear();
        }
    } catch (error) {
        yield put({ type: failure(FETCH_SPONSORS), error });
    }
}

export default [
    takeLatest(FETCH_SPONSORS, fetchSponsors),
]