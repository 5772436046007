import React from 'react'
import Styles from './pageNotFound.module.scss';
import NotFound from '../../asset/images/not-found.svg';
import { Button } from 'antd';
const PageNotFound = () => {
    return (
        <div className={Styles.pageNotFoundContainer}>
            <img src={NotFound} width="750" />
            <div className={Styles.details}>
                <h4>Whoops, That Page is not found</h4>
                <p>We are very sorry for the inconvenient, it looks like you are trying to access a page that either has been deleted or never existed.</p>
                <Button>Back to home</Button>
            </div>
        </div>
    )
}

export default PageNotFound
