import React from 'react';
import { useSelector } from 'react-redux';
import Styles from './ChallengeMenu.module.scss';

const ChallengeMenuItem = ({
  label,
  checked,
  onClick,
  disabled = false,
  submissionError,
  designError,
  contentError,
  selectedMenu
}) => {
  const { basic, design, content, submission } = useSelector((state) => state.challengeTabError);

  return (
    <div
      className={`${Styles.label} ${checked ? Styles.activeMenu : ''} ${
        disabled && label != 'Basics' && Styles.disable
      }`}
      onClick={disabled && label != 'Basics' ? undefined : onClick}>
      {label == 'Data & Submission' && submission && <span className="text-danger">* </span>}
      {label == 'Design' && design && <span className="text-danger">* </span>}
      {label == 'Content' && content && <span className="text-danger">* </span>}
      {label}
    </div>
  );
};

export default ChallengeMenuItem;
