import "./content.scss";
import List from "./list";
import Notify from "../../../../theme/notifications";
import { Input as antInput } from "antd";
import { AButton } from "../../../../theme/button";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTeamMembers, sentInvite } from "../../../../services/team/api";
import { emailValidate } from "../../../../helpers/regex";
import Refresh from "../../../../asset/images/team/refresh.svg";
import { useParams } from "react-router-dom";
import { removeSpaces } from "../../../../utils";

import { useTranslation } from "react-i18next";

const { TextArea } = antInput;

const TeamContent = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { user_id } = useParams();
  const [invitation, setinvitation] = useState("");
  const [validEmails, setvalidEmails] = useState([]);
  const [inValidEmails, setinValidEmails] = useState([]);
  const { team_id } = useSelector((state) => state.Team);
  const teamSaved = useSelector((state) => state.Team.teamSaved);
  const teamMembers = useSelector((state) => state.Team.teamMembers);

  useEffect(() => {}, [teamMembers]);

  const tosetInvitation = (validEmailsArr) => {
    let payload = [];
    validEmailsArr.map((email) => {
      payload.push({
        team_id: team_id || data.team_id,
        invitee_email: removeSpaces(email),
        invitee_user_id: user_id,
        notification_id: null,
      });
    });
    sentInvite(dispatch, payload);
  };

  const tokenization = (invitation) => {
    let validEmailsArr = [];
    let invalidEmailsArr = [];
    if (invitation.length !== 0) {
      setinValidEmails([]);
      setvalidEmails([]);
      let fetchEmails = invitation.split(",");

      fetchEmails.map((email) => {
        if (emailValidate(removeSpaces(email))) {
          if (!validEmailsArr.includes(email)) {
            validEmailsArr.push(email);
            setvalidEmails(validEmailsArr);
          }
        } else {
          if (!invalidEmailsArr.includes(email)) {
            invalidEmailsArr.push(email);
            setinValidEmails(invalidEmailsArr);
          }
        }
      });

      if (invalidEmailsArr.length === 0 && validEmailsArr.length > 0) {
        tosetInvitation(validEmailsArr);
      }
    } else {
      setinValidEmails([]);
      setvalidEmails([]);
    }
  };

  const refreshMembersList = () => {
    getTeamMembers(dispatch, [
      { team_id: team_id || data.team_id, invitee_user_id: user_id },
    ]);
  };

  const toggleRefreshButton = (team_id, data) => {
    if (team_id || data.team_id)
      return (
        <img
          src={Refresh}
          alt={Refresh}
          className="refresh"
          onClick={() => refreshMembersList()}
        />
      );
  };

  const toggleNotification = (teamSaved, team_id, data) => {
    if (!teamSaved && !data.team_id) {
      return (
        <div className="notification">
          <Notify
            msg="Your team need to be saved for create & invite member."
            className="error"
            twoToneColor="#eb2f2f"
          />
        </div>
      );
    }
  };

  return (
    <div className="content__wrapper">
      <div className="d-flex justify-content-between mb-4">
        <p className="heading">{t("odp-pu.team-profile.team-members")}</p>

        {toggleRefreshButton(team_id, data)}
      </div>
      <List teamMembers={teamMembers} />

      {toggleNotification(teamSaved, team_id, data)}

      <div className="addMember">
        <div className="addMember_content">
          <p className="addMember_heading">
            {t("odp-pu.label.team.add-members")}
          </p>
          <p className="addMember_sub_heading">
            {t("odp-pu.label.team.seprate-emails")}
          </p>
        </div>
        <div className="addMember_body">
          <TextArea
            placeholder={t("odp-pu.label.team.add-email-team-members")}
            autoSize={{ minRows: 3, maxRows: 5 }}
            maxLength={200}
            onChange={(e) => {
              setinvitation(e.target.value);
            }}
            value={invitation}
          />
        </div>

        <div className="addMember_footer">
          <AButton
            label={t("odp-pu.label.team.send-invitation-")}
            onClick={() => tokenization(invitation)}
            disabled={team_id || data.team_id ? false : true}
          />
        </div>
        <div className="ValidEmail">
          {validEmails.length > 0 && <p>Valid Email</p>}
          <ul>
            {validEmails.length > 0 &&
              validEmails.map((email) => {
                return (
                  <li className="valid__email" key={email}>
                    {email}
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="nonValidEmail">
          {inValidEmails.length > 0 && (
            <p>
              These email(s) are not valid kindly correct them for invitations
            </p>
          )}

          <ul>
            {inValidEmails.length > 0 &&
              inValidEmails.map((email) => {
                return (
                  <li className="error__email" key={email}>
                    {email}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="pendingInvitation">
        {teamMembers &&
          teamMembers.team_invitations &&
          teamMembers.team_invitations.length > 0 && (
            <h3>Pending Invitation</h3>
          )}
        <ul>
          {teamMembers &&
            teamMembers.team_invitations &&
            teamMembers.team_invitations?.length > 0 &&
            teamMembers.team_invitations?.map((email) => (
              <li className="valid__email" key={email.invitee_email}>
                {email.invitee_email}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamContent;
