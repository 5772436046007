import { put, select, takeEvery, takeLatest, call } from "redux-saga/effects";
import { failure, request, success } from "../../utils/fetch";
import {
  CLOSE_CHALLENGE,
  CREATE_CHALLENGE,
  DOWNLOAD_PARTICIPANT,
  EDIT_CHALLENGE,
  FETCH_ACTIVE_CHALLENGES,
  FETCH_CHALLENGE,
  FETCH_CHALLENGES,
  FETCH_CHALLENGE_SUBMISSIONS,
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_LISTINGS,
  FETCH_PARTICIPANTS,
  FETCH_USERS_LISTINGS,
  FETCH_USER_CHALLENGE,
  JOIN_CHALLENGE,
  LIVE_CHALLENGES,
  LIVE_CHALLENGES_SUCCESS,
  PUBLISH_CHALLENGE,
  SUBJECT_LOOKUP,
  SUBJECT_LOOKUP_SUCCESS,
  SUBMIT_CHALLENGE_SOLUTION,
  TYPE_LOOKUP,
  TYPE_LOOKUP_SUCCESS,
  unPublishChallenge,
  UN_PUBLISH_CHALLENGE,
  UPCOMING_CHALLENGES,
  UPCOMING_CHALLENGES_SUCCESS,
} from "../actions/ChallengeAction";
import {
  getChallengeListings,
  createChallenge,
  getChallengeById,
  updateChallenge,
  getLeaderBoardByChallengeId,
  joinChallenge,
  getChallengeSubmissionHistories,
  submitChallengeFile,
  getGlobalLeaderboardListings,
  getUsers,
} from "../services/Challenges";
import { notify } from "../../theme/antNotify";
import { axiosInstance } from "../../configs/axois";
import { challengeEndpoint } from "../../utils/endPoints";

function* fetchLiveChallenge() {
  try {
    const challenges = yield call(axiosInstance.get, challengeEndpoint, {
      params: {
        competition_statuses: 2,
        is_published: true,
        order_field: 'start_date',
        order_direction: 'asc'
      },
    });

    yield put({ type: LIVE_CHALLENGES_SUCCESS, challenges });
  } catch (error) { }
}

function* fetchUpcomingChallenge() {
  try {
    const challenges = yield call(axiosInstance.get, challengeEndpoint, {
      params: { competition_statuses: 1, is_published: true, order_field: 'start_date', order_direction: 'asc' },
    });
    yield put({ type: UPCOMING_CHALLENGES_SUCCESS, challenges });
  } catch (error) { }
}

function* fetchTypeLookup() {
  try {
    const types = yield call(axiosInstance.get, "lookup/competition_types");
    yield put({ type: TYPE_LOOKUP_SUCCESS, types });
  } catch (error) { }
}

function* fetchSubjectLookup() {
  try {
    const subjects = yield call(
      axiosInstance.get,
      "lookup/competition_categories"
    );
    yield put({ type: SUBJECT_LOOKUP_SUCCESS, subjects });
  } catch (error) { }
}

function* fetchChallengeListings(params = null) {
  try {
    yield put({ type: request(FETCH_CHALLENGES) });
    const response = yield getChallengeListings(params);
    yield put({ type: success(FETCH_CHALLENGES), response });
  } catch (error) {
    yield put({ type: failure(FETCH_CHALLENGES), error });
  }
}

function* unpublishChallenge({ challengeId }) {
  try {
    yield put({ type: request(UN_PUBLISH_CHALLENGE) });
    const { data } = yield call(
      axiosInstance.put,
      `challenge/${challengeId}/unpublish`,
      {}
    );
    yield put({ type: success(UN_PUBLISH_CHALLENGE), response: data });
    notify("success", "Challenge successfully unpublished");
  } catch (error) {
    yield put({ type: failure(UN_PUBLISH_CHALLENGE), error });
  }
}

function* publishChallenge({ requestObject }) {
  try {
    yield put({ type: request(PUBLISH_CHALLENGE) });
    const { data } = yield call(
      axiosInstance.put,
      `challenge/${requestObject.competition_id}/publish`,
      requestObject
    );

    yield put({ type: success(PUBLISH_CHALLENGE), response: data });
    notify("success", "Challenge successfully published", "");
  } catch (error) {
    yield put({ type: failure(PUBLISH_CHALLENGE), error });
  }
}

function* createChallengeSaga(requestPayload) {
  try {
    yield put({ type: request(CREATE_CHALLENGE) });
    const response = yield createChallenge(requestPayload);
    yield put({ type: success(CREATE_CHALLENGE), response });
    notify("success", "Action successfully performed", "");
  } catch (error) {
    yield put({ type: failure(CREATE_CHALLENGE), error });
  }
}

function* editChallengeSaga(requestPayload) {
  try {
    yield put({ type: request(EDIT_CHALLENGE) });
    const response = yield updateChallenge(requestPayload);
    yield put({ type: success(EDIT_CHALLENGE), response });
    notify("success", "Action successfully performed", "");
  } catch (error) {
    yield put({ type: failure(EDIT_CHALLENGE), error });
  }
}

function* fetchChallengeById(requestPayload) {
  try {
    yield put({ type: request(FETCH_CHALLENGE) });
    const response = yield getChallengeById(requestPayload);
    yield put({ type: success(FETCH_CHALLENGE), response });
  } catch (error) {
    yield put({ type: failure(FETCH_CHALLENGE), error });
  }
}

function* fetchLeaderBoard({ filters }) {
  try {
    yield put({ type: request(FETCH_LEADERBOARD) });
    const response = yield getLeaderBoardByChallengeId(filters);
    yield put({ type: success(FETCH_LEADERBOARD), response });
  } catch (error) {
    yield put({ type: failure(FETCH_LEADERBOARD), error });
  }
}

function* fetchLeaderboardListings(filters) {
  try {
    yield put({ type: request(FETCH_LEADERBOARD_LISTINGS) });
    const response = yield getGlobalLeaderboardListings(filters);
    yield put({ type: success(FETCH_LEADERBOARD_LISTINGS), response });
  } catch (error) {
    yield put({ type: failure(FETCH_LEADERBOARD_LISTINGS), error });
  }
}

function* fetchUsersListings(filters) {
  try {
    yield put({ type: request(FETCH_USERS_LISTINGS) });
    const response = yield getUsers(filters);
    yield put({ type: success(FETCH_USERS_LISTINGS), response });
  } catch (error) {
    yield put({ type: failure(FETCH_USERS_LISTINGS), error });
  }
}

function* joinChallengeSaga(requestPayload) {
  try {
    yield put({ type: request(JOIN_CHALLENGE) });
    const response = yield joinChallenge(requestPayload);
    yield put({ type: success(JOIN_CHALLENGE), response });
    notify("success", "You have successfully joined Challenge", "");
  } catch (error) {
    yield put({ type: failure(JOIN_CHALLENGE), error });
  }
}

function* challengeHistoriesSaga(requestPayload) {
  try {
    yield put({ type: request(FETCH_CHALLENGE_SUBMISSIONS) });
    const response = yield getChallengeSubmissionHistories(requestPayload);
    yield put({ type: success(FETCH_CHALLENGE_SUBMISSIONS), response });
  } catch (error) {
    yield put({ type: failure(FETCH_CHALLENGE_SUBMISSIONS), error });
  }
}

function* challengeFileSubmissionSaga(requestPayload) {
  try {
    yield put({ type: request(SUBMIT_CHALLENGE_SOLUTION) });
    const response = yield submitChallengeFile(requestPayload);
    yield put({ type: success(SUBMIT_CHALLENGE_SOLUTION), response });
    notify("success", "Challenge Solution successfully submitted");
  } catch (error) {
    yield put({ type: failure(SUBMIT_CHALLENGE_SOLUTION), error });
  }
}

function* fetchChallengesByUserId({ payload }) {
  try {
    yield put({ type: request(FETCH_USER_CHALLENGE) });
    const { data } = yield call(
      axiosInstance.get,
      `user/${payload.userId}/challenge`,
      { params: payload.filters }
    );
    yield put({ type: success(FETCH_USER_CHALLENGE), response: data });
  } catch (error) {
    yield put({ type: failure(FETCH_USER_CHALLENGE), error });
  }
}

function* fetchUserActiveChallenges({ payload }) {
  try {
    yield put({ type: request(FETCH_ACTIVE_CHALLENGES) });
    const { data } = yield call(
      axiosInstance.get,
      `user/${payload.userId}/challenge`,
      { params: payload.filters }
    );
    yield put({ type: success(FETCH_ACTIVE_CHALLENGES), response: data });
  } catch (error) {
    yield put({ type: failure(FETCH_ACTIVE_CHALLENGES), error });
  }
}

function* closeChallengeSaga({ payload }) {
  try {
    yield put({ type: request(CLOSE_CHALLENGE) });
    let challengeId = payload.challengeId;
    delete payload.challengeId;
    const { data } = yield call(
      axiosInstance.put,
      `challenge/${challengeId}/action`,
      payload
    );
    yield put({ type: success(CLOSE_CHALLENGE), response: data });
    notify("success", "Challenge has been successfully Closed");
  } catch (error) {
    yield put({ type: failure(CLOSE_CHALLENGE), error });
  }
}

function* getParticipantsSaga({ params }) {
  try {
    yield put({ type: request(FETCH_PARTICIPANTS) });
    let challengeId = params.challengeId;
    delete params.challengeId;
    const { data } = yield call(
      axiosInstance.get,
      `challenge/${challengeId}/participants`,
      { params }
    );
    yield put({ type: success(FETCH_PARTICIPANTS), response: data });
  } catch (error) {
    yield put({ type: failure(FETCH_PARTICIPANTS), error });
  }
}

function* downloadParticipantSaga({ challengeId }) {
  try {
    let params = {
      challenge_id: challengeId,
    };
    yield put({ type: request(DOWNLOAD_PARTICIPANT) });
    const { data } = yield call(
      axiosInstance.get,
      `challenge/participants/download`,
      { params }
    );
    yield put({ type: success(DOWNLOAD_PARTICIPANT), response: data });
  } catch (error) {
    yield put({ type: failure(DOWNLOAD_PARTICIPANT), error });
  }
}

export default [
  takeLatest(FETCH_CHALLENGES, fetchChallengeListings),
  takeLatest(CREATE_CHALLENGE, createChallengeSaga),
  takeLatest(FETCH_CHALLENGE, fetchChallengeById),
  takeLatest(EDIT_CHALLENGE, editChallengeSaga),
  takeLatest(FETCH_LEADERBOARD, fetchLeaderBoard),
  takeLatest(JOIN_CHALLENGE, joinChallengeSaga),
  takeLatest(FETCH_CHALLENGE_SUBMISSIONS, challengeHistoriesSaga),
  takeLatest(SUBMIT_CHALLENGE_SOLUTION, challengeFileSubmissionSaga),
  takeLatest(FETCH_LEADERBOARD_LISTINGS, fetchLeaderboardListings),
  takeLatest(FETCH_USERS_LISTINGS, fetchUsersListings),
  takeLatest(FETCH_USER_CHALLENGE, fetchChallengesByUserId),
  takeLatest(LIVE_CHALLENGES, fetchLiveChallenge),
  takeLatest(UPCOMING_CHALLENGES, fetchUpcomingChallenge),
  takeLatest(TYPE_LOOKUP, fetchTypeLookup),
  takeLatest(SUBJECT_LOOKUP, fetchSubjectLookup),
  takeLatest(FETCH_ACTIVE_CHALLENGES, fetchUserActiveChallenges),
  takeLatest(PUBLISH_CHALLENGE, publishChallenge),
  takeLatest(UN_PUBLISH_CHALLENGE, unpublishChallenge),
  takeLatest(CLOSE_CHALLENGE, closeChallengeSaga),
  takeLatest(FETCH_PARTICIPANTS, getParticipantsSaga),
  takeLatest(DOWNLOAD_PARTICIPANT, downloadParticipantSaga),
];
