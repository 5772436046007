import "./style.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const AButton = ({
  label = "Button",
  loading = false,
  onClick,
  id = 0,
  type = "regular",
  disabled = false,
  children,
}) => {
  return (
    <>
      <button
        className={
          type === "regular" && disabled === false
            ? "accent"
            : "" || (type === "big" && disabled === false)
              ? "accent big"
              : "" || (type === "regular" && disabled === true)
                ? "accent disabled"
                : "" || (type === "big" && disabled === true)
                  ? "accent big disabled"
                  : ""
        }
        onClick={(e) => onClick(e)}
        id={id}
        disabled={disabled}
      >
        {!loading ? (
          <span>
            <span className="chidl__icon">{children}</span> {label}
          </span>
        ) : (
          <Spin indicator={antIcon} />
        )}
      </button>
    </>
  );
};

export const PButton = ({
  label = "Button",
  onClick,
  id = 0,
  type = "regular",
  disabled = false,
  children,
}) => {
  return (
    <>
      <button
        className={
          type === "regular" && disabled === false
            ? "primary"
            : "" || (type === "big" && disabled === false)
              ? "primary big"
              : "" || (type === "regular" && disabled === true)
                ? "primary disabled"
                : "" || (type === "big" && disabled === true)
                  ? "primary big disabled"
                  : ""
        }
        onClick={(e) => onClick(e)}
        id={id}
        disabled={disabled}
      >
        <span className="chidl__icon">{children}</span> {label}
      </button>
    </>
  );
};

export const SButton = ({
  label = "Button",
  onClick,
  id = 0,
  type = "regular",
  disabled = false,
  children,
}) => {
  return (
    <>
      <button
        className={
          type === "regular" && disabled === false
            ? "secondy"
            : "" || (type === "big" && disabled === false)
              ? "secondy big"
              : "" || (type === "regular" && disabled === true)
                ? "secondy disabled"
                : "" || (type === "big" && disabled === true)
                  ? "secondy big disabled"
                  : ""
        }
        onClick={(e) => onClick(e)}
        id={id}
        disabled={disabled}
      >
        {children && <span className="chidl__icon">{children}</span>}<span>{label}</span>
      </button>
    </>
  );
};

export const SButtonWithIcon = ({
  label = "Button",
  onClick,
  id = 0,
  type = "regular",
  disabled = false,
  children,
  icon = "",
}) => {
  return (
    <>
      <button
        className={
          type === "regular" && disabled === false
            ? "secondy"
            : "" || (type === "big" && disabled === false)
              ? "secondy big"
              : "" || (type === "regular" && disabled === true)
                ? "secondy disabled"
                : "" || (type === "big" && disabled === true)
                  ? "secondy big disabled"
                  : ""
        }
        onClick={(e) => onClick(e)}
        id={id}
        disabled={disabled}
      >
        <span className="icon">
          <img src={icon} alt={icon} />
        </span>
        <span className="chidl__icon">{children}</span> {label}
      </button>
    </>
  );
};
