import update from 'immutability-helper';
import { UPLOAD_PROTECTED_FILE } from '../actions/protectedFileUploadingAction';
import { failure, request, success } from "../../utils/fetch";

const initialState = {
    statusCode: 0,
    protectedFileId: null,
    loading: false,
    progress: 0
}

export function updateProtectedFileUploadStatus(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_PROTECTED_FILE:
            // debugger;
            const { loading, progress, protectedFileId } = action.request;
            return update(state, {
                loading: { $set: loading },
                progress: { $set: progress },
                protectedFileId: { $set: protectedFileId }
            });
        default:
            return state;
    }
}