import React from "react";
import Styles from "./pagination.module.scss";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

const index = ({
  pageSize = 0,
  totalRecord = 0,
  currentPage = 0,
  styles,
  onPaginationChange,
  showPageDifference = true,
}) => {
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <a className={`${Styles.arrows} previous`}>
          <ArrowLeftOutlined /> <span>Previous</span>
        </a>
      );
    }
    if (type === "next") {
      return (
        <a className={`${Styles.arrows} next`}>
          <span>Next</span> <ArrowRightOutlined />
        </a>
      );
    }
    return originalElement;
  }

  return (
    totalRecord > 0 && (
      <div className={`${Styles.paginationContainer} row`} style={styles}>
        {/* <p className={`col-4 ${Styles.pageSizeInfo}`}>
          {showPageDifference && pageSize + " - " + totalRecord}
        </p> */}
        <Pagination
          className="col-12 d-flex align-items-center justify-content-center"
          current={currentPage}
          defaultCurrent={currentPage}
          pageSize={pageSize}
          total={totalRecord}
          itemRender={itemRender}
          onChange={onPaginationChange}
          responsive
        />
      </div>
    )
  );
};

export default index;
